const TermsAndConditionsPopup = ({ closePopup }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <h1>Terms and Conditions</h1>
          <p>Welcome to pakexplore.pk, a website that provides information and services related to tourism in Pakistan. By using this website, you agree to the following terms and conditions:
            <ol>
                <li>Use of the Website: pakexplore.pk is intended for personal and non-commercial use only. You may not use this website for any unlawful purpose or in any way that may damage, disable, overburden, or impair the website.</li>
                <li>Accuracy of Information: While we strive to provide accurate and up-to-date information on this website, we make no guarantees or warranties regarding the accuracy, completeness, or reliability of the information provided. We reserve the right to make changes to the website and its content at any time without prior notice.</li>
                <li>User-Generated Content: pakexplore.pk may allow users to submit content such as reviews, comments, and ratings. By submitting such content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable license to use, modify, and publish the content in any format or medium. You are solely responsible for the content you submit and must ensure that it does not infringe on any intellectual property rights or violate any laws.</li>
                <li>Third-Party Links: This website may contain links to third-party websites that are not under our control. We are not responsible for the content, accuracy, or reliability of these websites and do not endorse or recommend any products, services, or information provided by them.</li>
                <li>Disclaimer of Liability: We are not liable for any direct, indirect, incidental, special, or consequential damages that may arise from the use or inability to use this website, or any errors or omissions in its content. This includes damages arising from viruses, hacking, or other malicious software.</li>
                <li>Indemnification: You agree to indemnify and hold us harmless from any claims, damages, or expenses (including attorneys' fees) arising from your use of this website or any violation of these terms and conditions.</li>
                <li>Intellectual Property: All content on this website, including text, images, graphics, logos, and software, is the property of pakexplore.pk or its licensors and is protected by copyright, trademark, and other intellectual property laws.</li>
                <li>Modifications: We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of this website after any such modifications constitutes your acceptance of the new terms and conditions.</li>
            </ol>
            By using pakexplore.pk, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use this website.
          </p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>
    )
}

export default TermsAndConditionsPopup