import React from "react";
import image from '../images/comingSoon.jpg'

const NoContent = () => {
  return (
    <div style={{ width: "100%", alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <img
        src={image}
        alt="Component Image"
        style={{ display: "block", margin: "0 auto", maxWidth: '500px' }}
      />
      <p style={{ textAlign: "center", maxWidth: '500px', fontFamily: 'cursive' }}>We are currently in the process of adding new and exciting content to our pages. We appreciate your patience as we work to bring you the most up-to-date and informative material possible.</p>
    </div>
  );
};

export default NoContent;
