import {gbDistrictData, kpDistrictData, blnDistrictData, sndDistrictData,pjbDistrictData,ajkDistrictData } from "../dataFiles/data";

function DistrictInfo(props){
    let displayData={};
    const options=[]
    switch(props.province){
        case 'khyber pakhtunkhwa': displayData=kpDistrictData;
        break;
        case 'gilgit baltistan': displayData=gbDistrictData;
        break;
        case 'balochistan': displayData=blnDistrictData;
        break;
        case 'punjab': displayData=pjbDistrictData;
        break;
        case 'azad jammu & kashmir': displayData=ajkDistrictData;
        break;
        case 'sindh': displayData=sndDistrictData;
        break;
        default:break;
    }
    for(const dist in displayData){
        options.push(<option key={dist} value={dist}>{displayData[dist]['name']}</option>)
    }
    return ( 
        <div className='district-info-container'>
            {/* <h2 className='district-name'>District:</h2> */}
            <label className='district-name' for="cars">Select District: </label>
            <select onChange={props.handleDistrictSelect} className="district-select" id="district" value={props.districtName!==''?props.districtName:''}>
            {options}
            <option value=''>None</option>
            </select> 
        </div>
    )
}

export default DistrictInfo;