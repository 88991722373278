import React, { useEffect,useState } from 'react';

const SearchBar = ({ onSearch, handleShowSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(()=>{
    const id=setTimeout(()=>{
      onSearch(searchTerm);
    },700)
    return ()=>{
      clearTimeout(id)
    }
  },[searchTerm])

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className='search-div'>
      <input
        onClick={handleShowSearch}
        className='search-input'
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchBar;
