import './ProvinceMaps.css';
function PjbMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="pjb-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 790.47 962.04" style={{enableBackground:'new 0 0 790.47 962.04'}} xmlSpace="preserve">
                <g>
                    <g>
                        <path style={props.district==='oka'?styles.select:{}} id='oka' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M589.81,564.63c0,0-3.67,3.67-4.83,3.17c-1.17-0.5-6.5-1.33-7.17-0.83c-0.67,0.5-3,4.33-5.17,5.17
                            c-2.17,0.83-5.5-0.33-8,0c-2.5,0.33-3,2.17-5.33,1.5c-1.09-0.31-1.13-0.81-1.52-1.09l0.02-0.08c0,0-0.17-11,0.67-12.67
                            c0.83-1.67,1.33-1.83,0.5-4.33c-0.83-2.5-3.83,0-6-0.33c-2.17-0.33,2-4.33,4-5.33s1.5-1.17,1.33-4.17c-0.17-3-5-5.83-6.83-6.5
                            c-1.83-0.67-4.67-0.67-6.33-0.67c-1.67,0-4.17-1.83-4.83-4.33c-0.67-2.5,0.5-4.5,0.83-7.17c0.33-2.67-3.83,1-5,2
                            c-1.17,1-4.17,5.67-5.33,7c-1.17,1.33-2.33,2.5-4,2.5c-1.67,0-2.83,0.33-4.17,1.17c-1.33,0.83-4-2.67-6.33-3.17
                            c-0.06-0.01-0.12-0.02-0.19-0.04l0.19,0.04c-0.5-2.17,4.5-4.33,6.33-4.5c1.83-0.17,3-2.33,2.17-4.67
                            c-0.83-2.33,2.17-4.5,2.17-6.33c0-1.83-1.83-4.67-2.67-6.33c-0.83-1.67,0.83-2.17,2.17-3.5c1.33-1.33,0.5-2.5,0.5-5
                            s0.33-3.17-2.67-5.17s-4.67,0.33-6.67,0.67s-2-2.5-1.67-4c0.33-1.5,0.17-4.83,1.33-7.83c1.17-3-0.33-3.5-1.83-3.5
                            s-1.33-2.5-1.33-4.17s0-3.33,0-3.33l-0.18,0.2c0.53-0.5,0.96-0.89,1.18-1.03c0.83-0.5,3-3.67,4.33-5.33
                            c1.33-1.67,4.83-0.83,6.83-0.83s3.67-1.5,4-4.17c0.33-2.67,1-2.17,3-3.83c2-1.67,2.17-3.33,3.33-4.83c1.17-1.5,7.5-1.83,10.17-2
                            c2.67-0.17,7,0,8.17-0.17c1.17-0.17,2-2.5,2.33-3.83c0.26-1.05,2.59-1.97,4.59-2.49l0.08,0.15c0,0,3.5,5.5,2.17,6.67
                            c-1.33,1.17-3.33,3.83-0.83,4.67c2.5,0.83,4.17,2,4.5,3.33c0.33,1.33,3.5,4.5,4.83,5c1.33,0.5,2,1.67,1.67,4.33
                            c-0.33,2.67,0.67,6.33,2.5,6.17c1.83-0.17,4-0.17,5.17,0.5c1.17,0.67,2.5,2.5,2.83,4.5c0.33,2,1.17,3.33,2.83,3.83
                            c1.67,0.5,6.33,1,7.67,1.67c1.33,0.67,5.17,3.67,6.83,4c1.67,0.33,11.67,5.33,11.17,7c-0.5,1.67-0.33,2.67,0.5,4
                            c0.83,1.33,3.67,2.5,3.67,4s0.83,1,1.17,2.5c0.33,1.5,2.83,4.67,2.83,4.67l0.23,0.26c-2.25,1.75-4.28,3.54-5.01,5
                            c-1.65,3.3,1.65,11.97-5.37,12.8c-7.02,0.82-10.73-1.65-13.21,6.6c-2.48,8.26,0.41,9.08-4.13,11.15
                            c-4.54,2.06-6.6,6.19-6.6,6.19s1.38,2.02,3.09,4.78"/>
                    </g>
                    <g>
                        <path style={props.district==='att'?styles.select:{}} id='att' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M333.94,184.37c0,0-2.67-0.67-4.5-3.5c-1.83-2.83-2.67-3-2.67-3l-0.08-0.23c0.06,0.02,0,0,0,0
                            s-0.33-2-0.33-6.83c0-4.84,3.33-5.5,5-10c1.67-4.5-2.17-6.67-4-11c-1.83-4.34-1.5-5.5-0.5-7s1-3.5,1.17-6.34
                            c0.16-2.83,4.5-4.66,7.16-5.66c2.67-1,6.67-2.84,8.67-3.34s1.33-3,1.67-6c0.33-3,5.5-4.83,6.33-5.83c0.83-1,4.67-4.17,4.67-6.5
                            c0-2.33-0.17-5,1.66-6.83c1.84-1.84,1.17-10.5,1.67-12.34c0.5-1.83,6.17-9.16,7.67-10.83c1.5-1.67,3-5.33,3-5.33s7,0.66,10.33,1
                            c3.33,0.33,7.67,3.33,8,0c0.33-3.34-1-17.34,1-18c2-0.67,9.33-5.34,11.67-6.34c0,0,3.89-1.13,5.37-2.32
                            c1.49-1.19,4.31-4.9,5.95-4.9c1.63,0,13.52-2.83,14.42,0c0.89,2.82,1.48,4.16,4.16,5.05c2.67,0.89,9.06,1.49,8.17,4.61
                            c-0.89,3.12-5.05,6.54-2.67,7.87c2.37,1.34,5.64,0.6,7.87-1.19c2.23-1.78,4.76-5.64,6.69-6.98c1.94-1.34,3.72-4.61,4.31-0.6
                            c0.6,4.02,0.9,5.5,2.68,6.25c1.78,0.74,5.8,1.48,4.75,3.42c-1.04,1.93-3.41,3.27-1.04,4.45c2.38,1.19,6.25,4.32,3.87,4.76
                            c-1.74,0.33-4.35,1.21-4.87,2.18l-0.33,0.07c0,0-3.17-4.67-3.5-5.83c-0.33-1.17-4.83-6.5-5.67-4.83c-0.83,1.67-1.33,3.67-3,5.17
                            c-1.67,1.5-3.5,7.83-1,10.5c2.5,2.67,5,5.33,4.17,6.83s-1.17,1.83-0.83,4.17c0.33,2.33,2.83,5.83,4.33,5.83s3.67,0.83,4.17,3.33
                            s1,6,2.67,6.5c1.67,0.5,5,3.5,6.83,5.33c1.83,1.83,5.67,6.33,4.67,6.5c-1,0.17-5.67,1.67-7.5,2c-1.83,0.33-9.83,3.83-10.17,5.5
                            c-0.33,1.67-0.33,4.67-1.5,5.33c-1.17,0.67-4,1.67-5,3.67s-4.33,5.83-4.5,8.17c-0.17,2.33,0.33,4.83-1.33,5.17
                            c-1.67,0.33-4.17,3-4.5,3.83c-0.33,0.83-1.67,7.17-3.67,6.33s-5.67-4.67-9.17-2.33c-3.5,2.33-1.83,7.17-7.33,7.83
                            s-11.83,0.83-13.33,1.17c-1.5,0.33-6.83,1.5-9.5,1.67c-2.67,0.17-4.83-0.17-5.5-0.33c-0.67-0.17-3.67-2.83-4.67-0.5
                            s-4.5,5.17-6.17,5.33c-1.67,0.17-8.67-1.5-11.67-1.5s-8.67-0.17-10.67,1.67s-6.67,6.83-9.67,7.33s-8,1.83-10,2.83
                            c-1.54,0.77-4.25,2.82-3.54,4.18"/>
                    </g>
                    <g>
                        <path style={props.district==='mia'?styles.select:{}} id='mia' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M334.6,184.24c0,0-3.45-0.8-5.28-3.64c-1.83-2.83-2.67-3-2.67-3l-0.08-0.07c0,0-5.66-1.67-8.33-3.83
                            c-2.67-2.17-5.5-6.5-5.67-9c-0.16-2.5-0.16-5.84-1.33-7.5c-1.17-1.67-5.67-5.84-7-4c-1.33,1.83-3,4-5,2.33
                            c-2-1.67-6.83-5.17-8.33-3.33c-1.5,1.83-3.84,1.83-1.84,4.83s4.84,7.83,4,9.5c-0.83,1.67-2.83,6.33-0.83,6.83s4.83,3,4.17,5.17
                            c-0.67,2.17-2.84,5.17-6.5,5.67c-3.67,0.5-8.34,2.5-9.5,1.83c-1.17-0.67-1.84-3.17-4.34-2.83c-2.5,0.33-7.33,2.33-10.33,3.83
                            s-8.83,5.33-9.33,7.5c-0.5,2.17-0.5,10.83-0.5,13.5c0,2.67-3,3-2.67,6.17c0.33,3.16,3.17,8,2.67,11.33
                            c-0.5,3.33,2.5,8.33,2.5,11c0,2.67,1.16,8.17,2.33,8.67c1.17,0.5,8.5,3,7.5,5.33c-1,2.33-2.5,5.83-2.5,5.83s3.5,0.84,4.67,0.67
                            c1.16-0.17,5.5-5.67,7.16-5.17c1.67,0.5,4.67,4.34,3.84,6c-0.84,1.67-2.84,7.67-2.84,9.5c0,1.84,1.34,7.67-0.5,8.17
                            c-1.83,0.5-4.33,0.33-5.66,1.5c-1.34,1.17-7,6-8,8.67c-1,2.66-5.5,5.66-5.5,8.16c0,1.55,0.43,4.12,0.13,6.38l0,0
                            c0,0,4.54,4.12,5.37,3.45c0.83-0.67,2-4,3.67-3.5c1.67,0.5,7.17,3.83,8.33,3.33c1.17-0.5,5.17-3.67,7-3.33
                            c1.83,0.33,11.17,2,12.5,1c1.33-1,3.33-2.83,5.83-2.17c2.5,0.67,12,3.83,14.67,3.33c2.11-0.4,7.65-1.31,11.11-0.36l-0.45-0.14
                            c0,0-0.67-5.33-0.33-7.67c0.33-2.33,0.33-4,0.83-5.67c0.5-1.67,11.83-15.17,13.67-17.67s-6-7-6.5-10s3.33-3.33,4.67-4
                            c1.33-0.67,1.67-2.83,3.5-4.17c1.83-1.33,2.67-3.5,6-2.83c0.77,0.15,1.46,0.2,2.24,0.16l0,0c0,0,0.92-2.16,1.42-2.83
                            c0.5-0.67-1.83-5.33-2.33-6.33s-1-4.33-0.5-6.67c0.5-2.33-0.17-3.33-1.67-6c-1.5-2.67-0.67-7-0.33-8.67s5-3.83,7.17-5
                            c2.17-1.17,2.33-1.83,2.5-4.5c0.17-2.67-3.17-3.17-4.33-4.67c-1.17-1.5-3.83-3.17-4.33-4.67s-1.33-5.83-1.67-10.5
                            c-0.33-4.67-7-4.33-11.17-5.33c-1.41-0.34-1.37-0.88-1.3-1.55"/>
                    </g>
                    <g>
                        <path style={props.district==='rwp'?styles.select:{}} id='rwp' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M558.49,149.2c0,0-4.33,1.67-5.67,2.17s-1.83,1.5-2.67,3.17c-0.83,1.67-2-1.5-3.17-2.5
                            c-1.17-1-3.83,0.5-5.83,0.67c-2,0.17-2.83-2-3-3.83c-0.17-1.83-5.33,4.33-5.83,6c-0.5,1.67-1.5,2.83-4,4.17
                            c-2.5,1.33-1,1.5,0.33,4c1.33,2.5,1.17,3.33,0.33,5.5c-0.83,2.17-3,1.5-5.67,1.33c-2.67-0.17-6.33,1.67-10.5,1.67
                            s-6.33,0-9.17,0c-2.83,0-3.67,0.5-4.83,1.33c-1.17,0.83-2.83,0.5-3.5-1.33c-0.67-1.83-3.83,1.5-5.17,3.17
                            c-1.33,1.67-6.17-3.17-6.67-4.83c-0.5-1.67-6.67-2.33-8.33-2.83c-1.67-0.5-2-4.17-2.5-5.33c-0.5-1.17-8,0-11.5,0s-11.83-5-13-6
                            c-1.17-1-2.33-0.5-5-0.33c-2.67,0.17-6.83,1-6.83,1l0,0c1.61-0.63,2.54-5.6,2.83-6.33c0.33-0.83,2.83-3.5,4.5-3.83
                            c1.67-0.33,1.17-2.83,1.33-5.17c0.17-2.33,3.5-6.17,4.5-8.17s3.83-3,5-3.67c1.17-0.67,1.17-3.67,1.5-5.33
                            c0.33-1.67,8.33-5.17,10.17-5.5c1.83-0.33,6.5-1.83,7.5-2c1-0.17-2.83-4.67-4.67-6.5c-1.83-1.83-5.17-4.83-6.83-5.33
                            c-1.67-0.5-2.17-4-2.67-6.5s-2.67-3.33-4.17-3.33s-4-3.5-4.33-5.83c-0.33-2.33,0-2.67,0.83-4.17s-1.67-4.17-4.17-6.83
                            c-2.5-2.67-0.67-9,1-10.5c1.67-1.5,2.17-3.5,3-5.17c0.83-1.67,5.33,3.67,5.67,4.83c0.33,1.17,3.5,5.83,3.5,5.83l0.28,0.03
                            c-0.13,0.33-0.01,0.67,0.46,0.99c1.93,1.34,6.09,2.23,7.88,3.27c0.67,0.39,1.87,1.06,3.24,1.65c-0.14,0.6-0.78,2.72-2.95,3.11
                            c-2.52,0.44-8.76,2.97-7.87,5.2c0.89,2.23,2.82,3.86,4.16,7.58c1.34,3.72,3.71,7.43,5.65,6.99
                            c1.93-0.45,22.14-10.85,22.14-10.85s5.8,9.81,5.8,11.14c0,1.34-0.3,1.94-1.63,2.83c-1.34,0.89-3.42,2.52-3.42,2.52
                            s2.08,3.13,3.42,3.87c1.33,0.74,8.02,3.86,8.47,3.86c0.44,0,8.77-8.02,10.55-10.4c1.78-2.38,1.93-5.5,2.08-7.88
                            c0.15-2.38,0.3-6.39,2.23-6.39c1.93,0,4.91,0.59,5.8-0.45c0.89-1.04,3.12-5.05,3.12-5.05s-0.15-0.3-5.95-3.27
                            c-5.79-2.97-7.13-6.54-12.04-8.17c-4.9-1.64-7.13-3.57-7.13-3.57l-0.14-0.28c3.2-1.46,8.4-3.83,10.54-4.18
                            c2.68-0.45,6.25-2.68,6.99-5.2c0.74-2.53,1.04-5.21,2.53-6.39c1.48-1.19,3.12-2.23,3.71-4.46c0.6-2.23,1.34-4.61,3.12-3.72
                            c1.79,0.89,3.57,2.68,4.91,0.74c1.33-1.92,4.59-6.79,8.13-6.98c0.19,0.94,0.43,1.79,0.71,2.53c1.91,4.96,9.15,16.4,8.01,21.74
                            c-1.14,5.33-3.05,8.38-1.14,11.82c1.9,3.43,3.81,11.81,2.66,13.34c-1.14,1.52-4.19,1.52,0.77,10.67
                            c4.95,9.15,2.67,7.25,2.67,12.97c0,5.72-3.05,10.29-3.82,13.72c-0.76,3.43-0.38,6.86,0.38,9.91c0.77,3.06,1.91,10.68,3.44,12.59
                            c0.08,0.09,0.16,0.2,0.25,0.33L558.49,149.2z"/>
                    </g>
                    <g>
                        <path style={props.district==='jhe'?styles.select:{}} id='jhe' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M558.83,148.93c1.68,2.31,5.87,10.11,5.13,11.94c-0.76,1.9-8.77,7.62-3.81,9.91
                            c4.96,2.29,10.68,4.57,13.34,7.24c1.65,1.65,3.73,2.86,5.98,3.28l-0.1,0.21c0,0-0.17,6.5-1.17,7.5s-7.17,7.5-8.67,8.67
                            c-1.5,1.17-8,6.5-8.67,8.33c-0.67,1.83-2.67,9.5-4.33,11.33c-1.67,1.83-3.83,4-4.83,5.5s-4.83,6.67-5.83,7.17s-16,7.5-18.33,8.5
                            c-2.33,1-11.5,4.33-13.33,4.67c-1.83,0.33-8.83,1-10.67,2.17c-1.83,1.17-10.5,4.33-13,4.83s-7.83-0.17-9.83,1.67
                            s-4.17,3.83-6.33,4s-6.83,2-8.67,3.67c-1.83,1.67-5.83,6.33-9.33,6.83s-7,1.83-10.17,3.5c-2.28,1.2-3.27,1.62-4.76,1.89l0-0.2
                            c0,0-0.53-4.37-0.74-5.56c-0.2-1.19-0.53-4.29,0.37-5.27c0.9-0.98-1.02-4.86-1.47-6.62c-0.45-1.76,0.29-4.09,0.25-5.64
                            c-0.04-1.55-0.13-4.94-0.13-4.94l0.15-0.01c2.59-0.09,8.39-2.35,10.51-3.33c2.17-1-0.17-7.33,0-9.67c0.17-2.33,3-1.67,4.33-1.67
                            c1.33,0,1.83,2.83,4,4.33c2.17,1.5,10.17,1.17,13.33,1.17c3.17,0,6.5-2,8-2.33c1.5-0.33,3.17-4.17,4.5-5.17
                            c1.33-1,5,3.67,7.5,4.83c2.5,1.17,2.33-3.83,3.33-4.5c1-0.67,8.33-3.17,10.17-3.17c1.83,0,6.17-2.17,7.33-2.33
                            c1.17-0.17-0.83-8.17-1.33-9.5c-0.5-1.33-3-3-7.5-3s-3.33-4.5-3-6c0.33-1.5,0.67-3.17,1.83-4.5c1.17-1.33,1.17-3.17,1.83-5.67
                            c0.67-2.5,4-6.17,4-8c0-1.83-2.5-6.17-2.67-7.83c-0.17-1.67,1.5-5.67,1.5-5.67h-0.31c1.56,0,3.28,0,5.65,0
                            c4.17,0,7.83-1.83,10.5-1.67c2.67,0.17,4.83,0.83,5.67-1.33c0.83-2.17,1-3-0.33-5.5c-1.33-2.5-2.83-2.67-0.33-4
                            c2.5-1.33,3.5-2.5,4-4.17s5.67-7.83,5.83-6c0.17,1.83,1,4,3,3.83c2-0.17,4.67-1.67,5.83-0.67c1.17,1,2.33,4.17,3.17,2.5
                            c0.83-1.67,1.33-2.67,2.67-3.17c1.33-0.5,5.67-2.17,5.67-2.17"/>
                    </g>
                    <g>
                        <path style={props.district==='khu'?styles.select:{}} id='khu' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M441.5,271.37c0,0-0.53-4.37-0.74-5.56c-0.2-1.19-0.53-4.29,0.37-5.27c0.9-0.98-1.02-4.86-1.47-6.62
                            c-0.45-1.76,0.29-4.08,0.25-5.64c-0.04-1.55-0.15-4.95-0.15-4.95l0,0c-0.06,0,0.05,0,0,0c-2.5,0-5.33-1-7-3
                            c-1.67-2-3.83-5.5-4.17-6.67c-0.33-1.17,0-4.17-1.83-3.83c-1.83,0.33-6.17,2.33-7.83,3.33c-1.67,1-6,9.67-9.17,5.33
                            c-3.17-4.33-6-8.5-9-9.33c-3-0.83-8.5-1.17-12.5-2c-4-0.83-6.67-2-8-1.17c-1.33,0.83-2.17-0.5-1.67,2s2.33,4.33,0,6.33
                            c-2.33,2-7.67,6.5-9.5,7.17c-1.83,0.67-8.5,1-9.17,2.67c-0.67,1.67-2,3.5-2.83,4c-0.83,0.5-4.67,2-8,1.33
                            c-3.33-0.67-4.17,1.5-6,2.83c-1.83,1.33-2.17,3.5-3.5,4.17c-1.33,0.67-5.17,1-4.67,4s8.33,7.5,6.5,10
                            c-1.83,2.5-13.17,16-13.67,17.67s-0.5,3.33-0.83,5.67c-0.33,2.33,0.33,7.67,0.33,7.67l-0.13,0c1.18,0.24,2.17,0.66,2.8,1.33
                            c2.5,2.67,2.67,4.83,2.67,6.5c0,1.67,1.17,12-1.5,14c-2.67,2-5.67,4.5-5.67,6.33s1,4.33-0.33,6.5c-1.33,2.17-0.83,6.5-3.67,7.17
                            c-2.83,0.67-6,1.17-6.33,3c-0.33,1.83,0.33,4.17,3.67,5.17c3.33,1,5.33,2.17,7.17,5.33c1.83,3.17,2.67,4.83,3,9
                            c0.33,4.17,1.5,7,3.17,8.17c1.67,1.17,6.5,4.83,9.17,7.17c2.67,2.33,5,2.5,7,3.67c2,1.17,3.33,4.33,4.33,5.5
                            c1,1.17,2.17,2.83,3,3.5c0.83,0.67,2,0.83,3.5-0.17s4.17-6.67,4.5-7.83c0.33-1.17,2.17-5.5,3.67-7.5s5.67-5.5,6.67-7.5
                            s2.83-5.67,3.67-5.67c0.83,0,5.5,1.33,6.17,0.67c0.67-0.67,3.51-6.34,4.33-7.5c1.67-2.33,3.5-4.17,3.33-6.17
                            c-0.17-2,1.5-2.83,2.67-3.17c1.17-0.33-0.67-4.83-1-6c-0.33-1.17-1.17-5.33,0-8.17c1.17-2.83-1.17-5.33-1-6.5
                            c0.17-1.17,3-2.83,5.17-4.5c2.17-1.67,3-3.33,3-7.5c0-4.17,0-14.33,0.83-17.83c0.83-3.5,11-10.17,13.83-11.67
                            c2.83-1.5,7.17-6.83,8.67-8.83s12.83-5.83,15.83-6.17c0.91-0.1,1.47-0.16,2.09-0.28L441.5,271.37z"/>
                    </g>
                    <g>
                        <path style={props.district==='cha'?styles.select:{}} id='cha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M351.59,249.59c2.54-0.15,4.75-0.65,5.38-1.03c0.83-0.5,2.17-2.33,2.83-4c0.67-1.67,7.33-2,9.17-2.67
                            c1.83-0.67,7.17-5.17,9.5-7.17c2.33-2,0.5-3.83,0-6.33s0.33-1.17,1.67-2c1.33-0.83,4,0.33,8,1.17s9.5,1.17,12.5,2s5.83,5,9,9.33
                            c3.17,4.33,7.5-4.33,9.17-5.33c1.67-1,6-3,7.83-3.33c1.83-0.33,1.5,2.67,1.83,3.83c0.33,1.17,2.5,4.67,4.17,6.67
                            c1.67,2,4.5,3,7,3s8.5-2.33,10.67-3.33c2.17-1-0.17-7.33,0-9.67c0.17-2.33,3-1.67,4.33-1.67c1.33,0,1.83,2.83,4,4.33
                            c2.17,1.5,10.17,1.17,13.33,1.17c3.17,0,6.5-2,8-2.33c1.5-0.33,3.17-4.17,4.5-5.17c1.33-1,5,3.67,7.5,4.83
                            c2.5,1.17,2.33-3.83,3.33-4.5c1-0.67,8.33-3.17,10.17-3.17c1.83,0,6.17-2.17,7.33-2.33c1.17-0.17-0.83-8.17-1.33-9.5
                            c-0.5-1.33-3-3-7.5-3s-3.33-4.5-3-6c0.33-1.5,0.67-3.17,1.83-4.5c1.17-1.33,1.17-3.17,1.83-5.67c0.67-2.5,4-6.17,4-8
                            c0-1.83-2.5-6.17-2.67-7.83c-0.17-1.67,1.5-5.67,1.5-5.67h-0.21c-1.23,0-2.36,0-3.63,0c-2.83,0-3.67,0.5-4.83,1.33
                            c-1.17,0.83-2.83,0.5-3.5-1.33c-0.67-1.83-3.83,1.5-5.17,3.17c-1.33,1.67-6.17-3.17-6.67-4.83s-6.67-2.33-8.33-2.83
                            c-1.67-0.5-2-4.17-2.5-5.33c-0.5-1.17-8,0-11.5,0s-11.83-5-13-6c-1.17-1-2.33-0.5-5-0.33c-2.67,0.17-6.83,1-6.83,1l-0.29,0.06
                            c-0.18,0.04-0.36,0.02-0.54-0.06c-2-0.83-5.67-4.67-9.17-2.33c-3.5,2.33-1.83,7.17-7.33,7.83s-11.83,0.83-13.33,1.17
                            c-1.5,0.33-6.83,1.5-9.5,1.67c-2.67,0.17-4.83-0.17-5.5-0.33c-0.67-0.17-3.67-2.83-4.67-0.5s-4.5,5.17-6.17,5.33
                            c-1.67,0.17-8.67-1.5-11.67-1.5s-8.67-0.17-10.67,1.67s-6.67,6.83-9.67,7.33s-8,1.83-10,2.83s-6,4.17-1.83,5.17
                            c4.17,1,10.83,0.67,11.17,5.33c0.33,4.67,1.17,9,1.67,10.5s3.17,3.17,4.33,4.67c1.17,1.5,4.5,2,4.33,4.67
                            c-0.17,2.67-0.33,3.33-2.5,4.5c-2.17,1.17-6.83,3.33-7.17,5c-0.33,1.67-1.17,6,0.33,8.67c1.5,2.67,2.17,3.67,1.67,6
                            c-0.5,2.33,0,5.67,0.5,6.67s2.83,5.67,2.33,6.33c-0.5,0.67-1.27,2.37-1.27,2.37L351.59,249.59z"/>
                    </g>
                    <g>
                        <path style={props.district==='guj'?styles.select:{}} id='guj' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M553.96,219.26c0,0,1.67,2.83,1.83,3.83c0.17,1,2.17,3.33,3.33,3.83s2,2.17,3,5.83c1,3.67,7.17,4,8,7.67
                            s1.67,4.67,4,4.67s5.33,2.5,4.67,6.17c-0.67,3.67,0.83,7.33,3,8.17c2.17,0.83,7.83,4.5,8,6.83c0.17,2.33,1.33,4.17,1.33,4.17
                            l-0.43,0.15c2.9-0.57,9.94-2.72,10.83-2.87c1-0.17,4.83-4.17,6.17-6.33c1.33-2.17,3.17-1.83,5.33-2c2.17-0.17,2.33-1.33,4-3.67
                            c1.67-2.33,6.5-4.5,7.5-5.67c1-1.17,4.5-1.17,5.83-1.67c1.33-0.5,6.83-3.83,7.67-3.83c0.83,0,7.17-1.17,9.17-2
                            c2-0.83,6.5-5.67,8.33-7.5c1.83-1.83,3.5-3.67,4.5-6.83c1-3.17-2.5-9-2.5-9l-0.12-0.32c-0.26,0.03-0.25,0.03-0.51,0.06
                            c-1.53,0.18-3.02,0.39-4.4,0.28c-5.42-0.43-9.46-8.34-9.84-9.86c-0.38-1.53-36.98-17.54-38.89-18.68
                            c-1.9-1.15-4.95-5.72-5.71-8.77c-0.77-3.05-10.3-2.29-14.11-1.15c-1.5,0.45-2.99,0.49-4.42,0.21v0.23c0,0-0.17,6.5-1.17,7.5
                            s-7.17,7.5-8.67,8.67c-1.5,1.17-8,6.5-8.67,8.33c-0.67,1.83-2.67,9.5-4.33,11.33c-0.83,0.92-1.79,1.92-2.67,2.88"/>
                    </g>
                    <g>
                        <path style={props.district==='sar'?styles.select:{}} id='sar' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M503.15,245.68c-2.73,1.36-10.04,4-12.3,4.45c-2.5,0.5-7.83-0.17-9.83,1.67s-4.17,3.83-6.33,4
                            c-2.17,0.17-6.83,2-8.67,3.67c-1.83,1.67-5.83,6.33-9.33,6.83s-7,1.83-10.17,3.5c-3.17,1.67-3.83,1.83-6.83,2.17
                            c-3,0.33-14.33,4.17-15.83,6.17s-5.83,7.33-8.67,8.83c-2.83,1.5-13,8.17-13.83,11.67c-0.83,3.5-0.83,13.67-0.83,17.83
                            c0,4.17-0.83,5.83-3,7.5c-2.17,1.67-5,3.33-5.17,4.5c-0.17,1.17,2.17,3.67,1,6.5c-1.17,2.83-0.33,7,0,8.17
                            c0.33,1.17,2.17,5.67,1,6c-1.17,0.33-2.83,1.17-2.67,3.17c0.17,2-1.67,3.83-3.33,6.17c-0.44,0.62-1.37,2.37-2.33,4.12l0,0
                            c0,0,3.49,1.88,4.49,0.88s1.17-1.67,3.17-1.5c2,0.17,10.33,5.5,9.33,7.5s-2.33,7.33-3.67,6c-1.33-1.33-3-3-3-1.17
                            c0,1.83,2.33,4.5-0.67,6.33c-3,1.83-5.33,5-5.17,7c0.17,2,2.83,5.83,3.5,4.67c0.67-1.17,1.5-4.83,3.33-5.83
                            c1.83-1,7.5-4.17,7.67-5.17c0.17-1-0.33-3.67,2.5-3.83c2.83-0.17,6.47-1.87,9-2.5c3.33-0.83,3.33-2.5,4.67-3.67
                            c1.33-1.17,1.67-4.5,4.33-5.67c2.67-1.17,3-2.67,3.67-3.33c0.67-0.67,8.17-1.67,10.5-1.67c2.33,0,4.67-2,8.33-3.67
                            c3.67-1.67,4.5-3.33,6.33-5.17c1.83-1.83,6.83,0.5,9.17,0.5c2.33,0,1.33-2.17,1.5-4.17c0.17-2,1.67-1.33,4.5-0.17
                            c2.83,1.17,2.83,2.17,3.67,2.33c0.83,0.17,3.67,1,5.17,0.33c1.5-0.67,1.17-2.33-0.5-5.5c-1.67-3.17-6.17-2.5-7.17-3.33
                            c-1-0.83-0.5-1.83,2-2.83s2.33-2.67,3.33-5.17s2-1.5,3.67,0c1.67,1.5,2.67,0.17,3.83,0.17c1.17,0,4.5,3.17,5.83,4
                            c1.33,0.83,8.33,1.33,10,1.83c1.67,0.5,2.83,4.5,2.83,4.5l0.36-0.41c0.15-0.26,0.31-0.51,0.48-0.76c1.67-2.5,2-5.83,2-6.67
                            c0-0.83,2.33-4.67,3.83-6.5c1.5-1.83,1.67-3.67,1.83-5.5c0.17-1.83,1.33-2.33,2.17-3.5c0.83-1.17,0.5-2.67,0.83-4.67
                            c0.18-1.09,2.85-3.18,5.69-5.12l-0.19-0.22c0,0-1.83-2.83-2.67-4.67c-0.83-1.83-3.33-3-3.83-6.5s-1.67-5.33-2.67-7.67
                            c-1-2.33-2.67,1-3.83,1.67c-1.17,0.67-2.67,0.5-5-0.5c-2.33-1-2.5-4.17-3-5.83c-0.5-1.67-1.17-0.5-5.5-1
                            c-4.33-0.5,0.5-3,1.83-4.5c1.33-1.5,0-5.83-1-8c-1-2.17-2.67-2.67-4.83-5.17c-2.17-2.5,2.67-4.33,4.17-5.83s6.17-1.17,8.5-1.67
                            c2.33-0.5,0.67-2.17,0.83-3.83c0.17-1.67,4-3.17,5-4c1-0.83-0.17-5.67-1.33-6.17c-1.17-0.5-3.5-1.5-3.5-1.5"/>
                    </g>
                    <g>
                        <path style={props.district==='man'?styles.select:{}} id='man' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M554.25,219.86C554.25,219.86,554.1,219.59,554.25,219.86c0.47,0.84,1.45,2.63,1.58,3.38
                            c0.17,1,2.17,3.33,3.33,3.83s2,2.17,3,5.83c1,3.67,7.17,4,8,7.67s1.67,4.67,4,4.67s5.33,2.5,4.67,6.17
                            c-0.67,3.67,0.83,7.33,3,8.17c2.17,0.83,7.83,4.5,8,6.83c0.17,2.33,1.33,4.17,1.33,4.17l0.03,0.05c-0.59,0.13-0.98,0.2-1.3,0.23
                            c-2,0.17-3.5-0.5-6.17,1.67c-2.67,2.17-6.83,5-7.83,5s-4.67-1-6.17-0.5s-12.5,5.33-16.5,7.5c-4,2.17-6.83,3.67-7.83,5.33
                            c-1,1.67-2.17,2.5-3.83,4c-1.67,1.5-4.67,5.67-7.33,7c-2.67,1.33-6,4.67-8.83,6.33c-1.29,0.76-3.79,2.28-6.17,3.9l-0.16-0.24
                            c0,0-1.83-2.83-2.67-4.67c-0.83-1.83-3.33-3-3.83-6.5s-1.67-5.33-2.67-7.67c-1-2.33-2.67,1-3.83,1.67s-2.67,0.5-5-0.5
                            s-2.5-4.17-3-5.83c-0.5-1.67-1.17-0.5-5.5-1s0.5-3,1.83-4.5c1.33-1.5,0-5.83-1-8c-1-2.17-2.67-2.67-4.83-5.17
                            s2.67-4.33,4.17-5.83s6.17-1.17,8.5-1.67c2.33-0.5,0.67-2.17,0.83-3.83c0.17-1.67,4-3.17,5-4c1-0.83-0.17-5.67-1.33-6.17
                            c-1.17-0.5-3.18-1.36-3.18-1.36l0.17-0.1c0.43-0.2,0.77-0.39,1.01-0.53c1.83-1.17,8.83-1.83,10.67-2.17
                            c1.83-0.33,11-3.67,13.33-4.67c2.33-1,17.33-8,18.33-8.5s4.83-5.67,5.83-7.17C552.47,221.83,553.23,220.97,554.25,219.86"/>
                    </g>
                    <g>
                        <path style={props.district==='sia'?styles.select:{}} id='sia' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M673.52,319.84c-0.1-0.09-0.16-0.14-0.16-0.14s-4.67-0.67-6.17-1.67s-1.5-2.5-1.33-4.5
                            c0.17-2-2.33-4.83-5.67-8c-3.33-3.17-8-1.67-10.83-2c-2.83-0.33-5-5.33-5.83-8.5c-0.83-3.17-4.67-3.33-7.67-3.83
                            s-4-10.17-4.17-12.67c-0.17-2.5-1.67-5.83-2.5-8.17c-0.83-2.33-0.83-5-0.67-8.5c0.17-3.5-0.17-7-0.33-8.83
                            c-0.17-1.83,1.67-4.5,1.67-4.5l-0.46,0.08c0.38-0.07,0.71-0.15,0.96-0.24c1.33-0.5,6.83-3.83,7.67-3.83c0.83,0,7.17-1.17,9.17-2
                            c2-0.83,6.5-5.67,8.33-7.5c1.83-1.83,3.5-3.67,4.5-6.83c1-3.17-2.5-9-2.5-9l-0.17-0.31c1.67-0.2,3.38-0.34,5.05,0.02
                            c5.78,1.24,12.38,8.67,13.62,1.24c1.24-7.43,2.06-9.5,4.13-10.73c2.06-1.24,11.14-3.72,8.25-0.42
                            c-2.89,3.31-6.6,11.56-4.95,14.86c1.65,3.31,5.78,8.67,1.65,11.56c-4.13,2.89-4.13,6.19-4.13,10.74c0,4.54-0.41,12.79,4.96,13.2
                            c5.36,0.42,12.38-0.41,15.68,0.42c2.99,0.74,11.68,6.53,16.08,5.53l0,0.4c0,0-1.33,3.33-3,4.5c-1.67,1.17-4,2.17-4.17,3
                            c-0.17,0.83-2.83,8.83-3.83,10c-1,1.17-0.83,1.83-3,2c-2.17,0.17-4.17-0.83-5.17,0.33s0,3.5-2.33,5.33
                            c-2.33,1.83-2.83,3.33-2.5,4.67c0.33,1.33,3,5.5,1.67,7.5c-1.33,2-2.83,3.17-5.33,4s-7,2.67-8.17,5c-1.17,2.33-2.83,5.5-4.5,6
                            c-0.81,0.24-2.61,1-4.43,1.98"/>
                    </g>
                    <g>
                        <path style={props.district==='guw'?styles.select:{}} id='guw' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M673.48,319.75c-0.1-0.09-0.16-0.14-0.16-0.14s-4.67-0.67-6.17-1.67s-1.5-2.5-1.33-4.5
                            c0.17-2-2.33-4.83-5.67-8c-3.33-3.17-8-1.67-10.83-2c-2.83-0.33-5-5.33-5.83-8.5c-0.83-3.17-4.67-3.33-7.67-3.83
                            s-4-10.17-4.17-12.67c-0.17-2.5-1.67-5.83-2.5-8.17c-0.83-2.33-0.83-5-0.67-8.5c0.17-3.5-0.17-7-0.33-8.83
                            c-0.17-1.83,1.67-4.5,1.67-4.5l-0.41,0.07c-1.61,0.31-4.11,0.48-4.92,1.43c-1,1.17-5.83,3.33-7.5,5.67
                            c-1.67,2.33-1.83,3.5-4,3.67c-2.17,0.17-4-0.17-5.33,2c-1.33,2.17-5.17,6.17-6.17,6.33c-1,0.17-9.67,2.83-11.67,3
                            c-2,0.17-3.5-0.5-6.17,1.67c-2.67,2.17-6.83,5-7.83,5s-4.67-1-6.17-0.5c-0.57,0.19-2.53,1.01-4.96,2.08l-0.04-0.25
                            c0,0,0.33,1,1.83,6.5s3,11,4.83,12.83c1.83,1.83,3.5,5.17,5.67,6.67c2.17,1.5,2.5,3.33,2.17,6.17c-0.33,2.83,0.17,6.33,1.67,7.5
                            c1.5,1.17,2,5.83,0.67,7.17c-1.33,1.33-2.67,5.17,0.33,7.33c3,2.17,8.17,2.33,6.5,4c-1.67,1.67-4.33,3.67-2.67,5
                            c1.67,1.33,2,1.83,2.5,4c0.5,2.17,2.83,4.5,4.67,4.67c1.83,0.17,4.83-1.67,5.67-1.67c0.83,0,2,3.83,4.33,2.83
                            c2.33-1,3.83-2.17,5.5-1c1.67,1.17,7.5,2,9.67,2.33c2.17,0.33,7.5-2.33,7.83-4.17c0.33-1.83,2.67-4.5,5-4.5
                            c2.33,0,4.83,0.33,7.5-0.33c2.67-0.67,2.33-1.17,4.83-1s4.61-0.85,6.67-2.5c1.67-1.33,7.33-6.33,10.33-8.5s2.67-3.17,4.83-4.33
                            c2.17-1.17,1.5-1.83,3.17-4.17c0.86-1.2,2.81-2.49,4.74-3.52"/>
                    </g>
                    <g>
                        <path style={props.district==='haf'?styles.select:{}} id='haf' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M564.61,278.68c0,0,0.33,1,1.83,6.5s3,11,4.83,12.83c1.83,1.83,3.5,5.17,5.67,6.67s2.5,3.33,2.17,6.17
                            c-0.33,2.83,0.17,6.33,1.67,7.5c1.5,1.17,2,5.83,0.67,7.17c-0.93,0.93-1.86,3.08-1.32,5.04l-0.18-0.04c0,0-3.83,2.17-6.17,4
                            c-2.33,1.83-3.5,4-5.17,4.67c-1.67,0.67-3.33,3.33-5.83,4c-2.5,0.67-6.5,1.67-7.5,2.5c-1,0.83-2-0.17-4.67-1.83
                            c-2.67-1.67-2.17,6.5-3.83,8c-1.67,1.5-5.67,0.83-6.83,0.17c-1.17-0.67-2.83,0.17-3.67,1.33c-0.83,1.17-2.83,1.33-4.5,1.17
                            c-1.67-0.17-4.17,1.67-6.5,2.33c-2.33,0.67-3.67,1.17-5,2.67c-1.33,1.5-2,2.17-7,2c-5-0.17-5.33-6.17-5.67-7.5
                            c-0.33-1.33-5.17-5-6.33-5.33c-1.17-0.33-0.17-3.33,1.5-5.83c1.67-2.5,2-5.83,2-6.67c0-0.83,2.33-4.67,3.83-6.5
                            c1.5-1.83,1.67-3.67,1.83-5.5c0.17-1.83,1.33-2.33,2.17-3.5c0.83-1.17,0.5-2.67,0.83-4.67s9-7.33,11.83-9
                            c2.83-1.67,6.17-5,8.83-6.33c2.67-1.33,5.67-5.5,7.33-7s2.83-2.33,3.83-4c1-1.67,3.83-3.17,7.83-5.33
                            c2.5-1.35,7.73-3.75,11.67-5.48"/>
                    </g>
                    <g>
                        <path style={props.district==='nar'?styles.select:{}} id='nar' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M686.46,337.02c0,0-5.83-6.5-6.5-7.83c-0.67-1.33-3-5.17-3.67-6.33c-0.54-0.95-2.19-2.45-2.78-2.98
                            l-0.1-0.16c1.63-0.84,3.16-1.47,3.89-1.69c1.67-0.5,3.33-3.67,4.5-6c1.17-2.33,5.67-4.17,8.17-5s4-2,5.33-4
                            c1.33-2-1.33-6.17-1.67-7.5c-0.33-1.33,0.17-2.83,2.5-4.67c2.33-1.83,1.33-4.17,2.33-5.33s3-0.17,5.17-0.33
                            c2.17-0.17,2-0.83,3-2c1-1.17,3.67-9.17,3.83-10c0.17-0.83,2.5-1.83,4.17-3c1.67-1.17,3-4.5,3-4.5l0.09-0.42
                            c0.54-0.1,0.77-0.24,1.17-0.56c3.72-2.89,9.5-3.3,12.38-3.3c2.89,0,11.15,9.08,15.28,9.08c3.51,0,16.29,8.37,19.25,16.21
                            c0.53,1.37,0.74,2.73,0.56,4.02c-1.24,8.67,1.65,9.49-5.36,13.62c-7.02,4.13-8.26,4.13-15.28,7.84
                            c-7.01,3.72-14.86,6.61-22.7,7.85c-7.84,1.23-11.56,5.36-17.34,7.84c-3.49,1.5-12.1,5.55-19.1,9.72"/>
                    </g>
                    <g>
                        <path style={props.district==='she'?styles.select:{}} id='she' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M687.11,337.31c-4.71,2.78-8.74,5.62-10.07,7.79c-2.83,4.59-11.33,14.33-12.44,22.31l-0.21,0.11
                            c0,0-3.5,2.5-4.67,3.33c-1.17,0.83-2.17,0.83-2.5,2c-0.33,1.17,0,1.83-1.33,3.5c-1.33,1.67-3.83,4.67-4.67,3
                            c-0.83-1.67-4-3.5-5-2.17c-1,1.33-3.5,2.33-4.17,1.17s-4.5-2-5.5,0s-1.83,6.83-3,9.5s-4.67,6.5-5.17,7.17
                            c-0.5,0.67-2.17,1.67-2.83,4c-0.67,2.33-1.17,6-2.17,6.17c-1,0.17-2.17,0.33-2.33,1.83s-2.17,5.5-2.5,3.83s-1.83-5.67-2.83-3.83
                            c-1,1.83-3.67,3.67-3.33,5.17c0.33,1.5,1.17,4.83-1.5,6.33c-2.67,1.5-6.17,0.17-6.17,0.17l-0.17-0.11c0-1.5,0.83-2.17,1.5-3.33
                            c0.67-1.17,0.83-3.17,1-4.67c0.17-1.5-5.67-6.17-8-6.5c-2.33-0.33-1.83-2.5-1.67-3.83c0.17-1.33,2-3.67,4.17-5.67
                            c2.17-2,1.33-3.17-0.33-3.33c-1.67-0.17-3.17-1-3.33-2.33c-0.17-1.33-3.17-2.5-4.67-1.67c-1.5,0.83-7.33,1-10.33,1
                            s-4.67-1.33-5.83-1.83l-0.85-2.52c0.31-0.65,0.27-2.06,0.35-4.48c0.17-4.83-3.17-2.67-3.83-3.17s1.33-6.33,1.67-9.33
                            c0.33-3-0.83-5.17-1.5-6.17c-0.67-1-4.83-2.67-5.67-4.33s1.17-5.83,1.67-7.33s2.5-6,2.5-6l-4.17-3l0.28-0.09
                            c0.47-0.4,0.93-0.73,1.38-0.91c1.67-0.67,2.83-2.83,5.17-4.67c2.33-1.83,6.17-4,6.17-4l0.22-0.14c0.2,0.94,0.61,1.75,1.61,2.47
                            c3,2.17,8.17,2.33,6.5,4c-1.67,1.67-4.33,3.67-2.67,5c1.67,1.33,2,1.83,2.5,4c0.5,2.17,2.83,4.5,4.67,4.67
                            c1.83,0.17,4.83-1.67,5.67-1.67s2,3.83,4.33,2.83c2.33-1,3.83-2.17,5.5-1c1.67,1.17,7.5,2,9.67,2.33s7.5-2.33,7.83-4.17
                            c0.33-1.83,2.67-4.5,5-4.5c2.33,0,4.83,0.33,7.5-0.33c2.67-0.67,2.33-1.17,4.83-1s4.61-0.85,6.67-2.5
                            c1.67-1.33,7.33-6.33,10.33-8.5s2.67-3.17,4.83-4.33c2.17-1.17,1.5-1.83,3.17-4.17c0.95-1.33,3.14-2.71,5.25-3.79l0.13,0.15
                            c0.6,0.53,2.24,2.03,2.78,2.98c0.67,1.17,3,5,3.67,6.33c0.67,1.33,6.5,7.83,6.5,7.83"/>
                    </g>
                    <g>
                        <path style={props.district==='lhr'?styles.select:{}} id='lhr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M669.03,419.73c0,0-5,0.33-6-0.5s-1.17-1-4.33-0.33c-3.17,0.67-1.5,6-1.67,7c-0.17,1-3.83,2-5,2
                            c-1.17,0-3.67,0.83-5.17,1.17c-1.5,0.33-6.67,0.33-8.83,0c-2.17-0.33-4.83,2.17-6.83,3.5c-2,1.33-4-1.67-4.83-2.83
                            c-0.83-1.17-3.17-0.83-4.67-0.83s-2.33,0.83-3,2.17c-0.67,1.33-2.33,1-5.5-0.83s-5.5-1.83-6.5-4.17
                            c-0.96-2.24-2.07-5.85-2.16-7.47l0.17-0.09c0,0,3.5,1.33,6.17-0.17c2.67-1.5,1.83-4.83,1.5-6.33c-0.33-1.5,2.33-3.33,3.33-5.17
                            c1-1.83,2.5,2.17,2.83,3.83s2.33-2.33,2.5-3.83s1.33-1.67,2.33-1.83c1-0.17,1.5-3.83,2.17-6.17c0.67-2.33,2.33-3.33,2.83-4
                            c0.5-0.67,4-4.5,5.17-7.17s2-7.5,3-9.5s4.83-1.17,5.5,0s3.17,0.17,4.17-1.17c1-1.33,4.17,0.5,5,2.17c0.83,1.67,3.33-1.33,4.67-3
                            c1.33-1.67,1-2.33,1.33-3.5c0.33-1.17,1.33-1.17,2.5-2c1.17-0.83,4.67-3.33,4.67-3.33l0.13,0.21c-0.18,1.33-0.16,2.61,0.13,3.79
                            c2.07,8.25,11.56,13.62,11.56,18.57c0,4.95,7.43,21.88,2.89,22.29c-3,0.27-7.27,4.34-10.06,7.78"/>
                    </g>
                    <path style={props.district==='dgk'?styles.select:{}} id='dgk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M205.23,656c0,0-3-8.5-3.67-9.67c-0.67-1.17-0.33-1.83,0.17-3s-5.17-5.33-7.17-6s-4.33,1.33-5.5,2
                        c-1.17,0.67-2.33,5-2.33,8.5s-4.5,9-6.83,10.5c-1.17,0.75-3.79,2.38-6.44,3.13c-2.85,0.8-7.98,2.36-9.32,0.8
                        c-1.34-1.56-5.36-5.13-5.8-8.03c-0.45-2.9-2.9-11.83-4.69-12.05c-1.79-0.22-4.02-2.23-8.03,0c-4.02,2.23-3.79,5.13-6.47,2.23
                        c-2.68-2.9-7.81-8.93-10.04-4.46c-2.23,4.46-0.89,9.59-3.79,11.83c-2.9,2.23-6.47,11.16-5.13,16.29
                        c1.34,5.13,5.8,18.07,3.35,25.88c-2.46,7.81-5.8,8.26-6.92,12.72c-1.12,4.46-3.79,11.38-5.58,15.4
                        c-1.79,4.02-0.67,7.59-4.46,7.36c-3.79-0.22-6.47-0.45-6.47,3.35c0,3.79-0.67,7.14-2.68,10.04c-2.01,2.9-3.35,11.6-2.23,15.4
                        c1.12,3.79,1.56,11.83,0.22,12.94c-1.34,1.12-6.92,4.24-7.36,6.47c-0.45,2.23-1.34,5.8-2.23,8.26c-0.89,2.45-1.12,6.69-3.57,6.92
                        c-2.45,0.22-8.03,1.79-8.48,4.02c-0.45,2.23-0.89,15.17,1.12,16.51c2.01,1.34,8.26,4.69,7.81,12.27l-0.07,0.23
                        c-1.21-0.04-11.96-0.36-13.23-1c-1.33-0.67,0.5-5.17,0.5-5.17s-7.5-0.83-11.33-1.5c-3.83-0.66-9,1.17-10.33,2.34
                        c-1.34,1.16-4.5,2.5-6.67,2.5c-0.4,0-1.41,0.01-2.82,0.03l-0.01-0.37c-0.17-4.5-4.34-14.33-4.34-14.33l11.67-5.17
                        c0,0,12.17-19.66,13.17-22c1-2.33,0.83-8.33-1.34-10.5c-2.16-2.16-4.5-4.5-3.33-6.5c1.17-2,3-4.16,5-5.83c2-1.67,5-5,7.33-8.67
                        c2.34-3.66,6.17-9.33,7.17-10.66c1-1.34,4.67-3,5.83-3.84c1.17-0.83,2.17-2.83,2.67-5c0.5-2.16,2-4.66,2.67-5.66
                        c0.66-1,0.66-6.67,0.66-9.34c0-2.66,1.5-6.33,1.67-7.5c0.17-1.16-5,2-6.67,3.17c-1.66,1.17-3.16,1.67-3-1.83
                        c0.17-3.5-1.66-2.67-4.33-3.84c-2.67-1.16,3.5-6.5,3.83-7c0.34-0.5-6.66-7.83-6.66-7.83s-2,1-4.17,2c-2.17,1-2.83-1.67-2.83-5.83
                        c0-4.17,1.5-4.17,3-6.84c1.5-2.66,2-12.5,2-14.33c0-1.83,0-4,1.16-5.17c1.17-1.16,2.5-2.16,3.67-4.16c1.17-2,3.83-3.5,5.83-4.5
                        s7.5-5,10-6.67c2.5-1.67,5.34-9.5,6.34-12.5s4.66-6,6-6.83c1.33-0.84,4.83-9.34,5.33-12.84s2.67-5.16,4.83-7.66
                        c2.17-2.5,3.34-9.17,3.67-12.34c0.33-3.16,2-8.16,3-9.66s3.5-3.67,6.33-6.34c2.84-2.66,0.34-5-0.5-5c-0.83,0-5.83,1.84-7.5,3.84
                        c-1.66,2-2.66-3.17-2.33-4c0.33-0.84-0.83-2-1.33-4s2-6.67,2.83-9c0.83-2.34,0.33-7.17,1.17-10.5c0.83-3.34,6.16-8.34,7.66-11.84
                        s2.67-11.5,3-16.16c0.34-4.67,0.5-7.34,1-9.17s2.5-2.83,4.34-3.33c1.83-0.5,3-2.5,3.16-3.84c0.17-1.33,3-1.5,5.67-1.5
                        c2.67,0,1.67-5.33,1.67-7c0-1.66,3.16-2.5,5.16-2.83c2-0.33-0.33-4.67-0.5-7.17c-0.16-2.5,0.17-3.33,0.67-4.66
                        c0.5-1.34,0-9.5,0.17-11.34c0.16-1.83,1-5.83,2.66-7.66c1.67-1.84,1-8.17,1.17-11.17c0.06-0.99-0.34-1.9-0.91-2.77l0.08-0.06
                        l13-4.84c0,0,4-9.66,5.5-12.16s6.16-2.5,8-2.67c1.83-0.17,5.66-6.17,5.66-6.17s11,3,12.67,3c1.67,0,3.33-0.83,5.33-1.83
                        s4.67,2.5,7,3.83c0.25,0.14,0.41,0.21,0.61,0.24l0,0c0,0-2.44,2.6-2.11,4.77c0.33,2.17,1.5,3.67,1,7.5
                        c-0.5,3.83,0.67,5.83,1.17,8c0.5,2.17,1.33,6.17,1.17,8.17c-0.17,2,0.33,7.83,0,10.67c-0.33,2.83,0.33,7.17,1.5,7.33
                        c1.17,0.17,2.33,4.17,0.17,6c-2.17,1.83-3.5,4-3.17,4.5c0.33,0.5,2,3.83,1.83,5.67c-0.17,1.83-0.83,4.17-2.33,4.17
                        s-2.17,1.67-2.67,3.33c-0.5,1.67-1,3.17,1.17,4.17c2.17,1,5.33,2.17,5,4.67c-0.33,2.5,0.33,11.5,0.83,15.17
                        c0.5,3.67,2.33,7.33,1.67,10c-0.67,2.67-0.17,5.83-0.83,7.83c-0.67,2-1.5,4,0,7.17c1.5,3.17,2.67,4.5,3.5,6.83
                        c0.83,2.33-0.5,7-0.5,8.67c0,1.67,0.5,9.83-0.5,11.17c-1,1.33-2.33,3.5-1.5,4.83c0.83,1.33,4.5,4.33,4.5,8.33s-1,6,0.5,9
                        s4.67,6.17,4,7.67c-0.67,1.5-1.83,4.5-1.5,6.17c0.33,1.67,2.5,8.83,0.17,10.17c-2.33,1.33-4.67,2.5-4.17,3.83
                        c0.5,1.33,1,5.83,0.83,7.67c-0.17,1.83-4.5,7.33-5.5,8.5s-5.33,7.17-6.33,9.5c-0.73,1.71-1.92,4.51-2.69,6.66"/>
                    
                    <path style={props.district==='bah'?styles.select:{}} id='bah' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M227,713.24c0,0,0.67,3,0,4.33c-0.67,1.33-2.67,4.83-1.67,4.83s3.67,1.33,4.5,4.17
                        c0.83,2.83,2.33,6.33,3.5,7.17c1.17,0.83,4.5,4.17,5.33,0.83c0.83-3.33,1-5.17,2.67-6c1.67-0.83,5.17-2.5,6-1.17
                        c0.83,1.33,0.5-0.5,0.5,2.83s1.17,4.5,0.33,6.33c-0.83,1.83-1.83,3.67-2.33,4.67s-2.67,4.5-1.83,5.67c0.83,1.17,2,2,1.33,3.67
                        c-0.67,1.67-2.83,5.83-2,7.83c0.83,2,3.17,3,2.33,6.67c-0.83,3.67-2,7.83-4.17,12.5c-2.17,4.67-5.33,11.67-6.5,17.83
                        c-1.17,6.17-3.5,14-3.17,17.33c0.33,3.33,3.5,22.33,2.83,26.67c-0.67,4.33,0.17,10.17,0.67,15c0.5,4.83,6.67,10.67,6.83,12.67
                        c0.17,2,2.17,9.83,2.83,14.33c0.67,4.5,2.33,8.5,3.17,12.67c0.83,4.17,1.17,8.33,1.17,10.17s1.67,12.33,1.67,12.33v0.1
                        c13.16-4.72,27.66-9.84,29.95-10.75c4.13-1.65,34.68-0.42,37.16-0.42c2.47,0,26-9.9,27.65-13.21c1.65-3.3,3.72-19.81,4.13-23.11
                        c0.41-3.3,27.66-26.01,30.96-28.07c3.3-2.07,7.84-24.77,9.08-34.26c1.24-9.5,15.28-26.01,18.58-27.66
                        c0.84-0.42,5.23-2.64,11.37-5.78l0.12,0.16c0,0-4.33-8.67-5.17-11.5c-0.83-2.83-3-7.17-2.67-8.83c0.33-1.67-2-7.67-4.17-10.33
                        c-2.17-2.67-3.17-6.83-6.5-8.5c-3.33-1.67-2.67,3.83-4.33,5c-1.67,1.17-3.5-6.17-4-8.67s-1-3.17-0.5-4.67s-0.33-3.83,0-7.33
                        c0.33-3.5,3.5-3.17,6-3.33c2.5-0.17,3.83,3.17,4.83,4.33s7.33,0.17,10.83,0.17s4.5-2.67,5.67-3.67c1.17-1,8-2.33,11.17-2.33
                        c3.17,0,5.5,3.33,7.67,4.5c2.17,1.17,5.17,0.33,7,0c1.83-0.33,4.5-0.5,4.5-1.83c0-1.33-1.5-3.17-4.67-4.5
                        c-3.17-1.33-1-2.17,0-3.17s4.83-0.33,6.67-0.33c1.83,0,3-2.17,0.67-4.17c-2.33-2-2.33,0.33-4.67,0.83c-2.33,0.5-2.17-0.33-3-3.17
                        c-0.83-2.83-2.83,0.67-4.17,1c-1.33,0.33-3.67-2.17-4.5-4.17c-0.83-2,1-4.67,2-6.33c1-1.67,7-5.33,9.17-7.33
                        c2.17-2,0.67-6.83-0.17-8.83c-0.83-2-1.33-4.17-1.33-6.33c0-2.17-0.67-3.33-2.17-4c-1.5-0.67-2.5-2.5-2.5-3.67
                        c0-1.17-1.33-4-1.33-4s-2.33-1.83-2.5-3.33c-0.17-1.5-1.83-2.17-3.17-3.17c-1.33-1-4.67-4.33-6-7.17
                        c-1.33-2.83-0.83-7.83-0.83-7.83s-2.67-1.83-4.33-3c-1.67-1.17-5.33-0.17-7,0.17c-1.67,0.33-5.33-2-7.5-3.17
                        c-2.17-1.17-4.33,0.5-6,1.5c-1.67,1-5,3.33-5.83,4.17c-0.83,0.83-3.33,0.83-5.5,0.33c-2.17-0.5-4.83,4.67-5.33,5.67
                        s1,3,3.17,7.67c2.17,4.67-3.17,1.17-5.17,1.17s-1.5,2-1.5,5.33s-3.5,6.5-4.33,7.67c-0.83,1.17-5,1.33-7,1.33
                        s-2.33,2.83-2.33,5.17c0,2.33-1.83,0.83-4.67-0.83c-2.83-1.67-2.83,0-4.83,2.5s-3.5-1-7.67-1.67c-4.17-0.67-0.83,1-0.33,5
                        s-0.67,0.5-3.33-0.33c-2.67-0.83-2.83-1.5-3.33,0.5s-2.33,1.5-4.33-0.67c-2-2.17-4.17,1.67-5.33,3.17
                        c-1.17,1.5-3.5-0.5-5.33-2.33c-1.83-1.83-3.33,3.17-5.67,5.33c-2.33,2.17-4,1.33-7.33,1.33c-3.33,0-3.33,1.5-4,4.17
                        c-0.67,2.67-3.83,1.17-6.33,2.17s-1.83-0.33-3.83-1.67c-2-1.33-2.67,2.17-3.17,5c-0.5,2.83-3.83,3.83-6.17,0.33
                        c-2.33-3.5-4.67-1.33-6.67,0.33s-4.5-2.67-6.5-3.5s-1.67-2.67-1.5-4c0.17-1.33-3.67,0.83-5.33,1.5c-1.67,0.67-4.5-0.83-5.83-1.17
                        c-1.33-0.33-1.5-1.67-2-3.17s-1.83-0.5-4-0.33c-2.17,0.17-6.5,1.33-8.33,2c-1.83,0.67-4.17,3.5-4.83,3.83
                        c-0.67,0.33-3.33-0.33-3.33-0.33l0,0.26c0.44,0.66,0.5,1.34-0.17,2.08c-1.67,1.83-0.83,7-4.17,7c-3.33,0-9.17,2.67-10.33,3.33
                        c-1.17,0.67-3.33,0.5-1.5,3.17c1.83,2.67,5.83,6.33,3.33,6.17c-2.5-0.17-5-1.5-5,0c0,1.07,0.25,3.42-0.57,3.88"/>
                    <g>
                        <path style={props.district==='kas'?styles.select:{}} id='kas' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M621.56,517.34c0,0-2.5-3.17-2.83-4.67c-0.33-1.5-1.17-1-1.17-2.5s-2.83-2.67-3.67-4
                            c-0.83-1.33-1-2.33-0.5-4c0.5-1.67-9.5-6.67-11.17-7c-1.67-0.33-5.5-3.33-6.83-4c-1.33-0.67-6-1.17-7.67-1.67
                            s-2.5-1.83-2.83-3.83c-0.33-2-1.67-3.83-2.83-4.5c-1.17-0.67-3.33-0.67-5.17-0.5c-1.83,0.17-2.83-3.5-2.5-6.17
                            c0.33-2.67-0.33-3.83-1.67-4.33s-4.5-3.67-4.83-5c-0.33-1.33-2-2.5-4.5-3.33c-2.5-0.83-0.5-3.5,0.83-4.67
                            c1.33-1.17-2.17-6.67-2.17-6.67l-0.11-0.14c0.59-0.16,1.15-0.28,1.61-0.36c2-0.33,4.5-3,5.33-3.5c0.83-0.5,4.17-3.17,4.83-4.5
                            s6-2.5,9.5-3.17c3.5-0.67,6.33-1.67,7.83-2.17s1.5-4.83,1.5-6c0-1.17,3.5-2.33,4.5-2.5c1-0.17,3.67-0.5,4.67-1.5s5-0.83,5-0.83
                            l-0.15-0.35c0.05,0.12,0.1,0.24,0.15,0.35c1,2.33,3.33,2.33,6.5,4.17s4.83,2.17,5.5,0.83c0.67-1.33,1.5-2.17,3-2.17
                            s3.83-0.33,4.67,0.83c0.83,1.17,2.83,4.17,4.83,2.83c2-1.33,4.67-3.83,6.83-3.5c2.17,0.33,7.33,0.33,8.83,0
                            c1.5-0.33,4-1.17,5.17-1.17c1.17,0,4.83-1,5-2c0.17-1-1.5-6.33,1.67-7c3.17-0.67,3.33-0.5,4.33,0.33s6,0.5,6,0.5v0.25
                            c-1.43,1.76-2.46,3.35-2.74,4.19c-0.82,2.48-2.89,16.52-3.71,21.88c-0.83,5.37,5.36,11.56,7.84,9.91
                            c2.48-1.65,7.02-3.72,8.67-4.13c1.65-0.41,13.21,4.95,5.78,8.67c-7.43,3.71-12.8,0.82-16.51,5.78
                            c-3.72,4.95-10.74,11.97-12.8,13.21c-2.06,1.24-8.26,7.02-12.38,13.21c-4.13,6.19-11.56,16.92-14.04,19.4
                            c-1.41,1.41-4.67,3.61-7.53,5.85"/>
                    </g>
                    <g>
                        <path style={props.district==='nan'?styles.select:{}} id='nan' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M606.59,426.24c0,0-4-0.17-5,0.83s-3.67,1.33-4.67,1.5c-1,0.17-4.5,1.33-4.5,2.5c0,1.17,0,5.5-1.5,6
                            s-4.33,1.5-7.83,2.17c-3.5,0.67-8.83,1.83-9.5,3.17s-4,4-4.83,4.5c-0.83,0.5-3.33,3.17-5.33,3.5s-5.83,1.5-6.17,2.83
                            c-0.33,1.33-1.17,3.67-2.33,3.83c-1.17,0.17-5.5,0-8.17,0.17c-2.67,0.17-9,0.5-10.17,2s-1.33,3.17-3.33,4.83
                            c-1.4,1.17-2.14,1.27-2.58,2.14l-0.09,0.03c-2-1.33,1.17-9.33,3.33-11.17c2.17-1.83,1.5-4.17,1.67-5.33
                            c0.17-1.17,0.5-2.83,2.17-4.5c1.67-1.67,4.67-1.5,8.33-1.67c3.67-0.17,6.33-5.33,7.33-7.5c1-2.17,4.67-4.83,6.67-5.33
                            s3-6.5,2.83-8.5c-0.17-2-0.67-3,0.5-5.33c1.17-2.33,0.5-4.83,0.33-7.33c-0.17-2.5-4.5-4-6.5-3.17s-4.5,0.83-6.5,0.67
                            c-2-0.17-2.5,1.83-3.33,3.33c-0.83,1.5-5.83-2.67-6.33-3.83c-0.5-1.17-2.5-2-1.67-5.17c0.83-3.17,0.83-3.67,0.83-6
                            s-1.67-4.17-3.33-5.5c-1.67-1.33-0.33-4.33-0.33-7s-7.33-6.67-8.83-7.17s-0.5-3.83-0.17-5.33s-3.67-3.5-5.5-3.67
                            c-1.83-0.17-3.83-1.5-4.17-2.33c-0.33-0.83-0.83-2.83-0.83-2.83l-0.1-0.25c1.91-0.24,2.51-0.88,3.43-1.91
                            c1.33-1.5,2.67-2,5-2.67c2.33-0.67,4.83-2.5,6.5-2.33c1.67,0.17,3.67,0,4.5-1.17c0.83-1.17,2.5-2,3.67-1.33s5.17,1.33,6.83-0.17
                            c1.67-1.5,1.17-9.67,3.83-8c2.67,1.67,3.67,2.67,4.67,1.83c1-0.83,5-1.83,7.5-2.5c1.77-0.47,3.04-1.87,4.27-2.93l0,0l4.07,2.93
                            c0,0-2,4.5-2.5,6s-2.5,5.67-1.67,7.33s5,3.33,5.67,4.33c0.67,1,1.83,3.17,1.5,6.17c-0.33,3-2.33,8.83-1.67,9.33
                            s4-1.67,3.83,3.17c-0.08,2.42-0.04,3.83-0.35,4.48l0.85,2.52c1.17,0.5,2.83,1.83,5.83,1.83s8.83-0.17,10.33-1
                            c1.5-0.83,4.5,0.33,4.67,1.67c0.17,1.33,1.67,2.17,3.33,2.33c1.67,0.17,2.5,1.33,0.33,3.33c-2.17,2-4,4.33-4.17,5.67
                            c-0.17,1.33-0.67,3.5,1.67,3.83c2.33,0.33,8.17,5,8,6.5c-0.17,1.5-0.33,3.5-1,4.67c-0.67,1.17-1.5,1.83-1.5,3.33
                            c0,1.43,1.06,4.97,2.02,7.32"/>
                    </g>
                    <g>
                        <path style={props.district==='fai'?styles.select:{}} id='fai' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M517.3,361.02c-0.89,0.14-2.09,0.18-3.79,0.12c-2.29-0.08-3.57-1.32-4.35-2.84l-0.15,0.17
                            c0,0-9.5,8.67-10.83,10.83c-1.33,2.17-3.67,7.83-4.83,9.5c-1.17,1.67-5,8.17-8.83,9.67c-3.83,1.5-9.5,5.17-11.5,6.33
                            c-2,1.17-5.83,5.67-6.67,7.17c-0.83,1.5-6,9.33-6.17,10.83c-0.17,1.5-0.83,4.67-0.83,4.67s3,1.33-1.33,5.33
                            c-0.02,0.02-0.04,0.04-0.06,0.06l0.06-0.06c0,0,4,4.33,4.33,5.17c0.33,0.83,0.83,4.83-0.5,7.17c-1.33,2.33-3.17,4.83-4.67,5.5
                            s-2.5,2.33-2.5,4.5s1,8.5-0.33,9.33c-1.33,0.83-5,1-2.83,3.5c2.17,2.5,2.83,1.33,5.17,1.17c2.33-0.17,3.17,2,2.67,3.67
                            c-0.5,1.67-2.17,4.83-3.5,8.33c-1.33,3.5,0,5.5-2.67,6.5c-2.67,1-3.33,2.17-4.67,3.83c-1.33,1.67-4,5.83-0.83,7.33
                            c3.17,1.5,6.17,3.83,6,5.67c-0.17,1.83,0.67,4.17-1.67,5.83c-2.33,1.67-3.67,3.5-3.17,5s2.67,1.5,4,1.17
                            c1.33-0.33,4.33-0.67,5.83-0.67s4,2.33,1.5,3.67c-1.96,1.05-5.27,3.43-6.92,5.38l0.25,0.12c7.17-0.47,8.17-0.29,10.5-0.5
                            c1.83-0.17,4.67,1.5,6.67,1.67s3.17-5.67,2.5-8c-0.67-2.33,6.83-5.67,9.83-5.83c3-0.17,4.33-2.83,4.83-3.5
                            c0.5-0.67,4.33-0.83,6.83-1.33s3-4.17,3.17-7.17c0.17-3,2.67-0.17,4,2.17c1.33,2.33,1.33-6,1.5-7.83
                            c0.17-1.83,3.83-2.5,5.67-2.33c1.83,0.17,5.33-3.83,6.17-4.33s3-3.67,4.33-5.33c1.33-1.67,4.83-0.83,6.83-0.83s3.67-1.5,4-4.17
                            c0.11-0.89,0.11-1.28,0.33-1.67l0,0c-2-1.33,1.17-9.33,3.33-11.17c2.17-1.83,1.5-4.17,1.67-5.33c0.17-1.17,0.5-2.83,2.17-4.5
                            c1.67-1.67,4.67-1.5,8.33-1.67c3.67-0.17,6.33-5.33,7.33-7.5c1-2.17,4.67-4.83,6.67-5.33s3-6.5,2.83-8.5
                            c-0.17-2-0.67-3,0.5-5.33c1.17-2.33,0.5-4.83,0.33-7.33c-0.17-2.5-4.5-4-6.5-3.17s-4.5,0.83-6.5,0.67
                            c-2-0.17-2.5,1.83-3.33,3.33c-0.83,1.5-5.83-2.67-6.33-3.83c-0.5-1.17-2.5-2-1.67-5.17c0.83-3.17,0.83-3.67,0.83-6
                            s-1.67-4.17-3.33-5.5c-1.67-1.33-0.33-4.33-0.33-7s-7.33-6.67-8.83-7.17s-0.5-3.83-0.17-5.33s-3.67-3.5-5.5-3.67
                            c-1.83-0.17-3.83-1.5-4.17-2.33c-0.33-0.83-0.83-2.83-0.83-2.83"/>
                    </g>
                    <g>
                        <path style={props.district==='chi'?styles.select:{}} id='chi' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M502.12,344.14c0,0-1.17-4-2.83-4.5c-1.67-0.5-8.67-1-10-1.83c-1.33-0.83-4.67-4-5.83-4
                            c-1.17,0-2.17,1.33-3.83-0.17s-2.67-2.5-3.67,0s-0.83,4.17-3.33,5.17s-3,2-2,2.83s5.5,0.17,7.17,3.33c1.67,3.17,2,4.83,0.5,5.5
                            c-1.5,0.67-4.33-0.17-5.17-0.33s-0.83-1.17-3.67-2.33c-2.83-1.17-4.33-1.83-4.5,0.17c-0.17,2,0.83,4.17-1.5,4.17
                            c-2.33,0-7.33-2.33-9.17-0.5c-1.83,1.83-2.67,3.5-6.33,5.17c-3.67,1.67-6,3.67-8.33,3.67s-9.83,1-10.5,1.67s-1,2.17-3.67,3.33
                            c-2.67,1.17-3,4.5-4.33,5.67s-1.33,2.83-4.67,3.67c-0.87,0.22-1.75,0.52-2.8,0.88l-0.03,0.12c0,0-0.67,3.5-0.67,5.17
                            c0,1.67,1.17,4.17,1.17,4.17s3,1.83,2.5,4s-1,2.5,0.83,4c1.83,1.5,6.5,3.83,7.33,6.83c0.83,3,2.17,7.17,4,6.33
                            c1.83-0.83,7.33-4.5,7.17-0.67c-0.17,3.83-0.5,4,1.67,6.33c2.17,2.33,2.67,3.5,4,4.83c1.33,1.33,3.83,3.67,5.5,2.33
                            s3-2.5,5-2.17c2,0.33,7.17,1,7.17,1l0.04,0.02c-0.03-0.01-0.04-0.02-0.04-0.02s0.67-3.17,0.83-4.67s5.33-9.33,6.17-10.83
                            c0.83-1.5,4.67-6,6.67-7.17c2-1.17,7.67-4.83,11.5-6.33c3.83-1.5,7.67-8,8.83-9.67c1.17-1.67,3.5-7.33,4.83-9.5
                            c1.33-2.17,10.83-10.83,10.83-10.83l0.17-0.13c-1.02-1.83-1.15-3.96-1.34-4.71c-0.33-1.33-5.17-5-6.33-5.33
                            c-1.03-0.29-0.37-2.65,0.93-4.92"/>
                    </g>
                    <g>
                        <path style={props.district==='bha'?styles.select:{}} id='bha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M358.69,394.34c-0.82,1.49-1.97,3.95-1.29,4.96c1,1.5,3.67,2.67,3.5,5.5c-0.17,2.83,0.17,6.5,0.67,9.33
                            s1,11,0.5,12.33c-0.5,1.33-2.33,8.33-2.33,8.33s-12.17-2.33-14.33-1.5c-2.17,0.83-4.83,3.33-5.83,3.5
                            c-1,0.17-2.67,2.67-4.67,6.17c-0.98,1.72-1.64,3.47-1.98,5.11l-0.35,0.06c-2.67-3.33-6-2.5-8.5-2s-5.33-4-8.83-7
                            s-14-2.33-15.83-1.5c-1.83,0.83-3.17,9.17-3.83,10.33c-0.67,1.17-5.5,1.5-8.67,1.67c-3.17,0.17-5.5,0.17-7.83,0.67
                            c-2.33,0.5-3.83-0.33-4.67-2.17c-0.83-1.83-0.83-5.17-1.67-8.67c-0.83-3.5-1.33-2-3.33-2s-6.17-7.67-7-10.67
                            c-0.83-3-1.17-4.17-1.17-5.33c0-1.17-5.5,0.5-8.33-0.33c-2.83-0.83-7.17-1.67-10.33-1.67c-3.17,0-8.5,0.67-10.67,1.17
                            c-2.17,0.5-2.5-1.17-2.17-2.83c0.33-1.67,0.33-2.33-1-2.33c-1.33,0-6,4-7.5,4.67c-1.15,0.51-5.84-0.55-7.96-1.07l-0.11-0.03
                            c0.63-0.75,1.37-1.22,2.56-1.91c2.34-1.33,2.34-3.17,3.17-5.33c0.83-2.17,2.17-2,4.67-3s1-5.84,0.83-7.67
                            c-0.17-1.83,1.17-4.67,0.5-6.5c-0.67-1.83-1.17-3.83-0.5-6.83c0.67-3,0.33-4.84,0.33-8.5c0-3.67,1.5-4.34,2.34-6
                            c0.83-1.67,6.33-9.34,8-11.5c1.66-2.17,7.33-11.84,7.66-13.67c0.34-1.83,3.17-3.67,3.17-3.67s0.67-17.16,0.5-18.5
                            c-0.17-1.33,1.17-3.16,2.33-4.16c1.17-1,2.5-6.67,3-11.17s3.34-6.5,5.84-7.5c1-0.4,1.44-1.62,1.61-3.11l0.05-0.14
                            c0,0,4.51,4.08,5.34,3.41c0.83-0.67,2-4,3.67-3.5c1.67,0.5,7.17,3.83,8.33,3.33c1.17-0.5,5.17-3.67,7-3.33
                            c1.83,0.33,11.17,2,12.5,1c1.33-1,3.33-2.83,5.83-2.17s12,3.83,14.67,3.33c2.67-0.5,10.83-1.83,13.33,0.83
                            c2.5,2.67,2.67,4.83,2.67,6.5c0,1.67,1.17,12-1.5,14c-2.67,2-5.67,4.5-5.67,6.33s1,4.33-0.33,6.5c-1.33,2.17-0.83,6.5-3.67,7.17
                            c-2.83,0.67-6,1.17-6.33,3c-0.33,1.83,0.33,4.17,3.67,5.17c3.33,1,5.33,2.17,7.17,5.33c1.83,3.17,2.67,4.83,3,9
                            c0.33,4.17,1.5,7,3.17,8.17c1.67,1.17,6.5,4.83,9.17,7.17c2.67,2.33,5,2.5,7,3.67c2,1.17,3.33,4.33,4.33,5.5
                            c1,1.17,2.17,2.83,3,3.5c0.75,0.6,1.77,0.79,3.05,0.1"/>
                    </g>
                    <g>
                        <path style={props.district==='lay'?styles.select:{}} id='lay' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M333.14,447.64c-0.39,1.78-0.41,3.43-0.06,4.75c0.67,2.5,5.17,9.67,4.17,13.17s-4.67,4.67-4.17,7.5
                            c0.5,2.83,6.33,5.67,5.5,8c-0.83,2.33-2.33,4-0.5,5.83s5,1.5,3.67,3.5c-1.33,2-4.67,3.5-5,5c-0.33,1.5-0.67,4-2.83,4.67
                            c-2.17,0.67-3.5,1.33-4.83,2.5c-1.33,1.17-5.17,4.5-5.33,6.33c-0.17,1.83-0.17,4.17-1.33,5c-1.17,0.83-1.5,1.17-1.83,2.33
                            c-0.33,1.17,0,6.5-1.5,7.83c-1.5,1.33-7.17,1-7.17,1s-1.93,4.74-5.17,4.83c-5.5,0.17-8.83-2.5-14.5-6.83
                            c-5.67-4.33-12.33-13.17-14.67-15c-2.33-1.83-12.67-4.17-16-4.17s-4.17,4.17-4.67,7.17c-0.5,3-0.67,4.5-0.67,5.5
                            s-4.83,3.33-7,3.5c-2.17,0.17-3.83,0.33-6,0.33c-2.17,0-7-1.83-10.83-2.5c-3.83-0.67-11.33,0.67-13.33,1.5s-5.55,1.32-5.55,1.32
                            l0,0c-0.5-3.8-1.11-12.37-0.79-14.82c0.33-2.5-2.83-3.67-5-4.67c-2.17-1-1.67-2.5-1.17-4.17c0.5-1.67,1.17-3.33,2.67-3.33
                            s2.17-2.33,2.33-4.17c0.17-1.83-1.5-5.17-1.83-5.67c-0.33-0.5,1-2.67,3.17-4.5c2.17-1.83,1-5.83-0.17-6
                            c-1.17-0.17-1.83-4.5-1.5-7.33c0.33-2.83-0.17-8.67,0-10.67c0.17-2-0.67-6-1.17-8.17c-0.5-2.17-1.67-4.17-1.17-8
                            c0.5-3.83-0.67-5.33-1-7.5c-0.33-2.17,2.11-4.77,2.11-4.77l0,0l0,0c1.77,0.35,1.76-3.43,2.06-5.4c0.18-1.2,0.52-1.98,1.27-2.77
                            l0,0c1.87,0.47,6.84,1.64,8.07,1.1c1.5-0.67,6.17-4.67,7.5-4.67c1.33,0,1.33,0.67,1,2.33c-0.33,1.67,0,3.33,2.17,2.83
                            c2.17-0.5,7.5-1.17,10.67-1.17c3.17,0,7.5,0.83,10.33,1.67c2.83,0.83,8.33-0.83,8.33,0.33c0,1.17,0.33,2.33,1.17,5.33
                            s5,10.67,7,10.67s2.5-1.5,3.33,2c0.83,3.5,0.83,6.83,1.67,8.67s2.33,2.67,4.67,2.17c2.33-0.5,4.67-0.5,7.83-0.67
                            s8-0.5,8.67-1.67c0.67-1.17,2-9.5,3.83-10.33s12.33-1.5,15.83,1.5s6.33,7.5,8.83,7s5.83-1.33,8.5,2"/>
                    </g>
                    <g>
                        <path style={props.district==='jha'?styles.select:{}} id='jha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M359.53,392.9c0,0-3,4.67-2,6.17s3.67,2.67,3.5,5.5c-0.17,2.83,0.17,6.5,0.67,9.33s1,11,0.5,12.33
                            c-0.5,1.33-2.33,8.33-2.33,8.33s-12.17-2.33-14.33-1.5c-2.17,0.83-4.83,3.33-5.83,3.5c-1,0.17-2.67,2.67-4.67,6.17
                            s-2.67,7.17-2,9.67c0.67,2.5,5.17,9.67,4.17,13.17s-4.67,4.67-4.17,7.5c0.5,2.83,6.33,5.67,5.5,8c-0.83,2.33-2.33,4-0.5,5.83
                            c1.83,1.83,5,1.5,3.67,3.5c-1.33,2-4.67,3.5-5,5c-0.33,1.5-0.67,4-2.83,4.67c-2.17,0.67-3.5,1.33-4.83,2.5
                            c-1.33,1.17-5.17,4.5-5.33,6.33c-0.17,1.83-0.17,4.17-1.33,5c-1.17,0.83-1.5,1.17-1.83,2.33c-0.33,1.17,0,6.5-1.5,7.83
                            c-0.19,0.17-0.45,0.31-0.75,0.43l-0.42,0.07c0,0,4.17,3.67,6.67,4.83s5.83-1.17,7-1.67c1.17-0.5,3.33-4.83,4.17-6.33
                            c0.83-1.5,6.17-3.5,9.17-5.5s4.5,0.83,4.5,0.83s2.17,0.83,4,0.67c1.83-0.17,2.5,0.17,3.5,0.33c1,0.17,2.83-0.67,3.17-2.67
                            c0.33-2,1.67-2.33,3.83-2.33c2.17,0,3.33-2.83,4.33-4c1-1.17,11.5,1.17,12.67,1.83c1.17,0.67,4,0,6.17-0.67
                            c2.17-0.67,6.67,0.67,8,0.83c1.33,0.17,3.83,0.17,6.17,0.5c2.33,0.33,1.83-1,1.67-4c-0.17-3-0.33-6-1.17-8
                            c-0.83-2-0.83-2.67-3.33-2.83s-2.67-1.67-3.5-2.5c-0.83-0.83-2.67-0.17-3.83-0.83c-1.17-0.67,1-4.67,1.83-5.67
                            c0.83-1,5-2.83,8-4.83s3.5-8.17,5.33-11.83c1.83-3.67,1.67-4.83,3.83-6.33c2.17-1.5,2.67-2.17,3.33-4.33
                            c0.67-2.17,1.17-3.83,3.17-5c2-1.17,2.33-2.5,2.33-3.67s-1-3.67,1-5s0.5-3,0.17-5.33c-0.33-2.33,2.83-1.67,6.5-3
                            c3.67-1.33,1.83-1.83,4-1.83c2.17,0,8.67,1,10.83,1c2.17,0,3.17-2.83,6-4.33c2.83-1.5,6.17-5.83,10.5-9.83
                            c4.33-4,1.33-5.33,1.33-5.33s0.01-0.05,0.03-0.13l-0.03,0.13c0,0-5.17-0.67-7.17-1c-2-0.33-3.33,0.83-5,2.17
                            c-1.67,1.33-4.17-1-5.5-2.33c-1.33-1.33-1.83-2.5-4-4.83c-2.17-2.33-1.83-2.5-1.67-6.33c0.17-3.83-5.33-0.17-7.17,0.67
                            c-1.83,0.83-3.17-3.33-4-6.33c-0.83-3-5.5-5.33-7.33-6.83c-1.83-1.5-1.33-1.83-0.83-4s-2.5-4-2.5-4s-1.17-2.5-1.17-4.17
                            c0-1.67,0.67-5.17,0.67-5.17l-0.03-0.1c-2.03,0.7-4.26,1.49-6.14,1.6c-2.83,0.17-2.33,2.83-2.5,3.83
                            c-0.17,1-5.83,4.17-7.67,5.17c-1.83,1-2.67,4.67-3.33,5.83c-0.67,1.17-3.33-2.67-3.5-4.67c-0.17-2,2.17-5.17,5.17-7
                            c3-1.83,0.67-4.5,0.67-6.33s1.67-0.17,3,1.17c1.33,1.33,2.67-4,3.67-6s-7.33-7.33-9.33-7.5c-2-0.17-2.17,0.5-3.17,1.5
                            s-4.51-0.84-4.51-0.84l0,0c-0.9,1.65-1.65,3.01-1.99,3.34c-0.67,0.67-5.33-0.67-6.17-0.67c-0.83,0-2.67,3.67-3.67,5.67
                            s-5.17,5.5-6.67,7.5s-3.33,6.33-3.67,7.5c-0.33,1.17-3,6.83-4.5,7.83c-0.07,0.05-0.15,0.09-0.22,0.14"/>
                    </g>
                    <g>
                        <path style={props.district==='bnr'?styles.select:{}} id='bnr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M589.62,564.46c0,0-3.67,3.67-4.83,3.17c-1.17-0.5-6.5-1.33-7.17-0.83c-0.67,0.5-3,4.33-5.17,5.17
                            s-5.5-0.33-8,0s-3,2.17-5.33,1.5c-2.33-0.67,0.17-2.17-6.33-0.67s-5.83,0.67-8.17,2.83c-2.33,2.17-2.67,5.83-5,5.83
                            c-2.33,0-3.17-0.33-3.5,1.67c-0.33,2,1.5,5.67-2.33,5.5c-3.83-0.17-6-1.33-6.17,1.83c-0.17,3.17,0.67,10.17-1.67,8.83
                            c-2.33-1.33-5.67-4.67-7.67-4.5c-2,0.17-6.83,2.5-8.5,3.83c-1.67,1.33-1.83,3.67-4.83,3.67s-5.83,0.17-7.5,1.5
                            c-1.67,1.33-4.67,4.5-6,3.83c-1.33-0.67-7.17-5.5-7.5-2.17c-0.33,3.33,1.33,4.67-0.5,7.17c-1.59,2.17-3.94,3.34-4.64,1.64
                            l-0.2,0.03c0,0-3,2.83-4.17,0.67c-1.17-2.17-0.83-5.83-4-4.83c-3.17,1-5.33,2.17-5.33,4c0,1.83-2.17,5.83-3.5,2.67
                            c-1.33-3.17-7.33-3.5-7.83-2.5s0,5.67-0.5,7.83c-0.5,2.17-2,3.67-3,2.33s-4.67-3.67-5.67-1.33c-1,2.33-0.67,7-2.33,6.83
                            c-1.67-0.17-3.67-0.83-5.17,0.17s-3.33,2.67-4.33,2.67s-3.83-1.33-4.33,0c-0.5,1.33-0.67,3.83-0.67,3.83s0,0.02-0.01,0.06
                            c-0.05,0.59-0.41,5.12,0.84,7.77c1.33,2.83,4.67,6.17,6,7.17c1.33,1,3,1.67,3.17,3.17c0.17,1.5,2.5,3.33,2.5,3.33
                            s1.33,2.83,1.33,4c0,1.17,1,3,2.5,3.67c1.5,0.67,2.17,1.83,2.17,4c0,2.17,0.5,4.33,1.33,6.33c0.83,2,2.33,6.83,0.17,8.83
                            c-2.17,2-8.17,5.67-9.17,7.33c-1,1.67-2.83,4.33-2,6.33c0.83,2,3.17,4.5,4.5,4.17c1.33-0.33,3.33-3.83,4.17-1
                            c0.83,2.83,0.67,3.67,3,3.17c2.33-0.5,2.33-2.83,4.67-0.83c2.33,2,1.17,4.17-0.67,4.17c-1.83,0-5.67-0.67-6.67,0.33
                            s-3.17,1.83,0,3.17c3.17,1.33,4.67,3.17,4.67,4.5c0,1.33-2.67,1.5-4.5,1.83c-1.83,0.33-4.83,1.17-7,0
                            c-2.17-1.17-4.5-4.5-7.67-4.5c-3.17,0-10,1.33-11.17,2.33c-1.17,1-2.17,3.67-5.67,3.67s-9.83,1-10.83-0.17s-2.33-4.5-4.83-4.33
                            c-2.5,0.17-5.67-0.17-6,3.33c-0.33,3.5,0.5,5.83,0,7.33s0,2.17,0.5,4.67s2.33,9.83,4,8.67c1.67-1.17,1-6.67,4.33-5
                            c3.33,1.67,4.33,5.83,6.5,8.5c2.17,2.67,4.5,8.67,4.17,10.33c-0.33,1.67,1.83,6,2.67,8.83c0.83,2.83,5.17,11.5,5.17,11.5
                            l-0.12-0.16c18.09-9.24,51.39-26.43,53.85-28.9c3.3-3.3,5.37-12.79,6.6-15.68c1.24-2.89,7.43-8.26,11.98-12.39
                            c4.54-4.12,20.64-35.91,22.7-42.93c2.06-7.02,14.03-54.07,14.03-54.07s12.39-7.85,18.17-10.74c5.78-2.89,24.77-4.95,30.54-7.01
                            c5.78-2.07,18.58-18.58,18.99-23.12c0.26-2.83-3.5-9.53-6.37-14.16"/>
                    </g>
                    <g>
                        <path style={props.district==='pak'?styles.select:{}} id='pak' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M557.66,572.42c-0.46-0.31-1.4-0.34-4.79,0.44c-6.5,1.5-5.83,0.67-8.17,2.83c-2.33,2.17-2.67,5.83-5,5.83
                            c-2.33,0-3.17-0.33-3.5,1.67c-0.33,2,1.5,5.67-2.33,5.5c-3.83-0.17-6-1.33-6.17,1.83c-0.17,3.17,0.67,10.17-1.67,8.83
                            c-2.33-1.33-5.67-4.67-7.67-4.5c-2,0.17-6.83,2.5-8.5,3.83c-1.67,1.33-1.83,3.67-4.83,3.67s-5.83,0.17-7.5,1.5
                            c-1.67,1.33-4.67,4.5-6,3.83c-1.33-0.67-7.17-5.5-7.5-2.17c-0.33,3.33,1.33,4.67-0.5,7.17c-1.83,2.5-4.67,3.67-4.83,0.67
                            c-0.17-3,2.83-4.33-0.33-6c-3.17-1.67-4.33,0.17-4.67-5.33c-0.33-5.5,1.33-6.83,1.33-11s1.33-3.17,1.33-8s-1.67-7.33,1.33-9.33
                            s3.17-5.5,0.67-5.33c-2.5,0.17-2.67,1.17-4.5,0.17c-1.83-1-3-1-5-1.5s-6.83-2.83-6.83-5.67c0-2.83-0.17-6.33,1.33-6.67
                            c1.5-0.33,4.5-1,6.5-2.33c2-1.33,5.33-3.5,7.17-2.67c1.83,0.83,4.33,2.67,6.17,2.67c1.83,0,4.67,2.83,6.33-0.83
                            c1.67-3.67,2.33-6.67,4.67-7.17c2.33-0.5,10-1.17,10.33-3.17c0.33-2,0.5-3,1.83-3.67c1.33-0.67,7.5-1.67,9.83-1.17
                            c2.33,0.5,5,4,6.33,3.17s2.5-1.17,4.17-1.17c1.67,0,2.83-1.17,4-2.5c1.17-1.33,4.17-6,5.33-7s5.33-4.67,5-2
                            c-0.33,2.67-1.5,4.67-0.83,7.17c0.67,2.5,3.17,4.33,4.83,4.33s4.5,0,6.33,0.67c1.83,0.67,6.67,3.5,6.83,6.5
                            c0.17,3,0.67,3.17-1.33,4.17s-6.17,5-4,5.33c2.17,0.33,5.17-2.17,6,0.33c0.83,2.5,0.33,2.67-0.5,4.33
                            c-0.83,1.67-0.67,12.67-0.67,12.67L557.66,572.42z"/>
                    </g>
                    <g>
                        <path style={props.district==='tts'?styles.select:{}} id='tts' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M420.5,530.41c2,0,3.33-2.33,3.67-3.67c0.33-1.33,4.17-0.83,5.67-0.83s1.67,1.33,2.67,3.33
                            s3.67,1.5,7.67,1.5s7.67-1.5,8-3.67c0.33-2.17,2.33-1.33,4.33-1.5c2-0.17,1.67-3,2.33-5.5c0.67-2.5-3.33-1.67-2.67-3.67
                            c0.67-2,5.33-5.5,7.83-6.83c2.5-1.33,0-3.67-1.5-3.67s-4.5,0.33-5.83,0.67c-1.33,0.33-3.5,0.33-4-1.17s0.83-3.33,3.17-5
                            c2.33-1.67,1.5-4,1.67-5.83c0.17-1.83-2.83-4.17-6-5.67s-0.5-5.67,0.83-7.33c1.33-1.67,2-2.83,4.67-3.83s1.33-3,2.67-6.5
                            c1.33-3.5,3-6.67,3.5-8.33c0.5-1.67-0.33-3.83-2.67-3.67s-3,1.33-5.17-1.17c-2.17-2.5,1.5-2.67,2.83-3.5
                            c1.33-0.83,0.33-7.17,0.33-9.33s1-3.83,2.5-4.5s3.33-3.17,4.67-5.5c1.33-2.33,0.83-6.33,0.5-7.17
                            c-0.33-0.83-4.33-5.17-4.33-5.17c-0.04,0.03-0.07,0.07-0.11,0.1c-4.28,3.97-7.58,8.25-10.39,9.74c-2.83,1.5-3.83,4.33-6,4.33
                            c-2.17,0-8.67-1-10.83-1s-0.33,0.5-4,1.83c-3.67,1.33-6.83,0.67-6.5,3c0.33,2.33,1.83,4-0.17,5.33s-1,3.83-1,5
                            s-0.33,2.5-2.33,3.67c-2,1.17-2.5,2.83-3.17,5c-0.67,2.17-1.17,2.83-3.33,4.33s-2,2.67-3.83,6.33
                            c-1.83,3.67-2.33,9.83-5.33,11.83s-7.17,3.83-8,4.83c-0.83,1-3,5-1.83,5.67c1.17,0.67,3,0,3.83,0.83c0.83,0.83,1,2.33,3.5,2.5
                            s2.5,0.83,3.33,2.83c0.83,2,1,5,1.17,8c0.17,3,0.67,4.33-1.67,4c-2.33-0.33-4.83-0.33-6.17-0.5c-1.11-0.14-4.27-1.08-6.59-1.02
                            l0,0l0,0c-0.72,1.18-5.43,8.94-5.74,10.02c-0.33,1.17-0.83,4.33-1.33,5c-0.5,0.67-2,5-1.5,5.5s2,2,4,1.83c2-0.17,3.67-1,4.67,0
                            s4.33,2.83,6.83,1.83s5.17-3.33,7.17-3s3.83,0.17,5.67-0.67c1.83-0.83,7-3.5,8-3.33c0.94,0.16,3.63,1.19,4.1,3.79"/>
                    </g>
                    <g>
                        <path style={props.district==='sah'?styles.select:{}} id='sah' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M453.49,514.46c-0.58,0.64-1,1.23-1.17,1.74c-0.67,2,3.33,1.17,2.67,3.67c-0.67,2.5-0.33,5.33-2.33,5.5
                            c-2,0.17-4-0.67-4.33,1.5c-0.33,2.17-4,3.67-8,3.67s-6.67,0.5-7.67-1.5s-1.17-3.33-2.67-3.33s-5.33-0.5-5.67,0.83
                            c-0.33,1.33-1.67,3.67-3.67,3.67l-0.23,0.13c0.03,0.17,0.05,0.35,0.06,0.54c0.17,3-0.67,3.83-1.33,5c-0.67,1.17,0.5,6.5-1,7.67
                            s-3.5,4.83-4,8.67c-0.5,3.83-0.5,5.67-1.33,8c-0.83,2.33-4.5,7.83-4.17,9.83s0,5.17,0.5,8.67c0.5,3.5-0.67,5.5,1.33,5.67
                            c2,0.17,2.83,1.67,3.5,2.17c0.67,0.5,3,1.17,4,0.33c1-0.83,4.17-4.17,4.5-7c0.33-2.83,0.5-4.83,3.17-5.83s4-1.17,4.17,0.17
                            c0.17,1.33,0,4.67,1.33,3.83c1.33-0.83,3.5-0.5,4.33-3.33c0.83-2.83,4-8.17,5.17-8.83c1.17-0.67,1.83-0.5,3.67,1.5
                            c1.83,2,2.83,2.5,3.17,0.67c0.33-1.83,0-4.83,1.33-4.67c1.33,0.17,3,2.5,5.83,0.67c2.83-1.83,3.17-4.5,4.83-4.83
                            c1.29-0.26,2.29-0.32,2.67-0.33l0,0l0,0c0.03-2.12,0.24-3.93,1.33-4.17c1.5-0.33,4.5-1,6.5-2.33c2-1.33,5.33-3.5,7.17-2.67
                            c1.83,0.83,4.33,2.67,6.17,2.67c1.83,0,4.67,2.83,6.33-0.83c1.67-3.67,2.33-6.67,4.67-7.17c2.33-0.5,10-1.17,10.33-3.17
                            s0.5-3,1.83-3.67c1.32-0.66,7.34-1.64,9.74-1.19l0.09,0.02c-0.5-2.17,4.5-4.33,6.33-4.5c1.83-0.17,3-2.33,2.17-4.67
                            c-0.83-2.33,2.17-4.5,2.17-6.33c0-1.83-1.83-4.67-2.67-6.33c-0.83-1.67,0.83-2.17,2.17-3.5c1.33-1.33,0.5-2.5,0.5-5
                            c0-2.5,0.33-3.17-2.67-5.17s-4.67,0.33-6.67,0.67s-2-2.5-1.67-4c0.33-1.5,0.17-4.83,1.33-7.83c1.17-3-0.33-3.5-1.83-3.5
                            s-1.33-2.5-1.33-4.17c0-1.67,0-3.33,0-3.33l-0.16,0.18c-1.43,1.34-3.66,3.45-5.01,3.32c-1.83-0.17-5.5,0.5-5.67,2.33
                            c-0.17,1.83-0.17,10.17-1.5,7.83c-1.33-2.33-3.83-5.17-4-2.17c-0.17,3-0.67,6.67-3.17,7.17s-6.33,0.67-6.83,1.33
                            c-0.5,0.67-1.83,3.33-4.83,3.5c-3,0.17-10.5,3.5-9.83,5.83c0.67,2.33-0.5,8.17-2.5,8s-4.83-1.83-6.67-1.67
                            c-2.33,0.21-3.33,0.03-10.5,0.5"/>
                    </g>
                    <g>
                        <path style={props.district==='kha'?styles.select:{}} id='kha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M327.19,529.17c0,0-1.33,4.5-3,5.67c-1.67,1.17-5.67,3.83-8,4.83c-2.33,1-3.5,1.5-4.17,2.33
                            c-0.67,0.83-3.83,6-5,6.17c-0.39,0.06-0.66,0.08-1.05,0.13l0.05,0.2c0,0,0.67,4.33,3.17,4.33s4.67,0.83,4.67,3.33
                            s-0.33,2.5,1.67,3.83c2,1.33,2.67,1.83,2.67,2.83s1.67,1.83,2.5,2.83c0.83,1,3.5,3,5.5,2.5s8-0.5,6.83,1.67
                            c-1.17,2.17-1.5,4.17,1.17,4.17c2.67,0,5.33,0.33,4.33,2.67c-1,2.33-3,8.83-4.5,10.17c-1.5,1.33-3.67,3.5-4.17,5.33
                            c-0.5,1.83-1.83,5.17-2.17,6.17c-0.33,1-1.33,3-3.17,4.5s-5.33,4.33-7.5,5.33s-3.5,0.83-4.5,2c-1,1.17-0.67,1.33,0,4.33
                            s1.83,5,1.17,6.5c-0.67,1.5,5.83,4.17,6.5,3c0.67-1.17,2-6.5,4.33-6.5s4.83-0.5,6-3s2-8.5,4.33-7.17
                            c2.33,1.33,2.67,2.33,4.17,1.5c1.5-0.83,3.67-3.67,5.5-3.67s6.33,0.33,8.33-0.5c2-0.83,2.17-4.83,3.67-5.33s3-1.83,4.67-1
                            c1.67,0.83,2.17,1.5,4,1.5c1.83,0,4.33-0.83,5.67-0.17c1.33,0.67,3.5,2.83,4.83,0.17c1.33-2.67,0.5-5.5,4-5.33
                            c3.5,0.17,2.67-0.67,3.33-3.17s2.33-2,4.17-2.83c1.83-0.83,5.83-2.33,7.5-3.67c1.67-1.33,4-3.33,4.83-2.83s3.5,1.67,4.83,1.17
                            c1.33-0.5,4.67-2.67,4.67-2.67l0.13-0.06c-0.02-0.45-0.05-0.93-0.13-1.44c-0.5-3.5-0.17-6.67-0.5-8.67s3.33-7.5,4.17-9.83
                            c0.83-2.33,0.83-4.17,1.33-8c0.5-3.83,2.5-7.5,4-8.67c1.5-1.17,0.33-6.5,1-7.67c0.67-1.17,1.5-2,1.33-5
                            c-0.17-3-3.17-4.17-4.17-4.33s-6.17,2.5-8,3.33c-1.83,0.83-3.67,1-5.67,0.67s-4.67,2-7.17,3s-5.83-0.83-6.83-1.83
                            s-2.67-0.17-4.67,0c-2,0.17-3.5-1.33-4-1.83s1-4.83,1.5-5.5c0.5-0.67,1-3.83,1.33-5c0.33-1.17,5.74-10.01,5.74-10.01l-0.15-0.01
                            c-0.46,0.01-0.89,0.07-1.25,0.18c-2.17,0.67-5,1.33-6.17,0.67c-1.17-0.67-11.67-3-12.67-1.83c-1,1.17-2.17,4-4.33,4
                            c-2.17,0-3.5,0.33-3.83,2.33s-2.17,2.83-3.17,2.67c-1-0.17-1.67-0.5-3.5-0.33c-1.83,0.17-4-0.67-4-0.67s-1.5-2.83-4.5-0.83
                            s-8.33,4-9.17,5.5c-0.83,1.5-3,5.83-4.17,6.33c-0.84,0.36-2.82,1.68-4.8,1.94"/>
                    </g>
                    <g>
                        <path style={props.district==='lod'?styles.select:{}} id='lod' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M312.38,613.79c0.08,0.34,0.06,0.23,0.16,0.69c0.67,3,1.83,5,1.17,6.5c-0.67,1.5,5.83,4.17,6.5,3
                            c0.67-1.17,2-6.5,4.33-6.5c0.84,0,1.6-0.04,2.41-0.24l0,0c0,0,3.59,4.24,5.25,5.07c1.67,0.83,2.67,0.67,5,0.33
                            c2.33-0.33,3.5-0.33,5.5-0.33s4.17-0.17,4.17,0.83s2.17,2.33,0.5,4.33c-1.67,2,0.17,3.33,1.83,4c1.67,0.67,9.67,7.67,7.83,9
                            c-1.83,1.33-6.67,4.17-4.5,4.83c2.17,0.67,6.67,2,6.33,4c-0.33,2,1.5,3.5,2.5,3.5s6.67,0.5,5.67,2.17c-1,1.67-1,3.67,0.83,3.33
                            c1.83-0.33,5.5-1.5,4.83,1.17c-0.67,2.67-1.25,4.78,1.08,6.28l-0.49,0.01c-0.66,0.03-1.28,0.04-1.76,0.04
                            c-2,0-2.33,2.83-2.33,5.17c0,2.33-1.83,0.83-4.67-0.83c-2.83-1.67-2.83,0-4.83,2.5s-3.5-1-7.67-1.67c-4.17-0.67-0.83,1-0.33,5
                            s-0.67,0.5-3.33-0.33c-2.67-0.83-2.83-1.5-3.33,0.5s-2.33,1.5-4.33-0.67c-2-2.17-4.17,1.67-5.33,3.17
                            c-1.17,1.5-3.5-0.5-5.33-2.33c-1.83-1.83-3.33,3.17-5.67,5.33c-2.33,2.17-4,1.33-7.33,1.33c-3.33,0-3.33,1.5-4,4.17
                            c-0.67,2.67-3.83,1.17-6.33,2.17s-1.83-0.33-3.83-1.67c-2-1.33-2.67,2.17-3.17,5c-0.5,2.83-3.83,3.83-6.17,0.33
                            c-2.33-3.5-4.67-1.33-6.67,0.33c-1.76,1.47-3.92-1.73-5.77-3.07l-0.06-0.09c0,0,3.5-4.17,4.67-6.33c1.17-2.17-2.67-5.67-2.67-7
                            s-0.5-5.33,0.33-7.67c0.83-2.33,4.67-4.17,5.67-4.83s1.67-3.67,1.5-7.17c-0.17-3.5,0-4,2.17-5.5c2.17-1.5-1.5-5.33-3.33-5.83
                            c-1.83-0.5,1.17-6.5,2-7.67c0.83-1.17,5.5,0,7.83,0.17c2.33,0.17,0-3.17,0-6.5s4.33-2,5.33-1.17c1,0.83,0.17-6.5,0.17-7.33
                            s1.17-3.67,2.5-4.5c1.33-0.83,1.33-4.17,0.33-5S311.38,613.12,312.38,613.79"/>
                    </g>
                    <g>
                        <path style={props.district==='veh'?styles.select:{}} id='veh' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M373.99,665.73c-2.33-1.5-1.83-3.67-1.17-6.33c0.67-2.67-3-1.5-4.83-1.17c-1.83,0.33-1.83-1.67-0.83-3.33
                            c1-1.67-4.67-2.17-5.67-2.17s-2.83-1.5-2.5-3.5c0.33-2-4.17-3.33-6.33-4c-2.17-0.67,2.67-3.5,4.5-4.83
                            c1.83-1.33-6.17-8.33-7.83-9c-1.67-0.67-3.5-2-1.83-4c1.67-2-0.5-3.33-0.5-4.33s-2.17-0.83-4.17-0.83s-3.17,0-5.5,0.33
                            c-2.33,0.33-3.33,0.5-5-0.33c-1.67-0.83-5.24-5.05-5.24-5.05l0.19-0.07c1.4-0.37,2.65-1.15,3.38-2.71c1.17-2.5,2-8.5,4.33-7.17
                            c2.33,1.33,2.67,2.33,4.17,1.5c1.5-0.83,3.67-3.67,5.5-3.67c1.83,0,6.33,0.33,8.33-0.5c2-0.83,2.17-4.83,3.67-5.33
                            s3-1.83,4.67-1c1.67,0.83,2.17,1.5,4,1.5c1.83,0,4.33-0.83,5.67-0.17c1.33,0.67,3.5,2.83,4.83,0.17c1.33-2.67,0.5-5.5,4-5.33
                            c3.5,0.17,2.67-0.67,3.33-3.17c0.67-2.5,2.33-2,4.17-2.83c1.83-0.83,5.83-2.33,7.5-3.67c1.67-1.33,4-3.33,4.83-2.83
                            c0.83,0.5,3.5,1.67,4.83,1.17c1.33-0.5,4.67-2.67,4.67-2.67l0.13,0.07c0.07,2.52-0.48,3.95,1.2,4.09c2,0.17,2.83,1.67,3.5,2.17
                            c0.67,0.5,3,1.17,4,0.33c1-0.83,4.17-4.17,4.5-7c0.33-2.83,0.5-4.83,3.17-5.83c2.67-1,4-1.17,4.17,0.17
                            c0.17,1.33,0,4.67,1.33,3.83c1.33-0.83,3.5-0.5,4.33-3.33c0.83-2.83,4-8.17,5.17-8.83c1.17-0.67,1.83-0.5,3.67,1.5
                            c1.83,2,2.83,2.5,3.17,0.67c0.33-1.83,0-4.83,1.33-4.67c1.33,0.17,3,2.5,5.83,0.67c2.83-1.83,3.17-4.5,4.83-4.83
                            c1.67-0.33,2.67-0.33,2.67-0.33l0,0c-0.02,0.85,0,1.66,0,2.5c0,2.83,4.83,5.17,6.83,5.67s3.17,0.5,5,1.5c1.83,1,2,0,4.5-0.17
                            c2.5-0.17,2.33,3.33-0.67,5.33s-1.33,4.5-1.33,9.33c0,4.83-1.33,3.83-1.33,8c0,4.17-1.67,5.5-1.33,11
                            c0.33,5.5,1.5,3.67,4.67,5.33c3.17,1.67,0.17,3,0.33,6c0.02,0.38,0.08,0.69,0.18,0.94l-0.18,0.06c0,0-3,2.83-4.17,0.67
                            c-1.17-2.17-0.83-5.83-4-4.83c-3.17,1-5.33,2.17-5.33,4c0,1.83-2.17,5.83-3.5,2.67c-1.33-3.17-7.33-3.5-7.83-2.5
                            s0,5.67-0.5,7.83c-0.5,2.17-2,3.67-3,2.33c-1-1.33-4.67-3.67-5.67-1.33c-1,2.33-0.67,7-2.33,6.83
                            c-1.67-0.17-3.67-0.83-5.17,0.17s-3.33,2.67-4.33,2.67s-3.83-1.33-4.33,0c-0.5,1.33-0.67,3.83-0.67,3.83l-0.2-0.13
                            c-0.64-0.44-2.74-1.89-4.14-2.87c-1.67-1.17-5.33-0.17-7,0.17c-1.67,0.33-5.33-2-7.5-3.17c-2.17-1.17-4.33,0.5-6,1.5
                            c-1.67,1-5,3.33-5.83,4.17c-0.83,0.83-3.33,0.83-5.5,0.33c-2.17-0.5-4.83,4.67-5.33,5.67s1,3,3.17,7.67
                            c2.17,4.67-3.17,1.17-5.17,1.17s-1.5,2-1.5,5.33c0,3.33-3.5,6.5-4.33,7.67c-0.64,0.89-3.23,1.2-5.31,1.3"/>
                    </g>
                    <g>
                        <path style={props.district==='mul'?styles.select:{}} id='mul' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M312.25,613.5c-0.5-2.05-0.64-2.31,0.23-3.33c1-1.17,2.33-1,4.5-2c2.17-1,5.67-3.83,7.5-5.33
                            c1.83-1.5,2.83-3.5,3.17-4.5c0.33-1,1.67-4.33,2.17-6.17c0.5-1.83,2.67-4,4.17-5.33c1.5-1.33,3.5-7.83,4.5-10.17
                            c1-2.33-1.67-2.67-4.33-2.67c-2.67,0-2.33-2-1.17-4.17c1.17-2.17-4.83-2.17-6.83-1.67s-4.67-1.5-5.5-2.5
                            c-0.83-1-2.5-1.83-2.5-2.83s-0.67-1.5-2.67-2.83c-2-1.33-1.67-1.33-1.67-3.83s-2.17-3.33-4.67-3.33s-3.17-4.33-3.17-4.33
                            l-0.04-0.2c-0.83,0.11-1.65,0.27-2.96,0.87c-1.83,0.83-9.17,2-7.67,3.83c1.5,1.83,6.5,1.33,0.17,5.33c-6.33,4-7,3.67-7.17,6.5
                            c-0.17,2.83-2.17,6.33-2.83,7.5c-0.67,1.17-4.33,6.17-5.83,7.5c-1.5,1.33-5.67,6.83-5.83,9.17c-0.17,2.33,1.67,8.67-0.5,10.67
                            c-2.17,2-3.33,2.33-3.33,4c0,1.67,4.5,8,0.67,8c-3.83,0-5.83,1.5-6.5,2.33c-0.67,0.83-5.5,11.67-4.33,12.83
                            c1.17,1.17,2.5,2,1.5,5s-3,5.17-2.17,8.67c0.83,3.5,2.33,5.17,1,6.17c-1.33,1-0.17,2.83,0.17,5.5c0.33,2.67-5.33,4.17-7.17,4.17
                            c-1.83,0-7.5-0.33-8.67,2.5c-1.17,2.83,2.33,6.83,1,7.33c-1.33,0.5-2.83,0.33-3.17,1.83c-0.33,1.5-0.17,4.33-1,4.83
                            c-0.83,0.5-0.33,2.5-0.33,5.33c0,2.83-1.5,5.67,0.67,7.33c1.33,1.02,2.71,1.74,3.35,2.75l0.15-0.09c0,0,2.67,0.67,3.33,0.33
                            c0.67-0.33,3-3.17,4.83-3.83c1.83-0.67,6.17-1.83,8.33-2c2.17-0.17,3.5-1.17,4,0.33s0.67,2.83,2,3.17
                            c1.33,0.33,4.17,1.83,5.83,1.17c1.67-0.67,5.5-2.83,5.33-1.5c-0.17,1.33-0.5,3.17,1.5,4c0.23,0.1,0.4,0.19,0.65,0.37l0.02-0.03
                            c0,0,3.5-4.17,4.67-6.33c1.17-2.17-2.67-5.67-2.67-7c0-1.33-0.5-5.33,0.33-7.67c0.83-2.33,4.67-4.17,5.67-4.83
                            c1-0.67,1.67-3.67,1.5-7.17c-0.17-3.5,0-4,2.17-5.5c2.17-1.5-1.5-5.33-3.33-5.83c-1.83-0.5,1.17-6.5,2-7.67
                            c0.83-1.17,5.5,0,7.83,0.17c2.33,0.17,0-3.17,0-6.5c0-3.33,4.33-2,5.33-1.17c1,0.83,0.17-6.5,0.17-7.33
                            c0-0.83,1.17-3.67,2.5-4.5c1.33-0.83,1.33-4.17,0.33-5c-1-0.83,4-0.67,5,0"/>
                    </g>
                    <g>
                        <path style={props.district==='ryk'?styles.select:{}} id='ryk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M226.98,713.29c0,0,0.67,3,0,4.33c-0.67,1.33-2.67,4.83-1.67,4.83s3.67,1.33,4.5,4.17
                            c0.83,2.83,2.33,6.33,3.5,7.17c1.17,0.83,4.5,4.17,5.33,0.83c0.83-3.33,1-5.17,2.67-6c1.67-0.83,5.17-2.5,6-1.17
                            c0.83,1.33,0.5-0.5,0.5,2.83s1.17,4.5,0.33,6.33c-0.83,1.83-1.83,3.67-2.33,4.67s-2.67,4.5-1.83,5.67c0.83,1.17,2,2,1.33,3.67
                            c-0.67,1.67-2.83,5.83-2,7.83c0.83,2,3.17,3,2.33,6.67c-0.83,3.67-2,7.83-4.17,12.5c-2.17,4.67-5.33,11.67-6.5,17.83
                            c-1.17,6.17-3.5,14-3.17,17.33c0.33,3.33,3.5,22.33,2.83,26.67c-0.67,4.33,0.17,10.17,0.67,15c0.5,4.83,6.67,10.67,6.83,12.67
                            c0.17,2,2.17,9.83,2.83,14.33c0.67,4.5,2.33,8.5,3.17,12.67c0.83,4.17,1.17,8.33,1.17,10.17s1.67,12.33,1.67,12.33l0.1,0.06
                            c-10.58,3.8-20.31,7.35-22.16,8.27c-4.13,2.06-18.99,4.54-23.12-0.83c-4.12-5.37-4.12-11.97-5.77-19.4
                            c-1.66-7.43-5.37-18.99-16.52-21.05c-11.14-2.07-23.53,3.71-30.54,8.66c-3.84,2.72-9.42,9.51-13.94,15.44
                            c-0.95-3.31-4.87-6.35-7.88-6.99c-3.17-0.67-5.5-2-7.5-3.17c-2-1.16-2.5-3.5-6-4.33c-3.5-0.83-4-3.33-4.5-5.5
                            c-0.5-2.17-2.67-5-6.84-9.5c-4.16-4.5-7.16-9.33-8-12c-0.83-2.67-0.66-5.17,0.34-7.17s-3.34-11-3.84-12.16
                            c-0.5-1.17-0.83-8.5-1-10c-0.1-0.91-4.16-4.8-7.35-7.74l-0.31-0.27c0,0,5.5,0.67,7.83,1c2.33,0.33,3.67-5.5,4.83-7.5
                            c1.17-2,1.67,0.67,4.67,2.83s4.33,0.33,5.67-0.17c1.33-0.5,3.67-5,3.5-8.33c-0.17-3.33,1.83-3.5,4-4.83
                            c2.17-1.33,4.17-0.17,5.67,1.33s4.5-7.33,6.33-9c1.83-1.67-0.17-3.67-1.33-5.17c-1.17-1.5-0.83-1.67,0.17-3.67
                            c1-2,10.33-3,11.83-3s6.33-2,5.83-4.83c-0.5-2.83-2-5.33-0.83-6.83c1.17-1.5,5.33,0.33,7.83,0.33s0.5-6.17-0.33-7.33
                            c-0.83-1.17,2-3,3-3s6.17,4.17,7.83,5c1.67,0.83,3.67-6.83,3.67-9.33s-6.5-6.67-6.83-8.67c-0.33-2,2.67-2.33,5.33-2.33
                            c2.67,0,6.5,0.67,8.67-0.67c2.17-1.33,1.83-3.33,2-5.33c0.17-2,3.17-0.83,4.5-2.17c1.33-1.33,6.5-4.33,7.83-4.67
                            c1.33-0.33,3.33-2.33,4.33-4.67c1-2.33,3.17-2.67,6.17-3c3-0.33,3-6.5,4-7.67s3.17-2.17,4.5-1.33c1.33,0.83,3-5.5,4.17-9.5
                            c1.17-4,4.5-2.17,5.67-1c1.17,1.17,2.5-0.5,3.17-3.33c0.67-2.83,2.67-0.83,5.33,1.33c0.84,0.68,1.37,0.75,1.69,0.48"/>
                    </g>
                    <g>
                        <path style={props.district==='raj'?styles.select:{}} id='raj' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M86.24,828.96c0,0,5.49,0.66,7.82,1c2.33,0.33,3.67-5.5,4.83-7.5c1.17-2,1.67,0.67,4.67,2.83
                            c3,2.17,4.33,0.33,5.67-0.17c1.33-0.5,3.67-5,3.5-8.33c-0.17-3.33,1.83-3.5,4-4.83c2.17-1.33,4.17-0.17,5.67,1.33
                            s4.5-7.33,6.33-9c1.83-1.67-0.17-3.67-1.33-5.17c-1.17-1.5-0.83-1.67,0.17-3.67s10.33-3,11.83-3s6.33-2,5.83-4.83
                            c-0.5-2.83-2-5.33-0.83-6.83c1.17-1.5,5.33,0.33,7.83,0.33s0.5-6.17-0.33-7.33c-0.83-1.17,2-3,3-3s6.17,4.17,7.83,5
                            c1.67,0.83,3.67-6.83,3.67-9.33s-6.5-6.67-6.83-8.67c-0.33-2,2.67-2.33,5.33-2.33c2.67,0,6.5,0.67,8.67-0.67
                            c2.17-1.33,1.83-3.33,2-5.33c0.17-2,3.17-0.83,4.5-2.17c0.45-0.45,1.34-1.09,2.37-1.75l-0.2,0.09c0,0,1.33-8.17,1.83-9.67
                            s1.83-5.83,1.83-10c0-4.17-0.67-6.67-1.67-7.33c-1-0.67,6.5-10,7.5-11.17c1-1.17,0.17-8.17,0.17-10.83c0-2.67-0.33-4.5,1-5.33
                            c1.33-0.83,1.5-2.83,1.67-6.67c0.17-3.83,2.5-3.33,5-3.67c2.5-0.33,3-4.67,1.67-7c-1.33-2.33,0.5-4.5,1.33-6
                            c0.83-1.5,1.5-9,2-11c0.13-0.54,0.19-0.75,0.48-1.54l0,0c0,0-2.81-7.96-3.48-9.13c-0.67-1.17-0.33-1.83,0.17-3
                            c0.5-1.17-5.17-5.33-7.17-6c-2-0.67-4.33,1.33-5.5,2c-1.17,0.67-2.33,5-2.33,8.5s-4.5,9-6.83,10.5
                            c-1.17,0.75-3.79,2.38-6.44,3.13c-2.85,0.8-7.98,2.36-9.32,0.8c-1.34-1.56-5.36-5.13-5.8-8.03c-0.45-2.9-2.9-11.83-4.69-12.05
                            c-1.79-0.22-4.02-2.23-8.03,0c-4.02,2.23-3.79,5.13-6.47,2.23c-2.68-2.9-7.81-8.93-10.04-4.46c-2.23,4.46-0.89,9.59-3.79,11.83
                            c-2.9,2.23-6.47,11.16-5.13,16.29c1.34,5.13,5.8,18.07,3.35,25.88c-2.46,7.81-5.8,8.26-6.92,12.72
                            c-1.12,4.46-3.79,11.38-5.58,15.4c-1.79,4.02-0.67,7.59-4.46,7.36c-3.79-0.22-6.47-0.45-6.47,3.35c0,3.79-0.67,7.14-2.68,10.04
                            c-2.01,2.9-3.35,11.6-2.23,15.4c1.12,3.79,1.56,11.83,0.22,12.94c-1.34,1.12-6.92,4.24-7.36,6.47c-0.45,2.23-1.34,5.8-2.23,8.26
                            c-0.89,2.45-1.12,6.69-3.57,6.92c-2.45,0.22-8.03,1.79-8.48,4.02c-0.45,2.23-0.89,15.17,1.12,16.51
                            c2.01,1.34,8.26,4.69,7.81,12.27l-0.02,0.18c0.04,0.02,0.07,0.06,0.07,0.06S84.07,826.97,86.24,828.96L86.24,828.96z"/>
                    </g>
                    <g>
                        <path style={props.district==='muz'?styles.select:{}} id='muz' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M327.18,529.18c0,0-1.33,4.5-3,5.67c-1.67,1.17-5.67,3.83-8,4.83c-2.33,1-3.5,1.5-4.17,2.33
                            c-0.67,0.83-3.83,6-5,6.17c-1.17,0.17-2.17,0.17-4,1c-1.83,0.83-9.17,2-7.67,3.83c1.5,1.83,6.5,1.33,0.17,5.33s-7,3.67-7.17,6.5
                            c-0.17,2.83-2.17,6.33-2.83,7.5s-4.33,6.17-5.83,7.5s-5.67,6.83-5.83,9.17c-0.17,2.33,1.67,8.67-0.5,10.67
                            c-2.17,2-3.33,2.33-3.33,4c0,1.67,4.5,8,0.67,8c-3.83,0-5.83,1.5-6.5,2.33c-0.67,0.83-5.5,11.67-4.33,12.83
                            c1.17,1.17,2.5,2,1.5,5s-3,5.17-2.17,8.67c0.83,3.5,2.33,5.17,1,6.17c-1.33,1-0.17,2.83,0.17,5.5c0.33,2.67-5.33,4.17-7.17,4.17
                            c-1.83,0-7.5-0.33-8.67,2.5c-1.17,2.83,2.33,6.83,1,7.33c-1.33,0.5-2.83,0.33-3.17,1.83c-0.33,1.5-0.17,4.33-1,4.83
                            c-0.83,0.5-0.33,2.5-0.33,5.33c0,2.83-1.5,5.67,0.67,7.33c2.17,1.67,5,3.17,3.33,5c-1.67,1.83-0.83,7-4.17,7
                            c-3.33,0-9.17,2.67-10.33,3.33c-1.17,0.67-3.33,0.5-1.5,3.17c1.83,2.67,5.83,6.33,3.33,6.17c-2.5-0.17-5-1.5-5,0
                            s0.5,5.5-2.17,3.33c-2.67-2.17-4.67-4.17-5.33-1.33c-0.67,2.83-2,4.5-3.17,3.33c-1.17-1.17-4.5-3-5.67,1
                            c-1.17,4-2.83,10.33-4.17,9.5c-1.33-0.83-3.5,0.17-4.5,1.33s-1,7.33-4,7.67c-3,0.33-5.17,0.67-6.17,3c-1,2.33-3,4.33-4.33,4.67
                            c-0.88,0.22-3.43,1.6-5.45,2.9l-0.22,0.1c0,0,1.33-8.17,1.83-9.67s1.83-5.83,1.83-10c0-4.17-0.67-6.67-1.67-7.33
                            c-1-0.67,6.5-10,7.5-11.17c1-1.17,0.17-8.17,0.17-10.83c0-2.67-0.33-4.5,1-5.33c1.33-0.83,1.5-2.83,1.67-6.67
                            c0.17-3.83,2.5-3.33,5-3.67c2.5-0.33,3-4.67,1.67-7c-1.33-2.33,0.5-4.5,1.33-6c0.83-1.5,1.5-9,2-11s2.33-6.33,3.33-8.67
                            s5.33-8.33,6.33-9.5s5.33-6.67,5.5-8.5c0.17-1.83-0.33-6.33-0.83-7.67c-0.5-1.33,1.83-2.5,4.17-3.83
                            c2.33-1.33,0.17-8.5-0.17-10.17c-0.33-1.67,0.83-4.67,1.5-6.17c0.67-1.5-2.5-4.67-4-7.67s-0.5-5-0.5-9s-3.67-7-4.5-8.33
                            c-0.83-1.33,0.5-3.5,1.5-4.83c1-1.33,0.5-9.5,0.5-11.17c0-1.67,1.33-6.33,0.5-8.67c-0.83-2.33-2-3.67-3.5-6.83
                            c-1.5-3.17-0.67-5.17,0-7.17c0.67-2,0.17-5.17,0.83-7.83c0.67-2.67-1.17-6.33-1.67-10c-0.02-0.14-0.03-0.2-0.05-0.35l0,0
                            c0,0,3.55-0.48,5.55-1.32s9.5-2.17,13.33-1.5c3.83,0.67,8.67,2.5,10.83,2.5c2.17,0,3.83-0.17,6-0.33c2.17-0.17,7-2.5,7-3.5
                            s0.17-2.5,0.67-5.5s1.33-7.17,4.67-7.17s13.67,2.33,16,4.17c2.33,1.83,9,10.67,14.67,15c5.67,4.33,9,7,14.5,6.83
                            c3.23-0.1,5.17-4.83,5.17-4.83s3.93,0.24,6.06-0.45l0,0c0,0,4.11,3.61,6.61,4.78c0.71,0.33,1.48,0.38,2.25,0.27"/>
                    </g>
		        </g>
                </svg>
        </>
    )
}

export default PjbMap;