import React from 'react';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import TermsAndConditionsPopup from './t&cPopup';
import DisclaimerPopup from './disclaimerPopup';
import PrivacyPolicy from './PrivacyPolicy';
import {useNavigate} from 'react-router-dom'
import './Footer.css';

function Footer() {
  const [showTermsPopup, setShowTermsPopup] = React.useState(false);
  const [showDisclaimerPopup, setShowDisclaimerPopup] = React.useState(false);
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = React.useState(false);
  const navigate=useNavigate();
  
  const handleTermsClick = () => {
    setShowTermsPopup(true);
  }

  const handlePrivacyPolicyClick = () => {
    setShowPrivacyPolicyPopup(true);
  }

  const handleDisclaimerClick = () => {
    setShowDisclaimerPopup(true);
  }

  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  }
  const closePrivacyPolicyPopup = () => {
    setShowPrivacyPolicyPopup(false);
  }

  const closeDisclaimerPopup = () => {
    setShowDisclaimerPopup(false);
  }

  const year=new Date().getFullYear()

  return (
    <>
      <div className='row'>
        <p className='para1' onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
        {showPrivacyPolicyPopup && <PrivacyPolicy closePopup={closePrivacyPolicyPopup} />}
        <p className='para1' onClick={()=>{navigate('/about-us')}}>About Us</p>
        <p className='para1' onClick={handleDisclaimerClick}>Disclaimer</p>
        {showDisclaimerPopup && <DisclaimerPopup closePopup={closeDisclaimerPopup} />}
        <p className='para1' onClick={handleTermsClick}>Terms and Conditions</p>
        {showTermsPopup && <TermsAndConditionsPopup closePopup={closeTermsPopup} />}
      </div>
      <div className='row'>
        <p className='low-text'>© {year} pakexplore. All rights reserved</p>
        <div className="contact-container">
        <div className="contact-info">
          <p>Contact us for inquiries:</p>
          <p className="phone-number">Email: contact@pakexplore.pk</p>
        </div>
      </div>
        {/* <p className='low-text'>Developed by Dr. Mumtaz Ali</p> */}
      </div>
    </>
  );
}

export default Footer;
