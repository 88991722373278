import React from 'react';
import HomePageHeader from './HomePageHeader';
import './AboutUs.css'

const AboutUs = () => {
  return (
    <><div className='main-container'>
        <HomePageHeader plain={true} />
        <div className="about-us">
        <h2 className="about-us-heading">About Us</h2>
        <p className="about-us-intro">Welcome to our website, your ultimate guide to exploring the diverse and beautiful landscapes of Pakistan. We are a dedicated team of travel enthusiasts who are passionate about showcasing the incredible natural beauty, historical sites, and cultural richness that Pakistan has to offer.</p>

        <p className="about-us-mission">Our Mission:</p>
        <p className="about-us-mission-text">At pakexplore.pk, our mission is to inspire and assist travelers in discovering the hidden gems, historical treasures, and breathtaking sceneries that Pakistan is famous for. We believe that Pakistan is more than just a destination; it's an experience waiting to be explored. Through our platform, we aim to:</p>

        <ul className="mission-list">
            <li>Provide comprehensive and up-to-date information about tourist attractions, historical landmarks, and cultural events across Pakistan.</li>
            <li>Offer travel tips, itineraries, and guides to help you plan your perfect trip to this captivating country.</li>
            <li>Promote sustainable and responsible tourism to preserve the natural beauty and cultural heritage of Pakistan for future generations.</li>
        </ul>

        <p className="about-us-mission">Meet the Team:</p>
        <p className="about-us-mission-text">Our team consists individuals who share a deep love for Pakistan. We're dedicated to bringing you the best of what this incredible country has to offer:</p>

        <ul className="mission-list">
            <li><strong>Dr. Mumtaz Ali</strong>: The technical lead, Dr.Mumtaz Ali ensures that our website runs smoothly and provides you with the best user experience possible.</li>
            <li><strong>Umair Naeem</strong>: Content Manager, Umair Naeem crafts engaging and informative content to help you explore and experience Pakistan like never before.</li>
        </ul>

        <p className="about-us-thank-you">Thank you for choosing pakexplore.pk as your travel companion.</p>
        </div>
    </div></>
  );
}

export default AboutUs;
