import { useEffect, useRef } from 'react'

const AdsterraNativeBanner1x3=()=>{
    const banner = useRef(HTMLDivElement)

    useEffect(() => {
      if (!banner.current.firstChild) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//pl21787687.toprevenuegate.com/34caabc0ae96e5fd0452f46c48e42bf2/invoke.js`

        if (banner.current) {
            banner.current.append(script)
        }
    }})

    return (
      <>
        <div ref={banner}></div>
        <div id="container-34caabc0ae96e5fd0452f46c48e42bf2"></div>
      </>);
}

export default AdsterraNativeBanner1x3




// const AdsterraNativeBanner = () => {
//     const location=useLocation()
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `//pl21369607.toprevenuegate.com/ef2fde217d1080bbb40ecfb31f28be07/invoke.js?${Date.now()}`;
//     script.async = true;
//     script.dataCfasync=false;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   },[location.key]);

//   return (
//     <div id="container-ef2fde217d1080bbb40ecfb31f28be07">
//     </div>
//   );
// };