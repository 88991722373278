import './ProvinceMaps.css';
function SndMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="snd-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 565.3 667.9" style={{enableBackground:'new 0 0 565.3 667.9'}} xmlSpace="preserve">
                <path style={props.district==='jac'?styles.select:{}} id='jac' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick}  className="st0" d="M162.6,78.6c0,0,1.6,1.3,2.8,1.4c1.2,0.2,5.5,0.7,7,0.3s3.3-1.3,5.5-1.2c2.2,0.2,6.2,0.5,6.5,1.8s2.7,6.3,4,6.2
                    c1.3-0.2,4.8-0.7,6.2-0.5c1.3,0.2,6,1,8,1.3s8.7,0.2,8.7-1s0.2-9.5,0.2-13.7s-0.8-11.3,1.8-11.7c2.7-0.3,10.3,1.7,13-0.3
                    s8.2-5,11.2-4.8s3.7,0.2,6.3,0.2c2.7,0,7.2,0.2,9.5,1.2s5.2,2.2,7.8,2.5c2.7,0.3,7.7,1.7,10,2.7s9.2,3.8,10.3,3.2
                    c0.9-0.5,2-1.1,3.2-1.4l-0.1-0.1c-2.7-2-1.2-5.8-0.8-7.7c0.3-1.8-1-5.2-1-6.3c0-1.2-0.2-4-0.3-5.8c-0.2-1.8,2.2-6,2.3-6.7
                    c0.2-0.7,0-20,0-20l0,0c-24.8,0.3-55.5,0.5-55.5,0.5l-7.2,5.7l-5,13.7l-19,5.7c0,0-15.7,15.7-17.7,17.3c-2,1.7-5.3,6-7.3,6.7
                    s-8.3,3.2-9,5.3c-0.7,2.2-1.3,4.8-1.3,4.8l0,0L162.6,78.6z"/>
                <path style={props.district==='shi'?styles.select:{}} id='shi' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M202.6,87.9c0,0,2.7,5.2,3.7,7s2.2,2.2,2.2,3.8s1.8,3,3,4.7s3.7,5.8,4,8s3,3,5.8,3.8s4.7,3.8,6,5.2
                    c1.3,1.3,4.7-1.3,6-3s3.7-1,6.3-0.7c2.7,0.3,5.5-0.3,6.7-1.2c1.2-0.8,3.3-3.3,5.5-3.7c2.2-0.3,3.2-2,3.2-3.3s3.5-3.5,5.7-3.7
                    s3.7,1,6.8,1.5c3.2,0.5,6.7-2.7,7.8-4c1.2-1.3,2.5-6.2,2.5-7s1-3,2.2-3.2s1.8-1.8,2.3-3.7c0.5-1.8,1.8-2.2,3.5-2.3s3.5-3.2,4.5-4
                    s4.2-1.3,5.7-1.3s5-7.7,5-7.7V73c-1-1.6-2.6-4.3-3.9-5.6c-1.5-1.5-9.7-3-11.3-3c-0.4,0-0.8,0-1.1,0.1c-1.2,0.3-2.3,0.9-3.2,1.4
                    c-1.2,0.7-8-2.2-10.3-3.2s-7.3-2.3-10-2.7c-2.7-0.3-5.5-1.5-7.8-2.5s-6.8-1.2-9.5-1.2s-3.3,0-6.3-0.2s-8.5,2.8-11.2,4.8
                    s-10.3,0-13,0.3s-1.8,7.5-1.8,11.7s-0.2,12.5-0.2,13.7c0,1.1-5.9,1.3-8.3,1.1"/>
                <path style={props.district==='suk'?styles.select:{}} id='suk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick}  className="st0" d="M245.2,115.8c0,0,9,0.5,9.5,0.7s3.8,6.8,7,6.5c2.8-0.3,3.7-4.1,6.3-3.8c2,0.2,7.5,1,7.5,1s1.5,7.5,0.7,8.2
                    s-3.3,6.5-4.3,9s-2.5,8.2-1.2,8.7s3.7,3.7,5.5,3.7s5.3-0.7,4.7,1c-0.7,1.7-3.2,5.3-1.3,6.8c1.8,1.5,1.5,4.3-0.3,5.8
                    s-4.3,3.7-4.3,6.3c0,2.7,0.7,5.7,3.8,9.2c3.2,3.5,16,10.5,20.2,10s9.7-2.3,11.7-1s5.2,3,8,3s7.8,2.5,10.3,2.7s11.5,6,14.5,6.7
                    s15.5,7.8,15.5,7.8l0.6,0.4c2.9-7.1,6.2-14.4,7.3-16.2c1-1.5,10.7-10.6,21.5-20.6l-0.3-0.3c0,0-4.2-3.3-6.2-4.8s-7.8-4.2-10.8-4.7
                    s-11.3-9.2-11.3-9.2s2.3-6.2,2.3-7.8c0-1.7-3.7-1-7-0.3s-5.5,0-9-1.7s-4.5-0.5-9.3-1.5s-11.2-11.2-12.8-15.5
                    c-1.9-4.9-10.3-5.6-11.3-6.3c-2.2-1.5,2.5-3.8,5-3.3s1.5-1.5,2-2.5s3.8,0.5,6,1.3s1.7-3.2,1.2-4.3s-1.2-2.5-1-4.3s-3.5-5.7-5.2-8.3
                    c-1.7-2.7-3.7-9.2-4.2-10.3s-0.8-4.8-1.2-6.2c-0.1-0.3,0-0.6,0.2-1l0.1-0.2l-6.9-4.2c0,0-1.2,4-2.8,3.3c-2.4-0.9-4-5.4-4.2-5.7
                    c-0.1-0.2-0.3-0.4-0.4-0.7H301c0,0-3.5,7.7-5,7.7s-4.7,0.5-5.7,1.3s-3.4,3.3-5,3.5s-2.5,1-3,2.8s-1.2,3.5-2.3,3.7
                    c-1.2,0.2-2.2,2.3-2.2,3.2c0,0.8-1.3,5.7-2.5,7s-4.7,4.5-7.8,4c-3.2-0.5-4.7-1.7-6.8-1.5c-2.2,0.2-5.7,2.3-5.7,3.7
                    c0,1.3-1,3-3.2,3.3s-4.3,2.8-5.5,3.7c-0.1,0.1-0.2,0.1-0.3,0.2"/>
                <path style={props.district==='qam'?styles.select:{}} id='qam' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M72.2,155.2c0,0,5.2,1.3,7,3.7c1.8,2.3,7.5,4,9.3,5s6.3,3.7,6.3,3.7s2-7.2,4.5-4.8c2.5,2.3,7,4.7,9.7,6.5
                    s5.7,2.5,8.3,3.2c2.7,0.7,5.5,1,7,0.7s3.5-0.5,5,0.5s4.3,1.2,6.8,1.3c2.5,0.2,13.3,0.5,16-0.7s4.8-2.3,7.5-0.7c2.7,1.7,3.2,2,4.8,2
                    c1.7,0,6,0.7,7.7,1.2L172,177c0.8-0.8,1.4-1.6,1.8-2.3c1.2-2,1.7-9.2,1.7-9.2l4-1.5c0,0-3.8-2.2-5.5-4s1-3.8,2.7-6s4.5-9.3,4.7-11.3
                    s3.8-6.7,5-8.7s-0.5-9-1.3-11.2s5.8,0.7,5.8,0.7l6.3-5.8c0,0-2.7-4.8-3.3-6.2c-0.7-1.3,1.3-5.7,1.7-7.2c0.3-1.5-3.3-9.5-5.3-12
                    c-1.9-2.3-2.1-4.8-2.2-5.1l-0.2-0.3c-1.3-1-3.1-4.8-3.3-5.9c-0.3-1.3-4.3-1.7-6.5-1.8c-2.2-0.2-4,0.8-5.5,1.2s-5.8-0.2-7-0.3
                    c-1.2-0.2-3.7-2.1-3.7-2.1l0,0c-1.1,0.1-2.8,0.2-4.6,0.2c-2.7,0-9.5,0.8-9.8,2.7c-0.3,1.8,1.3,4.8-2.2,4.8s-13.7,1.5-15.8,2.3
                    c-2.2,0.8-8.5,2.3-11.5,2.7c-3,0.3-7.3,1.8-10.5,1.8s-6.8-3.7-6.8-3.7s-0.5,5.8-2.5,9.7c-2,3.8-1.8,14.2-3.8,16.2
                    s-10.8,9.7-11.5,12.5s-1.8,11.2-2.7,11.3c-0.8,0.2-5.7,3.7-5.8,5.2c-0.1,1.1-0.8,7.6-1.3,11.9"/>
                <path style={props.district==='lar'?styles.select:{}} id='lar' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M203,88c-0.1,0-0.3,0-0.4-0.1c-2-0.3-6.7-1.2-8-1.3c-1.3-0.2-4.8,0.3-6.2,0.5c-0.1,0-0.2,0-0.4-0.1l-0.1,0.1
                    c0,0,0.2,2.7,2.2,5.2s5.7,10.5,5.3,12c-0.3,1.5-2.3,5.8-1.7,7.2c0.7,1.3,3.3,6.2,3.3,6.2l-6.3,5.8c0,0-6.7-2.8-5.8-0.700
                    c0.8,2.2,2.5,9.2,1.3,11.2s-4.8,6.7-5,8.7s-3,9.2-4.7,11.3c-1.7,2.2-4.3,4.2-2.7,6c1.7,1.8,5.5,4,5.5,4l-4,1.5c0,0-0.5,7.2-1.7,9.2
                    c-0.4,0.7-1.1,1.4-1.8,2.3c-1.6,1.7-3.4,3.5-3.5,4.6c-0.2,1.7-0.7,4-1.5,4.5s-2.5,1.5-0.5,3.5s6.7,5,6.7,5c2.5,2,4.7,4.3,4.7,4.3
                    s4-0.2,5.3-0.2s1.2-6.2,1.7-9s1-0.7,3.2-1.2s7.2-4.5,7.2-4.5s1.3-6.3,2.7-8.2c1.3-1.8,2.8-3.8,3.2-5.5c0.3-1.7,5.7-3.2,7.3-3.2
                    s5.5-0.5,6.7-2.3s-3-4.8-4.2-6s-3.8-7-4.2-8.3c-0.3-1.3,0-4.5,1.7-5.2s5.2-1.2,6-2.2s-0.8-8-0.3-10.2s4.8-0.7,8.2-0.3
                    c3.3,0.3,6.3-1.3,8.3-2.2c2-0.8,3.2-3.3,2-4.5s-4.2-5.2-4.2-5.2h0.2c-0.5,0.1-0.9,0-1.2-0.3c-1.3-1.3-3.2-4.3-6-5.2
                    c-2.8-0.8-5.5-1.7-5.8-3.8c-0.3-2.2-2.8-6.3-4-8s-3-3-3-4.7s-1.2-2-2.2-3.8s-3.7-7-3.7-7"/>
                <path style={props.district==='kha'?styles.select:{}} id='kha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M246.2,115.6c-1.3,0.8-3.9,1.3-6.4,1c-2.7-0.3-5-1-6.3,0.7c-1,1.3-3.2,3.1-4.8,3.3l-0.1,0.1
                    c0.6,0.8,2.9,3.9,4.1,5.1c1.2,1.2,0,3.7-2,4.5c-2,0.8-5,2.5-8.3,2.2s-7.7-1.8-8.2,0.3c-0.5,2.2,1.2,9.2,0.3,10.2s-4.3,1.5-6,2.2
                    s-2,3.8-1.7,5.2c0.3,1.3,3,7.2,4.2,8.3c1.2,1.2,5.3,4.2,4.2,6c-1.2,1.8-5,2.3-6.7,2.3s-7,1.5-7.3,3.2s-1.8,3.7-3.2,5.5
                    c-1.2,1.6-2.3,6.6-2.6,7.9l-0.1,0.3c0,0,5,1.5,6.7,1.8s3.3,1,5.5,1s8,2.7,8.3,4.3c0.3,1.7,1.3,3.7,2.8,4.8c1.5,1.2,6.5,6.5,6.8,7.5
                    s2.5,7.3,1.7,9s-3.3,4.8-4.3,5.5s-3.3,2.8-3.7,3.3c-0.3,0.5-3,6-3.8,7.7s-3.2,9-3.2,12s0.5,4.5,0.5,4.5l-5,17.7
                    c0,0,9.3,4.7,14.8,5.2s10.8,0.7,14.5,1.5s7.3,2.2,11.5,2.2s8.5-0.2,12.5,1.3s9.2,2.3,10.5,4.5s4,9.3,4.5,11.7
                    c0.5,2.3,1.3,5.3,1.3,5.3s3.3-3.5,4.8-3.7s4.7,2.5,5.5,4.5s0.3,6,2.3,7s4.5,1.2,4.7,4.2s2.7,6.2,3.8,6.2c1.2,0,4.8,1.8,5,3.7
                    c0.2,1.8,1.7,7,6.5,6.3s12.8-1.8,16.2-1.5c3.3,0.3,14.2,2.8,19.5,3.2c5.3,0.3,13,1.3,16.8,0.8s8.3-0.8,11.7-0.7
                    c3.3,0.2,22.3,2.2,27.3,2.7s11.5,0.8,15.2,2.2c3.7,1.3,14.5,5.3,14.5,5.3l0.8,0.2c0.6-0.9,1.3-1.8,1.8-2.9c2.5-5,2.9-40,2.9-47.5
                    s-2.5-9.9-6.2-11.6c-3.7-1.6-23.1-0.8-29.3-0.8c-6.2,0-17.3-5-18.2-8.3c-0.8-3.3-17.3-2.9-23.5-12.8c-6.2-9.9-4.5-24.8-4.5-28.9
                    c0-1.9,2.1-7.7,4.5-13.7l-0.4-0.7c0,0-12.5-7.2-15.5-7.8c-3-0.7-12-6.5-14.5-6.7s-7.5-2.7-10.3-2.7s-6-1.7-8-3s-7.5,0.5-11.7,1
                    s-17-6.5-20.2-10s-3.8-6.5-3.8-9.2s2.5-4.8,4.3-6.3s2.2-4.3,0.3-5.8c-1.8-1.5,0.7-5.2,1.3-6.8c0.7-1.7-2.8-1-4.7-1
                    c-1.8,0-4.2-3.2-5.5-3.7s0.2-6.2,1.2-8.7s3.5-8.3,4.3-9s-0.7-8.2-0.7-8.2s-5.5-0.8-7.5-1c-2.6-0.2-3.6,3.5-6.3,3.8
                    c-3.2,0.3-6.5-6.3-7-6.5s-9.5-0.7-9.5-0.7"/>
                <path style={props.district==='dad'?styles.select:{}} id='dad' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M72.5,155c0,0,5.2,1.3,7,3.7c1.8,2.3,7.5,4,9.3,5s6.3,3.7,6.3,3.7s2-7.2,4.5-4.8c2.5,2.3,7,4.7,9.7,6.5
                    s5.7,2.5,8.3,3.2c2.7,0.7,5.5,1,7,0.7s3.5-0.5,5,0.5s4.3,1.2,6.8,1.3c2.5,0.2,13.3,0.5,16-0.7s4.8-2.3,7.5-0.7c2.7,1.7,3.2,2,4.8,2
                    c1.7,0,6,0.7,7.7,1.2l-0.2,0.3c-1.6,1.7-3.4,3.5-3.5,4.6c-0.2,1.7-0.7,4-1.5,4.5s-2.5,1.5-0.5,3.5s6.7,5,6.7,5
                    c2.1,1.7,3.9,3.6,4.5,4.1l0.2,0.2c0,0-2.3,6-2.8,7.3s-7.3,1-11.2,1c-3.8,0-0.8,4.3,2.2,6s4,2.8,3.8,5.2c-0.2,2.3-3.8,2.7-5.3,2.8
                    c-1.5,0.2-0.3,2.7,0,4.3c0.3,1.7-3.2,2.8-4.8,3.3c-1.7,0.5-5.3,6.2-6.3,7.3c-1,1.2,2.7,7.3,3.3,10.8c0.7,3.5-1.8,7.5-2.8,9
                    s2.2,4.5,2.3,5.3c0.2,0.8-1.2,4.8-2,6.3s-3.8,4-5.3,4.2s-6.8,0.6-8.3,1.7c-1.7,1.2-1,1-4.7,2.2s-8.2,2.2-8,4.3
                    c0.2,2.2,2,8.5,1.3,10.3s-0.5,2.2-1.2,5.3c-0.7,3.2-4.2,7.3-5,9.3s-1,3.8-2.2,3.3s-7.5-1-8.3,0.3s-0.8,7.2-0.3,8s0.8,2.7,0.3,4.3
                    c-0.5,1.7-1.3,5.7-1.5,7.3c-0.2,1.7-0.7,4.5-1.8,5.7c-1.2,1.2-6.3,6-6.3,6l0.4-0.3c-1.2-1.8-3.2-3.9-4.9-5.4
                    c-2.8-2.5-4.3-6.7-4.7-8.5c-0.3-1.8-5-11.5-6.2-13.8s-5.5-12.2-8-16.5s-8.7-13.5-8.8-17.5c-0.2-4-3-11.7-2.3-14s-0.2-4.8-0.8-7.7
                    s3-7.2,3-7.2s-1.7-10.8-1.8-15.2c-0.2-4.3,0.2-8.5,1-10s0-3.7,0-6.2s0-4.5,0.3-6.7s1.2-6.3,1-10.2c-0.2-3.8-3.5-17.7-3.8-21.2
                    s2.3-9.7,3.2-11.5c0.8-1.8,1.5-7,2-9c0.1-0.6,0.3-2,0.6-3.7"/>
                <path style={props.district==='naf'?styles.select:{}} id='naf' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M217.8,267.3c0-0.7-6.2,1.2-13.2,2.5s-5.8,3-8.2,3.3c-2.3,0.3-5,0.3-7.7,1.3s-7.3,0.3-9.2-1.2
                    c-1.8-1.5-3,0.2-4.7,1s-2.3,0.3-5,0.3s-3,1.7-3.5,2.3c-0.5,0.7-3,2.3-5.2,2.5s-6.8-0.5-9-2s-3.3-5.7-3.3-5.7l0.3-0.1
                    c1.6-0.4,4.3-2.7,5-4.1c0.8-1.5,2.2-5.5,2-6.3s-3.3-3.8-2.3-5.3c1-1.5,3.5-5.5,2.8-9s-4.3-9.7-3.3-10.8c1-1.2,4.7-6.8,6.3-7.3
                    c1.7-0.5,5.2-1.7,4.8-3.3c-0.3-1.7-1.5-4.2,0-4.3c1.5-0.2,5.2-0.5,5.3-2.8c0.2-2.3-0.8-3.5-3.8-5.2s-6-6-2.2-6s10.7,0.3,11.2-1
                    s2.8-7.3,2.8-7.3l-0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2s4-0.2,5.3-0.2s1.2-6.2,1.7-9s1-0.7,3.2-1.2s7.2-4.5,7.2-4.5s0-0.1,0.1-0.3
                    l-0.1,0.3c0,0,5,1.5,6.7,1.8s3.3,1,5.5,1s8,2.7,8.3,4.3c0.3,1.7,1.3,3.7,2.8,4.8c1.5,1.2,6.5,6.5,6.8,7.5s2.5,7.3,1.7,9
                    s-3.3,4.8-4.3,5.5s-3.3,2.8-3.7,3.3c-0.3,0.5-3,6-3.8,7.7s-3.2,9-3.2,12s0.5,4.5,0.5,4.5l-5,17.7c0,0,4.3,2.1,8.8,3.7"/>
                <path style={props.district==='ben'?styles.select:{}} id='ben' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M217.9,267.2c0-0.7-6.2,1.2-13.2,2.5s-5.8,3-8.2,3.3c-2.3,0.3-5,0.3-7.7,1.3s-7.3,0.3-9.2-1.2
                    c-1.8-1.5-3,0.2-4.7,1s-2.3,0.3-5,0.3s-3,1.7-3.5,2.3c-0.4,0.5-2.1,1.8-4,2.3v0.1c0,0,2.8,7.3,1,9.2c-1.8,1.8-3.8,4.8-3.7,5.8
                    c0.2,1,4,4.7,4.3,5.7s1.2,2.5-0.8,2.5s-4,1.3-4.7,3.5s-1.3,4.7-0.2,6.3c1.2,1.7,3,7.3,5.7,9.2c2.7,1.8,10.8,5.3,10.8,6.2
                    c0,0.8-0.7,3.3,1.5,5.5s18.8,20.2,19.3,20.2s6.2,0.2,6.2,0.2s1.5,1.1,2.7,2.2h0.2c0,0,3-2.7,4.8-3.3c1.8-0.7,3-2,3.7-4.2
                    s3.2-5.7,4.3-6.5c1.2-0.8,5.2,0.3,7,1.3s3.8,2,5.2,1.3c1.3-0.7-1.8-8-2.5-9.3s-1.8-2.7-3.7-2.7c-1.8,0-1.7-3.5-1.3-4.2
                    c0.3-0.7,4.3-2.8,6-3.2c1.7-0.3,3.3-3,3.5-4.3s3.8-0.3,5,2s3.3,2.3,3.8,4.3s5,2.5,6.3,2.2s3-0.5,4.7,0.5s1.8-1.8,2.8-3.5
                    s-3.7-11-4-12.2s4-1.2,5.8-1.5s1.8-2.7,2.5-3.7s3-1,5.5,0.8s3.3-1,4.8-2.7s4.8-6.8,5.7-7.8c0.8-1,2.5-4.3,2.5-4.3v-0.1
                    c-0.1-0.5-0.9-3.1-1.3-5.2c-0.5-2.3-3.2-9.5-4.5-11.7s-6.5-3-10.5-4.5s-8.3-1.3-12.5-1.3s-7.8-1.3-11.5-2.2c-3.7-0.8-9-1-14.5-1.5
                    c-1.8-0.2-3.9-0.8-6.1-1.5"/>
                <path style={props.district==='jam'?styles.select:{}} id='jam' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M91,400c0,0,7.9,0.2,8.1,2.3c0.2,2.1,0.5,7.7,0.9,10.9c0.5,3.3,2.3,10.9,2.3,12.5s-5.1,15.3-5.6,17.6
                    s-2.1,2.3-3,4.2s-3.5,4.6-4.6,6.5c-1.2,1.9-5.1,7.4-1.4,10.2s20.9,7.7,23.9,12.8c2.6,4.5,3.7,9.3,3.9,11.7l0.1,0.5
                    c0,0,3.7,1.2,7,1.2s3.8-2.3,6-4.3s8.2-0.7,9.5-0.7s3.8-5.2,5.3-7s5.7-3.5,7.5-3.8s2.2-10.5,2.2-13.8s1-4.2,1.8-5.7s4.7-5.7,6.3-7.7
                    c1.7-2,1.7-3.7,1.3-6c-0.3-2.3,1.7-4.8,2.8-6.5c1.2-1.7,2.2-3.2,3.2-3.3s2.7-1.7,3.5-3.2s2.1-1.1,3.3-1.5c0.5-0.2,11.2,3.7,12.8,7.2
                    c1.7,3.5,3.3,8.8,4.8,10.2c1.5,1.3,2.2,2,5.7,2.2s6.7,0.8,8.8,1.2s7.3,0.5,7.3,0.5h0.3c0.1-0.2,0.1-0.3,0.2-0.5c1-2.5-3-8.2-4-9.5
                    s-1.5-6-1-7s0.5-4.7,0.8-6.8c0.3-2.2-0.4-4.5,0.6-5.4s6-2.7,6.8-3.5s0.5-3.3,0.5-3.3l-2.1-5.1c-0.5-2.5,2.6-5.4,4.1-5.7
                    s7-2.5,7.7-3.8s-4.2-3.4-7.1-3.9c-2.8-0.5-9.5-5.7-10.7-7.3s-0.7-3.8,1.2-4.8c1.8-1,1.8-3,1.7-5c-0.2-2-3-5.3-4.8-6.7s-5-2.5-6.3-5
                    s2.8-6.3,3.2-7.3c0.1-0.4-0.4-1.1-1.2-1.8c-1.1-1.1-2.7-2.2-2.7-2.2s-5.7-0.2-6.2-0.2s-17.2-18-19.3-20.2c-2.2-2.2-1.5-4.7-1.5-5.5
                    s-8.2-4.3-10.8-6.2c-2.7-1.8-4.5-7.5-5.7-9.2s-0.5-4.2,0.2-6.3c0.7-2.2,2.7-3.5,4.7-3.5s1.2-1.5,0.8-2.5c-0.3-1-4.2-4.7-4.3-5.7
                    c-0.2-1,1.8-4,3.7-5.8c1.8-1.8-1-9.2-1-9.2l0,0c-0.4,0.1-0.7,0.2-1.1,0.2c-2.2,0.2-6.8-0.5-9-2s-3.3-5.7-3.3-5.7l0.3-0.1
                    c-0.1,0-0.2,0-0.3,0.1c-1.5,0.2-6.8,0.6-8.3,1.7c-1.7,1.2-1,1-4.7,2.2s-8.2,2.2-8,4.3c0.2,2.2,2,8.5,1.3,10.3s-0.5,2.2-1.2,5.3
                    c-0.7,3.2-4.2,7.3-5,9.3s-1,3.8-2.2,3.3s-7.5-1-8.3,0.3s-0.8,7.2-0.3,8s0.8,2.7,0.3,4.3c-0.5,1.7-1.3,5.7-1.5,7.3
                    c-0.2,1.7-0.7,4.5-1.8,5.7c-1.2,1.2-6.3,6-6.3,6l0.4-0.3c0.8,1.2,1.3,2.3,1.3,3.1c-0.2,2-1.5,12-1.7,14.5s-0.2,5.2-1,7.5
                    s0.2,3.7,0.7,6.8c0.5,3.2-0.5,8.8-2,10.5s-6.2,6.7-6.8,8.8c-0.3,1.1-1.2,5.1-2.2,9.1"/>
                <path style={props.district==='kar'?styles.select:{}} id='kar' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M86.7,522.3c0,0,2.7-0.5,4.3-0.8c1.6-0.2,4.2-3,5.8-5.1c1.6-2.1,7.4,0,8.4-0.2s1.6-4.4,2.3-6.5
                    c0.7-2.1,7,2.5,9.3,4.2s4.9,3,6.5-0.7s-2.1-7.9-3.5-12.1s-4.2-9.5-4.2-11.4c0-0.2,0-0.5-0.1-0.9c-0.2-2.4-1.3-7.2-3.9-11.7
                    c-3-5.1-20.2-10-23.9-12.8s0.2-8.4,1.4-10.2c1.2-1.9,3.7-4.6,4.6-6.5s2.6-1.9,3-4.2c0.5-2.3,5.6-16,5.6-17.6s-1.9-9.3-2.3-12.5
                    s-0.7-8.8-0.9-10.9s-7.4-2.3-7.4-2.3l0,0c-1.1,4.1-2.2,8-3.1,8.3c-1.7,0.5-3,0.7-4,3s-3.2,5.3-4.3,6.8s-7.2,6.5-7.2,10.7
                    s0.2,6-1.3,8.3s-1.8,7-2.7,9.2c-0.8,2.2-6.3,8.8-8.7,10.5c-2.3,1.7-6,9.3-6.3,14.3s-4.3,7.3-6.5,9.5s-4.8,4.3-6.7,4.7
                    c-1.8,0.3-11.5,5.2-12.2,5.5s-8.7,8.8-11.3,7l0,0c-0.3,0.9-1.1,1.8-2.6,2.9c-5,3.5-5.4,7.7-1.1,8c4.2,0.4,14.2-2.7,19.2-2.3
                    c5,0.4,13.4,0,18,4.6s6.9,9.2,13.8,7.3c6.9-1.9,10.3-3.8,14.2-3.1c3.1,0.6,8,4,7.9,6.7"/>
                <path style={props.district==='tan'?styles.select:{}} id='tan' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M291.2,445.8c-2.2,3.7-6.6,4.6-8.3,5.1c-1.7,0.5-0.8,1.5-2.5,4.3s-4.5,1.5-7,2s-3,0.8-4.7,1.3s-2.2-3.8-2.3-4.8
                    c-0.1-0.9-1.6-1.8-3.3-2.3H263c0,0-1.1,1.1-2.1,2.3c-1,1.2-1,4.7-1.8,5.1c-0.6,0.3-2.6,1.2-4,1.8l-6.8,3.5h-0.2
                    c1.2-2.1,5.4-10.2,3.4-13.1l-0.3-0.4c-1.4-0.6-3.2-3.9-3.2-4.9c0-1.3-3.5-3.7-4.3-4.3c-0.8-0.7,1-1.8,2.5-1.3s3.5-2,2.5-2.8
                    s-0.2-2.2,0.2-3.5c0.3-1.3-1.8-3.7-2.8-4.7l0.2-0.2c0,0-0.2-4.2,0.6-6c0.9-1.8,1.4-8.3,1.7-10c0.3-1.6,2.6-2.6,5-4.4
                    c0.1-0.1,0.2-0.1,0.3-0.2c2.6-2,0.9-3.2,0-5.8s0-4.8,1.2-6.3c0.3-0.3,0.2-0.7,0-1v-0.1c0.6-0.4,1.4-1.3,1.6-2.2c0.2-1.2-0.3-3,2-3.5
                    s3.3-1,4.3-2s1.3-1.8,3.3-1.3s8.8,2.5,11.7,5c0.8,0.7,1.6,1.5,2.3,2.3c1.7,1.9,2.9,4.1,2.9,6.7c0,3.7-2.8,13.8-1.3,17.8
                    s5,7.5,6.3,8.8s2.3,9.2,2.8,11.5c0.4,1.8,0.2,4.3,0.3,8.1"/>
                <path style={props.district==='tmk'?styles.select:{}} id='tmk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M217.9,517.5c-1.3-2.1-3.4-4.1-4.1-4.4c-1-0.5-3.2-2.3-3.2-3.2s-0.7-2.5-2.3-3.7c-1.7-1.2-1.5-4-1.5-4.8
                    s2.7-4.5,3.2-5.3s0.7-2.5,0.7-3.3s-5.2-6.1-5.5-8.4s1.7-11.3,2-12.3s6.2-2.3,7.7-2.3s0.8-7.7,0.2-9.2c-0.7-1.5,1.2-3,1.2-3l0.1,0.1
                    c1,1.6,2.2,3,3,3.7c1.7,1.3,3.7,1.8,5.7,1.8s3.8-0.5,5.3,0s4.2,2,6,1.8s4.5,0,5-2s-0.2-4.3,1.7-3.2c0.9,0.6,1.8,1.4,2.3,2
                    c0.9,1,1.8,2,2.7,2.9c2.3,2.5,4.1,4.6,3.3,5.2c-3.1,2.1-7.4,4.5-7.4,4.5s4.7,4.5,6.9,5.9s6.9,5.3,9.4,5.9c2.4,0.6,5.9,4.1,5.5,5.1
                    s-1.8,2.9-1.4,3.7c0.4,0.8,2.9,3.5,0.4,5.3c-2.4,1.8-5.5,1.4-6.5-0.8c-1-2.2-4.3-3-4.7-1.8s-2.6,4.1-3.9,4.5s-2.2,2.9-5.3,0.8
                    c-3.1-2-4.1-3.7-5.9-1.6c-1.8,2-4.1,2.9-5.7,3.9c-1.6,1-2.9-0.6-3.9,3.7s-1.8,5.3-1.8,5.3l-9.6,3.5"/>
                <path style={props.district==='bad'?styles.select:{}} id='bad' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M291.4,445.7c-2.2,3.7-6.7,4.7-8.3,5.2c-1.7,0.5-0.8,1.5-2.5,4.3s-4.5,1.5-7,2s-3,0.8-4.7,1.3s-2.2-3.8-2.3-4.8
                    c-0.1-0.9-1.6-1.8-3.3-2.3h-0.1c0,0-1.1,1.1-2.1,2.3c-1,1.2-1,4.7-1.8,5.1c-0.6,0.3-2.6,1.2-4,1.8l-7.3,3.7l-0.3,0.1
                    c2.3,2.5,4.1,4.6,3.3,5.2c-3.1,2.1-7.4,4.5-7.4,4.5s4.7,4.5,6.9,5.9s6.9,5.3,9.4,5.9s5.9,4.1,5.5,5.1s-1.8,2.9-1.4,3.7
                    c0.4,0.8,2.9,3.5,0.4,5.3c-2.4,1.8-5.5,1.4-6.5-0.8c-1-2.2-4.3-3-4.7-1.8s-2.6,4.1-3.9,4.5s-2.2,2.9-5.3,0.8c-3.1-2-4.1-3.7-5.9-1.6
                    c-1.8,2-4.1,2.9-5.7,3.9c-1.6,1-2.9-0.6-3.9,3.7s-1.8,5.3-1.8,5.3l-9.1,3.3l0.1,0.1c0.7,1,1.1,2.1,1.1,2.9c-0.2,2.5,0.3,8.2,0,9.8
                    c-0.3,1.7,0.3,3,0.8,4.8s3.3,8.5,3.3,9.8s-0.5,3.3,0,5.8s1.8,3,1,5.3s-3,7.5,0.3,6.8s6.8-2,5.8,0.3s-1,3,2.8,5.7s8.2,4,10.5,3.3
                    s7.3-2.5,10.2-2.7c2.8-0.2,9.7-1.3,10,1.5s2,7.5,2,7.5l5.3,2.7l0.3,0.3c2.2-5.3,4,2.8,4.3,4.7c0.4,2.1,4.5,3.3,5.4,0.4
                    s4.1-4.1,12.4-1.2c3,1,8.1,1.4,13.6,1.5l-0.2-0.3c0,0-3.5-6.3-4.2-9.8s2-12.8,2.5-15s9.7-10.8,11.5-12.2c1.8-1.3,3.8,0.5,5.2-4.2
                    c1.3-4.7-1-5.5-2.2-6.5s2.2-2,4.3-0.5s1-4.2,0.8-5.2s-1-2.2-2.2-3.8c-1.2-1.7,4.2-4,5.7-5s0-1.3-1-3.5s1.7-2.5,3.5-2.8
                    s2.7-2,3.5-3.7c0.2-0.4,0.6-0.9,1.2-1.5l0,0c0,0-2.2-2.4-2.9-4c-0.7-1.7,2.2-5.7,2.5-7.7s-3.3-7.2-4.3-8.2s-5.2-1-9-0.8
                    s-6.8-3.3-9.2-4.7c-2.3-1.3-1.2-4-2-4.7s-3.7-3-4-4.7s-3.2-3-5.3-4.2c-2.2-1.2-6.5-3.5-7.7-5.5s-1.2-12.3-1.8-18
                    c-0.2-1.4-0.3-2.6-0.3-3.8"/>
                <path style={props.district==='kas'?styles.select:{}} id='kas' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M357.2,45.4c0,0,1.2-4.5,2.2-3.7s2.8,1.5,4.5,1.7s3.5-0.2,3.5-0.2v-0.3c-0.3-1-0.5-2.2-0.8-3.2
                    c-0.5-2-5.7-12.7-6.8-14.3c-1.2-1.7,2-2.2,3.7-0.5c0,0,1.5,2.5,1.3,3.8s1.7,0.5,4,0.3s7.8,4,9.3,6.8s4,0.8,6.7-0.3
                    c0.4-0.2,0.7-0.1,0.9,0.2h0.2l7.5-1.3l0.1-0.2c-0.1-1.6-0.2-3.1-0.3-3.7C393,29,382,19.4,382,19.4s-12-0.3-13.3-1s0.5-5.2,0.5-5.2
                    s-7.5-0.8-11.3-1.5s-9,1.2-10.3,2.3c-1.3,1.2-4.5,2.5-6.7,2.5s-22,0.3-26.7,1c-1.8,0.3-14.3,0.5-29.3,0.7h-0.1c0,0,0.2,19.3,0,20
                    s-2.5,4.8-2.3,6.7c0.2,1.8,0.3,4.7,0.3,5.8c0,1.2,1.3,4.5,1,6.3s-1.8,5.7,0.8,7.7l0.1,0.1c0.4-0.1,0.7-0.1,1.1-0.1
                    c1.7,0,9.8,1.5,11.3,3s3.5,5,4.3,6.3s2.5,5,4.2,5.7s2.8-3.3,2.8-3.3l6.9,4.2l0,0c0.6-1.4,2.5-3.2,3.3-4c1-1,0.2-5.3,0.7-6.5
                    s5.5-5.5,7.3-5.8s4.2-2.2,6.5-2.8c2.3-0.7,5.2-3.8,6-4.8s6.5,0.7,7.3,0.7s5.5-6.8,5.5-6.8l0.3-0.2c-0.1,0-0.1-0.1-0.1-0.1
                    c-1-0.8,0-5.7,0.3-7.8c0.2-1.6,2.6,0.8,4.4,3"/>
                <path style={props.district==='gho'?styles.select:{}} id='gho' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M394.1,34.2l-8.3,1.4l-0.2,0.1c0.9,1.4-1,7.7-2.3,9.3c-1.5,1.8-3.7-0.3-4.8-1c-1.2-0.7-3,1-4.3,2
                    s-4.2,0.5-5.5-0.7c-0.6-0.6-1.1-1.5-1.4-2.4v0.3c0,0-1.8,0.3-3.5,0.2s-3.5-0.8-4.5-1.7c-1-0.8-2.2,3.7-2.2,3.7l-0.1,0.1
                    c0.7,0.8,1,1.3,1.4,1.9c2,2.8,6.5,3.3,6.5,3.3s-0.8,1.8-2.2,3.2s0.2,4.7-0.5,6.8c-0.7,2.2-4.5-2-4.3-4.5c0.2-2.4-4.4-5.1-5.7-6h-0.1
                    c0,0-4.8,7-5.7,7c-0.8,0-6.5-1.7-7.3-0.7s-3.7,4.2-6,4.8c-2.3,0.7-4.7,2.5-6.5,2.8s-6.8,4.7-7.3,5.8c-0.5,1.2,0.3,5.5-0.7,6.5
                    c-0.8,0.8-2.7,2.7-3.3,4.2c-0.2,0.4-0.2,0.7-0.2,1c0.3,1.3,0.7,5,1.2,6.2s2.5,7.7,4.2,10.3c1.7,2.7,5.3,6.5,5.2,8.3
                    c-0.2,1.8,0.5,3.2,1,4.3s1,5.2-1.2,4.3c-2.2-0.8-5.5-2.3-6-1.3s0.5,3-2,2.5s-7.2,1.8-5,3.3s9.7,2,11.3,6.3c1.7,4.3,8,14.5,12.8,15.5
                    s5.8-0.2,9.3,1.5s5.7,2.3,9,1.7c3.3-0.7,7-1.3,7,0.3c0,1.7-2.3,7.8-2.3,7.8s8.3,8.7,11.3,9.2s8.8,3.2,10.8,4.7s6.2,4.8,6.2,4.8
                    l0.6-0.1c12.5-11.5,26.2-24,29.3-27.3c5.8-6.2,12-30.6,13.6-32.6c0.7-0.9,3.8-5.3,7.5-10.2c-1-3.3-4.9-6.3-7.9-7
                    c-3.2-0.7-5.5-2-7.5-3.2s-2.5-3.5-6-4.3s-4-3.3-4.5-5.5s-2.7-5-6.8-9.5c-4.2-4.5-7.2-9.3-8-12s-0.7-5.2,0.3-7.2s-3.3-11-3.8-12.2
                    C394.5,39.7,394.3,36.7,394.1,34.2"/>
                <path style={props.district==='hyd'?styles.select:{}} id='hyd' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M218.6,414h1.4l0.9-1.8h1.8l1.1,1.8v2.2l2.7,2l3.1,1.8v1h2l1-1.1l6.9-0.1l1.1,2.8l2,1.1h3.9l0,0
                    c-0.6,2-0.4,5.3-0.4,5.3v0.3c1,1,3,3.2,2.7,4.5s-1.2,2.7-0.2,3.5s-1,3.3-2.5,2.8s-3.3,0.7-2.5,1.3c0.8,0.7,4.3,3,4.3,4.3
                    c0,1,1.8,4.3,3.2,4.9l0.4,0.4c2.3,3.3-3.7,13.6-3.7,13.6l-0.3,0.1c-0.9-1-1.8-1.9-2.7-2.9c-0.6-0.6-1.4-1.4-2.3-2
                    c-1.8-1.2-1.2,1.2-1.7,3.2s-3.2,1.8-5,2s-4.5-1.3-6-1.8s-3.3,0-5.3,0s-4-0.5-5.7-1.8s-4.7-5.8-5.2-8c-0.4-1.9,0.2-3.2,1-5.2
                    c0.1-0.2,0.2-0.5,0.3-0.8c1-2.5-3-8.2-4-9.5s-1.5-6-1-7s0.5-4.7,0.8-6.8c0.3-2.2-0.4-4.5,0.6-5.4c1-0.8,6-2.7,6.8-3.5
                    C218.4,414.9,218.5,414.5,218.6,414z"/>
                <path style={props.district==='mat'?styles.select:{}} id='mat' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M246.5,423.8h-3.9l-2-1.2l-1.1-2.8l-6.9,0.2l-1.1,1.1h-2v-1l-3.1-1.8l-2.6-2.1v-2.1l-1.2-1.8h-1.8l-0.9,1.8
                    h-1.2l0,0c0.2-1,0-2.1,0-2.1l-0.7-2l0,0l0.1-0.3l-1.4-3c-0.5-2.5,2.7-5,4.2-5.3s7-2.5,7.7-3.8s-4.6-3.4-7.5-3.9
                    c-2.8-0.5-9.2-6.1-10.4-7.7c-1.2-1.7-0.7-3.8,1.2-4.8c1.8-1,1.8-3,1.7-5c-0.2-2-3.5-5.1-5.3-6.5c-1.8-1.3-4.4-2.1-5.7-4.6
                    s2.7-6.9,3.1-7.9c0.1-0.4-0.4-1-1.1-1.7l0.1-0.1c0,0,3-2.7,4.8-3.3c1.8-0.7,3-2,3.7-4.2s3.2-5.7,4.3-6.5c1.2-0.8,5.2,0.3,7,1.3
                    c0.5,0.3,1.2,0.3,1.7,0.6l0,0c0,0,1.4,4.1,4,3.4s7-2.5,8-2.5s3.2,1.3,1.4,2.7c-1.9,1.3-3.2,2.2-4.4,3.8c-1.2,1.7-2.2,5.2-2.6,7
                    s0,3.3-1.9,4.2c-1.9,0.8-4.9,2.8-1.9,3.7c3.1,0.8,8.5,3,6.8,5.2s-2.9,4.5-0.5,4.8s4.1,0.7,4.1,3.7s1.5,6.8,2.7,7.8s5.4,4.8,7.4,5.5
                    c1.5,0.5,4.7,1.7,5.7,2.7c0.3,0.4,0.4,0.7,0.1,1.1c-1.2,1.5-2.1,3.7-1.2,6.3c0.8,2.4,2.5,3.5,0,5.5c-0.1,0.1-0.2,0.1-0.3,0.2
                    l-0.1,0.1v0.2c-2.4,1.9-4.7,2.8-5,4.4c-0.3,1.7-0.9,8.1-1.7,10C246.7,423.3,246.6,423.5,246.5,423.8L246.5,423.8z"/>
                <path style={props.district==='tha'?styles.select:{}} id='tha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M451.4,392.6c3.1,2.9,5.5,4.4,7.9,4.6c8.7,0.8,39.2-3.3,39.2-3.3l-1.6,40.3c0,0,27.3,29.3,28.1,33
                    s2.5,25.6,6.6,28.9s19.8,28.1,16.9,31.4c-2.9,3.3-9.5,11.6-10.3,14.5s0,17.8,5.4,18.2c5.3,0.4,9.5,5.8,7.4,8.7
                    c-2.1,2.8-0.4,6.5-6.6,4.5s-19,1.7-20.2,5c-1.3,3.3,4.1,8.7-1.7,9.1c-5.7,0.4-35.9,5-36.7,2.1c-0.9-2.9,2.9-24.4,1.6-24.4
                    s-57,15.7-57,15.7l-10.7,17.8l-37.1,0.4c0,0-16.1-13.6-31-14.4c-14.8-0.8-21.4-0.8-23.1,0.4c-1.1,0.8-12,2-21.9,1.9l-0.2-0.3
                    c0,0-3.5-6.3-4.2-9.8s2-12.8,2.5-15s9.7-10.9,11.5-12.2c1.9-1.4,3.9,0.5,5.2-4.2s-1-5.5-2.2-6.5s2.2-2,4.3-0.5s1-4.2,0.8-5.2
                    s-1-2.1-2.2-3.8c-1.1-1.7,4.2-4,5.7-5s0-1.4-1-3.5s1.7-2.5,3.5-2.8c1.8-0.4,2.7-2,3.5-3.7c0.2-0.4,0.7-1,1.3-1.6
                    c2-1.9,5.4-4.4,6.4-4.8c1.3-0.5,6.1-2.3,8.8-3.5c0.8-0.3,1.9-1.2,3.1-2.3c1.5-1.3,3.1-3,4.5-4.4l0.2-0.2h0.4l0.5-1.1l0.9-1.8
                    l1.8-1.9l1.7-1.8l1.7-2l1.5-1.4l1-0.3v-0.7h0.9l0.2-2l5.9,0.2l2.2-1.3l1.7-1.8l1.1-1.4l1.8-2.9l0.9-1.8l0.7-2.9l0.9-3.7l1-2.9
                    l0.6-0.6l1-2.8l0.9-2.9l0.8-0.7l0.9-2.9l0.7-2.8v-0.8l1.8-0.8V451l2.6-0.2l2.8-0.7h2.5l2.4-1l0.8,0.2l2.4-0.2h0.9l1.1-1h1.5l3.9-0.1
                    l3.1,1.1l2.7,0.9l1.7,1.1l1.8-0.1l2.1,0.6l2.6,0.3l1.2,0.6l1.6,0.2l3.1-0.1l1.4,1l4,0.2l1.5,0.8h2l0.7-0.2l0.3-0.1v-2.8v-2.6
                    l0.8-0.7V447l-0.9-0.7v-2.6l0.9-1.1v-3.9l1.1-0.7v-3.4l0.8-0.3v-2.9l0.6-0.5V420l-0.8-1.1l0.1-6.3l-0.9-0.9l-0.1-1.1l0.1-0.7l0.7-1
                    l0.1-0.7l-0.7-0.2l-0.2-1.1l0.1-7.9h-1.1l0.3-1.1l0.8-0.8l0.9-0.9l1.7-1.8h0.6l0.4-0.9h1.6l0.7-0.9H451.4L451.4,392.6z"/>
                <path style={props.district==='san'?styles.select:{}} id='san' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M431.7,335.3c-2.4,3.3-5.4,6-6.1,11.2c-0.8,6.6,4.1,14.9,6.6,20.6c1,2.5,4.5,8.2,8.9,14l0.2,0.1
                    c0.8,1,1.5,2,2.3,3.1l0,0l-0.4,0.4h-1.7v0.6l-2.8,0.2l-0.8,0.8l-4.3,0.2h-0.5l-0.4,0.6l-0.7,0.2l-0.9-0.8l-0.7-0.2h-1.8l-1.5,0.1
                    l-1.2,0.9l-0.9,0.9l-0.8,0.2l-0.9,0.8l-0.7,0.7l-2.5,0.2l-0.2,0.7l-0.9,0.3l-0.9,0.9h-2.3l-0.9-1.1v-0.7l-1.9-1.1v-0.9l-0.7-0.7
                    l-2.4-0.2l-0.3,1l-1.8,1.8l-0.6,1l-0.8,0.9l-0.9,0.8l-0.9,0.7l-0.8,1.1l-1,1l-0.6,0.9h-1.9l-0.8,0.9l-1.7,0.9l-1.5,0.6l-1.2,1.2
                    h-0.8l-0.6,0.9h-1.5l-1.5,0.9v1.1l-0.8,0.7l-0.7,0.8l-0.9,1.3l-0.7,0.7v0.8l-1,0.8l-0.7,0.7v1.5l-0.3,2.4v2l-0.8,0.8h-2.7l-0.7,0.4
                    l-0.4,0.7h-2.8l-1.1,1l-0.6,0.8l-1.1,0.6l-0.4-0.5h-1.3L373,419l-1.1,1.5l-0.7,0.5l-3.5,0.5l-0.9-0.4v-0.7l-1.6-0.2l-2-0.2l-0.5-0.6
                    l-0.9-1.1l-1-0.7v-1.1h0.9l1,1.1l0.7-1.2v-0.8l1.8-1.8l0.5-0.9l-2.2,0.2l-0.9-1.2l-0.9-0.9l-1.8,0.2l-0.7-1l-1-0.1l-0.7-1l-1-0.8
                    v-1.2v-0.7l0.8-0.9l-0.8-0.9l-0.8-1v-0.7v-0.5l-0.7-0.6l-0.2-1.7v-0.9l-0.9,0.1H353h-0.8l-0.6-0.1l-0.4-1.8l-1.2-0.1l-1,0.7l-1,0.5
                    l-1.1-0.1h0.5l0.3-0.6l-1.8-1.6l0.4-1.8l-1.3-0.1l-0.7,0.8l-3-2.8l1.8-1.2l-0.4-2.5l-2.6,0.1l-0.6,1l-1.1-1.1v-1.7l-0.6-1.2h-1.1
                    l-1.7,1.2l-1-2.1l-0.4-1.1l-4.2,0.1V382l-0.6-0.8h-1.5l-0.5-1.1h-3.3l-1,1l-3.3,0.1l-1.3-1h-2.8l-0.5-1.1l-2.8-0.1l-2.7-0.4
                    l-0.6,2.3l2.7,3.1l-1.5,2.6l-2.2,0.1v-1h-2.3l-1.9,0.9v1l0.9,2.9l-1.7,0.9l-1.1-0.9l-0.8,1.9h-3.5v2.1l-0.9,0.7l-0.6-0.7l-0.9-1.2
                    l-0.9-0.2c0,0-1.1-0.7-1.2-0.7s-0.7-1-0.7-1l-1.8-0.9l-0.9-1l-1.6-1l-1-1h-0.9v1.9v1.1l-0.8-0.2l-0.9-0.7l-0.9-0.2h-2.7l-0.9,0.2
                    v0.8l0.9,1.1v0.9v1.6l-0.2-0.2c-0.7-0.8-1.5-1.6-2.3-2.3c-2.8-2.5-9.7-4.5-11.7-5s-2.3,0.3-3.3,1.3s-2,1.5-4.3,2s-1.8,2.3-2,3.5
                    c-0.1,0.9-1,1.8-1.6,2.2l-0.1-0.1c-1.1-1.1-4.3-2-5.8-2.5c-2.1-0.7-6.1-4.7-7.3-5.7s-2.7-4.8-2.7-7.8s-1.7-3.3-4.1-3.7
                    c-2.4-0.3-1.2-2.7,0.5-4.8c1.7-2.2-3.8-4.3-6.8-5.2c-3.1-0.8,0-2.8,1.9-3.7c1.9-0.8,1.5-2.3,1.9-4.2c0.3-1.8,1.4-5.3,2.6-7
                    s2.6-2.5,4.4-3.8c1.9-1.1-0.5-2.5-1.6-2.5s-5.3,1.6-7.8,2.3c-2.6,0.7-4.3-3.2-4.3-3.2h0.1c1.4,0.7,2.8,1.1,3.8,0.6
                    c1.3-0.7-1.8-8-2.5-9.3s-1.8-2.7-3.7-2.7c-1.8,0-1.7-3.5-1.3-4.2c0.3-0.7,4.3-2.8,6-3.2c1.7-0.3,3.3-3,3.5-4.3s3.8-0.3,5,2
                    s3.3,2.3,3.8,4.3s5,2.5,6.3,2.2s3-0.5,4.7,0.5s1.8-1.8,2.8-3.5s-3.7-11-4-12.2s4-1.2,5.8-1.5c1.8-0.3,1.8-2.7,2.5-3.7s3-1,5.5,0.8
                    s3.3-1,4.8-2.7s4.8-6.8,5.7-7.8c0.8-1,2.5-4.3,2.5-4.3v-0.1c0,0.1,0,0.1,0,0.1s3.3-3.5,4.8-3.7s4.7,2.5,5.5,4.5s0.3,6,2.3,7
                    s4.5,1.2,4.7,4.2s2.7,6.2,3.8,6.2s4.8,1.8,5,3.7c0.2,1.8,1.7,7,6.5,6.3s12.8-1.8,16.2-1.5c3.3,0.3,14.2,2.8,19.5,3.2
                    c5.3,0.3,13,1.3,16.8,0.8s8.3-0.8,11.7-0.7c3.3,0.2,22.3,2.2,27.3,2.7s11.5,0.8,15.2,2.2c3.7,1.3,14.5,5.3,14.5,5.3"/>
                <path style={props.district==='mik'?styles.select:{}} id='mik' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M280.4,393.6v-1.3v-0.9l-0.9-1.1v-0.8l0.9-0.2h2.7l0.9,0.2l0.9,0.7l0.8,0.2v-1.1v-1.9h0.9l1,1l1.6,1l0.9,1
                    l1.8,0.9c0,0,0.6,1,0.7,1s1.2,0.7,1.2,0.7l0.9,0.2l0.9,1.2l0.6,0.7l0.9-0.7v-2.1h3.5l0.8-1.9l1.1,0.9l1.7-0.9l-0.9-2.9v-1l1.9-0.9
                    h2.3v1l2.2-0.1l1.5-2.6l-2.7-3.1l0.6-2.3l2.7,0.4l2.8,0.1l0.5,1.1h2.8l1.3,1l3.3-0.1l1-1h3.3l0.5,1.1h1.5l0.6,0.8v1.8l4.2-0.1
                    l0.4,1.1l1,2.1l1.7-1.2h1.1l0.6,1.2v1.7l1.1,1.1l0.6-1l2.6-0.1l0.4,2.5l-1.8,1.2l3,2.8l0.7-0.8l1.3,0.1l-0.4,1.8l1.8,1.6l-1.2,2.1
                    l-1.6,0.7h-1.1l-0.8-0.7l-1,0.7h-4.2l1.7,2v2.1l1.7,1.6l-0.9,1h-1.5l-1,1.9v1.7l-7.3,0.3l-0.8,2.6h-1.5l-2.6-0.8H323l-0.6-2.7h-1.8
                    l-0.4,0.8l-0.1,3.8l-1,1v1.6l1.2,1.5l-0.2,2.2l1.2,1.7l-0.4,1.4l-0.9,1.1l1.5,1.5v-0.1l1.8,1.7l0.9,0.9h0.7l1.8,0.8l0.8,1l0.7,0.7
                    l0.8,2h1.1l0.8,1v2.5h1.8h2.4l0.7,1.4v2.3l1.2,1.1v3l1.8,1.4l0.7,1.7l0.9,1.5v0.5v2l0.8,1.5l-0.8,1v0.9l-0.9,0.6l0.8,1.3l0.2,1.8
                    l0.7,0.9v1.7l0.9,2l1.7,0.8l1.6,2.5v9.1l0.9,2.2l0.2,0.8l0.7,1l0.9,0.8l1.6,1.8l1.7,1.7l1,2l0.8,2v7.8l1,1.4h1.4h1.8h0.6v0.2
                    c-1.4,1.5-3,3.1-4.5,4.4c-1.2,1.1-2.3,2-3.1,2.3c-2.7,1.2-7.5,3-8.8,3.5c-1,0.4-4.4,2.9-6.4,4.8l-0.3-0.2c-0.8-1-2-2.5-2.4-3.5
                    c-0.7-1.7,2.2-5.7,2.5-7.7c0.4-2-3.3-7.2-4.3-8.2s-5.2-1-9-0.8c-3.9,0.1-6.9-3.4-9.2-4.7c-2.3-1.4-1.2-4-2-4.7s-3.7-3-4-4.7
                    s-3.1-3-5.3-4.2s-6.5-3.5-7.7-5.5c-1.1-2-1.1-12.3-1.8-18c-0.2-1.3-0.2-2.6-0.3-3.8c-0.2-3.8,0-6.7-0.4-8.5
                    c-0.5-2.3-1.5-10.2-2.8-11.5s-4.8-4.8-6.3-8.8s1.3-14.1,1.3-17.8c0-2.6-0.9-4.6-2.6-6.5l0,0L280.4,393.6z"/>
                <path style={props.district==='ume'?styles.select:{}} id='ume' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M451.4,392.6l-1.4,0.2l-0.7,0.9h-1.6l-0.4,0.9h-0.6l-1.7,1.8l-0.9,0.9l-0.8,0.8l-0.3,1.1h1.1l-0.1,7.9l0.2,1.1
                    l0.7,0.2l-0.1,0.7l-0.7,1l-0.1,0.7l0.1,1.1l0.9,0.9l-0.1,6.3l0.8,1.1v10.9l-0.6,0.5v2.9l-0.8,0.3v3.4l-1.1,0.7v3.9l-0.9,1.1v2.6
                    l0.9,0.7v1.2l-0.8,0.7v2.6v2.8l-0.3,0.1l-0.7,0.2h-2l-1.5-0.8l-4-0.2l-1.4-1l-3.1,0.1l-1.6-0.2l-1.2-0.6l-2.6-0.3l-2.1-0.6l-1.8,0.1
                    l-1.7-1.1l-2.7-0.9l-3.1-1.1l-3.9,0.1h-1.5l-1.1,1h-0.9l-2.4,0.2l-0.8-0.2l-2.4,1h-2.5l-2.8,0.7l-2.6,0.2v0.8l-1.8,0.8v0.8l-0.7,2.8
                    l-0.9,2.9l-0.8,0.7l-0.9,2.9l-1,2.8l-0.6,0.6l-1,2.9l-0.9,3.7l-0.7,2.9l-0.9,1.8l-1.8,2.9l-1.1,1.4l-1.7,1.8l-2.2,1.3l-5.9-0.2
                    l-0.2,2h-0.9v0.7l-1,0.3l-1.5,1.4l-1.7,2l-1.7,1.8l-1.8,1.9l-0.9,1.8l-0.5,1.1h-1.2h-1.8h-1.4l-1-1.4v-7.8l-0.8-2l-1-2l-1.7-1.7
                    l-1.6-1.8l-0.9-0.8l-0.7-1l-0.2-0.8l-0.9-2.2v-9.1l-1.6-2.5l-1.7-0.8l-0.9-2v-1.7l-0.7-0.9l-0.2-1.8l-0.8-1.3l0.9-0.6v-0.9l0.8-1
                    l-0.8-1.5v-2v-0.5l-0.9-1.5l-0.7-1.7l-1.8-1.4v-3l-1.2-1.1v-2.3l-0.7-1.4h-2.4h-1.8v-2.5l-0.8-1H329l-0.8-2l-0.7-0.7l-0.8-1
                    l-1.8-0.8h-0.7l-0.9-0.9l-1.8-1.7v0.1l-1.5-1.5l0.9-1.1l0.4-1.4l-1.2-1.7l0.2-2.2l-1.2-1.5v-1.6l1-1l0.1-3.8l0.4-0.8h1.8l0.6,2.7
                    h2.6l2.6,0.8h1.5l0.8-2.6l7.3-0.3v-1.7l1-1.9h1.5l0.9-1l-1.7-1.6v-2.1l-1.7-2h4.2l1-0.7l0.8,0.7h1.1l1.6-0.7l0.8-1.5l0,0l0.7,0.1
                    l1-0.5l1-0.7l1.2,0.1l0.4,1.8l0.6,0.1h0.8h0.9l0.9-0.1v0.9l0.2,1.7l0.7,0.6v0.5v0.7l0.8,1l0.8,0.9l-0.8,0.9v0.7v1.2l1,0.8l0.7,1
                    l1,0.1l0.7,1l1.8-0.2l0.9,0.9l0.9,1.2l2.2-0.2l-0.5,0.9l-1.8,1.8v0.8l-0.7,1.2l-1-1.1h-0.9v1.1l1,0.7l0.9,1.1l0.5,0.6l2,0.2l1.6,0.2
                    v0.7l0.9,0.4l3.5-0.5l0.7-0.5l1.1-1.5l0.7-0.5h1.3l0.4,0.5l1.1-0.6l0.6-0.8l1.1-1h2.8l0.4-0.7l0.7-0.4h2.7l0.8-0.8v-2l0.3-2.4v-1.5
                    l0.7-0.7l1-0.8v-0.8l0.7-0.7l0.9-1.3l0.7-0.8l0.8-0.7v-1.1l1.5-0.9h1.5l0.6-0.9h0.8l1.2-1.2l1.5-0.6l1.7-0.9l0.8-0.9h1.9l0.6-0.9
                    l1-1l0.8-1.1l0.9-0.7l0.9-0.8l0.8-0.9l0.6-1l1.8-1.8l0.3-1l2.4,0.2l0.7,0.7v0.9l1.9,1.1v0.7l0.9,1.1h2.3l0.9-0.9l0.9-0.3l0.2-0.7
                    l2.5-0.2l0.7-0.7l0.9-0.8l0.8-0.2l0.9-0.9l1.2-0.9l1.5-0.1h1.8l0.7,0.2l0.9,0.8l0.7-0.2l0.4-0.6h0.5l4.3-0.2l0.8-0.8l2.8-0.2v-0.6
                    h1.7l0.4-0.4l0,0c1.6,2.1,3.3,3.9,5.1,5.7l0,0c0.8,0.9,1.6,1.7,2.4,2.4L451.4,392.6z"/>
                <path style={props.district==='suj'?styles.select:{}} id='suj' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M271.1,582.6v1.3v2.9l-0.8,0.4v3.7v2.8v6.6c0,0-0.9,1.8-0.7,1.9c0.2,0.1-0.2,2.9-0.2,2.9v3.2v2.9l-0.1,1.6
                    l0.1,6.1v1.6l-0.2,1.1l-2.4,0l-0.7-0.9l-20.6,0l-7,0l-1,0.9l-8.8,0h-5.1l-1.2,0l-0.5-0.7v-0.9l-0.3-0.8l-1.4-0.4l-1,0.9l-0.7,0
                    l-1.7,1.8v3.4l-1.1,1.8c0,0-0.8,0-1,0s-1,0.1-1,0.1l-1.4-0.2l-0.4-0.7L209,626l-1,1l-0.7,1.4l-0.2,1.1l-0.7-0.1l-1,0.9v0.7l-0.8,0
                    l-1.4,1l-1.1,0l-0.7,0.7l-1,0v1.8l-1,0.8v7.1v2.4c0,0-1.9,0.2-1.9,0.2c-0.1,0-0.7,0.8-0.7,0.8v3.3c0,0-0.1,1-0.2,1
                    c0,0.1-0.5,0.1-0.5,0.1l-0.3,1.9v0.7l-0.8,0.8l-1.3-0.1l-1.1,1h-0.7l-1.6-0.7l-1.5-0.9l-0.7,0.9h-2.6l-1.5-0.4l-1.5-0.5l-1.1-1.2
                    V650l-2-0.8l-0.6-0.5l-1.3-0.6l-0.4-0.4h-1.2h-2.5h-1.6l-2.4-1l-0.7-0.4l-0.8-1l-0.9-0.9l-0.6-0.6l-1.3-1.3l-0.5-0.8l-2.1-0.4
                    l-2.3-0.8l-0.4-0.8c0,0-0.7,0-0.8,0c-0.1,0-1.2,0.1-1.6,0c-0.4,0-1.2-0.1-1.2-0.1l-2.2,0.2l-0.6,0.8l-0.8,0h-1H150l-3.2,0l-4.4,0
                    l-1.3,1h-1.3h-2l-1.4-0.5h-1.7l-1.9-0.9c0,0-0.6-0.8-0.8-1c-0.2-0.2-1.3-1-1.3-1l-1.9-1.9l-1.4-0.9l-1.5-0.9h-1.8l-1.7-0.8l-1.3-1
                    l-1.6-1.3l-0.6-1.3l-1.7-0.7l-1.9-0.9c0,0-0.5-0.3-0.6-0.3c0,0-1.2-0.5-1.3-0.5s-0.6-0.5-0.6-0.5s-0.9-0.4-1-0.5
                    c0,0-0.2-0.1-0.4-0.3l0.2-0.3l0.4-1.4c0,0,0.7-4.8,2.2-7.4s3.3-2.2,5.7-3.2c2.3-1,1.3-5.3,1.5-7.2c0.2-1.8-1.8-1.3-2.7-4.3
                    c-0.8-3,3.3-3,5.7-2.8c2.3,0.2,2.8-1.5,4.2-3c1.3-1.5,3.2,1.5,5,2.5s4.2-0.7,5.7-0.8c1.5-0.2,1.3-0.2,3.5-6.8
                    c2.2-6.7,3.8-2.5,8.5-3.8s-3.2-6-4-8.5s2.8-2.8,6-2.2c3.2,0.7,2-0.8,3-3.7c1-2.8-0.5-3.5-1.3-5s0-4.3,3-4.5s8.2,1.2,10,0.8
                    c1.8-0.3,0.5-9,0-11.5s1.3-2.3,3.3-3.5s1.8-3.7,1.3-7.7s-3.2-4.8-4.3-7.2c-1.2-2.3,5.8-11.7,7.5-14.7s1.7-4.8,1.3-8.7
                    c-0.3-3.8,4.8-5.2,9-3.7s0.3-3.8-1-6s-0.2-2.5,2.7-3.8c2.8-1.3,1-3.2,2.5-7s7.7,0.3,9,0.3s2.5-0.7,3.5-2s3.2-2.5,3.2-2.5l0.1,0
                    l0.1,0c1.6,2.5,4.3,5.4,4.3,6c0,0.8-0.3,2.5-0.8,3.3s-3.2,4.5-3.2,5.3s-0.2,3.6,1.5,4.8c1.6,1.2,2.3,2.9,2.3,3.7
                    c0,0.9,2.2,2.7,3.2,3.2c0.7,0.3,2.8,2.3,4.1,4.4c0.7,1,1.2,2.1,1.1,2.9c-0.2,2.5,0.3,8.1,0,9.8c-0.4,1.6,0.3,3,0.8,4.8
                    s3.3,8.5,3.3,9.8s-0.5,3.3,0,5.8s1.8,3,1,5.3c-0.9,2.3-3,7.5,0.3,6.8s6.8-2,5.8,0.3c-1,2.4-1,3,2.8,5.7c3.8,2.6,8.2,4,10.5,3.3
                    c2.4-0.7,7.4-2.5,10.2-2.7c2.8-0.2,9.7-1.3,10,1.5s2,7.5,2,7.5l5.3,2.7L271.1,582.6z"/>
                <path style={props.district==='tta'?styles.select:{}} id='tta' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M80.6,527.2h-0.4l-1.1,0.8l-0.9,0.9l-1.5,0.2l-0.9,0.6v1l-0.9,0.9l-0.7,0.8v0.9l-1,0.6v0.8c0,0-0.1,1-0.1,1.2
                    s0,2,0,2l0,0.7l0.9,1l0.9,0.9l0.2,0.6l0.6,0.7l1,0.9l0,0.9l0.9,0.9l-1,1l-0.9,0.8l0,0.8l-0.9,1l0.8,0.9l0,0.6l1,1.2l0,0.6l0.9,0.8
                    l0.7,0.8l1,0.6l0.6,0.5l1.1,1.7l0.8,0.9v2.6L81,560l0.5,1.1l0.3,1v1.5v0.9c0,0,0,0.4,0,0.5s1,1.4,1,1.4l1.4,0.6h1l1,1l-0.2,2.5
                    l0,0.8l0,0.9l0.2,0.7l0.8,0.4v1.4c0,0-0.8,0.6-0.8,0.7s-0.2,0.6-0.2,0.6l-0.1,1.1c0,0,0,0.9,0,1s0.1,0.5,0.1,0.5l-0.1,0.7l0.1,1.1
                    v0.8l0,0.5c0,0,0.6,0.2,0.6,0.3s0.4,0.5,0.4,0.5l-0.1,1.2l-0.5,0.5l0.6,1l0.7,0.7l1,1.1c0,0-0.1,0.5,0,0.5c0.1,0,0.7,0.4,0.7,0.4
                    s0.2,0.6,0.2,0.6s0,1,0,1.1c0,0.1,0,0.8,0,0.8l0.9,1.5l0.6,1.5l1.1,1.2l0.6,1.5l0.1,1.2c0,0,1,0.5,1,0.7s0,1,0,1s0.6,0.4,0.7,0.6
                    s0.9,0.9,1.1,1c0.1,0.1,0.7,0.8,0.7,0.8v1.8l-0.8,0.9c0,0,0.9,0.7,0.8,0.7c0,0.1,0.5,1.3,0.5,1.3l0.3,1l0.3,1.2l0.4,1.1l0.8,0.7
                    l0.4,1l0.7,0.7v3.5l0.7,0.9l2.6,2.4l0.1,1.5v0.9l0.9,0.4c0,0,0.4,0.9,0.7,0.9s1.5,0,1.5,0l1,0.1l0.4,0.6l0.9,0.6l0.6,0.4l0.8,1.5
                    c0,0,0.7,0.4,0.8,0.5c0,0,0.2,0.1,0.4,0.2l0.2-0.4l0.4-1.4c0,0,0.7-4.8,2.2-7.4s3.3-2.2,5.7-3.2c2.3-1,1.3-5.3,1.5-7.2
                    c0.2-1.8-1.8-1.3-2.7-4.3c-0.8-3,3.3-3,5.7-2.8c2.3,0.2,2.8-1.5,4.2-3c1.3-1.5,3.2,1.5,5,2.5s4.2-0.7,5.7-0.8
                    c1.5-0.2,1.3-0.2,3.5-6.8c2.2-6.7,3.8-2.5,8.5-3.8s-3.2-6-4-8.5s2.8-2.8,6-2.2c3.2,0.7,2-0.8,3-3.7c1-2.8-0.5-3.5-1.3-5s0-4.3,3-4.5
                    s8.2,1.2,10,0.8c1.8-0.3,0.5-9,0-11.5s1.3-2.3,3.3-3.5s1.8-3.7,1.3-7.7s-3.2-4.8-4.3-7.2c-1.2-2.3,5.8-11.7,7.5-14.7
                    s1.7-4.8,1.3-8.7c-0.3-3.8,4.8-5.2,9-3.7s0.3-3.8-1-6s-0.2-2.5,2.7-3.8c2.8-1.3,1-3.2,2.5-7s7.7,0.3,9,0.3s2.5-0.7,3.5-2
                    s3.2-2.5,3.2-2.5v-0.1c-0.6-0.9-1.1-1.8-1.1-2.4c-0.3-2.3,1.5-11.2,1.8-12.2s6.5-2.4,8-2.4s1.1-7.7,0.2-9.1c-0.7-1.5,0.9-3,0.9-3
                    l0,0c-1-1.5-1.8-3.1-2-4.1c-0.4-1.9,0.2-3.2,1-5.2l-0.2-0.3c0,0-5.2-0.2-7.3-0.5c-2.2-0.3-5.3-1-8.8-1.2s-4.2-0.8-5.7-2.2
                    c-1.5-1.3-3.2-6.7-4.8-10.2c-1.7-3.5-12.3-7.3-12.8-7.2c-1.3,0.4-2.5,0-3.3,1.5s-2.5,3-3.5,3.2s-2,1.7-3.2,3.3
                    c-1.2,1.7-3.2,4.2-2.8,6.5c0.3,2.3,0.3,4-1.3,6c-1.7,2-5.5,6.2-6.3,7.7s-1.8,2.3-1.8,5.7c0,3.3-0.3,13.5-2.2,13.8
                    c-1.8,0.3-6,2-7.5,3.8s-4,7-5.3,7s-7.3-1.3-9.5,0.7s-2.7,4.3-6,4.3s-7.1-1.2-7.1-1.2l0,0c0,0.3,0,0.1,0,0.4c0,1.9,2.8,7.2,4.2,11.4
                    s5.1,8.4,3.5,12.1s-4.2,2.3-6.5,0.7s-8.6-6.3-9.3-4.2s-1.4,6.3-2.3,6.5s-6.7-1.9-8.4,0.2c-1.6,2.1-4.2,4.9-5.8,5.1
                    c-2.2,0.5-4.9,1.1-4.9,1.1c0,0.6-0.3,1-0.9,1.5c-1.4,1.1-3.2,2.2-4.7,3.4"/>
            </svg>
        </>
    )
}

export default SndMap;