const DisclaimerPopup = ({ closePopup }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <h1>Disclaimer</h1>
          <p>pakexplore.pk is a tourism guidance information website created for the purpose of providing information and guidance to tourists who wish to explore the beauty of Pakistan. While we make every effort to ensure the accuracy and reliability of the information presented on this website, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p> 
          <p>Please note that the maps and illustrations featured on this website are intended for general guidance and may not reflect precise or accurate information. While we strive to provide accurate representations, we cannot guarantee the absolute accuracy of these materials. Users should exercise caution and verify information independently for any critical decisions.</p>
          <p>Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
          <p>Through this website, you may be able to link to other websites which are not under the control of pakexplore.pk. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
          <p>Every effort is made to keep the website up and running smoothly. However, pakexplore.pk takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
          <p>The information provided on this website is intended for general information purposes only and should not be considered as professional advice.</p>
          <button onClick={closePopup}>Close</button>
        </div>
      </div>
    )
}

export default DisclaimerPopup