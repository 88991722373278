import {useState,useEffect} from 'react'
import GbMap from '../images/GbMap'
import './provincePage.css'
import HomePageHeader from './HomePageHeader.jsx';
import KpMap from '../images/KpMap.jsx';
import PjbMap from '../images/PjbMap.jsx';
import BlnMap from '../images/BlnMap.jsx';
import SndMap from '../images/SndMap.jsx';
import AjkMap from '../images/AjkMap.jsx';
import DistrictInfo from './DistrictInfo.jsx';
import {useNavigate} from 'react-router-dom'
import { useParams} from "react-router-dom";
import Footer from './Footer';
import {isMobile} from 'react-device-detect'
import axios from 'axios'
import { url } from '../dataFiles/data';
import PulseLoader from "react-spinners/PulseLoader";

function ProvincePage() {
  const [district,setDistrict]=useState('');
  const [gbClicked,setGbClicked]=useState(false);
  const [kpClicked,setKpClicked]=useState(false);
  const [pjbClicked,setPjbClicked]=useState(false);
  const [blnClicked,setBlnClicked]=useState(false);
  const [sndClicked,setSndClicked]=useState(false);
  const [ajkClicked,setAjkClicked]=useState(false);
  const [mouseActionHold,setMouseActionHold]=useState(false);
  const [availableDistricts,setAvailableDistricts]=useState(null);
  const [clickedProvince,setClikedProvince]=useState('');
  const [showBottomDiv,setShowBottomDiv]=useState(window.innerWidth<650)
  const {province}=useParams()
  const updateShowBottomDiv=()=>{
    setShowBottomDiv(window.innerWidth<650)
  }
  const furl=url;

  const updateAvailableDistricts=()=>{
    let clkProvince
    switch(province){
      case 'gb': clkProvince='gilgit baltistan';
      break;
      case 'kp': clkProvince='khyber pakhtunkhwa';
      break;
      case 'pjb': clkProvince='punjab';
      break;
      case 'bln': clkProvince='balochistan';
      break;
      case 'snd': clkProvince='sindh';
      break;
      case 'ajk': clkProvince='azad jammu & kashmir';
      break;
      default: break;
    }
    const url = `${furl}get/districts/available/${clkProvince}`;
    axios.get(url).then((data)=>{
        data.data.forEach((district)=>{
        const element = document.getElementById(district)
        element.classList.remove('st0')
        element.classList.add('str');
      })
        setAvailableDistricts(data.data)
      }).catch((err)=>{
        console.log(err)
      })
    }

  useEffect(()=>{
    window.addEventListener('resize', updateShowBottomDiv)
    let mouseHoldTimer
    switch(province){
      case 'gb': setGbClicked(true);
      setClikedProvince('gilgit baltistan');
      break;
      case 'kp': setKpClicked(true);
      setClikedProvince('khyber pakhtunkhwa');
      break;
      case 'pjb': setPjbClicked(true);
      setClikedProvince('punjab');
      break;
      case 'bln': setBlnClicked(true);
      setClikedProvince('balochistan');
      break;
      case 'snd': setSndClicked(true);
      setClikedProvince('sindh');
      break;
      case 'ajk': setAjkClicked(true);
      setClikedProvince('azad jammu & kashmir');
      break;
      default: break;
    }
    updateAvailableDistricts();
    if (mouseActionHold) {
      mouseHoldTimer = setTimeout(() => {
        setMouseActionHold(false);
      }, 3000);
    }
    return () => {
      window.removeEventListener('resize', updateShowBottomDiv)
      clearTimeout(mouseHoldTimer)
    }
  },[province,clickedProvince,mouseActionHold])

  const navigate=useNavigate();
  function handleDistrictClick(e){
    if(availableDistricts!==null && availableDistricts.includes(district) && (!mouseActionHold || e.target.id==='nextButton'))
      navigate('/'+province+'/'+district);
  }
  
  function handleProvinceMouseEnter(e){
    if(!mouseActionHold || isMobile){
      setDistrict(e.target.id);
    }
  }
  function handleProvinceMouseLeave(){
    //setDistrict('')
  }
  
  function handleDistrictSelect(e){
    setDistrict(e.target.value);
    setMouseActionHold(true);
  }


  const legends=
      <div className="color-legend-container">
        <div className="color-legend-item">
          <div className="color-square red"></div>
          <span className="legend-text">No locations available</span>
        </div>
        <div className="color-legend-item">
          <div className="color-square blue"></div>
          <span className="legend-text">Locations available</span>
        </div>
      </div>

  const override: CSSProperties = {
    display: "block",
    margin: showBottomDiv?'auto':''
  };

  return (
    <>
      <HomePageHeader/>
      <div className='main-container'>
        <div className='header-place'>
        </div>
        <div className='map-place'>
          {gbClicked && <GbMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
          {kpClicked && <KpMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
          {pjbClicked && <PjbMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
          {blnClicked && <BlnMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
          {sndClicked && <SndMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
          {ajkClicked && <AjkMap district={district} handleDistrictClick={isMobile?handleProvinceMouseEnter:handleDistrictClick} handleProvinceMouseEnter={handleProvinceMouseEnter} handleProvinceMouseLeave={handleProvinceMouseLeave}/>}
        </div>
        <div className='district-info-place'>
          <DistrictInfo province={clickedProvince} display={district!==''} districtName={district} handleDistrictSelect={handleDistrictSelect}/>
        </div>
        {district!=='' && availableDistricts!==null && availableDistricts.includes(district) && <button id='nextButton' onClick={handleDistrictClick} className='button'>Next</button>}
        {!showBottomDiv && <div className='province-text-container'>
            <div className='province-text'>{clickedProvince}</div>
            {availableDistricts===null?<div>Loading Districts <PulseLoader cssOverride={override}/> </div>:legends}
        </div>}
        {showBottomDiv && <div className='bottom-province-text-container'>
            {availableDistricts===null?<div>Loading Districts <PulseLoader cssOverride={override}/> </div>:legends}
            <div className='province-text'>{clickedProvince}</div>
        </div>}
      </div>
      <Footer/>
    </>
  );
}

export default ProvincePage;