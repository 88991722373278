import './ProvinceMaps.css'

function GbMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="gb-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 2194 1502.7" style={{enableBackground:'new 0 0 2194 1502.7'}} xmlSpace="preserve">
            <g>
                <g>
                    <path style={props.district==='hnz'?styles.select:{}} id='hnz' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1601.8,598.2c-2.1-6.7-3.9-14.7-4.2-18.9c-0.9-13,14.8-27,26.9-29.5c12.2-2.6,0.9-21.7,0-29.5
                        s15.7-6.1,26.9-8.7c11.3-2.6-11.3-20-15.7-29.5s-13-11.3-15.7-26.1s7.8-24.3,19.1-30.4c11.3-6.1-13-46.9-13-46.9
                        s-13.3-29.8-21.4-33.3s-3.5-29-1.2-33.6s-41.7-46.4-54.5-52.1c-12.7-5.8-70.7-31.3-91.6-31.3s-12.8,4.6-19.7,12.8
                        c-6.9,8.1-18.5,2.3-31.3,0c-12.7-2.3-25.5-11.6-29-32.5s3.5-45.2,2.3-61.4s-32.5-24.3-44.1-25.5s-20.9,0-41.7,0
                        s-38.3-16.2-61.4-19.7c-23.2-3.5-35.9,13.9-56.8,27.8c-20.9,13.9-30.1-22-40.6-31.3c-10.4-9.3-61.4-1.2-80-1.2
                        c-18.5,0-22,6.9-28.3,17.3c-2.8,4.6-1.7,10.3-2.4,13.7c-2.6,11.3-22.6-8.7-25.2-11.3c-2.6-2.6-12.2-3.5-12.2-3.5
                        s-22.6-8.7-30.4-2.6s-50.8,52-68.2,48.5s-35.6-9.6-39.1-0.9s-7,32.2-17.4,33.9c-5.9,1-21.8,1.4-29.5,2.7l-0.1-0.2
                        c0,0,3.9,9.6,12.1,11.2c8.5,1.6,17.6,4,16.9,12.8c-0.6,7.6-7,22,0.6,23.8c7.5,1.7,18-0.6,24.3,1.7c6.4,2.3,8.7,1.2,8.7,7.5
                        c0,6.3-7,9.3,1.7,12.2s18.5,3.5,23.8,8.1c5.2,4.6,22,12.2,22,23.2c0,4.1,0.3,3.6-0.1,7.1c0,0,11-0.8,15.2-3.4
                        c4.2-2.5,13.5-11.8,22.7-8.4s20.2,6.7,20.2,15.2s-0.8,12.6,4.2,19.4c5.1,6.7,30.3,30.3,26.1,35.4c-4.2,5-19.4,7.6-29.5,22.7
                        c-10.1,15.2-12.6,11-6.7,20.2c5.9,9.3,22.7,23.6,15.2,30.3c-7.6,6.7-24.4,27-29.5,39.6c-5,12.6-16.8,25.3,6.7,25.3
                        s60.6,3.4,69.1-5.9c8.4-9.3,18.5-32.9,37.1-28.6c18.5,4.2,37.9,11,55.6,6.7c17.7-4.2,24.4-8.4,41.3-5.9
                        c16.8,2.5,26.1,7.6,35.4,0.8c9.3-6.7,23.6-7.6,35.4,0s16.8,13.5,30.3,6.7c13.5-6.7,23.6-16,32.8-12.6s5.1,5,9.3,17.7
                        c4.2,12.6,15.8,30.2,25.9,36.9l0,0c12.1-6.4,8.1-7.7,16.2-12.4c8.1-4.6,13.3-1.7,17.4,1.7c0,0,22.6,17.4,26.7,27.2
                        s12.2,12.2,23.2,19.7s35.9,16.2,46.4,14.5c10.4-1.7,23.2-7,33-5.2c9.8,1.7,26.7,15.7,26.1,20.3s-0.6,14.5,5.8,16.2
                        s9.3,1.2,9.8,9.8s2.3,12.8,12.2,13.3c9.8,0.6,19.1,2.9,25.5-1.2c6.4-4.1,27.6-20.2,30.4-20.8"/>
                </g>
                <g>
                    <path style={props.district==='gzr'?styles.select:{}} id='gzr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M679.9,704.9L679.9,704.9L679.9,704.9c-6.1,0.3-21.6-7.4-30.3-11.8c-9.3-4.6-15.1,2.9-22.6,5.8
                        c-7.5,2.9-13.9-1.2-23.8-5.8c-9.8-4.6-7.5,5.2-13.9,9.3s-14.5-3.5-19.1-7s-25.5-5.2-37.1-9.3s-15.1-17.4-16.8-22
                        s-20.3-3.5-35.4-1.7s-8.1,18.5-8.7,26.1c-0.6,7.5-4.6,12.2-12.8,15.1c-6.1,2.2-10.7,7.3-12.6,9.7l0,0
                        c-3.4-7.6-11.6-12.8-23.4-12.8c-15.9,0-35.8,1.3-50.4,4s-42.4,1.3-55.7-1.3c-13.3-2.6-26.5-9.3-41.1-2.6
                        c-14.6,6.6-27.9,19.9-50.4,19.9s-58.3-26.5-63.6-37.1s5.3-35.8,11.9-51.7c6.6-15.9-4-29.1-10.6-42.4c-6.6-13.3,0-27.8,4-38.4
                        c4-10.6,2.6-26.5,6.6-47.7s31.8-14.6,43.7-14.6s21.2-9.3,29.2-19.9s35.8-27.9,42.4-34.5s14.6-26.5,15.9-41.1s31.8-18.6,42.4-23.9
                        s23.9-25.2,29.1-29.1c5.3-4,12-42.4,8-51.7c-4-9.3,14.6-25.2,22.5-31.8c8-6.6,42.4-4,62.3-5.3s35.8-10.6,55.7-11.9
                        c19.8-1.3,67.6,8,82.2,17.3c1.3,0.8,2.6,1.5,3.9,2l0,0l0,0l0.4-1.4c15,6.1,30.7-2.2,48.5-4.8c18.6-2.7,25.2,1.3,51.7,2.6
                        s2.6-22.5,3.4-28.3c0.8-6.1-5.7-11.1-8.3-13c-5.7-4.3-23-18.6-35.9-21.5s-37.3-30.1-37.3-30.1l-0.7-1.7c2.3-1,4.5-1.8,6.5-2.5
                        c14.3-4.8,22.9-2.9,40.1,1.9c17.2,4.8,29.6-0.9,47.7-5.7c18.2-4.8,21,15.3,25.8,20l20,24.2c0,0,11.3-1,28-2.7l0,0
                        c0,0,4.7,7.9,12.8,10.2s17.6,4.3,16.8,12.8c-0.7,7.6-6.8,22,0.6,23.8c7.6,1.8,18-0.6,24.3,1.7c6.4,2.3,8.7,1.2,8.7,7.5
                        c0,6.3-7,9.3,1.7,12.2s18.5,3.5,23.8,8.1c5.2,4.6,22,12.2,22,23.2s-2.3,22-11,20.9s-15.1-2.9-21.4,0.6
                        c-6.4,3.5-12.2,5.8-13.3,15.1c-1.2,9.3-3.5,17.4-9.8,17.4c-6.4,0-16.2-3.5-16.8,5.8s0,9.3-6.4,18s-20.9,20.3-11.6,30.7
                        c9.3,10.4,7.5,14.5,2.3,18.5c-5.2,4.1-12.2,12.2-17.4,19.7s-5.8,21.4-5.8,35.9s4,20.3,8.7,28.4c4.6,8.1,11.6,13.3,16.8,20.9
                        c5.2,7.5,10.4,7,7,18.5c-3.5,11.6-7.5,12.8-5.2,22c2.3,9.3,5.8,22.6,3.5,26.1s-12.8,17.4-12.8,27.8c0,10.4,4.1,24.9-1.7,26.1
                        c-5.8,1.2-29,8.7-29,11.6s2.3,18.5-3.5,19.7s-17.4-1.7-18.5,8.1s-1.2,19.1-1.2,19.1c-5.1-3.9-15.5-0.6-20.9-0.6
                        c-6.4,0-11.7-0.8-14.5-7.5c-2.9-7-21.4,0-27.8,0s-13.9,3.5-19.1,5.2c0,0,0.1,0.2-1.6,1l-0.1-0.6"/>
                </g>
                <g>
                    <path style={props.district==='ngr'?styles.select:{}} id='ngr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1328.8,515.2c-10.1-6.7-21.9-24.4-26.1-37.1c-4.2-12.6,0-14.3-9.3-17.7c-9.3-3.4-19.4,5.9-32.8,12.6
                        c-13.5,6.7-18.5,0.8-30.3-6.7c-11.8-7.6-26.1-6.7-35.4,0s-18.5,1.7-35.4-0.8s-23.6,1.7-41.3,5.9c-17.7,4.2-37.1-2.5-55.6-6.7
                        s-28.6,19.4-37.1,28.6c-8.4,9.3-45.5,5.9-69.1,5.9s-11.8-12.6-6.7-25.3c5-12.6,21.9-32.9,29.5-39.6s-9.3-21.1-15.2-30.3
                        c-5.9-9.3-3.4-5,6.7-20.2s25.3-17.7,29.5-22.7s-21.1-28.6-26.1-35.4s-4.2-11-4.2-19.4s-11-11.8-20.2-15.2
                        c-9.3-3.4-18.5,5.9-22.7,8.4s-15.2,3.4-15.2,3.4l-0.3-0.1c-0.9,8.2-3.8,14.7-10.6,13.8c-8.7-1.2-15.1-2.9-21.4,0.6
                        c-6.4,3.5-12.2,5.8-13.3,15.1c-1.2,9.3-3.5,17.4-9.8,17.4c-6.4,0-16.2-3.5-16.8,5.8s0,9.3-6.4,18s-20.9,20.3-11.6,30.7
                        c9.3,10.4,7.5,14.5,2.3,18.5c-1.9,1.5-4.1,3.5-6.3,5.9l2.8-2.7c0,0-7.9,2.5,0.5,16.8s20.2,21.1,23.6,40.4s13.5,25.3,21.1,33.7
                        c7.6,8.4,14.3,20.2,21.9,20.2s42.1,0,47.2,7.6c5,7.6,23.6,46.3,31.2,39.6c7.6-6.7,7.6-24.4,25.3-27s49.7-2.5,54.8,1.7
                        s14.3,12.6,19.4,14.3s18.5,1.7,23.6,10.1c5.1,8.4,10.9,19.4,22.7,19.4s36.2-1.7,36.2,5.9s4.5,19.2,18.8,17.5l0.2,0.3
                        c-3.8-7.8,1.5-16.2,6-20.7c5.2-5.2,18.5-9.3,28.4-10.4s9.8-6.4,12.2-8.7c2.3-2.3,9.3,8.1,11.6,13.9c2.3,5.8,2.9,11,6.9,11.6
                        c4.1,0.6,13.3-2.3,18.5-6.4c5.2-4.1,19.7-17.4,19.7-17.4l30.7-66.1c0,0,4.6-0.6,15.1-1.7c4.4-0.5,8.7-2.9,12.9-5.9"/>
                </g>
                <g>
                    <path style={props.district==='glt'?styles.select:{}} id='glt' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1166.5,626.2c-14.3,1.7-19.4-10.1-19.4-17.7s-24.4-5.9-36.2-5.9c-11.8,0-17.7-11-22.7-19.4
                        c-5.1-8.4-18.5-8.4-23.6-10.1c-5.1-1.7-14.3-10.1-19.4-14.3s-37.1-4.2-54.8-1.7s-17.7,20.2-25.3,27s-26.1-32-31.2-39.6
                        c-5-7.6-39.6-7.6-47.2-7.6c-7.6,0-14.3-11.8-21.9-20.2c-7.6-8.4-17.7-14.3-21.1-33.7s-14.6-26.4-23-40.7
                        c-5.6-10.4-3.4-13.3-2.2-14.7l-0.5-0.5c-4.3,4.3-8.5,9.6-12.3,15.1c-5.2,7.5-5.8,21.4-5.8,35.9s4,20.3,8.7,28.4
                        c4.6,8.1,11.6,13.3,16.8,20.9c5.2,7.5,10.4,7,7,18.5c-3.5,11.6-7.5,12.8-5.2,22c2.3,9.3,5.8,22.6,3.5,26.1s-12.8,17.4-12.8,27.8
                        c0,10.4,4.1,24.9-1.7,26.1c-5.8,1.2-29,8.7-29,11.6s2.3,18.5-3.5,19.7s-17.4-1.7-18.5,8.1s-1.2,18.7-1.2,18.7l-0.4-0.2
                        c1.7,0.9,2.8,2.4,3.3,4.7c1.7,9.8,6.4,13.3,17.4,12.2c11-1.2,14.5,1.2,16.2,6.4s7,12.8,12.2,14.5s8.1-3.5,19.7,8.7
                        c11.6,12.2,22.6,22,28.4,30.1c5.8,8.1,23.8,37.1,30.7,36.5s15.7-6.4,23.2,1.2c7.5,7.5,20.9,20.3,27.2,24.9c6.4,4.6,1.2,7.5,11,4.1
                        c9.8-3.5,12.8-1.2,18.5,2.3s13.3,7,20.3,4.1s16.8-4.6,19.7-7.5s9.3-18,15.7-18.5c6.4-0.6,20.3-4.6,20.3-9.8s0-12.2,4.6-19.1
                        c4.6-7,8.7-15.7,6.9-18.5c-1.7-2.9-11.6-18.5-8.1-22s9.3-7,5.8-12.8s-7.5-15.7-3.5-19.7c4.1-4.1,15.7-14.5,23.8-17.4
                        c8.1-2.9,22.6-5.2,22,0.6s-5.8,16.2,0,22.6c5.8,6.4,9.3,20.9,8.1,22.6s-12.8,10.4-5.2,15.1c1.9,1.2,3.7,2.2,5.4,3l-0.1,0.1
                        c5.8-7.8,21.1-15.5,27.7-19.9c6.9-4.6,8.1-12.2,9.8-15.7c1.7-3.5,9.8-8.1,15.1-9.3c5.2-1.2,4.1-6.4,7.5-15.1
                        c3.5-8.7,6.4-5.8,23.8-9.3s4.1-13.3,1.7-24.9c-2.3-11.6-2.9-11.6-10.4-15.1s-5.2-1.2-2.9-15.7c2.3-14.5-2.9-10.4-11-19.1
                        c-1.3-1.4-2.2-2.8-2.8-4.3"/>
                </g>
                <path style={props.district==='dmr'?styles.select:{}} id='dmr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M694.2,876.5L694.2,876.5l-0.2,0.2c0,0-30.5-6.6-50.3-6.6s-53-4-66.3-2.6c-7.7,0.8-15.2,1.6-23.7,1.1l0,0
                    l-0.5-0.5l0.5-0.1c-6.3-0.3-13-1.4-20.5-3.7c-17.2-5.3-14.6-41.1-22.5-50.3c-8-9.3-30.5-15.9-54.3-15.9S406,775.6,419.3,757
                    c13.3-18.5,29.2-23.8,29.2-35.8c0-3.3-0.6-5.7-2-8.5l0,0c1.9-2.3,6.8-7.7,13.1-9.9c8.1-2.9,12.2-7.5,12.8-15.1
                    c0.6-7.5-6.4-24.3,8.7-26.1c15.1-1.7,33.6-2.9,35.4,1.7s5.2,18,16.8,22c11.6,4.1,32.5,5.8,37.1,9.3s12.8,11,19.1,7s4-13.9,13.9-9.3
                    c9.8,4.6,16.2,8.7,23.8,5.8s13.3-10.4,22.6-5.8c9.3,4.6,26.7,13.3,31.9,11.6c3-1,6.7-2.5,10.5-3.7l0,0l0,0l0,0
                    c3.2-1,6.3-2.3,9.3-2.3c6.4,0,25.4-6.6,28.3,0.4c3.2,6.4,8,7.2,14.1,7.2c6.5,0,21.8-4.8,23.6,5.8c1.7,9.8,6.4,13.3,17.4,12.2
                    c11-1.2,14.5,1.2,16.2,6.4s7,12.8,12.2,14.5s8.1-3.5,19.7,8.7c4.5,4.8,8.3,6.9,12.4,11l0,0c6.5,6.5,12.2,12.2,15.8,17.3
                    c5.8,8.1,23.8,37.1,30.7,36.5s15.6-6.4,23.2,1.2c7.5,7.5,20.9,20.3,27.2,24.9s1.2,7.5,11,4.1c9.8-3.5,12.7-1.2,18.5,2.3
                    s13.3,7,20.3,4.1s16.8-4.6,19.7-7.5s9.3-18,15.6-18.5c5.3-0.5,15.7-3.3,19.2-7.3v0.3c0,0,6.4,8.1,8.7,13.3s9.8,27.2,13.9,38.3
                    s12.8,18.6,15.7,24.3c2.9,5.8,7.5,14.5,5.8,22.6s3.5,15.6,5.8,23.2s3.5,27.8-4.6,28.4c-8.1,0.6-12.2,1.7-16.8,8.7
                    s-10.4,32.5-19.7,32.5s-13.9,0-16.2,4.1c-2.3,4-16.2,19.1-22,19.7c-5.8,0.6-19.1,0.6-23.8,3.5c-4.6,2.9-19.1,18-26.1,24.9
                    c-7,7-8.1,6.9-6.4,9.3s9.3,11,9.3,11l1,1.6c-9.7,4.8-21,23.4-26.3,30c-7,8.7-32.2,4.3-38.9,5.4c-7.4,1.1-15,1.1-22-1
                    c-11.3-3.5-19.1-12.2-26.1-18.3c-2.8-2.5-9.6-3-16.6-2.6c-0.6-1.7-6.7-20.1-10.5-27.7c-4-7.9-33.1-10.6-43.7-10.6
                    c-10.6,0-26.5-11.9-35.8-21.2c-9.3-9.3-30.5-10.6-43.7-10.6c-13.3,0-17.2,5.3-35.8-13.2c-18.5-18.6-11.9-55.7-10.6-65
                    c1.3-9.3,22.5-31.8,27.8-37.1c4.8-4.8,5.2-33.7,5.3-38.9"/>

                <path style={props.district==='ast'?styles.select:{}} id='ast' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1306.6,1336.7c-15.2-7.6-46.8-12.1-56.9-12.1c-12.2,0-37.4,14.8-37.4,14.8s-18.3-13-31.3-16.5
                    c-8.1-2.2-23.6-4.7-38.3-6.2l0.1-0.7c0,0,4.3-9.6,7.8-20.9s0-17.4-11.3-19.1s-34.8-19.1-39.1-23.5s-21.7-26.9-28.7-38.3
                    c-6.9-11.3-30.4-13-41.7-14.8c-11.3-1.7,0.9-27.8,1.7-35.6c0.9-7.8-11.3-22.6-19.1-27.8s-7.8-13.9-11.3-26.1s-11.3-13.9-21.7-22.6
                    c-2.7-2.2-5.4-2.1-8.7-0.4l-0.9-1.7c0,0-7.5-8.7-9.3-11c-1.7-2.3-0.6-2.3,6.4-9.3c7-6.9,21.4-22,26.1-24.9c4.6-2.9,18-2.9,23.8-3.5
                    s19.7-15.7,22-19.7c2.3-4.1,6.9-4.1,16.2-4.1s15.1-25.5,19.7-32.5s8.7-8.1,16.8-8.7c8.1-0.6,6.9-20.9,4.6-28.4s-7.5-15.1-5.8-23.2
                    s-2.9-16.8-5.8-22.6s-11.6-13.3-15.7-24.3s-11.6-33-13.9-38.3c-2.3-5.2-8.7-13.3-8.7-13.3l0,0c1.1-1,1.2-1.8,1.2-2.9
                    c0-5.2,0-12.2,4.6-19.1c4.6-7,8.7-15.7,6.9-18.5c-1.7-2.9-11.6-18.5-8.1-22s9.3-7,5.8-12.8s-7.5-15.7-3.5-19.7
                    c4.1-4.1,15.7-14.5,23.8-17.4c8.1-2.9,22.6-5.2,22,0.6s-5.8,16.2,0,22.6c5.8,6.4,9.3,20.9,8.1,22.6s-12.8,10.4-5.2,15.1
                    s12.2,6.7,23.8,6.4c9.5-0.3,29.1,5.1,30.1,10.4c0.6,5.2,3.6,21.1-2.9,29.5c-6,7.7-19.6,23.1-11,27.2c9.8,4.6,10.4,6.4,12.2,13.9
                    c1.7,7.5,9.8,11.6,19.7,18.5c9.8,7,11,9.3,15.7,13.9c4.6,4.6,19.1,12.8,22,20.9s5.2,11.6,15.1,13.9c9.8,2.3,27.8,11.6,27.8,19.1
                    s2.9,16.8,11,20.9c8.1,4.1,18,12.8,19.7,19.1c1.7,6.4,10.4,21.4,6.9,27.8s-8.7,12.8-4.1,21.4c4.6,8.7,2.9,18.5,10.4,19.1
                    s20.9,11,20.9,15.7s-10.4,8.7-11.6,12.2s-10.4,15.1-12.2,19.7s1.7,15.1,3.5,24.3s9.8,14.5,6.4,24.3s-7.5,13.3-8.1,18.5
                    s-1.7,21.4-5.2,22s-8.7,0.6-13.9,2.3c-5.2,1.7-26.1,11.6-21.4,15.7c4.6,4.1,17.4,15.7,17.4,18.5c0,2.9,1.7,13.9,8.1,15.7
                    c6.4,1.7,30.7,8.7,33,19.7s1.7,16.8,3.5,22.6c1.7,5.8,4.6,26.7,0.6,31.3c-4.1,4.6-12.2,20.9-12.2,20.9L1306.6,1336.7z"/>
                <g>
                    <path style={props.district==='ghe'?styles.select:{}} id='ghe' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1961.2,894.7c0,0-15.2,26-18.4,30c-3.2,4-4.4,9.6-8.4,7.6s-26.4-25.6-31.9-38.3s-8-26.4-12.4-36.8
                        c-4.4-10.4-25.2-12.8-33.2-18s-22-24-29.2-35.5c-7.2-11.6-18.8-9.2-22-7.6c-3.2,1.6-35.6,16-45.1,23.6s-13.7,7.8-20.4,12
                        c-8.4,5.2-18.8,13.2-20.4,20s-12,23.6-22.4,24.8s-25.6,9.2-29.9,10.8c-4.4,1.6-31.2,20.4-32.3,28.4c-1.2,8-0.8,37.5-8,45.9
                        s-25.6,38.3-30,48.7c-4.4,10.4-8.8,16.8,4,29.6s27.2,28.4,41.1,30.8s32.3,10,37.2,13.6c4.8,3.6,14.8,17.6,31.9,20.8
                        c17.2,3.2,30.8,7.6,46.3,12.4s39.5,19.6,44.7,26s0.4,14.4,6.4,27.2s14,30,13.6,39.5c-0.4,9.6-2.8,12-2.4,20.8s7.6,31.6,7.6,31.6
                        l0.2,0.7c7.6,2.3,47.6,14.6,60.2,16.9c13.9,2.6,23.5,6.1,26.9-0.9s0-13.9,12.2-23.5s15.7-14.8,15.7-26.9s3.5-38.3,3.5-38.3
                        s15.7-5.2,28.7-10.4c13-5.2,19.1-9.6,36.5-5.2c17.4,4.3,28.7,5.2,28.7,5.2s8.7-40,15.6-43.5s21.7-10.4,24.3-13s8.7-18.3,8.7-18.3
                        s-10.4-9.6-10.4-14.8s4.4-13.9,6.1-18.3s-20-13-28.7-18.3c-8.7-5.2-13.9-20.9-17.4-31.3c-3.5-10.4,5.2-20,6.9-27.8
                        c1.7-7.8-1.7-18.3-9.6-26.1c-7.8-7.8-15.7-19.1-18.3-26.1c-2.6-7-12.2,6.1-20,8.7s-19.2-15.7-19.1-25.2c0-8.2,3.6-3.1-17.1-31.7
                        L1961.2,894.7z"/>
                </g>
                <g>
                    <path style={props.district==='sgr'?styles.select:{}} id='sgr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1593.9,1030.5c0,0-10.4,0-17.4-3.5c-7-3.5-9.3-46.4-9.3-56.8s-23.2-3.5-26.7,3.5s-8.1,5.8-19.7,9.3
                        s-12.8,3.5-22,8.1s-10.4,2.3-23.2-13.9s-9.3-4.6-16.2-5.8s-19.7-10.4-27.8-19.7s-27.8-26.7-34.8-39.4c-6.9-12.8-7.9-14-5.5-21.7
                        c2.7-8.4-23.4-37.4-35-44.3c-11.6-7-18.5-16.2-30.1-19.7s-23.2-18.5-26.7-23.2c-3.5-4.6-24.3-5.8-30.1-7c-5.8-1.2-2.6-21-3.5-27.8
                        c-0.8-6.3-15.5-19.4-25.5-22c-10.3-2.7-12.8-2.3-20.9-1.2c-8.1,1.2-20.9-18.5-26.7-23.2s-22.5-12.4-22.5-12.4l0,0
                        c3.1-3.4,7.9-2.3,21.3-5c17.4-3.5,4.1-13.3,1.7-24.9c-2.3-11.6-2.9-11.6-10.4-15.1s-5.2-1.2-2.9-15.7c1.2-14-3.6-10.1-11-19.1
                        c-8.1-8.7-1.7-19.1,3.5-24.3s18.5-9.3,28.4-10.4s9.8-6.4,12.2-8.7c2.3-2.3,9.3,8.1,11.6,13.9c2.3,5.8,2.9,11,6.9,11.6
                        c4.1,0.6,13.3-2.3,18.5-6.4c5.2-4.1,19.7-17.4,19.7-17.4l30.7-66.1c0,0,4.6-0.6,15.1-1.7c10.4-1.2,20.9-13.3,29-18
                        c8.1-4.6,13.3-1.7,17.4,1.7c0,0,22.6,17.4,26.7,27.2s12.2,12.2,23.2,19.7s35.9,16.2,46.4,14.5c10.4-1.7,23.2-7,33-5.2
                        c9.8,1.7,26.7,15.7,26.1,20.3s-0.6,14.5,5.8,16.2s9.3,1.2,9.8,9.8s2.3,12.8,12.2,13.3c9.8,0.6,19.1,2.9,25.5-1.2
                        c6.4-4.1,26.1-19.1,29-19.7l1.5-0.9c4.5,13.6,12.3,27.8,21.9,27.3c14.8-0.9,40-6.1,44.3,7c4.3,13,22.6,39.1,21.7,46.9
                        s-6.1,28.7-1.7,35.6c4.3,7,19.1,12.2,27.8,9.6c8.7-2.6,40.8-13.9,55.6-13s22.6-0.9,35.6-7s28.7-4.3,35.6-11.3s16.5-18.3,20-18.3
                        s14.8,1.7,12.2,14.8c-2.6,13-10.4,23.5-10.4,33s0.9,26.1,14.8,29.5c13.9,3.5,35.6,11.3,35.6,11.3s6.9,10.4,8.7,14.8
                        c1.3,3.4,16.1,16.4,21.9,25.6c1.6,2.6,2.6,4.8,2.4,6.5c-0.9,7.8-0.9,13,1.7,19.1s7.8,24.3,7.8,24.3s-21.7,5.2-17.4,14.8
                        c4.3,9.6,13,13,19.1,21.7c0.8,1.2,1.8,2.5,2.9,4l-0.2,0.4c0,0-15.2,26-18.4,30c-3.2,4-4.4,9.6-8.4,7.6s-26.4-25.6-31.9-38.3
                        s-8-26.4-12.4-36.8c-4.4-10.4-25.2-12.8-33.2-18s-22-24-29.2-35.5c-7.2-11.6-18.8-9.2-22-7.6c-3.2,1.6-35.6,16-45.1,23.6
                        s-13.7,7.8-20.4,12c-8.4,5.2-18.8,13.2-20.4,20s-12,23.6-22.4,24.8s-25.6,9.2-29.9,10.8c-4.4,1.6-31.2,20.4-32.3,28.4
                        c-1.2,8-0.8,37.5-8,45.9s-25.6,38.3-30,48.7c-3.1,7.3-6.2,12.6-3.3,19.6L1593.9,1030.5z"/>
                </g>
                <g>
                    <path style={props.district==='kmg'?styles.select:{}} id='kmg' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1298.1,1119.2c1.9,0.2,17.1,2.2,21.8,5c5,3,16,2,28-2s25-14,36-14s35,1,46,1s26-1,37-5s14-9,29-9
                        s36-12,43-18s23-12,34-12s19,1,30,5s39-1,39-1l-0.3-0.1c0.5,0.1-0.3,0,0.3,0.1c14,2.4,32.3,10,37.2,13.6
                        c4.8,3.6,14.8,17.6,31.9,20.8c17.2,3.2,30.8,7.6,46.3,12.4s39.5,19.6,44.7,26s0.4,14.4,6.4,27.2s14,30,13.6,39.5
                        c-0.4,9.6-2.8,12-2.4,20.8s7.6,31.6,7.6,31.6l-0.3,0.5c-0.7-0.2-1.1-0.3-1.1-0.3s-18.3,14.8-28.7,23.5s-33,9.6-41.7,11.3
                        s-27.8,21.7-38.3,26.9s-21.7,13.9-30.4,11.3c-8.7-2.6-29.6-22.6-40-26.9s-23.5,30.4-32.2,38.3c-8.7,7.8-21.7,17.4-27.8,19.1
                        c-6.1,1.7-26.1,17.4-31.3,20.9c-5.2,3.5-33,16.5-41.7,16.5s-23.5-8.7-31.3-12.2s-48.7-1.7-72.2-1.7s-41.7-5.2-53-5.2
                        s-17.4-10.4-24.3-21.7c-6.9-11.3-11.3-9.6-19.1-20c-1.4-1.9-4.1-3.7-7.5-5.3l0.5-1.1c0,0,8.1-16.2,12.2-20.9
                        c4.1-4.6,1.2-25.5-0.6-31.3s-1.2-11.6-3.5-22.6s-26.7-18-33-19.7c-6.4-1.7-8.1-12.8-8.1-15.7s-12.8-14.5-17.4-18.5
                        c-4.6-4.1,16.2-13.9,21.4-15.7c5.2-1.7,10.4-1.7,13.9-2.3s4.6-16.8,5.2-22s4.6-8.7,8.1-18.5s-4.6-15.1-6.4-24.3
                        C1298.6,1121.6,1298.4,1120.8,1298.1,1119.2"/>
                </g>
                <g>
                    <path style={props.district==='sku'?styles.select:{}} id='sku' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1280.5,976.9L1280.5,976.9c2.4,2.7,4.1,4.4,4.7,6.8c1.7,6.4,11.2,20.5,7.7,26.9s-9.6,13.2-5,21.8
                        c4.6,8.7,3.7,19.7,11.2,20.3s20.2,10.8,20.2,15.5s-9.6,7.3-10.8,10.8s-10.4,15.1-12.2,19.7c-3,4.3-0.2,14.8,2.7,22.8l0,0
                        c0,0,15.3,0.8,20.3,3.8s17.6,2.1,29.6-1.9s24.9-13.7,35.9-13.7s35.2,1,46.2,1s26.4-1.2,37.4-5.2s13.6-8.4,28.6-8.4
                        s37-12.9,44-18.9s21-10.9,32-10.9s19.3,0.6,30.3,4.6s38.4-0.1,38.4-0.1l0,0c0,0,0.2,0,0,0c-14-2.4-27.4-20.8-40.1-33.6
                        c-3.5-3.5-5.7-6.5-7-9.3l-0.6-0.4c0,0-10.4,0-17.4-3.5c-7-3.5-9.3-46.4-9.3-56.8s-23.2-3.5-26.7,3.5s-8.1,5.8-19.7,9.3
                        s-12.8,3.5-22,8.1s-10.4,2.3-23.2-13.9s-9.3-4.6-16.2-5.8s-19.7-10.4-27.8-19.7s-27.8-26.7-34.8-39.4c-2.6-4.8-4.4-7.9-5.4-10.4
                        l0,0l0,0l0,0c-1-4.1-0.7-5.1,0.2-10.1c3.5-8.1-24.3-37-35.9-44c-11.5-6.9-18.5-16.2-30.1-19.7s-23.2-18.6-26.7-23.2
                        c-3.4-4.7-24.3-5.8-30.1-7c-4.7-1.1-1.9-20.9-3.5-27.8c-1.5-6.7-15.1-19.7-25.5-22c-10.5-2.4-12.8-2.4-20.9-1.2
                        c-8.2,1.1-20.9-18.5-26.7-23.2s-23.2-12.8-23.2-12.8l0.7,0.3c-0.9,1-1.6,2.3-2.4,4.3c-3.4,8.7-2.3,13.9-7.5,15.1
                        c-5.3,1.2-13.4,5.8-15.1,9.3s-2.9,11.1-9.8,15.7c-6.7,4.5-21,10.9-26.7,18.7l0,0c5,2.4,8.8,4.5,17.2,4.7
                        c11.6,0,30.4,5.2,30.1,10.4c-0.3,5,1.8,20.9-3.9,28.3c-8.1,6.9-19.8,23.8-10,28.4c9.9,4.6,10.5,6.4,12.2,13.9s9.9,11.5,19.7,18.5
                        c9.9,6.9,11.1,9.2,15.7,13.9s19.1,12.8,22,20.9c2.9,8.1,5.3,11.6,15.1,13.9s27.8,11.6,27.8,19.1c0,7.6,2.9,16.8,11,20.9
                        C1270.7,967.9,1276.5,972.2,1280.5,976.9L1280.5,976.9L1280.5,976.9"/>
                </g>
            </g>
            {/* <text transform="matrix(1 0 0 1 1209.9219 338.562)" className="st1 st2">Hunza</text>
            <text transform="matrix(1 0 0 1 563.5215 460.1807)" className="st1 st2">Ghizer</text>
            <text transform="matrix(1 0 0 1 894.8601 687.8384)" className="st1 st2">Gilgit</text>
            <text transform="matrix(1 0 0 1 1015.3707 536.6895)" className="st1 st2">Nagar</text>
            <text transform="matrix(1 0 0 1 735.5323 885.0233)" className="st1 st2">Diamer</text>
            <text transform="matrix(1 0 0 1 1405.4983 751.3513)" className="st1 st2">Shigar</text>
            <text transform="matrix(1 0 0 1 1108.2693 1092.2639)" className="st1 st2">Astore</text>
            <text transform="matrix(1 0 0 1 1272.7324 948.6998)" className="st1 st2">Skardu</text>
            <text transform="matrix(1 0 0 1 1454.5195 1238.427)" className="st1 st2">Kharmang</text>
            <text transform="matrix(1 0 0 1 1787.4558 1042.6313)" className="st1 st2">Ghanche</text> */}
            </svg>
        </>
    )
}

export default GbMap;