import './ProvinceMaps.css';
function BlnMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="bln-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 1150.6 1000.8" style={{enableBackground:'new 0 0 1150.6 1000.8'}} xmlSpace="preserve">
                <path style={props.district==='lor'?styles.select:{}} id='lor' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M832.1,219.7l5.9,4.6l5.9,5.1c0.4,0.3,0.8,0.5,1.3,0.5h12.6c0.4,0,0.7,0.1,1,0.3l2.9,1.8
                    c1.1,0.7,2.5,0.1,2.9-1.1l0.8-2.3c0.2-0.5,0.6-1,1.1-1.2l4.7-2c0.2-0.1,0.5-0.1,0.7-0.2l10-0.3c0.3,0,0.6,0,0.8,0.2l2.9,1.2
                    c0.3,0.1,0.3,0.2,0.6,0.2l3.8,0.8c0.4,0,2.7,1.8,3,2.1l1.7,0.2c0.6,0.3,0.9,0.5,0.9,0.5l-0.2,1.3c-0.1,1.1,0.6,2,1.6,2.2l5.4,1
                    c0.8,0.1,1.5,0.8,1.6,1.6l0.7,4.4c0.2,1,1,1.7,2,1.7h19.7c0.9,0,1.7,0.6,1.9,1.4l0,0c0.3,1-0.2,2-1.1,2.4l-0.3,0.2
                    c-0.9,0.4-1.3,1.3-1.1,2.3l0.6,2.8c0.2,0.7,0.7,1.3,1.5,1.5l5.5,1.4c0.5,0.1,0.9,0.4,1.2,0.8l1.1,1.6c0.4,0.5,1,0.9,1.6,0.9h3.2
                    c0.3,0,0.6-0.1,0.8-0.2l2.5-1.1c0.3-0.1,0.7-0.2,1-0.2l2.6,0.2c0.2,0,0.3,0.1,0.5,0.1l2.8,1c0.2,0.1,0.4,0.1,0.7,0.1h6.1
                    c0.4,0,0.9-0.1,1.2-0.4l3-2.3c0.3-0.2,0.7-0.4,1.1-0.4l5.3-0.3c0.1,0,0.3,0,0.4-0.1l4-1.1c0.3-0.1,0.6-0.1,0.9,0l4.2,0.8
                    c0.2,0,0.4,0,0.5,0l6.8-0.5c0.4,0,0.8-0.2,1.2-0.5l4-3.5c0.3-0.3,0.7-0.4,1.1-0.5l3.8-0.5c0.1,0,0.3,0,0.4-0.1l8.9-3l4.7-1.6
                    c0.5-0.2,1.1-0.1,1.6,0.1l8.9,4.9c0.3,0.2,0.7,0.3,1,0.2l19.7-0.9c0.5,0,1-0.2,1.4-0.6l11.2-11.8l0-0.3c-3.9-2.7-11.9-7.6-14.2-8.9
                    c-2.6-1.5-6-5.6-6.9-9.3s-10.4-1.6-12.7-1.6s-4.5-4.3-4.8-6.6s-2.4-2.5-4.3-2.6c-0.9-0.1-1.8-0.3-2.7-0.6l-0.1,0
                    c0,0,0.1,3.8,0.1,4.3s-2.9,1.3-6.9,1.8s-7.4,6.9-8.9,7.6s-6.9,2.9-8.6,3.3s-13.8,7.1-15.1,7.6s-7,2.1-8,1.1s-4.6-5.9-8-8
                    s-10.9-0.1-12.5,0.5s-7,0.9-9.3,0.9s-4.1,1.3-6.4,2.1s-3.5-0.5-4.4-2.3s-5.3-3.1-7.1-3.9s-8.3,4-10.1,4.3s-6.5,1.5-8.4,1.3
                    s-4.9-2.8-7.3-6.8s-14.1-2.1-15.4-2s-3.4,1.5-5,2.4s-4.4-1.4-5.4-2.3s-1-7.6-1-7.6s-14.1,5.6-15.4,6s-5.6-0.8-7.6-1
                    s-7.3,0.6-7.3,0.6l0,0c0.2,1.2,0.4,2.4,0.7,3.4L832.1,219.7z"/>
                <path style={props.district==='zia'?styles.select:{}} id='zia' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M832.1,219.7l5.9,4.6l5.9,5.1c0.4,0.3,0.8,0.5,1.3,0.5h12.6c0.4,0,0.7,0.1,1,0.3l2.9,1.8
                    c1.1,0.7,2.5,0.1,2.9-1.1l0.8-2.3c0.2-0.5,0.6-1,1.1-1.2l4.7-2c0.2-0.1,0.5-0.1,0.7-0.2l10-0.3c0.3,0,0.6,0,0.8,0.2l2.9,1.2
                    c0.3,0.1,0.3,0.2,0.6,0.2l3.8,0.8c0.4,0,2.7,1.8,3,2.1l1.7,0.2c0.6,0.3,0.9,0.5,0.9,0.5l-0.2,1.3c-0.1,1.1,0.6,2,1.6,2.2l5.4,1
                    c0.8,0.1,1.5,0.8,1.6,1.6l0.7,4.4c0.2,1,1,1.7,2,1.7h19.7c0.9,0,1.7,0.6,1.9,1.4v0c0.3,1-0.2,2-1.1,2.4l-0.3,0.2
                    c-0.9,0.4-1.3,1.3-1.1,2.3l0.6,2.8c0.2,0.7,0.7,1.3,1.5,1.5l5.5,1.4c0.5,0.1,0.9,0.4,1.2,0.8l1.1,1.6c0.3,0.4,0.1,0.5,0.5,0.7l0.1,0
                    l-2,0.8l-1.5,3.8l-2.6,2.1l-6.6,6.1l-3.1,2.6c-0.3,0.2-0.6,0.4-1,0.6l-7.9,3.9c-1.1,0.4-5.9,1.6-7.1,1.4l-10.1-1.1l-0.7,0.5
                    c0,0-4.6-2-7.1-3.5s-5.1-4.1-7-6.3s-2.6-3.5-5-3.8s-6.4-1.9-7.9-3.1s-9.4-4.3-11.3-4.8s-3.8-2.7-4-4.4c-0.2-1.6-14.5-3.3-15.1-3.4
                    l0,0c0,0,0,0,0,0c0.1,0.6,0.8,3.5,1.1,5.1c0.4,1.9-4.5,4.6-6,5.1s-7.4,1.5-9.3,1.5s-3.1,1.3-6.1,2.5s-6.6-1.9-7.9-3.4
                    s-7.3-1.1-9.3-0.8s-8.3-2-9.8-2s-4.3-1.2-6.4-2.2s-4.8,1.5-6.5,1.9c-1.8,0.4-6.8-4.7-7.3-7s0.4-3.8,1.4-5.5s3.1-6.3,3.5-8.1
                    s2.3-4.1,3.8-6.3s4.8-3.3,6.5-3.6s4.5-3.6,6.3-5.1s8.3-0.5,10.1,0.1s5,6.5,5.8,7.9s2.5,1.6,3.5,0.5s3.8-6.3,4.8-7.5s5.1-2.1,7.4-2.1
                    c2,0,7-0.5,8-0.6L832.1,219.7z"/>
                <g>
                    <path style={props.district==='har'?styles.select:{}} id='har' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M821.8,286.3l1.7-0.6l5.5,0l5.4,2.3l5.1,3.2l2.2,2.9l-2.5,2l-1.1,0.9l2.2,1.2l9.7,0l4.5-2.2l3.5-0.2l4.6,2.3
                        l4.2,3.8l0,4.5l0,5.4l-4.4,7.6l-5.4,7l-3.8,5.1l0,3.9l2.3,0l7.8-7.6l-0.3-4.5l3.9-0.5l6.4-6.5l0-3.9l7.9-0.7l12.8-4.1l17.7-0.7
                        l1.5,0l1.2-8.8l0.7-0.7c-0.2-0.4-0.4-0.8-0.5-1.3c-0.8-2.8-4.8-4.1-7.9-5.6s-11.6-6.9-13.1-8.9c-1-1.3-0.4-2.8,0.3-3.7l-0.2,0.2
                        c0,0-4.6-2-7.1-3.5s-5.1-4.1-7-6.3c-1.5-1.8-2.3-3-3.9-3.5l-0.8-0.4l-0.1,0c-2.4-0.3-6.3-1.9-7.8-3.1c-1.5-1.3-9.4-4.3-11.3-4.8
                        s-3.8-2.8-4-4.4s-15.1-3.4-15.1-3.4s0.8,3.4,1.1,5.3s-4.5,4.6-6,5.1s-7.4,1.5-9.3,1.5s-3.1,1.3-6.1,2.5s-6.6-1.9-7.9-3.4
                        s-7.3-1.1-9.3-0.8s-8.3-2-9.8-2s-4.3-1.3-6.4-2.3c-1.1-0.5-2.2-0.1-3.4,0.4l0,0c0,0,1.6,3.1,1.1,4.7s-4.3,5-5.8,6.3
                        c-0.8,0.7-1.8,1.9-3,3.3l-0.1,0.2c0,0,2,1,3.5,0.5s3.1-3.1,3.9-2.8s0.4,2,0.8,3.9s1.4,5.3,3.8,5.9s6.1,0.8,7.4,0.3s5.6-2.5,6.1,0.4
                        s2.3,12.1,1.9,13.8c-0.4,1.5-0.3,2.5-1.3,2.8l-0.3,0.1c0,0,5.1,3.4,7.6,3.4s4.6-2.6,5.4-3.3s3-5.6,4.6-6s3.3-0.5,4.6-1.3
                        c0.5-0.3,1.1-0.6,1.7-1L821.8,286.3"/>
                </g>
                <path style={props.district==='duk'?styles.select:{}} id='duk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M894.6,277.4l10.1,1.1c1.1,0.2,6-1.1,7.1-1.4l7.9-3.9c0.4-0.1,0.7-0.3,1-0.6l3.1-2.6l6.6-6.1l2.6-2.1l1.5-3.8
                    l2.9-0.3l0.1-0.2h3.2c0.3,0,0.6-0.1,0.8-0.2l2.5-1.1c0.3-0.1,0.7-0.2,1-0.2l2.6,0.2c0.2,0,0.3,0.1,0.5,0.1l2.8,1
                    c0.2,0.1,0.4,0.1,0.7,0.1h6.1c0.4,0,0.9-0.1,1.2-0.4l3-2.3c0.3-0.2,0.7-0.4,1.1-0.4l5.3-0.3c0.1,0,0.3,0,0.4-0.1l4-1.1
                    c0.3-0.1,0.6-0.1,0.9,0l4.2,0.8c0.2,0,0.4,0,0.5,0l6.8-0.5c0.4,0,0.8-0.2,1.2-0.5l4-3.5c0.3-0.3,0.7-0.4,1.1-0.5l3.8-0.5
                    c0.1,0,0.3,0,0.4-0.1l8.9-3l4.7-1.6c0.5-0.2,1.1-0.1,1.6,0.1l8.9,4.9c0.3,0.2,0.7,0.3,1,0.2l19.7-0.9c0.5,0,1-0.2,1.4-0.6l11.2-11.8
                    l0.2-0.2c0.5,0.4,0.8,0.6,1.1,0.8c3.1,2.4,6.1,4.3,7.1,5s6.5,2.9,6.5,3.4s-0.5,3.9-0.5,3.9l5.4,3.6l3.6-1.6c0,0,1,0.4,2.2,1.2
                    l-0.2,0.3c0,0-2.5,6.7-3.6,8.6s-6.3,9.8-6.8,10.4s-1.6,5-2.6,7.6s-3.8,3.8-5.9,3.1s-3-2.5-2.3-4.5s2.6-4.4,2.9-5.6s-2.3-2-3.4-1
                    s-11,12.8-12.8,15.1s-5.9,8.5-7.3,10.4s-5.6,3.3-7.3,3.8s-11.6,0.4-14.9,0.3s-6.5,2.4-8.3,3.4s-5.4,0.6-7.4,0.6s-8.3,0.6-9.9,0.1
                    s-3.6-1.8-6-5.1s-8.6,1.6-9.5,2.1s-4.4,4.4-5.6,6.8s-5.4,2.6-7.4,4s-10.8,1.5-12.3,1.6s-7.9,0.1-10.6,0.3s-5.8-1.1-10.1-3.8
                    s-7.4-6.5-8.3-7.9s-4-3.5-4.8-6.3s-4.8-4.1-7.9-5.6s-11.6-6.9-13.1-8.9c-1-1.3-0.4-2.8,0.3-3.7l-0.2,0.2"/>
                <path style={props.district==='koh'?styles.select:{}} id='koh' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M893,355.2l22.2,0.9l2.9-1.5l4-4.8l5-1.7l6.5-1.7l5.9-5.2l1.7-4.2l-2.7-4.2c0,0,1.3-2.3,1.5-2.5
                    c0.2-0.2,0-6.3,0-6.3l-26.8-13.2V306l-0.1,0.8l1.2-8.8l0.7-0.7l0.2,0c1,1.8,3.1,3.2,4,4.3l0,0c0.2,0.2,0.3,0.3,0.4,0.5
                    c0.9,1.4,3.9,5.3,8.3,7.9s7.4,3.9,10.1,3.8s9.1-0.1,10.6-0.3s10.3-0.3,12.3-1.6s6.1-1.6,7.4-4s4.8-6.3,5.6-6.8s7.1-5.5,9.5-2.1
                    s4.4,4.6,6,5.1s7.9-0.1,9.9-0.1s5.6,0.4,7.4-0.6s5-3.5,8.3-3.4s13.3,0.3,14.9-0.3c1.6-0.5,5.9-1.9,7.3-3.8c0.3-0.4,0.8-1.1,1.3-1.9
                    l0.2,0.1c0,0,4.5,2.4,5.4,1.3s1-3.5,2.5-3.5s3.3,1,3,2.3s-1.6,3.3-1.9,4.9s-0.8,3.1-1.5,4.3s-5.4,6.6-6.5,7.8s-1.4,6-2.8,6.1
                    s-5.5,0.5-6.8,1.3s-18.6,9.8-19.9,10.9s-3.1,4.9-5.5,5.5s-8.3,2.6-8,4.3s1.5,6.8,4.9,8s7,4.4,11,6.1s10.1,3.5,17.1,0.4
                    c7-3.1,10.6-8.5,19.1-10s13.9-0.8,18.6,2.5l0.2,0.1c-0.3,0.3-0.5,0.6-0.7,0.9c-0.3,0.6-0.6,1-1,1.4l0,0c-9.1-4.5-15.3,0.9-17.8,3.4
                    s-6.3,5.4-7,9.8s6.6,5.9,8.8,7.9s-3.1,7.9-4.9,9.4s-7.8,4.3-10.1,5.3s-3.8,4.1-4.6,5.6s-8.4,2.1-10.1,3s-5.1,8.5-10.4,11.1
                    s-12-0.1-15.5-0.4s-6,1.1-7.5,2.4s-7,2.8-13,1.5s-23.6-4.6-27.3-4.5s-8.5,2.1-15.8,4.3s-13.6-0.3-18.4-3.3s-14.8-9.1-19-12.3
                    s-7.8-8.5-7.8-8.5s-1.9-0.6-3-0.6s-3.4,0.3-3.5-0.9s2.9-4.4,4.8-6.4c1.2-1.3,3.8-7.5,5.3-12.1c0.6-2.1,1-0.9,3.2-3.7
                    c1.1-1.4,1.1-2.4,0.7-3.2L893,355.2z"/>
                <g>
                    <path style={props.district==='she'?styles.select:{}} id='she' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1034.8,24.5c0,0,1.1,6.5,1.3,8.9c0.2,2.5,1.6,4.9,3.6,6.7c2,1.8,10.5,7.6,10.7,8.9s0.7,5.4,1.8,9.2
                        s7.6,13.8,10.7,16.5c3.1,2.7,6.9,6,10.1,6.9s2-0.7,5.4,4.9s3.1,6.9,6.3,9.4c3.1,2.5,6.9,6.7,13,5.8c6-0.9,16.1-4.2,16.1-4.2
                        l0.3-0.2c-0.5-2-0.8-3.9-0.9-5.3c-0.3-4.5-3.8-3.5-5.7-3.7c-1.8-0.2-3.7-1-3.7-4.7c0-3.7-0.5-14.8,0.5-21.7s-1.2-14.2-1.5-16.7
                        c-0.3-2.5,1.7-4.7,2.5-6.8c0.8-2.2-0.3-7.2-0.5-10c-0.2-2.8-7.2,5.2-8,6.5c-0.8,1.3-5-2.7-4.8-4c0.2-1.3,2.3-6.3,3-8.5
                        c0.7-2.2-5.8-5.3-6.5-5.8c-0.7-0.5,0-3.3,0.7-4.5c0.7-1.2,2.7-8.2,2.7-8.2s-16,4-19.8,4.7c-3.8,0.7-6,7.2-6.8,9
                        c-0.8,1.8-9.5,7.5-9.5,7.5l-3.8-2.7c0,0-6.3,1.2-8,1.7c-1.6,0.5-6.9-0.2-9.1,0"/>
                </g>
                <g>
                    <path style={props.district==='zho'?styles.select:{}} id='zho' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1056.3,238c0,0,0-1.6,0.4-3.1s-2-3.1-2.6-3.8s-1.6-2.3-1.9-3.8s-2.1-0.3-4.6-0.4s-2.9-2.3-2.5-4.4
                        s0.5-2.5,6.6-9.9s2.3-12.3,2.6-14.5s3.4-4.6,4.5-5.6s4.1-5.4,5.5-7s7-12.4,7.5-14.9s3.9-5.5,5.3-9s-1.1-7.4-2-8.4s0.4-4.3,0.6-6.3
                        s-1.3-6-1.4-8.3s0.4-5.4,1-6.9s0.1-2.5-1-4c-1.1-1.5,0.9-3.1,1.6-4s0.5-3.4,0.5-4.8s2-2.8,2-2.8c1-0.2,3.4-1.9,6.1-1.1
                        c2.7,0.9,3.8,1.3,6,0.4s5.6-0.4,7.2,0.7c1.6,1.1,4,4.7,6.3,5.4c2.2,0.7,5.1,0,7.6-1.8s5.1-2.5,5.6-4.9c0.4-2.5,0.4-8.6,0.4-8.6l0,0
                        c-0.5,0-0.8-0.2-0.9-0.4c-0.2-0.4-1.6-4.7-2.5-8.7l-0.3,0.1c0,0-10.1,3.3-16.1,4.2c-6,0.9-9.8-3.3-13-5.8s-2.9-3.8-6.3-9.4
                        c-3.3-5.6-2.2-4-5.4-4.9c-3.1-0.9-6.9-4.2-10.1-6.9s-9.6-12.7-10.7-16.5c-1.1-3.8-1.6-7.8-1.8-9.2c-0.2-1.3-8.7-7.2-10.7-8.9
                        c-2-1.8-3.3-4.2-3.6-6.7s-1.3-8.9-1.3-8.9l-0.3-0.4c-0.1,0-0.2,0-0.3,0c-1.8,0.3-5.8,1.8-5.8,1.8l-0.2,0c-0.1,1.3-0.9,3.1-2.9,5.9
                        c-5.2,7.4-12.3,13.2-15.1,18.7c-2.8,5.5-6.6,10.4-9.9,12.6c-3.3,2.2-11,3.6-13.7,6c-2.7,2.5-7.7,5.8-12.3,6.3
                        c-4.7,0.6-18.7-8.5-18.7-14s-5.2-8-8.5-10.7c-3.3-2.8-7.1-9.3-10.2-8.5s-15.1,8.5-12.3,9.1c2.7,0.5,15.1-0.3,14,4.1
                        c-1.1,4.4-1.6,6.6-5.5,5.2c-3.8-1.4-16.8-3.8-21.4-4.1c-4.7-0.3-8.8-0.5-11-3.8c-2.2-3.3-9.9-7.7-12.9-5.2s-11,13.4-12.9,15.6
                        c-1.9,2.2-11,9.1-13.4,9.1c-2,0-10.5,1.4-14.4,2.6l0.3,0.3c0,0,4.9,2,8.7,4.5c3.8,2.5,7.6,8,7.6,9.8s-0.7,6-3.6,8.9
                        c-2.9,2.9-5.1,9.2-4.2,15c0.9,5.8,4.5,18.5,9.6,19s11.4-0.4,15-0.2c3.6,0.2,4.9,0,8.9-2.9c4-2.9,9.2-4.9,13.6-5.4
                        c4.5-0.4,14.7-4.2,18.2-6.1c3.1-1.7,6.5-3.4,8.2-3.9c2.8-0.8,6.8-2.4,8.5-2.3s9.1,3.1,9,5.1s-1.1,7.4-0.6,8.5s4.9,7.6,4,8.5
                        s-1.8,2.4-1,4.6s2,3.4,1.9,6s6.6,4.4,8.4,2s3.5-7.1,6.9-5.6s6,1.3,8.1,1.1s4.3-0.9,4.8,1.1s2.5,4.3,5.4,1.9s5.8-3.4,9.9-2.5
                        s5.8-2.5,7.8-0.9s4.8,5.5,6.8,5.8s5.1,1.5,6.3,2.5s3.8,5.6,3.8,5.6c1.5,1.9,6,3.9,7.6,2.5s3.3-4.9,4.9-5s4.5-0.4,3.4,2.1
                        s-3.3,5.6-5.6,7.3s-5,3.8-5.4,6.4s-0.6,7.4-1.6,8.8s-2.3,6.3-3.6,7.3s-10.9,3.4-13,5.3s-3.8,3.9-4.9,4.1s-4.8,2.3-4.8,2.3l3.6,8.8
                        c0,0,3.4-1.5,5.1-0.5s3.4,1.6,5.3,1.8s4,0.3,4.3,2.6s2.4,6.6,4.8,6.6s11.9-2,12.8,1.6s4.3,7.8,6.9,9.3s12.4,7.5,15.5,9.9
                        c0.6,0.5,1.2,0.9,1.8,1.3"/>
                </g>
                <g>
                    <path style={props.district==='mus'?styles.select:{}} id='mus' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1056,237.4c2.4,1.8,4.6,3.1,5.4,3.7c1,0.8,6.5,2.9,6.5,3.4s-0.5,3.9-0.5,3.9l5.4,3.6l3.6-1.6
                        c0,0,5.1,2.4,5.4,5c0.3,2.6,2.6,6.5,5,6.4s6-1.4,7.4-2.5s2.5-3.8,5-3.5s7,1.1,7,1.1l0.2,0.1c0.5-2.3,2.1-5.5,2.8-7.3
                        c0.8-2.3,0.3-7.2,1.2-10.5c0.8-3.3,6.2-8.3,7.7-11.8s2.7-11.5,3-16.2c0.3-4.7,0.5-7.3,1-9.2s2.5-2.8,4.3-3.3c1.8-0.5,3-2.5,3.2-3.8
                        c0.2-1.3,3-1.5,5.7-1.5s1.7-5.3,1.7-7c0-1.7,3.2-2.5,5.2-2.8s-0.3-4.7-0.5-7.2c-0.2-2.5,0.2-3.3,0.7-4.7c0.5-1.3,0-9.5,0.2-11.3
                        c0.2-1.8,1-5.8,2.7-7.7c1.7-1.8,1-8.2,1.2-11.2c0.1-1-0.3-1.9-0.9-2.8c-1.2-1.8-3-3.3-3.3-4.9c-0.3-2.3-0.2-5.8,0.2-8
                        c0.3-2.2,0.5-4.7,0.3-8.3c-0.2-3.7,1-12,1.3-16.7c0.3-4.7-1.7-11.2-1.7-11.2s-5.2,2.7-7.2,4c-2,1.3-6.3,5.3-8.5,6.2
                        c-2.2,0.8-3.5-1.2-4.2-2c-0.7-0.8-2.3,3.3-2.7,6.3c-0.2,2.1-1.5,2.6-2.3,2.4l0.2,0c0,0,0-0.1,0,0c0,0.8,0,6.3-0.4,8.6
                        c-0.4,2.5-3.1,3.1-5.6,4.9c-2.5,1.8-5.4,2.5-7.6,1.8c-2.2-0.7-4.7-4.2-6.3-5.4s-4.9-1.6-7.2-0.7c-2.2,0.9-3.3,0.4-6-0.4
                        c-2.7-0.9-5.1,0.9-6.1,1.1c0,0-2,1.4-2,2.8s0.3,3.9-0.5,4.8s-2.8,2.5-1.6,4s1.6,2.5,1,4s-1.1,4.6-1,6.9s1.6,6.3,1.4,8.3
                        s-1.5,5.3-0.6,6.3s3.4,4.9,2,8.4s-4.8,6.5-5.3,9s-6.1,13.3-7.5,14.9s-4.4,6-5.5,7s-4.1,3.4-4.5,5.6s3.5,7.1-2.6,14.5
                        s-6.3,7.8-6.6,9.9s0,4.3,2.5,4.4s4.4-1.1,4.6,0.4s1.3,3.1,1.9,3.8s3,2.3,2.6,3.8s-0.4,3.1-0.4,3.1"/>
                </g>
                <g>
                    <path style={props.district==='ksu'?styles.select:{}} id='ksu' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M829.3,215.7c0,0,5.9-1.1,7.9-0.9s6.4,1.4,7.6,1s15.4-6,15.4-6s0,6.8,1,7.6s3.8,3.1,5.4,2.3s3.8-2.3,5-2.4
                        s13-2,15.4,2s5.4,6.5,7.3,6.8s6.5-1,8.4-1.3s8.3-5,10.1-4.3s6.3,2.1,7.1,3.9s2.1,3.1,4.4,2.3s4.1-2.1,6.4-2.1s7.6-0.3,9.3-0.9
                        s9.1-2.6,12.5-0.5s7,7,8,8s6.6-0.6,8-1.1s13.4-7.3,15.1-7.6s7.1-2.5,8.6-3.3s4.9-7.1,8.9-7.6s6.9-1.3,6.9-1.8s-0.1-4.3-0.1-4.3l0,0
                        c-0.9-0.3-1.6-0.7-2.5-1.2c-1.8-1-5.1,0.5-5.1,0.5l-3.6-8.8c0,0,3.6-2,4.8-2.3s2.8-2.3,4.9-4.1s11.6-4.3,13-5.3s2.6-5.9,3.6-7.3
                        s1.3-6.1,1.6-8.8c0.4-2.6,3-4.8,5.4-6.4s4.5-4.8,5.6-7.3s-1.8-2.3-3.4-2.1s-3.3,3.6-4.9,5s-6.1-0.6-7.6-2.5c0,0-2.6-4.6-3.8-5.6
                        s-4.3-2.3-6.3-2.5s-4.8-4.1-6.8-5.8s-3.6,1.8-7.8,0.9s-7,0.1-9.9,2.5s-4.9,0.1-5.4-1.9s-2.6-1.3-4.8-1.1s-4.8,0.4-8.1-1.1
                        s-5.1,3.3-6.9,5.6s-8.5,0.6-8.4-2s-1.1-3.8-1.9-6s0.1-3.8,1-4.6s-3.5-7.4-4-8.5s0.5-6.5,0.6-8.5s-7.3-5-9-5.1s-5.8,1.5-8.5,2.3
                        c-1.7,0.5-5.1,2.1-8.2,3.9c-3.5,1.9-13.7,5.7-18.2,6.1s-9.6,2.5-13.6,5.4s-5.4,3.1-8.9,2.9c-3.6-0.2-9.8,0.7-15,0.2
                        c-5.1-0.4-8.7-13.2-9.6-19c-0.9-5.8,1.3-12.1,4.2-15c2.9-2.9,3.6-7.2,3.6-8.9s-3.8-7.4-7.6-9.8s-8.7-4.5-8.7-4.5l-0.3-0.3
                        c0,0-0.1,0-0.2,0.1c-0.9,0.3-1.5,0.5-1.7,0.7c-1.1,1.1-3.3,11.5-11.8,11.5s-15.4-0.8-16.5,3.6c-1.1,4.4,1.9,14.3,12.1,14.3
                        c10.2,0,16.5,5.5,12.9,9.1c-3.3,3.3-28.2,12.2-40.8,15.5l0.1,0.1c0,0,2.5,3.2,4.4,3.1c2-0.1,7.5-0.9,8,0.4c0.6,1.2,1.2,5.6,3.1,5.9
                        s4-0.8,5.4-0.1s9.8,2.4,10.9,2.4c1.1,0,12.5-0.4,12.5-0.4s-0.8,5.8-2.2,6.9c-1.3,1.1-5.4,1.8-5.5,3.5c-0.1,1.7-0.4,5.8-2.3,7.1
                        c-1.9,1.3-11.8,9.5-12.7,10.5c-0.9,0.9-5,6.5-7.1,7c-2.2,0.5-6,3-8.7,3.3c-2.7,0.3-3.9,0.2-5,1.5c-1.1,1.3-4.1,4.2-2.2,5.6
                        s3.8,2.3,3.4,4.1s-0.8,8-2.8,9s-4.9,2.5-2.3,3.6s4,0.5,4.5,2.4s3.6,4.3,6.1,3.9s8.6-2,11.3-2.4s8.4,2.5,8.5,3.8
                        c0.1,0.6,0.2,2.9,0.6,5.2"/>
                </g>
                <g>
                    <path style={props.district==='bar'?styles.select:{}} id='bar' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M1078.9,251.4c1.4,0.9,3.1,2.3,3.2,3.8c0.3,2.6,2.6,6.5,5,6.4s6-1.4,7.4-2.5s2.5-3.8,5-3.5s7,1.1,7,1.1l0.3,0
                        c-0.2,0.8-0.2,1.3-0.1,1.8c0.5,2,1.7,3.2,1.3,4c-0.3,0.8,0.7,6,2.3,4c1.7-2,6.7-3.8,7.5-3.8c0.8,0,3.3,2.3,0.5,5
                        c-2.8,2.7-5.3,4.8-6.3,6.3s-2.7,6.5-3,9.7c-0.3,3.2-1.5,9.8-3.7,12.3c-2.2,2.5-4.3,4.2-4.8,7.7s-4,12-5.3,12.8
                        c-1.3,0.8-5,3.8-6,6.8s-3.8,10.8-6.3,12.5s-8,5.7-10,6.7c-1.7,0.8-3.8,2-5.1,3.5l-0.2-0.1c-4.8-3.3-10.1-4-18.6-2.5
                        s-12.1,6.9-19.1,10s-13.1,1.4-17.1-0.4s-7.6-4.9-11-6.1s-4.6-6.4-4.9-8s5.6-3.6,8-4.3s4.3-4.4,5.5-5.5s18.6-10.1,19.9-10.9
                        s5.4-1.1,6.8-1.3s1.6-5,2.8-6.1s5.8-6.6,6.5-7.8s1.3-2.6,1.5-4.3s1.6-3.6,1.9-4.9s-1.5-2.3-3-2.3s-1.6,2.4-2.5,3.5
                        s-5.4-1.3-5.4-1.3l-0.2-0.1c1.8-2.6,4.6-6.7,5.9-8.5c1.8-2.4,11.6-14.1,12.8-15.1s3.6-0.3,3.4,1s-2.1,3.6-2.9,5.6s0.1,3.9,2.3,4.5
                        s4.9-0.5,5.9-3.1s2.1-7,2.6-7.6s5.6-8.5,6.8-10.4s3.6-8.6,3.6-8.6L1078.9,251.4z"/>
                </g>
                <g>
                    <path style={props.district==='kia'?styles.select:{}} id='kia' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M658.2,286.6c0,0,8.1-8,9.1-8.5s1.9-0.9,3.8,0.4s4.5,1.6,6.3,1.6s6.8-0.3,8-0.9s2.3-5.4,2.5-7.3s2.3-0.8,3.4,0
                        s1.9,0.9,4,1.4s5.8,3.8,7.9,4.6s6.8-2.4,7.9-3.1s4.1-7.8,4.8-9.1s3.3-4.4,4.9-5.3s3.5-7.5,4.3-10.3s2-6.1,2.1-7.6s3.1-4.3,4.3-4.8
                        s5.8-1.5,5.8-1.5v0c-0.4-0.9-0.8-1.8-1.1-2.6c-2.5-5.8-14.6-27.5-16.6-33.8s3.8-11.1,4.9-12.5s2.8-6.8,3.1-9.4s-1.9-7.3-1.9-7.3
                        l3.6-3c0,0,2.8,1.9,4,3.6s5.3,0.5,6.8-0.4s2.6-4.4,2.9-5s2.8-1.4,5-1.4s7.5,6,7.5,6s8.4-7,9.6-8.1s6-2.3,7-2.5s1.3-4,0.8-6.8
                        s0.4-4.4,1.5-7s3.1-4.1,3.1-4.1s-0.1-4.4,0.1-6.4s1.9-4.5,1.9-4.5l-0.1-0.1c-2.2-0.2-3.8-0.4-4.7-0.4c-2.7,0-7.1-0.3-10.7-0.8
                        s-10.2,0.3-8.8-4.4c1.4-4.7-3.6-9.1-8.8-8.2c-5.2,0.8-11.3,5.2-15.1,8.2s-4.9,5.2-8.5,5.8c-3.6,0.5-8.5,3.3-9,5.8
                        c-0.5,2.5-3.8,16.5-8.8,19.8s-9,6.6-14.8,7.7c-5.8,1.1-16.2,3.6-17,8c-0.8,4.4-12.6,44.5-11.8,48.9c0.8,4.4,10.7,21.7,8.5,25.8
                        c-1.6,3-8.6,25.6-10.9,38.6"/>
                </g>
                <g>
                    <path style={props.district==='mas'?styles.select:{}} id='mas' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M772.3,269.5c-1.1,1.2-2.3,2.6-3.8,4c-3.3,3-9.1,4-11.3,4.5s-4.9,2.3-5.6,5.1s-0.9,11.8-3.1,12.4
                        s-8.4,1.1-10.6,0.4s-4.8-1.8-5.9-0.8s-5.8,4.1-7.3,3.9s-2-2.3-0.9-3.5s3.1-4,1-4.5s-3.3-0.4-3.4-2s0.1-2.9-1.6-3.4
                        s-3.8-0.5-5.4-2.1s-3.1-3.8-5.5-0.8s-8.9,16.8-9.4,20.3s-0.6,5.8-2.4,7.4s-2.8,4.9-2.9,6.8s-3.4,10-5.1,11s-4.5,2.8-6.4,0.6
                        s-4.3-6.3-5.1-6.3s-5.3,1.3-7.9,0.9s-9.4-1.6-9.4-1.6s1.8,3.8,0.8,5.1s-2.4,5.8-2.4,6.9s0.9,10.1,1.3,12.5s3,6.8,2.3,8.3
                        s-1.8,6-2.5,7.3s-3,3.5-3.1,6s-0.9,5-1.9,7s-3.1,7.4-4.1,8.8c-0.5,0.6-1.3,2-2.2,3.3l0,0c0,0,4.8,3.9,8,4.7s9.3,2.5,12.2,0.5
                        c2.8-2,3.2-3.7,3.7-6.7s4.7-12,6-14s10.8-14.3,15.2-16c4.3-1.7,9.3-2.8,9,3.3c-0.3,6.2-4.5,11-3.7,15s2.8,7.3,5.2,4.7
                        c2.3-2.7,3.3-4.5,6.5-6.5s4.5-3.3,6.5-5.3s4.8-3.7,8-3s5.8,2.5,4.7,6.3c-1.2,3.8-7,9.2-5.5,10.2s5.2,1.7,7.3,0.7s6.7-2.7,8.7-2.2
                        s8.2,1.5,10.8,1.5s4.5-0.3,5.2-1.7c0.7-1.3,3.5-7.5,6.3-9.2l0-0.3c-0.5-0.4-0.8-0.8-1-1.2c-0.5-1.8,6-5.3,6.8-6.3s2.7-6.7,3.5-9.3
                        c0.8-2.7,4.7-3.5,6.5-4.8s2-4.5,0.2-5.2s-9.3-4.8-11.3-6.2c-2-1.3-3.3-8-3.8-12.7s-2.3-9.2-2.7-10.8c-0.3-1.7,2.2-5.3,5-7.2
                        c2.8-1.8,4.3-0.8,5.5,0.7s2.5,0.5,4.3,0s3.8,3.5,4.7,5.8c0.8,2.3,2.7-0.7,4-1.8c1.3-1.2,3.3,2.8,4.3,4.2s4.2-1.2,4.2-1.2l-0.1-0.1
                        c-1-0.9-1.8-2.2-1.8-3.2c0-1.5-1-3.9-1.1-6.1s1.9-3,3.8-3.3s1.1-2.4,1-3.4s1.9-2.1,3.3-2.4s1.3-1.3,1.6-2.9s-1.4-10.9-1.9-13.8
                        s-4.9-0.9-6.1-0.4s-5,0.4-7.4-0.3s-3.4-4-3.8-5.9s0-3.5-0.8-3.9s-2.4,2.3-3.9,2.8s-3.9-0.6-3.9-0.6"/>
                </g>
                <g>
                    <path style={props.district==='que'?styles.select:{}} id='que' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M660.5,321.8c0.5,0.1,6.8,1.3,9.3,1.6c2.6,0.4,7-0.9,7.9-0.9s3.3,4.1,5.1,6.3s4.6,0.4,6.4-0.6s5-9.1,5.1-11
                        s1.1-5.1,2.9-6.8s1.9-3.9,2.4-7.4s7-17.3,9.4-20.3s3.9-0.9,5.5,0.8s3.6,1.6,5.4,2.1s1.5,1.8,1.6,3.4s1.3,1.5,3.4,2s0.1,3.3-1,4.5
                        s-0.6,3.3,0.9,3.5s6.1-2.9,7.3-3.9s3.6,0,5.9,0.8s8.4,0.3,10.6-0.4s2.4-9.5,3.1-12.4s3.5-4.6,5.6-5.1s8-1.5,11.3-4.5
                        s5.4-6.1,6.9-7.4s5.3-4.6,5.8-6.3s-1.1-4.8-1.1-4.8l-0.1,0.1c-1.1,0.5-2.2,1.2-3.1,1.4c-1.8,0.4-6.8-4.8-7.3-7s0.4-3.8,1.4-5.5
                        c0.4-0.8,1.1-2,1.7-3.4l0-0.1c0,0-2.8-0.1-3.9-0.7s-2.4-0.1-3.8,0.6s-2.5,1-4.5,0.9s-8.4,9.1-8.4,9.1l-10-1.9c0,0-2.9-6.7-5.4-12.3
                        l0,0c0,0-4.6,1-5.8,1.5s-4.1,3.3-4.3,4.8s-1.4,4.9-2.1,7.6s-2.6,9.4-4.3,10.3s-4.3,3.9-4.9,5.3s-3.6,8.4-4.8,9.1s-5.8,4-7.9,3.1
                        s-5.8-4.1-7.9-4.6s-2.9-0.6-4-1.4s-3.1-1.9-3.4,0s-1.3,6.6-2.5,7.3s-6.3,0.9-8,0.9s-4.4-0.4-6.3-1.6s-2.8-0.9-3.8-0.4
                        s-8.9,8.3-8.9,8.3l0,0c-0.8,4.6-1,7.6-0.2,8.4c3,3,12.1,11,11.3,13.2c-0.6,1.6-5.5,8.9-9.5,13.3L660.5,321.8z"/>
                </g>
                <g>
                    <path style={props.district==='pis'?styles.select:{}} id='pis' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M778.9,131.3c0,0-1.6,2.5-1.9,4.5s-0.1,6.4-0.1,6.4s-2,1.5-3.1,4.1s-2,4.3-1.5,7s0.3,6.5-0.8,6.8
                        s-5.8,1.4-7,2.5s-9.6,8.1-9.6,8.1s-5.3-6-7.5-6s-4.8,0.8-5,1.4s-1.4,4.1-2.9,5s-5.5,2.1-6.8,0.4s-4-3.6-4-3.6l-3.6,3
                        c0,0,2.3,4.6,1.9,7.3s-2,8-3.1,9.4s-6.9,6.3-4.9,12.5s14.1,28,16.6,33.8s6.5,14.9,6.5,14.9l10,1.9c0,0,6.4-9.3,8.4-9.1
                        s3.1-0.1,4.5-0.9s2.6-1.3,3.8-0.6s3.9,0.7,3.9,0.7l0,0c0.8-1.8,1.5-3.6,1.7-4.6c0.4-1.9,2.3-4.1,3.8-6.3s4.8-3.3,6.5-3.6
                        s4.5-3.6,6.3-5.1s8.3-0.5,10.1,0.1s5,6.5,5.8,7.9s2.5,1.6,3.5,0.5s3.8-6.3,4.8-7.5s5.1-2.1,7.4-2.1c2.1,0,7.4-0.5,8.2-0.6l0.1-0.4
                        c-0.8-3.1-1.1-7.5-1.2-8.4c-0.1-1.3-5.9-4.1-8.5-3.8s-8.8,2-11.3,2.4s-5.6-2-6.1-3.9s-1.9-1.3-4.5-2.4s0.3-2.6,2.3-3.6
                        s2.4-7.1,2.8-9s-1.5-2.8-3.4-4.1s1.1-4.3,2.2-5.6c1-1.3,2.3-1.2,5-1.5c2.7-0.3,6.5-2.8,8.7-3.3c2.2-0.5,6.2-6.1,7.1-7
                        c0.9-0.9,10.8-9.1,12.7-10.5s2.2-5.4,2.3-7.1s4.2-2.4,5.5-3.5c1.3-1.1,2.2-6.9,2.2-6.9s-11.4,0.4-12.5,0.4s-9.5-1.7-10.9-2.4
                        c-1.4-0.6-3.5,0.5-5.4,0.1c-1.9-0.4-2.5-4.7-3.1-5.9c-0.6-1.2-6.1-0.5-8-0.4c-2,0.1-4.4-3.1-4.4-3.1l-0.2-0.1
                        c-0.9,0.2-1.7,0.4-2.5,0.6C791.1,131.9,783.7,131.6,778.9,131.3L778.9,131.3z"/>
                </g>
                <g>
                    <path style={props.district==='deb'?styles.select:{}} id='deb' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M926.1,408.1c1.6,0,2.8-0.2,4.5-0.8c7.3-2.3,12-4.8,15.6-4.9s21.3,3.3,27.3,4.5s11.5-0.3,13-1.5s4-2.6,7.5-2.4
                        s10.3,3,15.5,0.4s8.6-10.3,10.4-11.1s9.2-1.5,10.1-3s2.3-4.6,4.6-5.6s8.4-3.8,10.1-5.3s7-7.4,4.9-9.4s-9.5-3.5-8.8-7.9
                        s4.5-7.3,7-9.8s8.7-7.9,17.8-3.4l-0.1,0.1c-0.9,1.1-1.8,1.8-2.6,2.6c-1.2,1.2-1.2,3.3-1.2,5.2s-0.5,11.7-2,14.3
                        c-1.5,2.7-3,2.7-3,6.8c0,4.2,0.7,6.8,2.8,5.8s4.2-2,4.2-2s7,7.3,6.7,7.8c-0.3,0.5-6.5,5.8-3.8,7c2.7,1.2,4.5,0.3,4.3,3.8
                        c-0.2,3.5,1.3,3,3,1.8c1.7-1.2,6.8-4.3,6.7-3.2c-0.2,1.2-1.7,4.8-1.7,7.5c0,2.7,0,8.3-0.7,9.3c-0.7,1-2.2,3.5-2.7,5.7
                        c-0.5,2.2-1.5,4.2-2.7,5c-1.2,0.8-4.8,2.5-5.8,3.8c-1,1.3-4.8,7-7.2,10.7c-2.3,3.7-5.3,7-7.3,8.7s-3.8,3.8-5,5.8s1.2,4.3,3.3,6.5
                        c2.2,2.2,2.3,8.2,1.3,10.5c-1,2.3-13.2,22-13.2,22l-11.7,5.2c0,0,4.2,9.8,4.3,14.3l0,0.4c-6.2,0.1-20,0.4-23.8,1
                        c-0.5,0.1-1.8,0.1-3.8,0.2v0c-0.8-5-3-7.2-4.7-7.3c-1.7-0.2-4.3,3.2-6.3,4c-2,0.8-16-0.3-24.2-1.5s-17.7-6.2-24.7-10.2
                        s-17.3-11.2-20.3-14.2s-3.4-8.2-4.1-12.6s0-10.1-1-14s-0.6-12.5-2.1-19.6c-3.3-7-13.9-20.4-15.5-23.2c-1.8-3.1-0.8-7.2,1-8.6
                        s6.9,1.7,9.9,2.5s8.2,0.2,10.5-0.3s3.2-1.5,3.2-1.5L926.1,408.1z"/>
                </g>
                <g>
                    <path style={props.district==='nas'?styles.select:{}} id='nas' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M921.9,482c-1.1-2.9-1.5-6.3-2-9.3c-0.7-4.3,0-10.2-1-14s-0.5-12.2-2-19.3c-0.4-2.1-2.6-4.4-4.3-7.2l0,0
                        c-0.4,0.5-0.3,1.2-2.7,2.8c-3.5,1.5-8.8,4.9-10.2,5.2c-1.3,0.3-7,5.2-4.2,5.7s2.7,2.3,2.2,5.7c-0.5,3.3-1.2,7.2-4,7.8
                        c-2.8,0.7-6.8,3.5-10.7,5s-4.8,2.8-7.3,3s-11.8-0.9-11.5,2.1c0.3,3,7.1,6.7,5.8,7.5c-1.3,0.8-6.6,4.8-6.8,0.9
                        c-0.2-3.8-1.8-11.2-3.2-8.7s-5,9.2-6.8,10.3c-1.8,1.2-3.3,3.2-1.8,4.3c1.5,1.2,2.3,4.2,2,5.2s-1,2.5-3.3,2.2s-6.7-0.7-10.8-0.2
                        l0,0.2c0.4,1.6,0.7,3.1,1,4.3c1,3.7,1,6.2-0.2,7.5s-4,6.3-4.3,9.8s2.2,11.7,3,15s3.5,10.3,3.7,12s1.2,7.2,1.2,7.2v-0.4
                        c5-1.3,9.7-2.5,14.5-3.6c6.5-1.5,8-4.3,9-6.5s4-3.5,5.8-5.2s3.3-4.7,3.7-8c0.3-3.3,4-5,7.2-6.7s14.5-2.5,19.2-1.8s7.7,0.8,9.7-1
                        c2-1.8,2-7.3,2-12.7c0-5.3-1.2-9.3-1-11.7c0.2-2.3,2.2-4.3,3.3-4.8c1.2-0.5,4.8-2.3,4.8-2.3"/>
                </g>
                <g>
                    <path style={props.district==='jha'?styles.select:{}} id='jha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M843.5,547c-1.7,0.4-3.4,0.9-5.2,1.3c-7.2,1.8-15,4.8-15.2,7s0.3,4,0,6.2s-0.5,5.8,0.8,7.7
                        c1.3,1.8,3.7,5.8,3.7,8.5s0,6.4,0,6.4l-0.1,0c-2.1,0.4-3.8,0.7-4.6,1c-2.2,0.8-8.5,2.3-11.5,2.7c-3,0.3-7.3,1.8-10.5,1.8
                        c-3.1,0-6.4-3.3-6.7-3.5c0,0,0,0,0,0l0-0.2c0,0-0.8-12.2-0.8-13.8c0-1.7,0-4.2,0.5-7s0.5-7-0.3-15s-1.3-12.2-4-15.7
                        s-2.3-7.5-4.2-10.3c-1.8-2.8-1-4.5-1.2-6s-2.5-2.2-4.2-2.2s-6.3-6-7.8-7.3s-3-0.8-6.5-1.7s3.2-5.3,4.8-7.7
                        c1.7-2.3,2.7-9.7,3.2-16.5c0.1-1.7,0.2-3.4,0.1-5l0.2-0.2c0,0,7.7,2.8,10.5,1.5s10-5,11.7-6s5.5-1.8,6.8,0.2s3.2,5.2,4.7,4.7
                        s2.2-3.7,2-7.2s-0.8-8.8,1.5-10.3s4-2.8,5.3-0.5s3.7,2.7,5.3,2c1.7-0.7,6.2-2,9.7-1.3l0.2,0c0.4,0.3,0.8,0.5,1.1,0.6
                        c1.5,0.8,2.3,3.2,0.7,4.8c-1.7,1.7-2.8,4.5-1.2,7.3c1.7,2.8,3.3,4.8,4.3,8s2.5,10.7,3.5,14.3c1,3.7,1,6.2-0.2,7.5s-4,6.3-4.3,9.8
                        s2.2,11.7,3,15s3.5,10.3,3.7,12s1.2,7.2,1.2,7.2"/>
                </g>
                <path style={props.district==='kec'?styles.select:{}} id='kec' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M437.4,819.7c-3.3,0-7.8-2.2-9.8-3.3c-2-1.2-3.5,4.7-5.5,6.5s-6,4-8.3,5.2s-4.7,0.8-7,0.2
                    c-2.3-0.7-10.7,1.7-15.7,2.7s-12-9-18.7-17.3s-19.3-2.8-21.5-1.5s-4.2,1.5-8.2,1.3s-5,1.7-8.5,2s-2.7-11.2-2.7-12.5
                    s-1.8-1.8-5.2-2.2c-3.3-0.3-2.2-1.7-1.8-3.5c0.3-1.8,4.7-1.5,6.5-2.3s-2.3-4.3-5.5-5.7c-3.2-1.3-10.2-4.7-22.2-4.7s-17.5,7-23.5,8
                    s-2.8-4.7-0.5-6.5s-4.8-3.3-7.3-4.3s1.8-6,3.3-7.3s-0.5-3.5-2.3-5.3s-3.3-4.5-3.3-4.5l-0.1-0.1c-15.3-0.2-36.6-0.4-37.5,0.3
                    c-1.4,1.1-7.4,4.1-12.1,4.9c-4.7,0.8-17.8,6-23.9,6.9c-6,0.8-12.9,3.3-16.2,5.5c-3.3,2.2-5.2,7.7-5.8,10.4
                    c-0.6,2.8-1.6,8.2-2.2,13.2s-4.1,12.9-7.4,12.3c-3.3-0.5-4.7-1.4-8.5-5.2c-3.8-3.8-9.1-6.9-11.5-4.9s-3,6-6.6,6
                    c-3.6,0-8.8,0.8-9.9,6.9c-1.1,6-4.9,20.3-5.5,25.3c-0.6,4.9-3.6,15.9-0.6,16.7s5.5,4.1,3.6,5.5c-1.9,1.4-6,6.9-6,9.9
                    s-0.3,9.3-0.3,9.3l-4.8,2.5l-0.5-0.1c0,0,7.2,2,9.3,2.7c2.2,0.7,5.5,4.2,8.7,3.7c3.2-0.5,5.7-3,6.8-5.3c1.2-2.3,3.3-9.2,5.3-8.7
                    s1.2,3,2,5.5s2,4.2,2,6.5s-2.3,6.2,0,8.2s7.3,6.2,3.2,7.5c-4.2,1.3-7,5-5.3,6.2s2.7,2,5,3.2s5.8,1.3,10.3-0.5s15.7-6.3,24-7.3
                    s26-1,31.3-1s16.8-1.5,20.3-1s5,1.8,7.8,0.2c2.8-1.7,11.5-5.8,13.7-4c2.2,1.8,6.2,7.3,8.2,8s9.7,2.2,14.5,2.2s13.2,2,17,2.2
                    s6,1.2,6.5,2.2s3.3,2.5,4.5,3.2s4.3,3.7,3.5,5.8c-0.8,2.2-4.2,5.2-2.5,6.5s2.7,0.7,5.7-2.3s9.2-7.8,12.2-10s17.8-7.8,22.3-10
                    s8.7-5,11.2-5.2s14.3-4,20.2-4.7c5.8-0.7,11.5,1,12.3,4.3s8.7,2.8,8.7,2.8l-0.1,0.2c0.2-0.7,0.4-1.3,0.6-2.1c1-3.2,5.3-5.5,7.5-6.8
                    s5.7,0.3,8.8,0.3s6.2-6,3.8-7.3c-2.3-1.3-7.8-4.8-8-6.8s2.8-5,4.5-5.8s1.8-3.5,1.8-5.5s0-4.7,2.3-5.8c2.3-1.2,1.2-4.5,0.5-7.2
                    s1-8.7,2.5-11s4.8-5.3,8-6s7-2,9.3-6.8s-2-13-4.8-16c-1.1-1.2-2.2-2.5-3.2-3.9"/>
                <g>
                    <path style={props.district==='gwa'?styles.select:{}} id='gwa' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M114.1,889.6c0,0,7.2,2,9.3,2.7c2.2,0.7,5.5,4.2,8.7,3.7s5.7-3,6.8-5.3c1.2-2.3,3.3-9.2,5.3-8.7s1.2,3,2,5.5
                        s2,4.2,2,6.5s-2.3,6.2,0,8.2s7.3,6.2,3.2,7.5c-4.2,1.3-7,5-5.3,6.2s2.7,2,5,3.2s5.8,1.3,10.3-0.5s15.7-6.3,24-7.3s26-1,31.3-1
                        s16.8-1.5,20.3-1s5,1.8,7.8,0.2c2.8-1.7,11.5-5.8,13.7-4c2.2,1.8,6.2,7.3,8.2,8s9.7,2.2,14.5,2.2s13.2,2,17,2.2s6,1.2,6.5,2.2
                        s3.3,2.5,4.5,3.2s4.3,3.7,3.5,5.8c-0.8,2.2-4.2,5.2-2.5,6.5s2.7,0.7,5.7-2.3s9.2-7.8,12.2-10s17.8-7.8,22.3-10s8.7-5,11.2-5.2
                        s14.3-4,20.2-4.7c5.8-0.7,11.5,1,12.3,4.3s8.7,2.8,8.7,2.8l-0.1,0.1c-0.6,2.3-1,4.2-1.6,4.9c-0.7,0.8-2.5,4.2,1.7,4.8
                        c4.2,0.7,10.5,1.3,14.8,3s6.7,6.3,14.8,1.3c8.2-5,13-6.8,18.2-7.3s23.8-4.2,34.7-5.3c10.8-1.2,26-4.7,30-5.3s9.2-2.2,11-1.2
                        s3.2,5.3,3.2,8.3s-0.7,20-0.7,20l5.5,5.4l0,0c-2,1.8-3.1,4-5.5,5.9c-3.8,3.1-20.3-1.2-24.5-1.5c-4.2-0.4-25.7,0.8-31.4,0.8
                        c-5.7,0-10.7,11.5-13,13c-2.3,1.5-7.7-5.7-11.5-8s-7.7,1.9-10,3.8c-2.3,1.9-6.5,0.4-12.6-1.5c-6.1-1.9-13-4.6-19.1-6.5
                        c-6.1-1.9-10.3-4.2-9.6-7.7c0.8-3.5,5.4-6.1,6.5-9.2c1.1-3.1-6.9-5-11.5-5c-4.6,0-7.7,3.1-7.3,8.8c0.4,5.8,4.6,6.5,4.2,8.8
                        s-6.1,1.2-10,0.8s-2.3,1.2-20.7-5c-18.4-6.1-27.2,3.1-33.7,6.9c-6.5,3.8-1.1,11.1-3.5,15.3c-2.3,4.2-31.8-1.5-41.4-4.2
                        c-9.6-2.7-45.2-7.7-54-8s-34.5,10.3-37.2,11.9c-2.7,1.5-4.2,4.2-4.6,10c-0.4,5.8-3.1,3.1-7.3,2.3c-4.2-0.8-1.9-6.9-1.5-11.1
                        c0.4-4.2-11.9-1.9-17.6-1.2c-5.7,0.8-6.1,8-8,11.9s-11.9,2.3-18.4,2.3c-6.5,0-4.6,3.8-8,8c-3.4,4.2-10,1.9-12.3,0.4
                        c-2.3-1.5,0-6.5,0.8-11.1c0.8-4.6-5.8-6.9-11.1-8.4c-1.3-0.4-2.8-0.6-4.3-0.7l0-0.1l9.9-66.4l8.3-4.3"/>
                </g>
                <path style={props.district==='khu'?styles.select:{}} id='khu' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M778.4,458.4c0,0-5,5.7-5.2,8.8c-0.2,3.2,1,9.3,0.5,16.2c-0.5,6.8-1.5,14.2-3.2,16.5s-8.3,6.8-4.8,7.7
                    s5,0.3,6.5,1.7c1.5,1.3,6.2,7.3,7.8,7.3s4,0.7,4.2,2.2s-0.7,3.2,1.2,6c1.8,2.8,1.5,6.8,4.2,10.3s3.2,7.7,4,15.7s0.8,12.2,0.3,15
                    s-0.5,5.3-0.5,7s0.8,13.8,0.8,13.8l-0.2,0c0,0.5-0.6,6-2.5,9.6c-2,3.8-1.8,14.2-3.8,16.2s-10.8,9.7-11.5,12.5
                    c-0.7,2.8-1.8,11.2-2.7,11.3c-0.8,0.2-5.7,3.7-5.8,5.2c-0.2,1.5-1.3,13.7-1.8,15.7s-1.2,7.2-2,9c-0.8,1.8-3.5,8-3.2,11.5
                    c0.3,3.5,3.7,17.3,3.8,21.2c0.2,3.8-0.7,8-1,10.2c-0.3,2.2-0.3,4.2-0.3,6.7s0.8,4.7,0,6.2c-0.8,1.5-1.2,5.7-1,10
                    c0.2,4.3,1.8,15.2,1.8,15.2s-3.7,4.3-3,7.2c0.7,2.8,1.5,5.3,0.8,7.7c-0.1,0.4-0.1,0.9-0.1,1.6v0.1c0,0-2.9-1-4.6,0.2
                    s-15.3,9.7-15.3,9.7s1.7,5.8,2.8,8.3c1.2,2.5,3.7,7.8,5.2,10.2c1.5,2.3,5,6.8,6.2,8.2c1.2,1.3,2.3,7.8,3.5,10.5s4.7,3.3,4.7,3.3
                    s-0.3,4-0.8,5.7s-0.2,15-0.5,17s-1.5,9.8-2.2,12.7c-0.7,2.8,1.5,13,1.8,15.5s0.7,5-0.5,7.2s-0.5,5-1.2,8.3s-1.7,3.7-4,4.8
                    c-2.3,1.2-7.7,1.3-11,1.7c-3.3,0.3-2.2-4.2-2.2-5.7s-3.7-12.8-4-16s-3.7-8.3-4.8-11.2c-1.2-2.8-3-9.8-3.5-12.8s-4.3-14.5-5-19.3
                    s-3.2-9-6-13.2s-2.8-14.5-3.2-17.3c-0.3-2.8-1.8-3.3-3.5-2.8s-7.7,3.2-9.3,3.3c-1.7,0.2-4.7,3-5.7,3.8s-2-2.5-5.2-5.7
                    s-10,2.2-11.8,3s-4.2-0.7-6.2-2.2s-5.7-2.8-8-2.8s-4-1.7-6.5-3.3c-2.5-1.7-5.5,1.8-5.5,1.8l0-0.1c-0.1-0.2-0.5-1-2.3-2.8
                    c-2.2-2.2-3.2-5.5-4.3-7.8c-1.2-2.3-4.7-1-7.5,1.2s-3.8,7.2-3.2,10.3c0.7,3.2-0.8,6.8-2.5,7.5s-1.2,4.8,0.2,10.3
                    c1.3,5.5,1.8,9.5,1.8,9.5s-18.3-31.2-21.2-36.7s1.2-14.2,2-17.5s5.5-20.5,6.5-26.2s5.5-28.5,5.7-34.3s0.3-11.5-1.7-11.5
                    s-3.3,4-4.3,5.8s-8,2.3-10.8,1.3s-5.7-8.7-6.2-13.7s-3.2-9.8-8-14s-14.8-7.3-18.7-9c-3.8-1.7-6.5-2.8-6.5-2.8l0-0.1
                    c0-0.2,0.1-0.3,0.1-0.4c0.7-1.2,8.2-6.3,11.2-10s2-6.2,1.3-8.5s0.7-6.8,1.7-10.8s5.8-8,6.7-9.3c0.8-1.3,1-6.2,2.3-10s7.2-7,9.8-9
                    c2.7-2,3.5-5.3,6-8.7c0.7-0.9,1.9-1.7,3.3-2.3c3.9-1.7,9.5-2.4,11.7-2.4c3,0,9.2-1.5,11.7-2.7s15.3-12.3,22-16.7
                    c6.7-4.3,22-11.5,22.7-12.8s2.2-10.3,2.2-14.3s-2.7-5.7-5.3-7.8c-2.7-2.2-5.8-8.5-5.8-11.7s3.7-10.7,4.7-12.8s13.8-6.2,15.8-6.7
                    s7.7-9.5,9.3-12.2c1.7-2.7,5.2-12,7.5-15.8s6.3-7.3,10.7-8.2c4.3-0.8,19.3-1.3,30.7-1.7c11.3-0.3,16.8-1.2,19.7-1.8
                    c1.1-0.3,2.3-0.3,3.5-0.4"/>
                <path style={props.district==='las'?styles.select:{}} id='las' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M534.4,939.4l-5.7-5.5c0,0,0.7-17,0.7-20c0-1.5-0.3-3.4-0.9-5l0,0c0,0,3.6,0.7,5.1,0c1.5-0.7,4.8-6.2,6.8-5.2
                    s4.3,2.5,5.5,1.3s2.8-3.7,5.2-4c2.3-0.3,6.3-1.3,7.5-2.3s2.7-3.2,4.3-3.8c1.7-0.7,5.7-2.8,5.7-0.7s0,3.8-1.2,5.3s-2.3,4-1.5,6
                    s4.8,5.3,4.5,6.7c-0.3,1.3-1,8.3-2.3,9.2c-1.3,0.8-4.2,1.3-3.5,3.3s3.8,4.2,6.8,2s13.8-8.3,18-10c4-1.6,8.7,0.2,12.8,2.3
                    c4.2,2.2,6.5,0,8.7-0.7s4.2,0.2,5.2,2s4,0,5.8-0.3s3,0.5,4.7,2.8s5.5-0.3,6.7-1.5s5.2-8.5,7.8-13.3c2.7-4.8,15.8-37.5,15.8-37.5
                    s-6.7-33.3-7-37.3s-4.2-11.8-4.8-13.8s-1.2-9.2-0.5-11.2s3.3-5.3,5.8-6.5s2.3-4.5,2.2-8.2c-0.2-3.7,3-7.2,3-7.2s0,0,0-0.1l0,0.1
                    c0,0,3-3.5,5.5-1.8s4.2,3.3,6.5,3.3s6,1.3,8,2.8s4.3,3,6.2,2.2c1.8-0.8,8.7-6.2,11.8-3c3.2,3.2,4.2,6.5,5.2,5.7s4-3.7,5.7-3.8
                    c1.7-0.2,7.7-2.8,9.3-3.3c1.7-0.5,3.2,0,3.5,2.8s0.3,13.2,3.2,17.3c2.8,4.2,5.3,8.3,6,13.2c0.7,4.8,4.5,16.3,5,19.3s2.3,10,3.5,12.8
                    s4.5,8,4.8,11.2s4,14.5,4,16s-1.2,6,2.2,5.7c3.3-0.3,8.7-0.5,11-1.7s3.3-1.5,4-4.8s0-6.2,1.2-8.3c1.2-2.2,0.8-4.7,0.5-7.2
                    s-2.5-12.7-1.8-15.5s1.8-10.7,2.2-12.7c0.3-2,0-15.3,0.5-17s0.8-5.7,0.8-5.7s-3.5-0.7-4.7-3.3c-1.2-2.7-2.3-9.2-3.5-10.5
                    s-4.7-5.8-6.2-8.2c-1.5-2.3-4-7.7-5.2-10.2s-2.8-8.3-2.8-8.3s13.7-8.5,15.3-9.7c1.7-1.2,4.6-0.2,4.6-0.2l0,0c0.3,3.2,2.3,9,2.4,12.4
                    c0.2,4,6.3,13.2,8.8,17.5c2.5,4.3,6.8,14.2,8,16.5c1.2,2.3,5.8,12,6.2,13.8c0.3,1.8,1.8,6,4.7,8.5c2.8,2.5,6.3,6.5,6.2,8.5
                    c-0.2,2-1.5,12-1.7,14.5c-0.2,2.5-0.2,5.2-1,7.5c-0.8,2.3,0.2,3.7,0.7,6.8c0.5,3.2-0.5,8.8-2,10.5c-1.5,1.7-6.2,6.7-6.8,8.8
                    c-0.7,2.2-3.7,17-5.3,17.5c-1.7,0.5-3,0.7-4,3c-1,2.3-3.2,5.3-4.3,6.8c-1.2,1.5-7.2,6.5-7.2,10.7c0,4.2,0.2,6-1.3,8.3
                    s-1.8,7-2.7,9.2c-0.8,2.2-6.3,8.8-8.7,10.5c-2.3,1.7-6,9.3-6.3,14.3c-0.3,5-4.3,7.3-6.5,9.5c-2.2,2.2-4.8,4.3-6.7,4.7
                    c-1.8,0.3-11.5,5.2-12.2,5.5c-0.7,0.3-8.7,8.8-11.3,7h0c0.7-2.1-0.7-4.5-0.7-9.8c0-7.7,2.7-15.3,5.4-19.2s-13.4-22.2-16.5-25.7
                    c-3.1-3.5-3.1-8.8-2.7-12.6c0.4-3.8-3.5-13.8-6.1-16.5c-2.7-2.7-11.5-4.6-19.2-5.4c-7.7-0.8-10.3-0.8-16.9,0.8s-7.3,5.4-9.6,8
                    c-2.3,2.7,11.5,0.4,17.2-0.8c5.8-1.2,7.3,0.8,11.1,1.9c3.8,1.2,9.2,4.2,14.2,6.1s-1.2,5-5.7,5c-4.6,0-13.4-1.9-18.8-3.1
                    c-5.4-1.2-18,1.5-24.5,2.3c-6.5,0.8-22.6,3.5-28.3,3.8c-5.8,0.4-13.8,5-23,8c-9.2,3.1-10.7,2.7-19.2-1.9c-8.4-4.6-21.8-1.5-28-0.4
                    c-2.4,0.5-4,1.4-5.2,2.5"/>
                <path style={props.district==='awa'?styles.select:{}} id='awa' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M574.2,655.7c0,0,3,1.3,6.8,3s13.8,4.8,18.7,9c4.8,4.2,7.5,9,8,14s3.3,12.7,6.2,13.7c2.8,1,9.8,0.5,10.8-1.3
                    s2.3-5.8,4.3-5.8s1.8,5.7,1.7,11.5c-0.2,5.8-4.7,28.7-5.7,34.3c-1,5.7-5.7,22.8-6.5,26.2c-0.8,3.3-4.8,12-2,17.5
                    s21.2,36.7,21.2,36.7s-0.5-4-1.8-9.5s-1.8-9.7-0.2-10.3c1.7-0.7,3.2-4.3,2.5-7.5s0.3-8.2,3.2-10.3c2.8-2.2,6.3-3.5,7.5-1.2
                    s2.2,5.7,4.3,7.8c1.8,1.8,2.2,2.6,2.3,2.8c0,0,0,0.1,0,0.1s-3.2,3.5-3,7.2s0.3,7-2.2,8.2s-5.2,4.5-5.8,6.5s-0.2,9.2,0.5,11.2
                    s4.5,9.8,4.8,13.8s7,37.3,7,37.3s-13.2,32.7-15.8,37.5c-2.7,4.8-6.7,12.2-7.8,13.3c-1.2,1.2-5,3.8-6.7,1.5s-2.8-3.2-4.7-2.8
                    c-1.8,0.3-4.8,2.2-5.8,0.3c-1-1.8-3-2.7-5.2-2s-4.5,2.8-8.7,0.7c-4.2-2.2-8.8-3.9-12.8-2.3c-4.2,1.7-15,7.8-18,10s-6.2,0-6.8-2
                    s2.2-2.5,3.5-3.3s2-7.8,2.3-9.2c0.3-1.3-3.7-4.7-4.5-6.7s0.3-4.5,1.5-6s1.2-3.2,1.2-5.3c0-2.2-4,0-5.7,0.7s-3.2,2.8-4.3,3.8
                    s-5.2,2-7.5,2.3s-4,2.8-5.2,4s-3.5-0.3-5.5-1.3s-5.3,4.5-6.8,5.2s-5.1,0-5.1,0l0-0.1c-0.6-1.5-1.3-2.8-2.2-3.3c-1.8-1-7,0.5-11,1.2
                    s-19.2,4.2-30,5.3c-10.8,1.2-29.5,4.8-34.7,5.3s-10,2.3-18.2,7.3s-10.5,0.3-14.8-1.3c-4.3-1.7-10.7-2.3-14.8-3
                    c-4.2-0.7-2.3-4-1.7-4.8c0.7-0.9,1.2-3.7,2.2-6.8c1-3.2,5.3-5.5,7.5-6.8s5.7,0.3,8.8,0.3s6.2-6,3.8-7.3c-2.3-1.3-7.8-4.8-8-6.8
                    s2.8-5,4.5-5.8s1.8-3.5,1.8-5.5s0-4.7,2.3-5.8c2.3-1.2,1.2-4.5,0.5-7.2s1-8.7,2.5-11s4.8-5.3,8-6s7-2,9.3-6.8s-2-13-4.8-16
                    s-5.3-6.5-6.5-11.5s1.2-10,5.5-11.3s22,0.7,26.2,0.8c4.2,0.2,18.2,0.8,25.2,1.2c7,0.3,4.2-6.5,3.5-9.3s0.2-5.7,4.8-10.2
                    c4.7-4.5,30.2-27.3,32-29.5s6.2-7.7,7.5-8.8c1.2-1,4.8-10.8,6-15.2c0.2-0.6,0.3-1.1,0.4-1.5c0.5-2.8,0.5-9,1.5-11.7
                    s24.7-40.8,25.5-43.2c0.8-2.2,2.7-15.2,3.5-17.8"/>
                <path style={props.district==='pan'?styles.select:{}} id='pan' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M436.4,819.8c-1.5-2.1-2.6-4.6-3.3-7.6c-1.2-5,1.2-10,5.5-11.3s22,0.7,26.2,0.8c4.2,0.2,18.2,0.8,25.2,1.2
                    c7,0.3,4.2-6.5,3.5-9.3s0.2-5.7,4.8-10.2c4.7-4.5,30.2-27.3,32-29.5s6.2-7.7,7.5-8.8c1.2-1,4.8-10.8,6-15.2l0-0.2c0,0-3.3,0-5.2,0.2
                    c-1.8,0.2-4.2-0.8-5.7-2.8s-3.7-6.3-4.2-10.5s-8.5-5.8-12.8-5.7c-4.3,0.2-3-3.5-3.3-7c-0.3-3.5-3.2-6.7-3.3-9.2
                    c-0.2-2.5-1.2-3.5-4-4.7s-12.7,6.3-14.2,7.7c0,0,0,0,0,0c-1.8,1.5-17,12.7-20.6,15c-3.7,2.3-9.5,0-12.3-1.7s-12.2-7.5-14.7-10.2
                    s-1.3-7.7-2.2-11.8c-0.8-4.2-4.8-7-6-11.2s-5.5-3.7-6.8-1.7s-2.7,2.3-5.7,4.2c-3,1.8-4.3,3.2-6.7,6.8c-2.3,3.7-5.7,5.8-9.7,8.8
                    s-4.7,1.7-9,2.3c-4.3,0.7-0.5,5.7-0.5,8.3c0,2.7-0.3,4.2-2.2,6.8c-1.8,2.7-4,1.8-3.8,0.5s2.7-5.3,3-7.8s-4.7-2.5-8-2.7
                    s-8.3-3.5-12.2-6.2c-3.8-2.7-5.2-5.7-5-8.2s-4.5-6.8-8.5-9.7s-8,0.3-11.8,1.7c-3.8,1.3-9.5,0.7-12,0.7s-6.3,1.5-8.7,3.2
                    c-2.3,1.7-8.5,0.7-11,0.8c-2.5,0.2-7,1.2-9.7,1s-9.2,0.2-9.2,0.2l0.4,0c0.5,0.4,0.9,0.8,1.1,1c1.4,1.9,5.5,10.4,2.7,11
                    c-2.7,0.5-10.1,4.9-10.1,7.4s1.1,32.1,1.1,32.1s-7.7,0-8.2,1.4c-0.5,1.4,0.3,24.7,0.3,24.7s-6.8-0.1-15.5-0.3l0.1,0.1
                    c0,0,1.5,2.7,3.3,4.5s3.8,4,2.3,5.3s-5.8,6.3-3.3,7.3s9.7,2.5,7.3,4.3c-2.3,1.8-5.5,7.5,0.5,6.5s11.5-8,23.5-8s19,3.3,22.2,4.7
                    c3.2,1.3,7.3,4.8,5.5,5.7c-1.8,0.8-6.2,0.5-6.5,2.3s-1.5,3.2,1.8,3.5s5.2,0.8,5.2,2.2c0,1.3-0.8,12.8,2.7,12.5s4.5-2.2,8.5-2
                    s6,0,8.2-1.3s14.8-6.8,21.5,1.5s13.7,18.3,18.7,17.3s13.3-3.3,15.7-2.7c2.3,0.7,4.7,1,7-0.2s6.3-3.3,8.3-5.2c2-1.8,3.5-7.7,5.5-6.5
                    s6.5,3.3,9.8,3.3"/>
                <path style={props.district==='soh'?styles.select:{}} id='soh' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M921.7,516.5L921.7,516.5l0-3.4H920l0-1.4l1.6-1.6v-5.1h-1.8V502l3.5-3.4v-8.3l-1.7-1.6h-8l0,0
                    c0.5-1.9,2.2-3.5,3.2-4c1.2-0.5,4.8-2.3,4.8-2.3l0.2-0.1c0.5,1.3,1.2,2.4,2.1,3.3c3,3,13.7,10,20.7,14s16.5,9,24.7,10.2
                    s22.2,2.3,24.2,1.5s4.7-4.2,6.3-4c1.7,0.2,3.8,2.3,4.7,7.3l-0.1,0c-17.1,0.5-81.6,1-81.6,1L921.7,516.5L921.7,516.5z"/>
                <path style={props.district==='jaf'?styles.select:{}} id='jaf' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M883.4,516.9c0.1-0.1,0.2-0.1,0.3-0.2c3.2-1.7,14.5-2.5,19.2-1.8s7.7,0.8,9.7-1c2-1.8,2-7.3,2-12.7
                    s-1.2-9.3-1-11.7c0-0.2,0.1-0.5,0.1-0.7l0-0.1h8l1.7,1.6v8.3l-3.5,3.4v2.9h1.8v5.1l-1.6,1.6l0,1.4h1.7l0,3.3l0,0.1l0,0l-6,4.7
                    l-5,13.7l-10.7,3.2l0,0l-0.1,0l0,0l-8.1,2.4c0,0-15.7,15.7-17.7,17.3c-2,1.7-5.3,6-7.3,6.7c-2,0.7-8.3,3.2-9,5.3
                    c-0.7,2.2-1.3,4.8-1.3,4.8s-3,0.3-5.7,0.3c-2.7,0-9.5,0.8-9.8,2.7c-0.3,1.8,1.3,4.8-2.2,4.8c-2.3,0-7.2,0.6-11.1,1.3h-0.1
                    c0,0,0-3.8,0-6.4s-2.3-6.7-3.7-8.5c-1.3-1.8-1.2-5.5-0.8-7.7s-0.2-4,0-6.2s8-5.2,15.2-7c7.2-1.8,13.3-3.5,19.8-5s8-4.3,9-6.5
                    s4-3.5,5.8-5.2c1.8-1.7,3.3-4.7,3.7-8c0.3-3.1,3.5-4.8,6.5-6.3l0,0L883.4,516.9z"/>
                <path style={props.district==='sur'?styles.select:{}} id='sur' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M635.5,476.4l4.9,1.9h10.7l1.4,1.5l5.5,1.6h5.5l3.5,1.4l3.3,3.3l1.5,1.4l1.7,1.8v4.5l3.1,3.3l3.2,1.7h4.2
                    l2.2,1.5h3.9l-0.1,0.2c-4.2,1.5-8.9,3.4-9.5,4.7c-1,2.2-4.7,9.7-4.7,12.8s3.2,9.5,5.8,11.7c2.7,2.2,5.3,3.8,5.3,7.8
                    s-1.5,13-2.2,14.3s-16,8.5-22.7,12.8c-6.7,4.3-19.5,15.5-22,16.7s-8.7,2.7-11.7,2.7c-2.2,0-7.2,0.5-11.1,2.1l-0.1,0.1
                    c2.9-3,5-10.5,4.1-14.6c-1-4.2-5-1.3-9-2.2s-1.3-15.3-1-19c0.3-3.7-4.8-3-7.3-4.7c-2.5-1.7,1.7-7.7,3-10.8c1.3-3.2,2.6-7.2,2.6-7.2
                    s5.3-10.1,7.9-11.8s6-9,9-12.5c0,0,2.6-3.9,4.3-5.9s1.3-5.6,2.4-8s2.4-9.5,2.4-12.3c0-0.5-0.2-0.9-0.4-1L635.5,476.4z"/>
                <path style={props.district==='kal'?styles.select:{}} id='kal' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M635.4,476.4l4.9,1.9h10.7l1.4,1.5l5.5,1.6h5.5l3.5,1.4l3.3,3.3l1.5,1.4l1.7,1.8v4.5l3.1,3.3l3.2,1.7h4.2
                    l2.2,1.5h3.9l0.6,0c2.9-1,4.8-1.6,5.7-1.8c2-0.5,7.7-9.5,9.3-12.2c1.7-2.7,5.2-12,7.5-15.8s6.3-7.3,10.7-8.2
                    c4.3-0.8,19.3-1.3,30.7-1.7c11.3-0.3,16.8-1.2,19.7-1.8c2.8-0.7,6.3-0.2,8.7-1.8c2.3-1.7,7-19,7.7-22.2s5.5-10,7-11.2s4-8.2,4.8-11
                    s-2.2-17-2.2-18.3s-0.3-8.7-0.3-8.7s-3.2-0.7-7.2-3.7s-4.7-7.5-4.5-11.5s-7.2-1.5-8.8-0.8c-1.7,0.7-5.8,1-9.8,0.7
                    c-2.9-0.2-5.5-0.7-7-1.6l-0.2,0.1c-2.8,1.7-5.7,7.8-6.3,9.2c-0.7,1.3-2.5,1.7-5.2,1.7s-8.8-1-10.8-1.5s-6.5,1.2-8.7,2.2
                    s-5.8,0.3-7.3-0.7s4.3-6.3,5.5-10.2c1.2-3.8-1.5-5.7-4.7-6.3c-3.2-0.7-6,1-8,3s-3.3,3.3-6.5,5.3s-4.2,3.8-6.5,6.5s-4.3-0.7-5.2-4.7
                    c-0.8-4,3.3-8.8,3.7-15c0.3-6.2-4.7-5-9-3.3s-13.8,14-15.2,16c-1.3,2-5.5,11-6,14s-0.8,4.7-3.7,6.7c-2.8,2-9,0.3-12.2-0.5
                    s-8-4.7-8-4.7l-0.1,0.2c-0.9,1.5-1.8,2.9-2.1,3.1c-0.3,0.3-1.4,1.7-2.9,3l0,0.1c0,0.4-0.6,6.9-1.9,9.5s-4.3,11.8-4.5,14.4
                    s1.8,6.1,2.9,8.4s1.6,4.3,1.6,6.8s-0.8,8.9-1.6,12.6s-4.1,9-4.6,11.5s-1.6,5-3.4,5.8s-4.3,2.4-4.9,4.9s-1.5,7.6-1,9.4
                    s3.1,3.3,4.3,2.3c0.9-0.8,3.8-2.7,5-1.9"/>
                <path style={props.district==='nus'?styles.select:{}} id='nus' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M659.7,321.2c-0.1,0,0.7,0.5,0.7,0.5s1.7,3.7,0.7,5.1s-2.4,5.8-2.4,6.9s0.9,10.1,1.3,12.5s3,6.8,2.3,8.3
                    s-1.8,6-2.5,7.3s-3,3.5-3.1,6s-0.9,5-1.9,7s-3.1,7.4-4.1,8.8s-3.9,6.1-4.4,6.6s-4.9,5.9-6.5,4.9s-3.4-2.4-5.3-0.9s-3.1,8.4-4.9,11.5
                    s-5,6.9-6.5,8s-14.8,9.1-17.3,7.6s-8.3-2.4-10.3-2.4s-7.4,0.8-8,3s0.4,5.1-2.9,6.3s-4.3,1.3-5.1,2.8s-2.8,4.1-4.6,4.1
                    c-1.6,0-10-1.9-14.3-0.9l0-0.2v-5.6l-4.5-7.1l-6-10.7l-5.3-8.5l-6.1-11.7l-6.1-8.2l-6.4-7.4l-8-7.3l0-0.4
                    c29.4-8.2,134.9-41.2,138-42.6C657,324,658.3,322.8,659.7,321.2L659.7,321.2z"/>
                <path style={props.district==='cha'?styles.select:{}} id='cha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M472.1,483.9L472.1,483.9c3.1-1,5.7-2,6.6-2.2c2.3-0.6,13.5,0.6,15.1,0.4s13.6-15.3,14.3-15.9s11-0.6,12.6-0.8
                    s9.6-8.9,10.9-10s4.9-4,7.5-4.8s16.5-13.4,19.6-15.9c0.5-0.4,1.1-0.6,1.9-0.8l0-0.2v-5.6l-4.5-7.1l-6-10.7l-5.3-8.5l-6.1-11.7
                    l-6.1-8.2l-6.4-7.4l-8-7.3l-0.3-0.3c-0.6,0.2-1.1,0.4-1.6,0.5l-0.1,0l-0.1,0c-3,0.8-4.9,1.3-5.4,1.3c-4.9,0-50.8-6.9-54.6-6.9
                    s-46.9,9.3-49.7,12.1c-2.7,2.7-3.6,3.6-3.6,7.1s-1.6,8-10.2,7.1c-8.5-0.8-61.2-14-66.7-14s-127.3,15.4-127.3,15.4l-194-68.9
                    c0,0,33.2,46.7,39.2,50.8c6,4.1,16.7,15.4,17.3,18.9c0.5,3.6,12.1,31.3,14,34c1.9,2.8,6.9,6.6,6.6,9.3c-0.3,2.7,0.3,4.7,3.8,9.9
                    c3.6,5.2,9.3,15.6,13.4,24.7c4.1,9,28.5,34.6,40.3,37.9c11.8,3.3,52.4,15.4,54.1,17.3c1.6,1.9,17.6,20.3,21.1,20
                    c3.6-0.3,22.2-3.8,22.2-3.8s-3.9,39-1.1,46.4c0.4,1.1,0.9,3,1.4,5.4l0.2-0.2c0,0,4.3-4,6.4-6.3s7-8.3,9-10.4s18.8-18.1,21.4-19.8
                    s7.5-3.4,9.4-3.8s5-4.8,5-9.3s9.5-2.9,11.6-2.6s7.5-0.6,10.4-1.5s12.3-2.5,18.8-2.8s13.6,0.8,17.9,0.3s8.3-3.3,9.8-4.6
                    s3.6-2.9,3.6-2.9l0.6,7.8c0,0,10.1-10.8,11.4-11.5s8-0.8,9.9-1s5.4-5.8,7.3-6.1s4.9-3,6.6-4.3c1.8-1.3,5.3-0.8,7.8-0.9
                    c2.5-0.1,5.6-1.9,9-3.1s3-6.5,3-6.5s11.6,2.6,14.1,2.4s5.9-5.8,7.4-8.5s6.8-4.3,7.9-4.4s5.4-2.1,7-2.6s5.8-1.8,8.1-1.9
                    C461.8,487.3,467.4,485.5,472.1,483.9L472.1,483.9"/>
                <path style={props.district==='kha'?styles.select:{}} id='kha' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M473.4,483.5v5l2.2,1.8l-1.1,13.1l-1.1,15.4l10.5,14.2l12,10.6l7,5.7l3.5,6.3l2.4,6l3.6,3.3h1.7l1.9-1.2h3.3
                    l1.2-1.7l3-0.1l6-2.7l9.2,0.5l4.1-3.7l5.9-3l5.3-4.1l6.9-5.2l8.1-4.1l11.1-5.3l8.4-4.3l4.5-0.1l3-1.8l7-2.5l9.4-2.6l0.4-0.2
                    c1.6-2.7,3.5-5.5,4.8-6.3c2.6-1.6,6-9,9-12.5c0,0,2.6-3.9,4.3-5.9s1.3-5.6,2.4-8s2.4-9.5,2.4-12.3s-4.3-0.1-5.4,0.9
                    s-3.8-0.5-4.3-2.3s0.4-6.9,1-9.4s3.1-4.1,4.9-4.9s2.9-3.3,3.4-5.8s3.8-7.8,4.6-11.5s1.6-10.1,1.6-12.6s-0.5-4.5-1.6-6.8
                    s-3.1-5.8-2.9-8.4s3.3-11.8,4.5-14.4s1.9-9.1,1.9-9.5l-0.3,0.2c-1.3,1.1-2.5,2-3.3,1.5c-1.6-1-3.4-2.4-5.3-0.9s-3.1,8.4-4.9,11.5
                    s-5,6.9-6.5,8s-14.8,9.1-17.3,7.6s-8.3-2.4-10.3-2.4s-7.4,0.8-8,3s0.4,5.1-2.9,6.3s-4.3,1.3-5.1,2.8s-2.8,4.1-4.6,4.1
                    s-13.1-2.6-16.3-0.1s-17,15.1-19.6,15.9s-6.3,3.6-7.5,4.8s-9.3,9.9-10.9,10s-12,0.1-12.6,0.8s-12.6,15.6-14.3,15.9s-12.9-1-15.1-0.4
                    C477.8,482,476.1,482.6,473.4,483.5z"/>
                <path style={props.district==='was'?styles.select:{}} id='was' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M473.4,483.4v5.1l2.2,1.8l-1.1,13.1l-1.1,15.4l10.5,14.2l12,10.6l7,5.7l3.5,6.3l2.4,6l3.6,3.3h1.7l1.9-1.2h3.3
                    l1.2-1.7l3-0.1l6-2.7l9.2,0.5l4.1-3.7l5.9-3l5.3-4.1l6.9-5.2l8.1-4.1l11.1-5.3l8.4-4.3l4.5-0.1l3-1.8l7-2.5l9.4-2.6l0.3,0
                    c-1.7,2.8-3,5.3-3,5.3s-1.3,4-2.6,7.2c-1.3,3.2-5.5,9.2-3,10.8s7.7,1,7.3,4.7s-3,18.2,1,19c4,0.8,8-2,9,2.2s-1.3,12-4.3,14.8
                    l-0.2-0.1c-1.4,0.6-2.6,1.4-3.3,2.3c-2.5,3.3-3.3,6.7-6,8.7c-2.7,2-8.5,5.2-9.8,9s-1.5,8.7-2.3,10c-0.8,1.3-5.7,5.3-6.7,9.3
                    s-2.3,8.5-1.7,10.8s1.7,4.8-1.3,8.5s-10.5,8.8-11.2,10s-2.8,15.8-3.7,18.2c-0.8,2.3-24.5,40.5-25.5,43.2s-1,8.8-1.5,11.7
                    c-0.1,0.3-0.2,0.9-0.4,1.5l0-0.2c0,0-3.3,0-5.2,0.2c-1.8,0.2-4.2-0.8-5.7-2.8s-3.7-6.3-4.2-10.5s-8.5-5.8-12.8-5.7
                    c-4.3,0.2-3-3.5-3.3-7c-0.3-3.5-3.2-6.7-3.3-9.2s-1.2-3.5-4-4.7c-2.8-1.2-12.7,6.3-14.2,7.7c0,0,0,0,0,0l0,0l0.2-0.1l0,0
                    c-1.8,1.5-17,12.7-20.6,15c-3.7,2.3-9.5,0-12.3-1.7s-12.2-7.5-14.7-10.2s-1.3-7.7-2.2-11.8c-0.8-4.2-4.8-7-6-11.2s-5.5-3.7-6.8-1.7
                    s-2.7,2.3-5.7,4.2c-3,1.8-4.3,3.2-6.7,6.8c-2.3,3.7-5.7,5.8-9.7,8.8s-4.7,1.7-9,2.3c-4.3,0.7-0.5,5.7-0.5,8.3c0,2.7-0.3,4.2-2.2,6.8
                    c-1.8,2.7-4,1.8-3.8,0.5s2.7-5.3,3-7.8s-4.7-2.5-8-2.7s-8.3-3.5-12.2-6.2c-3.8-2.7-5.2-5.7-5-8.2s-4.5-6.8-8.5-9.7s-8,0.3-11.8,1.7
                    c-3.8,1.3-9.5,0.7-12,0.7s-6.3,1.5-8.7,3.2c-2.3,1.7-8.5,0.7-11,0.8c-2.5,0.2-7,1.2-9.7,1s-9.2,0.2-9.2,0.2l0.4,0
                    c-3.1-2.6-11.5-7.5-14.6-7.5c-3.6,0-23.9,5.5-30.2,8.2c-6.3,2.7-16.2-6.3-16.2-9.1c0-2.7,1.6-9.6,2.5-12.9
                    c0.8-3.3,4.9-14.3,4.7-21.4c-0.2-6.1-4.5-38.7-7.4-52.8l0.2-0.2c0,0,4.3-4,6.4-6.3s7-8.3,9-10.4s18.8-18.1,21.4-19.8
                    s7.5-3.4,9.4-3.8s5-4.8,5-9.3s9.5-2.9,11.6-2.6s7.5-0.6,10.4-1.5s12.3-2.5,18.8-2.8s13.6,0.8,17.9,0.3s8.3-3.3,9.8-4.6
                    s3.6-2.9,3.6-2.9l0.6,7.8c0,0,10.1-10.8,11.4-11.5s8-0.8,9.9-1s5.4-5.8,7.3-6.1s4.9-3,6.6-4.3c1.8-1.2,5.3-0.8,7.8-0.9
                    c2.5-0.1,5.6-1.9,9-3.1s3-6.5,3-6.5s11.6,2.6,14.1,2.4s5.9-5.8,7.4-8.5s6.8-4.3,7.9-4.4s5.4-2.1,7-2.6s5.8-1.8,8.1-1.9
                    c1.4-0.1,7-1.9,11.7-3.4L473.4,483.4z"/>
                <path style={props.district==='kac'?styles.select:{}} id='kac' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M832,461.1c0.5,0.3,0.4,0.2,0.7,0.4c1.5,0.8,2.3,3.2,0.7,4.8c-1.7,1.7-2.8,4.5-1.2,7.3c1.7,2.8,3.3,4.8,4.3,8
                    c0.7,2.1,1.6,6.2,2.4,9.8l0.1,0.1c4.2-0.5,8.5-0.2,10.8,0.2s3-1.2,3.3-2.2s-0.5-4-2-5.2c-1.5-1.2,0-3.2,1.8-4.3
                    c1.8-1.2,5.5-7.8,6.8-10.3s3,4.8,3.2,8.7c0.2,3.8,5.7-0.5,7-1.3c1.3-0.8-5.5-4.4-5.8-7.4c-0.1-0.9,0.6-1.4,1.7-1.6l0.1-0.1l-2.9-1.2
                    l-2.4-3.9l-0.5-2.3l0.9-2.2h7.1l3.1-3.4l-2.6-5.1c0,0-1.9-0.3-2.1-0.5c-0.1-0.3-2.6-3-2.6-3l-3.2-3.1l-3.1-4.4V428l1.6-1.8l0.1-0.1
                    c0.1,0.1,0.1,0.1,0.3,0.1c4,1.5,4.3-1.5,4.8-4.7s-0.3-16.2-0.5-20.2s-0.3-5.8,0.3-8.5c0.7-2.7,0.8-5.2,0.8-5.2l-0.3,0.2
                    c-1.6,1.1-3.1,3.6-4.2,4.7c-1.5,1.6-4.6,2.4-7.6,1.5s-0.4-2.9,0.5-4.6s0.4-3.8,0-6.3s-4.8-5.1-6.6-6s-2-3.1-2-4.3s-1.4-6.1-2.1-9
                    s-0.8-5-2.6-6.5s-3.5-0.3-6.9-0.6s-4-2.8-4.5-4.8s-7.5-3.8-11-3.9s-5.9-1.9-8-4.8s-3.6-7.5-3.6-10.1s-1.3-6.3-3.1-10.9
                    s-6.9-8.3-8.8-8.9c-0.6-0.2-1.1-0.5-1.7-1l0,0c0,0-3.2,2.6-4.2,1.2s-3-5.3-4.3-4.2c-1.3,1.2-3.2,4.2-4,1.8c-0.8-2.3-2.8-6.3-4.7-5.8
                    s-3.2,1.5-4.3,0s-2.7-2.5-5.5-0.7c-2.8,1.8-5.3,5.5-5,7.2c0.3,1.7,2.2,6.2,2.7,10.8s1.8,11.3,3.8,12.7c2,1.3,9.5,5.5,11.3,6.2
                    s1.7,3.8-0.2,5.2s-5.7,2.2-6.5,4.8c-0.8,2.7-2.7,8.3-3.5,9.3s-7.3,4.5-6.8,6.3s4.2,2.7,8.2,3s8.2,0,9.8-0.7s9-3.2,8.8,0.8
                    s0.5,8.5,4.5,11.5s7.2,3.7,7.2,3.7s0.3,7.3,0.3,8.7s3,15.5,2.2,18.3s-3.3,9.8-4.8,11s-6.3,8-7,11.2s-5.3,20.5-7.7,22.2
                    c-1.5,1-3.1,1.2-5,1.4l0,0c0,0-4.7,5.3-4.9,8.5c-0.1,2.3,0.5,6.3,0.6,11l0.2,0c0,0,7.7,2.8,10.5,1.5c2.8-1.3,10-5,11.7-6
                    c1.7-1,5.5-1.8,6.8,0.2s3.2,5.2,4.7,4.7s2.2-3.7,2-7.2s-0.8-8.8,1.5-10.3s4-2.8,5.3-0.5s3.7,2.7,5.3,2c1.7-0.7,6.2-2,9.7-1.3
                    L832,461.1z"/>
                <g>
                    <path style={props.district==='sib'?styles.select:{}} id='sib' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M864.3,388.4c-1.4,1.2-2.7,3.3-3.6,4.3c-1.5,1.6-4.6,2.4-7.6,1.5s-0.4-2.9,0.5-4.6s0.4-3.8,0-6.3
                        s-4.8-5.1-6.6-6s-2-3.1-2-4.3s-1.4-6.1-2.1-9s-0.8-5-2.6-6.5s-3.5-0.3-6.9-0.6s-4-2.8-4.5-4.8s-7.5-3.8-11-3.9s-5.9-1.9-8-4.8
                        s-3.6-7.5-3.6-10.1s-1.3-6.3-3.1-10.9s-6.9-8.3-8.8-8.9s-3.5-2.8-3.5-4.3s-1-3.9-1.1-6.1s1.9-3,3.8-3.3s1.1-2.4,1-3.4
                        c-0.1-0.9,1.6-1.9,2.9-2.3l0.4,0.1c0,0,5.1,3.4,7.6,3.4s4.6-2.6,5.4-3.3s3-5.6,4.6-6s3.3-0.5,4.6-1.3c0.5-0.3,1.1-0.6,1.7-1
                        l0.2-0.1l-0.1,0l1.7-0.6l5.5,0l5.4,2.3l5.1,3.2l2.2,2.9l-2.5,2l-1.1,0.9l2.2,1.2l9.7,0l4.5-2.2l3.5-0.2l4.6,2.3l4.2,3.8l0,4.5
                        l0,5.4l-4.4,7.6l-5.4,7l-3.8,5.1l0,3.9l2.3,0l7.8-7.6l-0.3-4.5l3.9-0.5l6.4-6.5l0-3.9l7.9-0.7l12.8-4.1l17.7-0.7l1.4,0l0,0.1v3.8
                        l26.8,13.2c0,0,0.2,6.1,0,6.3s-1.5,2.5-1.5,2.5l2.7,4.2l-1.7,4.2l-5.9,5.2l-6.5,1.7l-5,1.7l-4,4.8l-2.9,1.5l-22.2-0.9l0,1.3
                        c0.4,0.8,0.3,1.8-0.7,3.2c-2.1,2.8-2.5,1.6-3.2,3.7c-1.4,4.5-4.1,10.8-5.3,12.1c-1.9,2-4.9,5.3-4.8,6.4s2.4,0.9,3.5,0.9
                        s3,0.6,3,0.6s1.3,1.9,3.2,4.2l0,0c1.2,1.3,2.5,2.6,4,3.7c4.3,3.1,14.3,9.3,19,12.3c3.8,2.4,8.6,4.8,14.1,4.6l-0.3,0.2
                        c0,0-0.8,1-3.2,1.5c-2.3,0.5-7.5,1.2-10.5,0.3c-3-0.8-7.8-3.7-9.7-2.3c-1.6,1.4-2.5,5.3-0.8,8.3c1.2,2.1,7.3,9.4,11.3,16l0,0.2
                        c-0.2,0.7-0.8,2-3.3,3c-3.5,1.5-9,5-10.3,5.4c-1.3,0.3-6.3,4.9-3.5,5.4s2.6,2.5,2.1,5.8c0,3.4-1.7,7.5-4.7,7.8
                        c-2.8,0.7-6.8,3.5-10.7,5s-4.8,2.8-7.3,3c-1.8,0.1-6.8-0.5-9.5,0.1l-0.1-0.1l-2.9-1.2l-2.4-3.9l-0.5-2.3l0.9-2.2h7.1l3.1-3.4
                        l-2.6-5.1c0,0-1.9-0.3-2.1-0.5c-0.1-0.3-2.6-3-2.6-3l-3.2-3.1l-3.1-4.4v-10.8l1.6-1.8l0.1-0.3c0.1,0,0.1,0.1,0.2,0.1
                        c4,1.5,4.3-1.5,4.8-4.7s-0.3-16.2-0.5-20.2s-0.3-5.8,0.3-8.5C864.1,390.4,864.2,389.1,864.3,388.4L864.3,388.4z"/>
                </g>
            </svg>
        </>
    )
}

export default BlnMap;