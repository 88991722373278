import './ProvinceMaps.css';
function AjkMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="ajk-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 183.83 349.28" style={{enableBackground:'new 0 0 183.83 349.28'}} xmlSpace="preserve">
                <g>
                    <path style={props.district==='nee'?styles.select:{}} id='nee' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M45.86,110.36c0,0-2.17-4-2.83-6.5c-0.67-2.5,2.33-4.17,3.17-7.5c0.83-3.33-3.83-4.33-6.33-4.33
                        s-4.83-1.67-5.33-1.83c-0.5-0.17-1.49-4.44-1.49-4.44l0.07-0.02c0.15,0,0.3-0.01,0.44-0.03c3.05-0.38,2.28-8.77,2.28-11.82
                        c0-3.05-0.38-4.95,0.77-6.48c1.14-1.52,8-12.2,10.67-12.96c2.67-0.76,10.3-4.2,17.54-5.34c7.24-1.14,9.53-7.24,13.34-8.77
                        c3.82-1.52,4.96-11.82,5.72-17.15c0.77-5.34,1.91-8.39,0.38-11.44c-1.52-3.05,0.11-4.63,0.11-4.63s3.06-0.61,5.97-0.75
                        c2.02-0.1,3.96,0.03,4.78,0.75c2,1.75,4.25,4.25,7.5,5.25c2.01,0.62,4.19,0.63,6.32,0.3c1.93-0.3,9.18,0.95,11.18-1.55
                        s7-11,10-8.5c3,2.5,5.25,3,6.25,6.5s1,6,3.25,7.5s5.75,5.75,5.5,8s-3.75,9.75-0.5,10.25s10,1,12,4.25s7,9.75,8.25,11
                        s8,6.25,11.25,6.75s4.25,2.25,3.25,5.5s-2.25,6-2.25,6l-0.07,0.2c-2.58-0.28-5.04-0.45-6.93-0.45c-0.25,0-0.5,0.01-0.75,0
                        c-7.74-0.51-19.25-9.25-25.62-12.69c-1.98-1.07-3.79-1.02-8.13-0.56c-4.75,0.5-10.5,0-13.5,0.75s-10.5-1.25-13.5-0.25
                        s-4.75,4.75-6,6s-6.75,3-9.25,5s-4.75,4.5-6.25,4s-4.25-3-7.75-4.5s-6.25-3.75-7-3.25s-7.25,10-6.75,11s1,8.5-0.25,10.25
                        s-7.75,8.5-7.75,10.5s-0.5,8-3.25,9.75c-2.19,1.39-6.44,4.21-8.33,6.44L45.86,110.36z"/>
                    <path style={props.district==='muz'?styles.select:{}} id='muz' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M15.14,154.39c0,0,4-6.83,4.5-3.33s1,4,2.17,4s2.67,1.67,4.67,4c2,2.33,5,2.5,7.33,2.17
                        c2.33-0.33,5.14-4.29,5.14-4.29l-0.14-0.09c-2.25-1.49-3.96-6.31-5-7.95c-1.17-1.83-3.17-6.67-3.17-8.83c0-2.17,3.83-0.67,7-1
                        c3.17-0.33,5.33-3.5,6.83-4.67c1.5-1.17,2.17-3.5,2-6.5c-0.17-3-1.67-3.83-1.33-5.33c0.33-1.5,1.67-5.17,1.67-5.17l0.01-0.05
                        c-1.52-1.28-2.17-3.36-1.76-5.2c0.1-0.46,0.43-0.99,0.92-1.56l-0.17-0.19c0,0-2.17-4-2.83-6.5c-0.67-2.5,2.33-4.17,3.17-7.5
                        c0.83-3.33-3.83-4.33-6.33-4.33s-4.83-1.67-5.33-1.83c-0.5-0.17-1.49-4.46-1.49-4.46l0,0c-2.98,0.07-6.59-2.68-11.31-2.32
                        c-4.96,0.39-12.96,3.44-12.96,4.96c0,1.53-2.29,16.39-2.67,19.06c-0.38,2.67-3.43,10.3-1.15,11.44
                        c2.29,1.14,7.63,20.97,9.15,27.83c0.48,2.14,0.77,4.58,1,7.07"/>
                    <path style={props.district==='hat'?styles.select:{}} id='hat' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M69.78,164.74c0,0-2.83,1.5-4.33,2s-5.67-0.5-7.67-1s-1.5-4.17-1.67-6.67c-0.17-2.5-5.17-1.83-6.83-1.5
                        c-1.67,0.33-7,0.33-9.67-0.33c-0.29-0.07-0.56-0.2-0.84-0.38c-2.25-1.49-3.96-6.31-5-7.95c-1.17-1.83-3.17-6.67-3.17-8.83
                        c0-2.17,3.83-0.67,7-1c3.17-0.33,5.33-3.5,6.83-4.67c1.5-1.17,2.17-3.5,2-6.5c-0.17-3-1.67-3.83-1.33-5.33
                        c0.33-1.5,1.67-5.17,1.67-5.17l0.13,0.05c0.34,0.28,0.71,0.52,1.12,0.7c2.25,1,5,1.75,7,1.25s7.25-2,7.25-2s8.25,12.75,9.5,15
                        s0.5,9.25-0.75,10s-6.5,8.5-7.75,9.75s-2.5,9-1.5,10.25c0.66,0.83,4.38,1.54,7.98,1.86L69.78,164.74z"/>
                    <path style={props.district==='hav'?styles.select:{}} id='hav' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M70.06,164.51c0,0-0.17,5.5,0.67,5.67c0.83,0.17,5.5,0.5,4.17,2.83c-1.33,2.33-2.83,4.33-2.17,6
                        c0.67,1.67,2.5,4.17,3,7c0.5,2.83,4.06,7.25,5.9,8.75l0,0c0.33-0.37,0.62-0.62,0.94-0.83c2.25-1.5,8.25-1.75,12.5-3.25
                        s7.75-9,8.75-12.75s1.5-8.5-0.5-12s-7.75-3-11.5-3.75s-12.75,1.75-16.75,2c-1.36,0.08-3.15,0.03-5-0.14L70.06,164.51z"/>
                    <path style={props.district==='bag'?styles.select:{}} id='bag' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M73.22,180.11c-0.2-0.39-0.38-0.74-0.51-1.07c-0.67-1.67,0.83-3.67,2.17-6c1.33-2.33-3.33-2.67-4.17-2.83
                        c-0.83-0.17-0.67-5.67-0.67-5.67s-2.83,1.5-4.33,2s-5.67-0.5-7.67-1s-1.5-4.17-1.67-6.67c-0.17-2.5-5.17-1.83-6.83-1.5
                        c-1.67,0.33-7,0.33-9.67-0.33c-0.29-0.07-0.42-0.11-0.69-0.29l0,0c0,0-2.81,3.96-5.14,4.29c-2.33,0.33-5.33,0.17-7.33-2.17
                        c-2-2.33-3.5-4-4.67-4s-1.67-0.5-2.17-4s-4.5,3.33-4.5,3.33l-0.03,0.1c0.4,4.3,0.57,8.07,1.28,11.44
                        c0.19,0.94,0.43,1.79,0.71,2.53c1.49,3.86,6.2,11.64,7.68,17.42l0.03,0.11c0.38-0.62,1.25-1.22,1.67-3.43
                        c0.5-2.67,2.83-9,4-9.17c1.17-0.17,4.33,0.5,6,2.33c1.67,1.83,5.5,5.67,8.5,8c3,2.33,5.33,4.67,7.17,2.67c1.83-2,1.5-3.5,4-3.17
                        c2.5,0.33,4-1.33,6.5,0c2.5,1.33,2.67,3,4.5,1.33c1.75-1.59,3.04-4.23,5.89-4.05L73.22,180.11z"/>
                    <path style={props.district==='poo'?styles.select:{}} id='poo' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M73.78,180.4c-3.17-0.5-4.5,2.33-6.33,4c-1.83,1.67-2,0-4.5-1.33c-2.5-1.33-4,0.33-6.5,0
                        c-2.5-0.33-2.17,1.17-4,3.17c-1.83,2-4.17-0.33-7.17-2.67c-3-2.33-6.83-6.17-8.5-8c-1.67-1.83-4.83-2.5-6-2.33
                        c-1.17,0.17-3.5,6.5-4,9.17c-0.41,2.21-1.29,2.82-1.67,3.43l-0.03-0.11c0.42,1.65,0.58,3.14,0.33,4.32
                        c-0.41,1.94-0.93,3.57-1.33,5.03l0,0.06c0.36,0.15,1.46,0.46,3.69-0.07c2.83-0.67,6.17-3.17,7.83-1.33c1.67,1.83,2.5,3,6.17,3.5
                        c3.67,0.5,4.33,0.5,5.33,1.67c1,1.17,4.67,6.5,5.83,6.5c1.17,0,3.33-0.33,4.5-0.33c1.17,0,3.33,0.17,3.33,1.5
                        c0,1.33-1,4.33,0.5,5c1.5,0.67,3.67,3.17,4,5.5c0.33,2.33,0.83,3.5,0.33,4.67c-0.5,1.17,0,2.83,0,2.83l0.06,0.4
                        c2.83-0.73,6.47-1.33,8.44-0.4l-0.16-0.34c-1.64-2.54-2.72-6.82-2.34-9.49c0.5-3.5-2.5-4.25-3-6.25s5-5,7-6
                        c1.72-0.86,3.99-5.4,6.02-7.62l0.04-0.05c-1.83-1.5-5.39-5.91-5.89-8.75c-0.4-2.26-1.64-4.3-2.47-5.89"/>
                    <path style={props.district==='kot'?styles.select:{}} id='kot' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M28.01,247.01c0.67-0.17,5.67-1.5,7.33-1.5c1.67,0,11.33,4.83,11.33,7.33s0,8.67,1.83,10.67
                        s5.33,3.67,7.33,6.83c1.82,2.89,7.24,6.32,8.44,8.43l0.06,0.07c2-2.17,4.83-6,10.17-0.67l0.37,0.23
                        c0.04-0.08,0.08-0.15,0.13-0.23c2-3.5,7-5,10.75-6.25s7-7.5,10-12.25s-0.5-10.5-0.5-14s-3.75-6.5-3.5-10.75s-13.75-7.5-16.25-9
                        c-0.59-0.35-1.15-0.97-1.66-1.76l0.16,0.34c-1.96-0.92-5.61-0.33-8.44,0.4c-1.25,0.32-2.35,0.67-3.06,0.93
                        c-2.33,0.83-4.17,0.5-6,0.67c-1.83,0.17-4.67-1.67-7-2.67s-3.17,2-3.83,3.17c-0.67,1.17-3.17,0.33-4-0.83
                        c-0.83-1.17-4.5-4.5-7.83-6.33c-3.33-1.83-7-4-7-4l-0.4-0.28c-1.28,1.19-3.31,2.01,1.16,10.25c4.95,9.15,2.67,7.25,2.67,12.97
                        c0,2.94-0.81,5.58-1.7,7.92"/>
                    <path style={props.district==='sud'?styles.select:{}} id='sud' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M26.46,215.6c0.14-0.13,0.27-0.27,0.39-0.42c1.15-1.53-0.76-9.91-2.66-13.34c-1.22-2.19-0.9-4.17-0.2-6.73
                        l0,0c0,0,0.87,0.6,3.71-0.06c2.83-0.67,6.17-3.17,7.83-1.33s2.5,3,6.17,3.5c3.67,0.5,4.33,0.5,5.33,1.67
                        c1,1.17,4.67,6.5,5.83,6.5c1.17,0,3.33-0.33,4.5-0.33c1.17,0,3.33,0.17,3.33,1.5c0,1.33-1,4.33,0.5,5c1.5,0.67,3.67,3.17,4,5.5
                        c0.33,2.33,0.83,3.5,0.33,4.67s0,2.83,0,2.83l0.06,0.4c-1.25,0.32-2.35,0.67-3.06,0.93c-2.33,0.83-4.17,0.5-6,0.67
                        c-1.83,0.17-4.67-1.67-7-2.67s-3.17,2-3.83,3.17c-0.67,1.17-3.17,0.33-4-0.83c-0.83-1.17-4.5-4.5-7.83-6.33c-3.33-1.83-7-4-7-4
                        L26.46,215.6z"/>
                    <path style={props.district==='mir'?styles.select:{}} id='mir' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M28.45,246.84c0.67-0.17,5.17-1.37,6.84-1.37c1.67,0,11.33,4.83,11.33,7.33s0,8.67,1.83,10.67
                        s5.33,3.67,7.33,6.83c1.82,2.89,7.24,6.32,8.44,8.43c0.12,0.2,0.19,0.4,0.22,0.57c0.33,2,0.83,6.17-0.17,7.17s-3,3-3,3.67
                        s-0.5,3.67-1.33,4s-2.33,1.67-1.5,2.67s3.67,2.5,2.67,4s-2.24,5.86-2.24,5.86l0,0c-1.43,0.18-2.56,0.42-3.5,0.7
                        c-3.81,1.15-7.63-0.38-10.3-3.05c-2.66-2.67-8.38-4.95-13.34-7.24c-4.96-2.29,3.05-8.01,3.81-9.91
                        c0.77-1.91-3.81-10.3-5.33-12.2c-1.53-1.91-2.67-9.53-3.44-12.59c-0.76-3.05-1.14-6.48-0.38-9.91
                        C26.77,250.8,27.61,249.05,28.45,246.84z"/>
                    <path style={props.district==='bhi'?styles.select:{}} id='bhi' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M74.51,278.12c-5.33-5.33-8.17-1.5-10.17,0.67l-0.06-0.07c0.12,0.2,0.19,0.4,0.22,0.57
                        c0.33,2,0.83,6.17-0.17,7.17c-1,1-3,3-3,3.67s-0.5,3.67-1.33,4s-2.33,1.67-1.5,2.67s3.67,2.5,2.67,4s-2.23,5.81-2.23,5.81
                        l0.15,0.02c4.33-0.56,9.87-0.42,10.45,1.87c0.76,3.05,3.81,7.62,5.71,8.77c1.91,1.14,38.51,17.15,38.89,18.68
                        c0.38,1.52,4.42,9.43,9.84,9.86c1.38,0.11,2.87-0.1,4.4-0.28l-0.12-0.91c0,0-2.75-7.25-4.5-8.5s-1-4.75-1-7.5
                        s-6.5-3.25-9.75-3.5s-1.5-3.5,0.5-5.75s-0.75-7.25-1.5-8.75s-12-6.25-14.5-6.75s-7-9.5-7-11.25s-8-5.75-11.25-6.75
                        c-3.18-0.98-6.12-4.11-4.38-7.52L74.51,278.12z"/>
                </g>
            </svg>
        </>
    )
}

export default AjkMap;