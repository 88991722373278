import { provinceData } from "../dataFiles/data";

function ProvinceInfo(props){
    return ( 
        <div>
            <div className="info-container"> 
                <p className="map-prompt">click on your desired province</p>
                {props.name!=='' && <><h2 className='info-name'>{provinceData[props.name].name}</h2>
                <p className='info-desc'><span className="info-name">area: </span>{provinceData[props.name].area} Km<sup>2</sup></p></>}
            </div>
        </div>
    )
}

export default ProvinceInfo;