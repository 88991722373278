import './ProvinceMaps.css';
function KpMap(props){
    const styles={
		select: {
			fill: 'rgb(147, 179, 139)'
		}
	}
    return (
        <>
            <svg className='map-image' id="kp-image" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 708.66 935.43" style={{enableBackground:'new 0 0 708.66 935.43'}} xmlSpace="preserve">
                <g>
                    <g>
                        <path style={props.district==='upc'?styles.select:{}} id='upc' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M474.07,171.08c0,0-1.76,2.57-2.54,3.12c-0.78,0.55-7.42,7.15-8.39,7.33c-0.98,0.18-2.34-0.18-3.51,2.02
                            c-1.17,2.2-2.93,5.13-4.69,6.78c-1.76,1.65-5.27,3.12-7.22,2.93c-1.95-0.18-4.49,0.37-8-0.73c-3.51-1.1-6.05-2.38-8-1.1
                            c-1.95,1.28-5.86,3.66-6.83,1.1c-0.7-1.84-2.44-4.03-4.16-3.33l0,0c1.46-7.7,15.77-32.77,17.24-36.62
                            c1.46-3.85,0.88-10.99-0.59-14.02c-1.46-3.02-7.91,0.82-11.13,1.37s-10.25,0.82-16.69,0.28c-6.44-0.55-21.38-8.25-30.45-15.12
                            c-9.08-6.87-14.93-39.31-14.93-39.31l-0.11-0.45c4.81,2.11,7.52-6.57,8.14-13.55c0.64-7.25,25.39-9.96,28.93-10.86
                            c3.54-0.91,14.78-14.18,20.57-15.69c5.79-1.52,4.83-5.13,6.43-10.57c1.6-5.43,13.82-6.94,17.36-6.94c3.54,0,17.36-3.93,22.19-4.22
                            c4.81-0.31,8.04-5.13,15.43-7.85c7.39-2.72,35.03,0,41.15,0.9c6.1,0.91,9.32-4.22,12.22-7.23c2.89-3.02,11.89-3.02,18.96-2.11
                            c7.07,0.9,14.15,0.3,20.25,1.51c6.11,1.21,16.4-0.31,20.25-0.91c3.85-0.59,16.71-0.3,22.5,1.21c4.84,1.26,9.89-1.26,14.23-3.01
                            l0.53,0.43c0,0,8.22,8.63,12.57,9.53c4.36,0.91,10.16,5.44,12.09,6.81c0.85,0.61,3.06,2.17,2.79,4.1
                            c-0.26,1.81,7.79,9.36-1.15,8.94c-8.93-0.42-11.16-1.68-17.42-0.84c-6.25,0.84-12.5,3.77-17.42,0.84
                            c-4.91-2.94-20.99-5.87-27.68-5.45c-6.7,0.42-12.06,3.35-18.76,3.77c-6.7,0.42-18.31-0.42-20.99,1.68
                            c-2.67,2.09-8.93,7.12-7.59,10.06c1.35,2.94-0.89,15.08-2.68,16.35c-1.78,1.25-6.24,7.54-9.82,9.21
                            c-3.57,1.68-13.85,2.94-14.29,7.55c-0.44,4.61-3.13,10.9-5.36,13c-2.22,2.09-11.61,7.54-14.29,10.9
                            c-2.68,3.35-5.81,6.29-9.83,6.29c-4.02,0-13.39-2.1-14.74,4.61c-1.33,6.71-0.89,11.74-2.22,15.1c-1.35,3.35-3.57,7.96-1.35,12.15
                            c2.24,4.2,5.81,8.39,3.57,13.41c-2.22,5.04-5.8,13-4.02,16.35l0.48,0.71"/>
                    </g>
                    <g>
                        <path style={props.district==='lwc'?styles.select:{}} id='lwc' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M350.49,282.27c-2.69-3.8-8.6-7.86-11.24-9.96c-4.18-3.32,0.33-11.76,5.79-18.71
                            c5.47-6.94,0.97-12.06,0.97-21.73c0-9.65-8.36-11.47-14.47-14.18c-6.11-2.72,0.63-11.16,3.53-15.09
                            c2.89-3.93-19.92-30.48-23.46-35.3c-3.54-4.83-5.79-8.44-12.22-9.96c-6.43-1.51-11.57-15.99-7.72-19.91
                            c3.87-3.93,6.76-8.44,11.26-10.86c4.5-2.41,22.83-22.33,26.36-26.26c3.54-3.91,11.26-15.69,16.08-20.81
                            c4.81-5.14,12.86,2.11,18,5.12c0.3,0.18,0.59,0.31,0.88,0.41l-0.23,0.32c0,0,5.86,32.43,14.93,39.31
                            c9.08,6.87,24.01,14.57,30.45,15.12c6.44,0.55,13.47,0.27,16.69-0.28s9.66-4.4,11.13-1.37c1.46,3.02,2.05,10.17,0.59,14.02
                            c-1.46,3.85-15.52,29.14-16.98,36.83l0,0c-0.8,0.13-1.35,0.58-2.15,2.2c-2.34,4.76-4.1,13.38-4.49,15.94
                            c-0.39,2.57-1.37,5.13-4.1,5.86c-2.73,0.73-7.81,3.85-11.13,6.05c-3.32,2.2-10.15,8.98-10.35,12.28
                            c-0.2,3.3,3.12,10.08,1.37,10.45c-1.76,0.37-6.44,3.12-6.44,5.31c0,2.2,0,4.95-0.39,7.7c-0.39,2.75-0.98,6.41-3.51,6.41
                            c-2.54,0-8-0.55-9.96,3.3c-1.95,3.85-2.93,4.76-3.51,7.33c-0.59,2.56-1.17,6.41-3.12,8.06c-1.95,1.65-8.16,2.82-12.65,2.27
                            l1.33,0.11"/>
                    </g>
                    <g>
                        <path style={props.district==='upd'?styles.select:{}} id='upd' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M441.57,193.12c0,0-0.39,4.03-1.56,6.96c-1.17,2.93-1.56,7.88-4.1,10.45c-2.54,2.56-5.08,3.11-5.27,4.95
                            c-0.2,1.83,0.78,1.47,3.12,4.58c2.34,3.12,4.29,8.25,7.22,9.71c2.93,1.47,8.78,4.95,8.98,6.41c0.2,1.47,2.54,2.57,2.93,6.23
                            c0.39,3.66-0.59,4.21-1.37,5.5c-0.78,1.28-2.15,2.38-1.56,4.76c0.59,2.38,2.34,8.8,0,9.9c-2.34,1.1-4.69,1.65-4.88,3.48
                            c-0.19,1.83-0.78,4.03-2.34,6.05c-1.56,2.02-1.56,3.66-0.78,6.6c0.78,2.93,6.64,10.08,3.9,10.26c-2.73,0.18-5.08,1.28-6.25,2.75
                            c-1.17,1.47-3.91,6.6-5.27,7.33c-1.37,0.73-4.3,1.65-4.3,3.66s2.15,10.45,0.39,12.83c-1.76,2.38-4.29,2.38-4.29,6.23
                            c0,3.85,0.19,4.58-0.98,7.15c-1.17,2.57-2.54,7.33-3.71,8.61c-1.06,1.16-2.45,2.63-1.68,4.8l-0.08-0.22c0,0-4.49,3.3-5.86,5.31
                            c-1.37,2.02-5.47,0.92-8.59-0.73c-3.12-1.65-9.57-6.41-10.74-9.16c-1.17-2.75-4.49-4.76-5.86-6.05c-1.37-1.28,0.19-4.21,0.59-8.06
                            c0.39-3.85-7.81-12.46-9.18-13.56c-1.37-1.1-6.83-0.18-9.96,0.92c-3.12,1.1-4.49,2.38-6.44,4.4c-1.95,2.02-8.79,0.55-11.13,0.18
                            c-2.34-0.37-6.44-0.73-9.18-2.02c-2.73-1.28-11.32-1.83-11.32-1.83l0.23,0.02c0.31-3.29,0.09-5.52,0.09-7.52
                            c0-5.12,11.89-12.68,18-15.69c2.25-1.11,1.88-3.08,0.31-5.28c-0.1-0.14-0.21-0.29-0.32-0.43h0.32c4.49,0.55,10.26-0.78,12.22-2.43
                            c1.95-1.65,2.54-5.5,3.12-8.06c0.59-2.57,1.56-3.48,3.51-7.33c1.95-3.85,7.42-3.3,9.96-3.3c2.54,0,3.12-3.66,3.51-6.41
                            c0.39-2.75,0.39-5.5,0.39-7.7c0-2.2,4.69-4.95,6.44-5.31c1.76-0.37-1.56-7.15-1.37-10.45c0.2-3.3,7.03-10.08,10.35-12.28
                            c3.32-2.2,8.39-5.31,11.13-6.05c2.73-0.73,3.71-3.3,4.1-5.86c0.39-2.57,2.15-11.18,4.49-15.94c2.34-4.76,5.08-1.65,6.05,0.92
                            c0.98,2.57,4.88,0.18,6.83-1.1c1.95-1.28,4.49,0,8,1.1c0.88,0.27,1.7,0.45,2.46,0.55"/>
                    </g>
                    <g>
                        <path style={props.district==='lwd'?styles.select:{}} id='lwd' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M367.47,376.58c0.83-2.97,0.14-8.46,0.14-9.72c0-1.65,2.15-2.93,4.3-4.4c2.15-1.47,0-3.3-2.15-4.21
                            c-2.15-0.92-6.64-3.85-8.98-4.58c-2.34-0.73-6.83-2.02-9.37-1.65c-2.54,0.37-5.27-0.92-5.86-3.67c-0.59-2.75-3.12-7.88-3.9-11.36
                            c-0.78-3.48-13.27-14.48-13.27-14.48l-0.15-0.2c2.72-2.37,3.67-6.75,3.95-10.85l0.05,0.08c0,0,8.26,0.53,10.99,1.81
                            c2.73,1.28,6.83,1.65,9.18,2.02c2.34,0.37,9.18,1.83,11.13-0.18c1.95-2.02,3.32-3.3,6.44-4.4c3.12-1.1,8.59-2.02,9.96-0.92
                            c1.37,1.1,9.57,9.71,9.18,13.56c-0.39,3.85-1.95,6.78-0.59,8.06c1.37,1.28,4.69,3.3,5.86,6.05c1.17,2.75,7.61,7.51,10.74,9.16
                            c3.12,1.65,7.22,2.75,8.59,0.73c1.37-2.02,5.86-5.31,5.86-5.31l-0.01-0.08c0.09,0.32,0.22,0.65,0.4,1
                            c1.37,2.56,6.25,7.15,7.22,8.61c0.98,1.47,2.15,6.05-0.98,6.78c-3.12,0.73-6.05,1.65-6.64,3.11c-0.59,1.47-1.76,4.21-2.54,6.78
                            c-0.78,2.57-1.95,6.78-3.71,7.7c-1.76,0.92-11.13,4.58-13.66,4.58c-2.54,0-0.78-0.37-9.57,0.18c-8.78,0.55-11.32,1.83-13.86,1.28
                            c-2.54-0.55-8.98-5.86-8.98-5.86"/>
                    </g>
                    <g>
                        <path style={props.district==='bjr'?styles.select:{}} id='bjr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M328.53,322.98c0,0,12.49,10.99,13.27,14.48c0.78,3.48,3.32,8.61,3.9,11.36c0.59,2.75,3.32,4.03,5.86,3.67
                            c2.54-0.37,7.03,0.92,9.37,1.65c2.34,0.73,6.83,3.67,8.98,4.58c2.15,0.92,4.29,2.75,2.15,4.21c-2.15,1.47-4.3,2.75-4.3,4.4
                            c0,1.65,1.17,10.45-1.37,11.54c-2.54,1.1-9.57,4.21-10.74,6.23c-1.17,2.02-2.93,8.43-4.69,10.45c-1.76,2.02-3.71,3.85-5.66,4.76
                            c-1.95,0.92-3.12,2.38-6.05-0.37c-2.93-2.75-3.51-2.75-3.51-5.13c0-2.38-2.54-9.53-1.17-10.99c1.37-1.47,3.12-3.85,3.32-5.31
                            c0.2-1.47-2.73-5.13-4.88-4.21c-2.15,0.92-4.88,3.3-8,2.02c-3.12-1.28-7.42-0.55-10.54,0.55c-3.12,1.1-3.12,1.1-7.22,1.1
                            c-4.1,0-8,0.55-8.39-1.1c-0.39-1.65-2.34-2.38-2.73-3.66c-0.39-1.28-4.49-6.78-5.66-6.23s-5.27,3.48-6.64,1.28
                            c-1.37-2.2-4.88-5.86-4.88-5.86l-0.05-0.07c5.61-3.65,14.34-7.74,17.78-8.35c5.14-0.9,1.28-10.57,3.53-15.39
                            c2.26-4.83,16.4-12.67,24.76-14.18c1.42-0.26,2.57-0.85,3.5-1.69"/>
                    </g>
                    <g>
                        <path style={props.district==='mkd'?styles.select:{}} id='mkd' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M349.49,396.29L349.49,396.29c0.21,0.29,1.83,2.51,2.21,4.92c0.39,2.56-0.78,6.41,1.56,7.32
                            c2.33,0.92,3.12-0.16,5.24,3.24c2.39-1.13,4.94-2.15,5.89-2.32c1.96-0.37,3.6,6.56,5.47,7.7c2.07,1.25,5.4,5.48,6.35,8.87
                            c1.59-3.83,8.22-5.33,13.37-10.35c5.27-5.12,5.07-3.66,5.47-7.51c0.39-3.85,2.93-6.6,5.07-8.61c2.14-2.02,5.27,0.55,7.03,3.3
                            s7.81-1.29,11.71-4.58c3.43-2.9,10.19,1.15,11.77,2.16l0.24-0.15c-0.66-1.67,1.26-1.29,2.45-2.56c1.37-1.47,0-3.48-0.39-4.76
                            c-0.39-1.28-0.98-2.2-0.98-2.2l0,0c-2.22-0.05-4.57-0.73-6.25-0.73c-2.73,0-5.08-2.02-6.25-5.13c-1.17-3.12-0.39-2.93,0-4.76
                            c0.39-1.83-0.2-2.57-2.73-2.93c-2.54-0.37-3.32-1.1-3.32-1.1c-1.76,0.92-11.13,4.58-13.66,4.58c-2.54,0-0.78-0.37-9.57,0.18
                            c-8.79,0.55-11.32,1.83-13.86,1.28c-2.54-0.55-8.71-5.64-8.71-5.64l-0.05,0.23c-0.25,0.86-0.63,1.5-1.21,1.74
                            c-2.54,1.1-9.57,4.21-10.74,6.23c-1.17,2.02-2.93,8.43-4.69,10.45c-0.56,0.64-1,1.13-1.6,1.71"/>
                    </g>
                    <g>
                        <path style={props.district==='bnr'?styles.select:{}} id='bnr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M503.54,434.52c-1.71,0.09-3.55,0.41-4.67,0.73c-2.54,0.73-13.08,10.26-15.03,11.73
                            c-1.95,1.47,0.2,5.5,0.2,6.41c0,0.92-4.88,4.22-6.64,4.58c-1.76,0.37-4.49-0.92-10.15-3.66c-5.66-2.75-5.47-2.93-5.66-5.31
                            s3.32-6.23,4.69-7.7s-0.39-1.1-0.98-5.5c-0.59-4.4,1.37-2.02,3.51-4.03c2.15-2.02-1.56-7.88-3.32-7.88s-3.71-2.2-4.49-4.4
                            c-0.78-2.2-6.05-4.03-7.61-4.95c-1.56-0.92-5.66-1.1-10.54-0.73c-4.88,0.37-9.37-4.22-9.18-6.23c0.19-2.02-0.59-3.48-2.34-6.23
                            c-1.76-2.75,0.59-2.02,1.95-3.48c1.37-1.47,0-3.48-0.39-4.76c-0.39-1.28-0.98-2.34-0.98-2.34l0.04,0
                            c1.47,0.05,2.81-0.11,3.67-0.78c2.15-1.65,2.54-4.95,6.25-5.31c3.71-0.37,5.47-1.1,7.81-2.75c2.34-1.65,4.88-8.98,9.18-8.8
                            c4.29,0.18,8.98,2.75,9.76,0c0.78-2.75,2.54-4.95,4.3-4.58s3.38,0.59,3.38,0.59l0,0c-0.11,0.45-0.2,0.98-0.26,1.61
                            c-0.39,4.4,3.12,4.76,3.91,6.41c0.78,1.65,4.1,2.93,5.86,2.75c1.76-0.18,5.27,0.92,7.03-1.1c1.76-2.02,0.98-3.66,4.88-3.85
                            c3.91-0.18,6.44,0,4.69,2.02c-1.76,2.02-3.51,4.21-1.95,5.68c1.56,1.47,4.29,3.12,4.1,6.05c-0.2,2.93-0.39,9.53,0.98,11.18
                            c1.37,1.65,6.64,5.86,3.51,7.33c-3.12,1.47-6.64,1.28-5.47,3.85c1.17,2.57,4.88,3.85,4.1,7.15c-0.78,3.3-0.39,6.23-1.76,8.98
                            c-1.37,2.75-2.54,7.7-2.54,7.7"/>
                    </g>
                    <g>
                        <path style={props.district==='swt'?styles.select:{}} id='swt' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M476.44,369.4c0,0-1.95-0.18-3.71-0.55c-1.76-0.37-3.51,1.83-4.3,4.58c-0.78,2.75-5.47,0.18-9.76,0
                            c-4.3-0.18-6.83,7.15-9.18,8.8c-2.34,1.65-4.1,2.38-7.81,2.75c-3.71,0.37-4.1,3.67-6.25,5.31c-2.15,1.65-7.22,0.18-9.96,0.18
                            c-2.73,0-5.08-2.02-6.25-5.13c-1.17-3.12-0.39-2.93,0-4.76c0.39-1.83-0.2-2.57-2.73-2.93c-2.54-0.37-3.32-1.1-3.32-1.1l-0.11,0.05
                            c0.04-0.02,0.07-0.04,0.11-0.05c1.76-0.92,2.93-5.13,3.71-7.7c0.78-2.57,1.95-5.31,2.54-6.78c0.59-1.47,3.51-2.38,6.64-3.11
                            c3.12-0.73,1.95-5.31,0.98-6.78c-0.98-1.47-5.86-6.05-7.22-8.61c-1.37-2.57,0.2-4.22,1.37-5.5c1.17-1.28,2.54-6.05,3.71-8.61
                            c1.17-2.57,0.98-3.3,0.98-7.15c0-3.85,2.54-3.85,4.29-6.23c1.76-2.38-0.39-10.81-0.39-12.83s2.93-2.93,4.3-3.66
                            c1.37-0.73,4.1-5.86,5.27-7.33c1.17-1.47,3.51-2.56,6.25-2.75c2.73-0.18-3.12-7.33-3.9-10.26c-0.78-2.93-0.78-4.58,0.78-6.6
                            c1.56-2.02,2.15-4.21,2.34-6.05c0.2-1.83,2.54-2.38,4.88-3.48c2.34-1.1,0.59-7.51,0-9.9c-0.59-2.38,0.78-3.48,1.56-4.76
                            c0.78-1.28,1.76-1.83,1.37-5.5c-0.39-3.67-2.73-4.76-2.93-6.23c-0.2-1.47-6.05-4.95-8.98-6.41c-2.93-1.47-4.88-6.6-7.22-9.71
                            c-2.34-3.12-3.32-2.75-3.12-4.58c0.2-1.83,2.73-2.38,5.27-4.95c2.54-2.57,2.93-7.51,4.1-10.45c1.17-2.93,1.56-6.96,1.56-6.96
                            l-0.01-0.23c2.44,0.39,4.33,0.08,5.86,0.23c1.95,0.18,5.47-1.28,7.22-2.93c1.76-1.65,3.51-4.58,4.69-6.78
                            c1.17-2.2,2.54-1.83,3.51-2.02c0.98-0.18,7.61-6.78,8.39-7.33c0.78-0.55,2.54-3.12,2.54-3.12l0.46-0.45
                            c0.02,0.05,0.05,0.1,0.07,0.15c1.79,3.35,13.85,11.74,21.44,11.74c6.58,0,10.82-3.16,15.04-5.35l0.08,0.14
                            c0,0,2.54,6.23,2.34,8.06c-0.2,1.84,0,11.18-0.78,14.29c-0.77,3.12-3.12,14.48-3.7,17.97c-0.59,3.47-1.37,11.17-1.57,13.37
                            c-0.19,2.2-0.39,7.7-0.19,9.9c0.19,2.2,0.77,4.95-0.78,6.23c-1.56,1.29-2.14,5.13-0.78,6.41c1.37,1.29,7.03,3.67,6.44,7.88
                            c-0.59,4.21-1.76,13.38-3.51,14.66c-1.76,1.29-5.07,4.58-6.04,6.23c-0.98,1.65-4.1,6.6-2.74,7.51c1.18,0.8,4.24,2.4,5.56,3.53
                            l0.1,0.14c0,0-4.88,1.28-4.88,2.38c0,1.1-0.59,3.48-1.37,5.5c-0.78,2.02-1.95,6.96-5.27,7.7c-3.32,0.73-5.27,2.38-6.64,4.03
                            c-1.37,1.65-5.08,6.05-4.88,6.96c0.2,0.92,4.3,6.41,3.51,7.7c-0.78,1.28-1.56,4.58-1.37,6.41c0.2,1.83,0.59,5.31,0,8.61
                            c-0.59,3.3-2.34,14.11-2.34,15.58c0,1.47-0.39,4.21-1.95,6.23c-1.34,1.73-2.98,1.7-3.65,4.46"/>
                    </g>
                    <g>
                        <path style={props.district==='sgl'?styles.select:{}} id='sgl' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M504.76,397.69c-0.4-2.69-0.27-6.55-0.13-8.61c0.19-2.93-2.54-4.58-4.1-6.05c-1.56-1.47,0.19-3.66,1.95-5.68
                            c1.76-2.02-0.78-2.2-4.69-2.02c-3.9,0.18-3.12,1.83-4.88,3.85c-1.76,2.02-5.27,0.92-7.03,1.1c-1.76,0.18-5.08-1.1-5.86-2.75
                            c-0.78-1.65-4.3-2.02-3.91-6.41c0.39-4.4,2.34-4.03,3.91-6.05c1.56-2.02,1.95-4.76,1.95-6.23c0-1.47,1.76-12.28,2.34-15.58
                            c0.59-3.3,0.2-6.78,0-8.61c-0.19-1.83,0.59-5.13,1.37-6.41c0.78-1.28-3.32-6.78-3.51-7.7c-0.2-0.92,3.51-5.31,4.88-6.96
                            c1.37-1.65,3.32-3.3,6.64-4.03c3.32-0.73,4.49-5.68,5.27-7.7c0.78-2.02,1.37-4.4,1.37-5.5c0-1.1,4.88-2.38,4.88-2.38l0,0
                            c0.18,0.16,0.29,0.23,0.39,0.37c0.78,1.1,1.37,2.39,3.71,4.21c2.34,1.84,3.32,2.75,3.12,4.95c-0.19,2.2-0.39,5.32,0.59,7.33
                            c0.98,2.01,0.98,1.84,1.57,5.86c0.59,4.03,0,6.78,2.73,7.52c2.73,0.73,5.86-2.02,8-0.55c2.16,1.46,3.71,1.83,3.71,1.83
                            s-3.13,4.03-3.9,6.05c-0.78,2.02-2.16,6.23,0.59,7.7c2.73,1.47,8.97,3.67,11.13,4.58c2.14,0.91,6.83,7.15,3.51,7.7
                            c-3.33,0.55-3.91,1.1-5.47,2.56c-1.56,1.47-3.13,5.32-5.66,5.32c-2.54,0-9.18-1.29-11.13,2.92c-1.96,4.22-3.33,7.52-2.93,9.53
                            c0.39,2.01,1.36,6.41,0.97,7.15c-0.39,0.74-3.13,2.75-2.93,5.31c0.2,2.57,2.73,9.17,2.14,10.45c-0.59,1.29-1.94,2.94-4.29,2.94
                            c-2.34,0-5.67,0-5.67,0l-0.52-0.15"/>
                    </g>
                    <g>
                        <path style={props.district==='upk'?styles.select:{}} id='upk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M679.01,290.41c0,0-0.2,4.95-1.56,6.96c-1.37,2.02-5.47,3.85-6.64,4.03c-1.17,0.18-5.86,2.38-6.44,4.21
                            c-0.59,1.83-1.56,8.61-2.34,10.26c-0.78,1.65-2.93,2.75-4.49,2.75c-1.56,0-6.64-1.28-8.98-1.65c-2.34-0.37-7.61-0.73-8.2,1.1
                            c-0.59,1.83-2.54,5.68-4.88,4.95s-4.3-2.75-7.42-1.1c-3.12,1.65-5.47,4.58-6.64,8.06c-1.17,3.48-1.95,3.48-4.49,4.76
                            c-1.6,0.81-2.34,2.12-3.11,3.85l0,0c0,0-2.35-1.47-7.82-1.47c-5.47,0-4.29-2.38-6.25-4.4c-1.95-2.02-15.62,0.92-19.91,1.47
                            c-4.3,0.55-6.64-0.92-9.18-2.56c-2.54-1.65-3.71,0.92-8,0.92c-4.29,0-6.83-2.93-7.61-3.85c-0.78-0.92-4.88-2.38-8.79-1.83
                            c-3.9,0.55-11.52,2.38-15.23,4.95c-3.71,2.57-7.42,3.48-7.42,3.48l0.39-0.11c0.17-1.33,0.63-2.66,0.98-3.56
                            c0.77-2.01,3.9-6.05,3.9-6.05s-1.56-0.36-3.71-1.83c-2.14-1.47-5.27,1.28-8,0.55c-2.73-0.74-2.14-3.49-2.73-7.52
                            c-0.59-4.02-0.59-3.85-1.57-5.86c-0.97-2.01-0.77-5.13-0.59-7.33c0.2-2.2-0.77-3.11-3.12-4.95c-2.34-1.83-2.93-3.11-3.71-4.21
                            c-0.78-1.1-4.69-3.11-6.06-4.04c-1.36-0.91,1.76-5.86,2.74-7.51c0.97-1.65,4.29-4.95,6.04-6.23c1.76-1.28,2.93-10.45,3.51-14.66
                            c0.59-4.21-5.07-6.6-6.44-7.88c-1.36-1.28-0.77-5.12,0.78-6.41c1.56-1.29,0.97-4.04,0.78-6.23c-0.2-2.2,0-7.7,0.19-9.9
                            c0.2-2.2,0.98-9.89,1.57-13.37c0.59-3.49,2.93-14.84,3.7-17.97c0.78-3.11,0.59-12.46,0.78-14.29c0.2-1.83-2.34-8.06-2.34-8.06
                            l-0.08-0.14c0.65-0.34,1.28-0.66,1.93-0.93c4.91-2.1,9.38,0,13.85,0.84c4.46,0.84,13.84,1.26,18.75,0.42
                            c4.91-0.84,11.61-1.25,16.97-1.25c5.35,0,8.48,2.94,8.48,6.71c0,3.77-5.35,5.45-9.83,11.31c-4.46,5.87,4.47,13,12.51,13
                            c8.04,0,15.63,2.1,18.31,5.04c2.68,2.94,1.79,14.25,7.59,15.92c5.81,1.68,10.27,1.26,14.74,0.85c4.47-0.42,15.64,0.84,22.34,0.84
                            c6.7,0,16.96,2.1,16.96,2.1s0,10.9-1.78,12.57c-1.79,1.68-8.94,8.81-9.38,11.74c-0.44,2.94-2.67,14.67,3.57,20.54
                            c6.25,5.87,7.59,4.19,12.06,4.19c4.46,0,11.61,0.42,14.74,3.35c1.75,1.65,4.21,3.56,6.65,4.91"/>
                    </g>
                    <g>
                        <path style={props.district==='btg'?styles.select:{}} id='btg' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M524.18,334.96c0,0,3.71-0.92,7.42-3.48c3.71-2.57,11.32-4.4,15.23-4.95c3.91-0.55,8,0.92,8.78,1.83
                            c0.78,0.92,3.32,3.85,7.61,3.85c4.3,0,5.47-2.57,8-0.92c2.54,1.65,4.88,3.12,9.18,2.56c4.3-0.55,17.96-3.48,19.91-1.47
                            c1.95,2.02,0.78,4.4,6.25,4.4c5.47,0,7.9,1.29,7.9,1.29l0,0c-0.48,1.06-0.96,2.29-1.65,3.66c-1.76,3.48-5.08,9.9-7.22,10.63
                            c-2.15,0.73-5.66,1.28-6.64,3.48c-0.98,2.2-2.34,3.85-4.29,2.75c-1.95-1.1-4.49-4.21-8.98-4.21c-4.49,0-9.76,0-10.15,2.56
                            c-0.39,2.57,0,4.76-1.17,7.51c-1.17,2.75-1.37,5.68-2.54,8.43c-1.17,2.75-1.37,5.13-4.1,5.31c-2.73,0.18-9.76,0.55-10.54,5.13
                            c-0.78,4.58-1.76,9.71-3.32,9.71c-1.56,0-4.49,0.18-6.25-1.28c-1.76-1.47-2.93-2.02-6.05-1.65c-3.08,0.36-6.34,6.23-7.75,7.64
                            l-0.06,0.06c0,0-0.98-5.13-0.98-6.96c0-1.83-3.12-4.22-3.71-5.5c-0.59-1.28,0-6.23,0.19-8.61c0.2-2.38-1.17-5.86-0.98-8.98
                            c0.2-3.12,1.95-8.8,1.95-8.8l0,0c2.36-0.28,3.58-3.72,5.08-5.14c1.56-1.46,2.14-2.01,5.47-2.56c3.32-0.55-1.37-6.78-3.51-7.7
                            c-2.15-0.91-8.4-3.11-11.13-4.58c-1.55-0.83-1.78-2.53-1.56-4.22"/>
                    </g>
                    <g>
                        <path style={props.district==='msr'?styles.select:{}} id='msr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M601.51,424.55c0,0-5.86-0.73-7.03,0.92c-1.17,1.65-4.69,4.21-6.05,5.86c-1.37,1.65-6.44,7.15-7.81,6.96
                            c-1.37-0.18-6.25-0.92-6.83,1.47c-0.59,2.38,0.39,6.41-2.93,5.5c-3.32-0.92-5.66-2.02-9.18-1.1s-8.2,1.65-8.39,1.1
                            c-0.2-0.55-1.76-4.03-3.71-4.03s-5.27,0.73-5.66,2.2c-0.23,0.86-1.32,3.34-2.19,5.51l0.05-0.2c0,0-1.17-0.18-5.86-3.66
                            c-4.69-3.48-6.64-6.23-9.37-8.43c-2.73-2.2-3.12-6.41-2.93-9.35c0.2-2.93-2.15-3.11-3.71-4.58c-1.56-1.47-1.56-5.31-1.17-9.35
                            c0.39-4.03,3.12-2.75,5.27-2.75c2.15,0,4.69-6.05,5.27-8.98c0.59-2.93,2.73-2.57,4.1-3.85c1.37-1.28,4.69-7.33,7.81-7.7
                            c3.12-0.37,4.3,0.18,6.05,1.65c1.76,1.47,4.69,1.28,6.25,1.28c1.56,0,2.54-5.13,3.32-9.71c0.78-4.58,7.81-4.95,10.54-5.13
                            c2.73-0.18,2.93-2.57,4.1-5.31c1.17-2.75,1.37-5.68,2.54-8.43c1.17-2.75,0.78-4.95,1.17-7.51c0.39-2.56,5.66-2.56,10.15-2.56
                            c4.49,0,7.03,3.12,8.98,4.21c1.95,1.1,3.32-0.55,4.29-2.75c0.98-2.2,4.49-2.75,6.64-3.48s5.47-7.15,7.22-10.63
                            c1.76-3.48,2.15-6.05,4.69-7.33c2.54-1.28,3.32-1.28,4.49-4.76c1.17-3.48,3.51-6.41,6.64-8.06c3.12-1.65,5.08,0.37,7.42,1.1
                            c2.34,0.73,4.3-3.11,4.88-4.95c0.59-1.83,5.86-1.47,8.2-1.1c2.34,0.37,7.42,1.65,8.98,1.65c1.56,0,3.71-1.1,4.49-2.75
                            c0.78-1.65,1.76-8.43,2.34-10.26c0.59-1.83,5.27-4.03,6.44-4.21s5.27-2.02,6.64-4.03c1.37-2.02,1.56-6.96,1.56-6.96l0.08-0.54
                            c1.92,1.07,3.84,1.8,5.42,1.8c3.57,0,13.4,0.85,14.74,3.35c1.35,2.52,3.56,8.76,3.56,8.76l-0.1,0c-3.38,0.16-6.9,0.82-6.9,0.82
                            s-1.91,1.74-0.13,5.09c1.79,3.35,0.46,6.71-0.44,12.58c-0.89,5.86-2.22,17.19-6.7,18.86c-4.46,1.68-7.14,8.39-15.63,9.64
                            c-8.48,1.25-17.42,5.04-20.55,5.87c-3.13,0.84-11.16,12.58-12.5,14.25c-1.35,1.68-0.9,3.77-0.9,7.12c0,3.35,0.9,12.58-2.67,13
                            c-3.57,0.42-8.05-2.94-13.85-2.52c-5.81,0.43-15.18,3.78-15.18,5.45c0,1.68-2.68,18.02-3.13,20.96c-0.31,2.08-2.2,6.89-2.32,9.97"
                            />
                    </g>
                    <g>
                        <path style={props.district==='tgr'?styles.select:{}} id='tgr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M530.11,358.6c0,0-1.76,5.68-1.95,8.8c-0.2,3.12,1.17,6.6,0.98,8.98c-0.19,2.38-0.78,7.33-0.19,8.61
                            c0.59,1.28,3.71,3.67,3.71,5.5c0,1.83,0.98,6.96,0.98,6.96l0.25-0.26c-0.09,0.1-0.17,0.19-0.25,0.26
                            c-1.37,1.28-3.51,0.92-4.1,3.85c-0.59,2.93-3.12,8.98-5.27,8.98c-2.15,0-4.88-1.28-5.27,2.75c-0.39,4.03-0.39,7.88,1.17,9.35
                            c1.56,1.47,3.9,1.65,3.71,4.58c-0.04,0.68-0.06,1.42-0.02,2.2h-0.37c0,0-6.25-3.3-8-2.57c-1.76,0.73-3.32-0.92-4.1-2.2
                            c-0.78-1.28-5.08,1.83-5.08,1.83l0.45-0.36c0.83-2.43,0.63-5.02,1.3-7.89c0.78-3.3-2.93-4.58-4.1-7.15
                            c-1.17-2.56,2.34-2.39,5.47-3.85c3.13-1.46-2.14-5.68-3.51-7.33c-0.43-0.52-0.7-1.53-0.88-2.76l0.68,0.2c0,0,3.33,0,5.67,0
                            s3.74-1.54,4.07-2.9c0.39-1.57-2.03-7.85-2.23-10.42c-0.2-2.56,2.84-4.63,3.22-5.36c0.39-0.74-0.59-5.13-0.97-7.15
                            c-0.4-2.01,0.97-5.31,2.93-9.53c1.94-4.21,8.59-2.92,11.13-2.92c0.13,0,0.25-0.01,0.38-0.03c0.07-0.01,0.15-0.03,0.22-0.04"/>
                    </g>
                    <g>
                        <path style={props.district==='hpr'?styles.select:{}} id='hpr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M523.22,429.1c0,0-6.25-3.3-8-2.57c-1.76,0.73-3.32-0.92-4.1-2.2c-0.63-1.03-3.53,0.78-4.64,1.53
                            c-0.27,0.18-0.43,0.3-0.43,0.3l0.22,0.26c-0.07,0.16-0.14,0.32-0.22,0.48c-1.14,2.31-2.14,6.16-2.45,7.33
                            c2.2-0.12,4.2,0.13,4.2,1.28c0,2.01,2.54,3.11,2.74,4.76c0.19,1.65-1.17,8.43-0.59,10.45c0.59,2.02-0.78,4.4-1.17,8.43
                            c-0.4,4.04-3.13,6.23-4.69,7.15c-1.57,0.91-3.71,5.13-5.27,6.6c-1.57,1.46-9.37,9.35-19.53,10.63l-0.25,0.08
                            c0.84,2.27,1.75,3.47,4.43,4.31c3.13,0.98,10.61,1.64,9.57,5.07c-1.04,3.43-5.92,7.19-3.13,8.65c2.78,1.47,6.61,0.66,9.22-1.31
                            c2.61-1.96,5.58-6.2,7.84-7.67c2.27-1.47,4.36-5.07,5.05-0.66c0.7,4.42,1.05,6.05,3.14,6.87c2.09,0.81,6.79,1.63,5.56,3.76
                            c-1.22,2.12-3.99,3.59-1.22,4.89c2.79,1.31,7.32,4.75,4.53,5.23c-2.79,0.5-7.49,2.12-5.22,3.6c2.26,1.47,7.13,2.45,9.23,3.6
                            c0.78,0.43,2.19,1.16,3.8,1.81c2.65,1.08,5.84,1.92,7.69,0.8c2.95-1.8,6.27-6.05,10.96-6.54c4.7-0.48,14.1-2.29,16.89-3.43
                            c0.55-0.23,1.33-0.56,2.27-0.96c1.25-0.54,2.76-1.18,4.31-1.82l-0.07,0.03c0,0-1.49-5.24-1.69-7.26c-0.19-2.02,0.2-0.73,1.37-4.4
                            c1.17-3.67-8.79-1.47-11.13-0.18c-2.34,1.28-4.1-4.03-3.9-5.13c0.19-1.1,0.78-2.02,3.12-3.48c2.34-1.47,2.54-2.56,2.93-7.15
                            c0.39-4.58-8.39-4.4-12.49-5.86c-4.1-1.47-5.47-4.4-5.66-6.41c-0.19-2.02-2.15-3.12-5.27-3.12c-3.12,0-0.98-3.3,0.98-5.31
                            c1.95-2.02-1.56-8.25-1.56-9.53c0-0.57,0.52-1.98,1.2-3.65l-0.03-0.01c0,0-1.17-0.18-5.86-3.66c-4.69-3.48-6.64-6.23-9.37-8.43
                            c-2.22-1.78-2.89-4.9-2.96-7.58"/>
                    </g>
                    <g>
                        <path style={props.district==='abt'?styles.select:{}} id='abt' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M602.19,424.21c0,0-6.35-0.78-7.52,0.87c-1.17,1.65-4.69,4.21-6.05,5.86c-1.37,1.65-6.44,7.15-7.81,6.96
                            c-1.37-0.18-6.25-0.92-6.83,1.47c-0.59,2.38,0.39,6.41-2.93,5.5c-3.32-0.92-5.66-2.02-9.18-1.1c-3.51,0.92-8.2,1.65-8.39,1.1
                            c-0.2-0.55-1.76-4.03-3.71-4.03c-1.95,0-5.27,0.73-5.66,2.2c-0.39,1.47-3.32,7.7-3.32,8.98c0,1.28,3.51,7.51,1.56,9.53
                            c-1.95,2.02-4.1,5.31-0.98,5.31c3.12,0,5.08,1.1,5.27,3.12c0.2,2.02,1.56,4.95,5.66,6.41c4.1,1.47,12.88,1.28,12.49,5.86
                            c-0.39,4.58-0.59,5.68-2.93,7.15c-2.34,1.47-2.93,2.38-3.12,3.48c-0.2,1.1,1.56,6.41,3.9,5.13c2.34-1.28,12.3-3.48,11.13,0.18
                            c-1.17,3.66-1.56,2.38-1.37,4.4c0.2,2.02,1.69,7.26,1.69,7.26l0,0c3.17-1.31,6.39-2.54,8.1-2.8c3.14-0.5,7.32-2.95,8.19-5.72
                            c0.87-2.78,1.22-5.73,2.96-7.03c1.73-1.31,3.65-2.45,4.35-4.9c0.7-2.45,1.57-5.07,3.65-4.09c2.1,0.98,4.18,2.95,5.75,0.81
                            c1.57-2.12,5.4-7.51,9.57-7.67l-0.14-0.43c-1.22-5.85-1.16-14.41-2.65-20.68c-1.78-7.54-8.04-29.35-10.72-30.6
                            C602.38,426.38,602.13,425.48,602.19,424.21"/>
                    </g>
                    <g>
                        <path style={props.district==='kbr'?styles.select:{}} id='kbr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M330.1,521.65c0,0,2.3-7.97,2.3-8.8c0-0.83-6.19-5.64-9.37-7.97c-3.18-2.33-0.88-6.14-0.53-9.46
                            c0.35-3.32-4.25-6.64-5.66-8.97c-1.41-2.33-1.06-4.98-2.65-6.81c-1.59-1.83-0.71-3.49-0.71-4.48c0-1-0.53-2.32-1.41-5.98
                            c-0.88-3.65,1.41-7.3,2.48-8.63c1.06-1.33,2.3-4.32,2.3-4.32l-0.06,0.26c-0.06,0.06,0-0.03,0-0.03s-4.72-1.56-7.37-1.23
                            c-2.65,0.33-6.54,2.32-6.9,1.16c-0.35-1.16-3.18-4.81-1.77-7.14c1.41-2.32,2.12-7.97,1.95-9.13c-0.18-1.16-6.01-8.3-12.2-9.46
                            c-6.19-1.16-13.8-2.49-13.8-2.49l0.63,0.09c-1,3.64-1.42,8.29-1.42,14.12c0,7.23-4.83,13.58-7.07,19.91
                            c-2.25,6.33-10.6,11.17-13.82,12.68c-3.21,1.51-10.28,8.75-19.28,9.36c-9.01,0.59-36.98,6.02-44.69,5.12
                            c-2.35-0.28-6.82-0.58-12.14-0.96c0.09,0.99,0.35,3.69,0.5,5.43c0.18,2.15,2.82,3.81,4.24,4.31c1.42,0.5,3.01,1,3.54,3.16
                            c0.53,2.15,4.6,7.64,7.25,6.97c2.66-0.66,4.06,1.33,4.06,3.32c0,2-0.16,5.64,4.09,5.97l0.03-0.26c0.13-1.02,0.2-2.15,0.83-3.04
                            c1.07-1.5,5.48,1.33,6.2,2.82c0,0,4.42,5.15,6.72,5.15c2.3,0,6.54-1.17,8.84-2.17c2.3-0.99,10.96-3.65,14.15-3.48
                            c3.19,0.16,6.36,0.51,8.49,0.34c2.12-0.18,7.6-0.83,7.6-2.17c0-1.33-2.66-6.97,2.65-7.14c5.31-0.17,12.74-1.33,15.39,0
                            c2.66,1.33,5.31,3.98,8.84,3.15c3.54-0.84,7.07-1.66,8.84-1.17c1.77,0.5,4.08,2.32,6.55,4.32c2.47,1.99,6.18,5.8,8.14,5.8
                            c1.94,0,3.89,0.17,7.07,0.84c3.17,0.66,7.95,0.99,10.44,0.99C325.86,521.64,330.1,521.64,330.1,521.65c0,0,0.02,0,0.06,0.02"/>
                    </g>
                    <g>
                        <path style={props.district==='sbi'?styles.select:{}} id='sbi' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M462.12,422.33c-0.59,0-2.54,2.2-5.08,3.66c-2.54,1.47-5.86,2.02-6.64,3.48c-0.78,1.47-3.71,6.6-3.32,7.51
                            c0.39,0.92,2.73,6.78-0.39,6.23c-3.12-0.55-11.71-0.55-13.66,2.02c-1.95,2.57-1.56,7.15-2.34,9.71c-0.78,2.57,0,12.83,0,12.83
                            l0.06,0.14c2.85-0.27,5.35-0.98,5.21,1.14c-0.2,2.75-0.98,10.99-1.76,12.28c-0.78,1.29-2.16,1.65-1.96,4.21
                            c0.2,2.53,2.45,7.5,2.15,12.02c0.06-0.04,0.12-0.08,0.19-0.1c2.34-0.74,10.93-5.87,13.67-6.97c0,0,4.56-1.24,6.29-2.55
                            c1.75-1.31,5.05-5.39,6.97-5.39c1.91,0,15.84-3.11,16.89,0c0.15,0.45,0.29,0.86,0.44,1.24l0.25-0.08
                            c10.16-1.29,17.96-9.17,19.53-10.63c1.56-1.46,3.7-5.68,5.27-6.6c1.56-0.91,4.29-3.11,4.69-7.15c0.39-4.03,1.76-6.41,1.17-8.43
                            c-0.59-2.01,0.77-8.8,0.59-10.45c-0.2-1.65-2.74-2.75-2.74-4.76c0-1.14-2-1.4-4.2-1.28c-1.68,0.09-3.48,0.41-4.58,0.73
                            c-2.53,0.74-13.07,10.26-15.03,11.73c-1.96,1.46,0.2,5.5,0.2,6.41c0,0.91-4.88,4.21-6.64,4.58c-1.76,0.36-4.49-0.92-10.16-3.67
                            c-5.66-2.75-5.46-2.92-5.66-5.31c-0.2-2.39,3.31-6.23,4.69-7.7c1.37-1.46-0.39-1.1-0.97-5.5c-0.59-4.4,1.36-2.01,3.51-4.04
                            c2.14-2.01-1.57-7.87-3.33-7.87c-1.04,0-2.15-0.77-3.04-1.86"/>
                    </g>
                    <g>
                        <path style={props.district==='mdn'?styles.select:{}} id='mdn' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M462.23,422.07c-0.59,0-2.54,2.2-5.08,3.66c-2.54,1.47-5.86,2.02-6.64,3.48c-0.78,1.47-3.71,6.6-3.32,7.51
                            c0.39,0.92,2.73,6.78-0.39,6.23c-3.12-0.55-11.71-0.55-13.66,2.02c-1.95,2.57-1.56,7.15-2.34,9.71c-0.78,2.57,0,12.83,0,12.83
                            l0.01,0.15c-0.84,0.08-1.71,0.12-2.55,0.03c-3.71-0.36-3.9,0.37-6.83,1.47c-2.93,1.1-6.44,0.18-8.98,0.36
                            c-2.54,0.19-8-5.86-8.79-7.88c-0.78-2.01-4.49-0.73-7.41-0.91c-1.46-0.09-2.4-0.19-3.64-0.19l-0.07-0.18c0,0-4.1-9.9-4.49-12.47
                            c-0.4-2.56-7.43-5.31-11.71-7.7c-4.3-2.38-1.17-8.25,0-11.54c0.28-0.79,0.21-1.78-0.09-2.85c1.59-3.83,8.22-5.33,13.37-10.35
                            c5.27-5.12,5.07-3.66,5.47-7.51c0.39-3.85,2.93-6.6,5.07-8.61c2.14-2.02,5.27,0.55,7.03,3.3c1.76,2.75,7.81-1.29,11.71-4.58
                            c3.9-3.3,12.1,2.39,12.1,2.39v0.02c0.09,0.21,0.22,0.25,0.4,0.53c1.76,2.75,2.53,4.21,2.34,6.22c-0.2,2.02,4.29,6.6,9.17,6.23
                            c4.88-0.36,8.98-0.19,10.54,0.74c1.56,0.91,6.83,2.75,7.61,4.95c0.32,0.91,0.84,1.81,1.46,2.56"/>
                    </g>
                    <g>
                        <path style={props.district==='pwr'?styles.select:{}} id='pwr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M317.38,456.35c0,0-1.68,2.75-2.74,4.08c-1.06,1.33-3.36,4.98-2.48,8.63c0.88,3.65,1.41,4.98,1.41,5.98
                            c0,1-0.88,2.66,0.71,4.48c1.59,1.83,1.24,4.48,2.65,6.81c1.41,2.32,6.01,5.64,5.66,8.97c-0.35,3.32-2.65,7.14,0.53,9.46
                            c3.18,2.32,9.37,7.14,9.37,7.97c0,0.83-2.3,8.8-2.3,8.8l0,0c0.04,0,0,0,0,0s5.04,1.72,6.37,2.82c0.8,0.67,1.97,3.4,3.91,4.85
                            c1.94,1.46,3.1,2.18,5.62,0.73c2.52-1.46,11.83-6.37,11.25-2.73c-0.58,3.64-1.16,5.82,0,6.73c1.16,0.91,4.46,1.64,5.04-0.36
                            c0.58-2,1.16-5.1,2.71-3.64c1.55,1.46,9.11,6.92,8.73,4.37c-0.39-2.55-0.29-3.47-0.29-3.47c0.71-2.66,2.83-3.65,5.13-5.31
                            c2.3-1.66,1.77-6.31,0.88-8.14c-0.88-1.83-4.78-1.83-7.07-1.83c-2.3,0-4.78-2.82-5.48-4.32c-0.71-1.49-1.41-4.81-1.41-4.81
                            l0.01-0.23c1.98-1.75,3.52-3.58,3.52-3.58l-0.53-6.64l-1.06-10.29c0,0-1.77,1.33-4.78,0.83c-3.01-0.5-4.07-2.16-3.36-4.15
                            c0.71-1.99,0.35-3.65,0-6.64c-0.35-2.99-1.95-6.31-1.95-8.63s-1.77-4.48-3.54-5.15c-1.77-0.66-8.49-2.66-9.38-3.65
                            c-0.88-1-2.83-3.32-5.31-3.49c-2.48-0.17-6.54,0.17-8.14-1.33c-1.59-1.49-5.48-4.32-5.48-4.32s-2.65,2.32-4.07,3.32
                            C320.27,453.35,317.86,455.85,317.38,456.35"/>
                    </g>
                    <g>
                        <path style={props.district==='mkd'?styles.select:{}} id='mkd' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M277.44,428.45c0.25-0.92,0.53-1.77,0.86-2.56c1.6-3.91-1.6-7.84-6.75-9.65
                            c-5.14-1.81-10.61-8.44-11.26-14.48c-0.64-6.04,4.18-14.79,8.04-18.41c3.87-3.63,4.83-13.28,5.47-16.6
                            c0.21-1.1,2.24-2.8,5.05-4.63l0.05,0.07c0,0,3.51,3.66,4.88,5.86c1.36,2.2,5.46-0.73,6.63-1.28c1.17-0.55,5.27,4.95,5.67,6.22
                            c0.39,1.29,2.34,2.02,2.73,3.67c0.39,1.65,4.3,1.1,8.4,1.1s4.1,0,7.22-1.1c3.13-1.1,7.43-1.84,10.54-0.55
                            c3.13,1.28,5.86-1.1,8.01-2.02c2.14-0.91,5.07,2.75,4.87,4.22c-0.19,1.46-1.94,3.85-3.31,5.31c-1.37,1.46,1.17,8.61,1.17,10.99
                            c0,2.39,0.59,2.39,3.51,5.14c2.93,2.75,4.1,1.28,6.06,0.36c1.49-0.7,2.99-1.95,4.39-3.39l0,0c0.21,0.29,1.67,2.27,2.05,4.67
                            c0.39,2.56-0.78,6.41,1.56,7.32c2.33,0.92,3.12-0.16,5.24,3.24c-0.08,0.03-0.15,0.08-0.23,0.11l0.23-0.11
                            c-0.01-0.02,0.01,0.01,0,0c0.01,0-0.01,0,0,0l0,0c-2.5,1.19-4.84,2.51-5.04,3.17c-0.39,1.28-3.12,6.96-4.49,7.88
                            c-1.37,0.92-3.9,0.55-5.66,1.65c-1.76,1.1-3.51,3.11-5.86,5.86c-2.34,2.75-2.34,3.3-2.54,4.76c-0.2,1.47-1.76,6.6-3.12,8.06
                            c-1.37,1.47-6.15,6-6.15,6l0.14,0.1c-0.09-0.07-0.14-0.1-0.14-0.1s-2.65,2.32-4.07,3.32c-1.27,0.89-3.67,3.39-4.16,3.89
                            c-0.06,0.06-0.09,0.09-0.09,0.09s-5.13-1.66-7.78-1.33c-2.65,0.33-6.54,2.32-6.9,1.16c-0.35-1.16-3.18-4.81-1.77-7.14
                            c1.41-2.32,2.12-7.97,1.95-9.13c-0.18-1.16-6.01-8.3-12.2-9.46c-6.19-1.16-13.8-2.49-13.8-2.49"/>
                    </g>
                    <g>
                        <path style={props.district==='chr'?styles.select:{}} id='chr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M392.38,460.46c0,0-4.1-9.9-4.49-12.46c-0.39-2.57-7.42-5.31-11.71-7.7c-4.3-2.38-1.17-8.25,0-11.54
                            c1.17-3.3-3.71-10.08-6.44-11.73c-1.87-1.13-3.51-8.06-5.47-7.7c-1.95,0.37-10.54,4.22-10.93,5.5c-0.39,1.28-3.12,6.96-4.49,7.88
                            c-1.37,0.92-3.9,0.55-5.66,1.65c-1.76,1.1-3.51,3.11-5.86,5.86c-2.34,2.75-2.34,3.3-2.54,4.76c-0.2,1.47-1.76,6.6-3.12,8.06
                            c-1.37,1.47-5.93,6.16-5.93,6.16l-0.28-0.1c0.2,0.05,0.2,0.05,0.2,0.05s3.75,2.72,5.34,4.21c1.59,1.49,5.66,1.16,8.14,1.33
                            c2.48,0.17,4.42,2.49,5.31,3.49c0.88,1,7.61,2.99,9.38,3.65c1.77,0.66,3.54,2.82,3.54,5.15c0,0.19,0,0.24,0.02,0.44l0.06,0
                            c0,0,4.49-0.73,6.44-0.73c1.95,0,9.57,0.92,9.76,2.38s1.56,5.13,3.32,5.13c1.76,0,4.3-3.12,5.66-5.31
                            c1.37-2.2,2.34-7.33,5.47-7.88c1.61-0.28,2.92-0.37,4.2-0.37"/>
                    </g>
                    <g>
                        <path style={props.district==='nwr'?styles.select:{}} id='nwr' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M374.76,528.21c0.01-0.01,0.02-0.02,0.03-0.03h0.02c0,0,3.58,1.88,7.3,1.06c3.71-0.84,8.49-5.16,10.26-5.32
                            c1.59-0.14,10.31,1.98,12.06,2.41c1.63-2.01,3.17-3.83,3.9-4.58c1.76-1.84,3.51-5.86,3.51-5.86s8.2,0.73,12.1,1.1
                            c3.9,0.36,8.98,3.66,9.37,0c0.38-3.56-1.09-18.2,0.98-19.69c0.29-4.52-1.96-9.49-2.15-12.02c-0.2-2.56,1.17-2.92,1.96-4.21
                            c0.77-1.29,1.56-9.53,1.76-12.28c0.19-2.75-4.1-0.73-7.81-1.1c-3.71-0.36-3.9,0.37-6.83,1.47c-2.93,1.1-6.44,0.18-8.98,0.36
                            c-2.54,0.19-8-5.86-8.79-7.88c-0.78-2.01-4.49-0.73-7.41-0.91c-1.46-0.09-2.52-0.18-3.76-0.18l0,0h-0.04
                            c-1.21,0.01-2.68,0.1-4.2,0.37c-3.12,0.55-4.1,5.68-5.47,7.88c-1.37,2.2-3.91,5.31-5.66,5.31s-3.12-3.67-3.32-5.13
                            s-7.81-2.38-9.76-2.38c-1.95,0-6.44,0.73-6.44,0.73l-0.07,0c0.15,2.3,1.6,5.36,1.93,8.19c0.35,2.99,0.71,4.65,0,6.64
                            c-0.71,1.99,0.35,3.65,3.36,4.15c3.01,0.5,4.78-0.83,4.78-0.83l1.06,10.29l0.53,6.64c0,0-1.54,1.83-3.52,3.58l-0.01,0.23
                            c0,0,0.71,3.32,1.41,4.81c0.71,1.49,3.18,4.32,5.48,4.32c2.3,0,6.19,0,7.07,1.83c0.88,1.83,1.41,6.47-0.88,8.14
                            c-1.38,0.99-2.69,1.75-3.68,2.77"/>
                    </g>
                    <g>
                        <path style={props.district==='krm'?styles.select:{}} id='krm' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M202.38,518.07c-0.09,0.46-0.23,0.87-0.48,1.2c-0.88,1.16-2.48,4.15-1.41,5.65c1.06,1.49,3.36,2.82,3.54,4.32
                            c0.18,1.49,2.83,4.81,5.66,5.81c2.83,1,5.48,1.66,7.96,1.99c2.48,0.33,5.66,0.99,5.48,1.99c-0.18,1-5.66,6.97-1.59,7.64
                            c0.73,0.12,1.4,0.21,2.02,0.28l0.19,0.04c0,0-2.73,6.88-2.73,8.17s1.76,6.78-0.59,7.15c-2.34,0.37-4.1,1.28-6.64,1.65
                            c-2.54,0.37-6.83,0.92-8.39,2.75c-1.56,1.83-5.08,5.68-6.44,6.05c-1.37,0.37-10.35-0.18-11.13,2.56
                            c-0.78,2.75,2.73,3.85,3.32,5.13c0.3,0.65,0.74,2.09,1.21,3.62l-0.11,0.24c-0.8,0.1-8.59,1.09-9.89,1.09
                            c-1.36,0-3.7-1.84-5.66-2.2c-1.96-0.36-8.4,0.19-11.13,1.29c-2.01,0.81-4.04,1.02-4.95,1.08c2.87-7.59-15.52-22.85-17.3-25.07
                            c-1.93-2.42-2.25-13.89-1.6-19.61c0.64-5.74-7.72-13.89-10.93-16.29c-3.22-2.42-5.15-1.81-11.9-2.11
                            c-6.75-0.31-17.68-21.43-19.61-26.86c-1.93-5.43-2.89-7.85,0.64-12.68c3.54-4.83,1.93-7.23,3.54-10.26
                            c1.6-3.01,12.22-0.3,18.96,3.02c6.75,3.32,13.51,5.12,21.86,8.44c5.82,2.31,22.36,3.59,34.48,4.48c0.09,0.99,0.35,3.69,0.5,5.43
                            c0.18,2.15,2.82,3.81,4.24,4.31c1.42,0.5,3.01,1,3.54,3.16c0.53,2.15,4.6,7.64,7.25,6.97c2.66-0.66,4.04,1.33,4.06,3.32
                            c0.02,1.94,0.15,5.26,4.25,5.98h0"/>
                    </g>
                    <g>
                        <path style={props.district==='han'?styles.select:{}} id='han' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M279.78,544.35c2.48-0.13,2.97-0.1,5.09,0.57c2.93,0.92,7.42,2.75,5.08,4.4c-2.34,1.65-6.44,5.13-4.49,6.6
                            c1.95,1.47,3.51,4.58,1.17,4.58c-2.34,0-7.22-1.83-8,0.37c-0.78,2.2-3.12,3.3-3.51,5.86c-0.39,2.56-0.39,7.33-1.17,7.51
                            c-0.78,0.18-5.08-0.37-6.25,2.57c-1.17,2.93-2.34,5.86-4.29,5.86s-16.59-1.47-18.16,1.65c-1.56,3.11-6.05,7.51-8.39,6.05
                            c-2.34-1.47-10.93-6.05-11.32-1.28c-0.39,4.77-1.92,15.73-1.92,15.73l0,0c-3.2-0.05-5.18,0.52-7.06,0.39
                            c-2.73-0.18-3.32-2.56-4.1-5.5c-0.78-2.93-4.1-1.47-6.25-1.65c-2.15-0.18-4.29-1.83-7.03-3.3c-2.73-1.47-4.69-4.58-5.47-6.6
                            c-0.78-2.02-1.95-6.41-2.54-7.7c-0.59-1.28-4.1-2.38-3.32-5.13c0.78-2.75,9.76-2.2,11.13-2.56c1.37-0.37,4.88-4.21,6.44-6.05
                            c1.56-1.83,5.86-2.38,8.39-2.75c2.54-0.37,4.3-1.28,6.64-1.65s0.59-5.86,0.59-7.15s2.71-8.19,2.71-8.19l0,0
                            c2.8,0.32,4.29,0.27,5.59,1.36c1.59,1.33,5.31,3.32,9.37,3.15c4.07-0.17,6.01-1.49,10.08,0.17c4.07,1.66,8.31,2.33,10.26,1.66
                            c1.95-0.67,6.89-3.73,8.31-4.48c1.89-1.01,1.5-0.64,6.18-1.92C278.23,545.65,279.78,544.35,279.78,544.35z"/>
                    </g>
                    <g>
                        <path style={props.district==='okz'?styles.select:{}} id='okz' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M279.29,544.26c-1.01,0.05-2.22,0.12-3.77,0.18c-5.08,0.18-3.32-5.5-1.95-6.05c1.37-0.55,4.69-0.73,7.61-1.83
                            c2.93-1.1-0.39-2.75-1.95-4.4c-1.56-1.65-1.76-2.38-1.17-4.22c0.59-1.83-0.98-2.75-0.98-4.21c0-1.47,6.83,0.18,9.96,1.65
                            c3.12,1.47,4.3,4.95,6.25,7.51c1.95,2.57,10.54,6.96,11.71,7.15c1.17,0.18,3.91-0.98,5.47-1.83c1.87-1.03,9.11-2.82,11.78-2.82
                            s8.77,0.72,12.2,0.72s10.67-0.36,8.77,2.15c-1.91,2.5-3.43,5.37-3.43,7.16c0,1.79,1.14,4.65,3.81,5.37
                            c2.67,0.72,7.63,3.22,10.67,0.36c3.05-2.86,5.72-4.65,9.91-5.01c4.19-0.36,6.86-0.36,9.15-2.15c2.29-1.79,5.77-4.16,0.52-9.3
                            l-0.25,0.16c-0.52,1.49-7.25-3.39-8.71-4.76c-1.55-1.46-2.13,1.64-2.71,3.64c-0.58,2-3.88,1.27-5.04,0.36
                            c-1.16-0.91-0.58-3.09,0-6.73c0.58-3.64-8.73,1.27-11.25,2.73c-2.52,1.46-3.68,0.73-5.62-0.73c-1.94-1.46-3.1-4.19-3.91-4.85
                            c-1.33-1.11-6.37-2.82-6.37-2.82s-4.25,0-6.72,0c-2.48,0-7.25-0.33-10.44-1c-3.18-0.67-5.13-0.83-7.08-0.83
                            c-1.95,0-5.66-3.82-8.14-5.81c-2.48-1.99-4.78-3.82-6.54-4.32c-1.77-0.5-5.31,0.33-8.84,1.16c-3.54,0.83-6.19-1.83-8.84-3.15
                            c-2.65-1.33-10.08-0.17-15.39,0c-5.31,0.17-2.65,5.81-2.65,7.14c0,1.33-5.48,1.99-7.61,2.16c-2.12,0.17-5.31-0.17-8.49-0.33
                            c-3.18-0.17-11.85,2.49-14.15,3.49c-2.3,1-6.54,2.16-8.84,2.16c-2.3,0-6.72-5.15-6.72-5.15c-0.71-1.49-5.13-4.32-6.19-2.82
                            c-1.06,1.49-0.53,3.65-1.42,4.81c-0.88,1.16-2.48,4.15-1.41,5.65c1.06,1.49,3.36,2.82,3.54,4.32c0.18,1.49,2.83,4.81,5.66,5.81
                            c2.83,1,5.48,1.66,7.96,1.99c2.48,0.33,5.66,0.99,5.48,1.99c-0.18,1-5.66,6.97-1.59,7.64c4.07,0.67,6.19,0.33,7.78,1.66
                            c1.59,1.33,5.31,3.32,9.37,3.15c4.07-0.17,6.01-1.49,10.08,0.17c4.07,1.66,8.31,2.33,10.26,1.66c1.95-0.67,6.89-3.73,8.31-4.48
                            c1.89-1.01,1.5-0.64,6.18-1.92c4.69-1.28,6.25-2.56,6.25-2.56L279.29,544.26z"/>
                    </g>
                    <g>
                        <path style={props.district==='kht'?styles.select:{}} id='kht' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M373.48,534.46c5.25,5.14,2,7.74-0.29,9.53c-2.29,1.79-4.96,1.79-9.15,2.15c-4.19,0.36-6.86,2.15-9.91,5.01
                            c-3.05,2.86-8.01,0.36-10.67-0.36c-2.67-0.72-3.81-3.58-3.81-5.37c0-1.79,1.52-4.65,3.43-7.16c1.91-2.5-5.34-2.15-8.77-2.15
                            s-9.53-0.72-12.2-0.72c-2.67,0-9.91,1.79-11.78,2.82c-1.55,0.85-4.29,2.02-5.47,1.83c-1.17-0.18-9.76-4.58-11.71-7.15
                            c-1.95-2.57-3.12-6.05-6.25-7.51c-3.12-1.47-9.96-3.12-9.96-1.65c0,1.47,1.56,2.38,0.98,4.21c-0.59,1.83-0.39,2.57,1.17,4.22
                            c1.56,1.65,4.88,3.3,1.95,4.4c-2.93,1.1-6.25,1.28-7.61,1.83c-1.37,0.55-3.12,6.23,1.95,6.05c5.08-0.18,6.44-0.55,9.37,0.37
                            c2.93,0.92,7.42,2.75,5.08,4.4c-2.34,1.65-6.44,5.13-4.49,6.6c1.95,1.47,3.51,4.58,1.17,4.58c-2.34,0-7.22-1.83-8,0.37
                            c-0.78,2.2-3.12,3.3-3.51,5.86c-0.39,2.56-0.39,7.33-1.17,7.51c-0.78,0.18-5.08-0.37-6.25,2.57c-0.26,0.64-0.52,1.29-0.78,1.9
                            l-0.07,0.15c0,0,7.1,2.34,10.61,2.34c3.51,0,9.57,0.37,12.69,0.37c3.12,0,13.08,0.92,13.08,3.11c0,2.2-1.37,4.4,1.95,4.77
                            c3.32,0.37,6.83,0,7.81,1.65c0.98,1.65,2.54,4.21,4.69,3.11c2.15-1.1,2.93-3.67,3.51-1.47c0.59,2.2,1.17,9.86,1.17,9.86l0,0
                            c2.23,0.85,4.66,2.61,6.05,3.7c2.34,1.84,4.3-0.55,5.86-2.56c1.56-2.02,6.83,2.56,8.2,4.4c1.37,1.83,1.37,5.5,1.56,8.25
                            c0.2,2.75,3.51,7.51,6.64,9.89c3.13,2.37,9.76,4.21,9.76,4.21s-0.39-2.2-0.39-7.51c0-5.32,3.9-6.05,5.86-10.99
                            c1.96-4.95-2.54-7.33-4.69-12.09c-2.14-4.77-1.76-6.05-0.59-7.7c1.17-1.65,1.17-3.85,1.37-6.97c0.19-3.11,5.27-5.12,8.39-6.22
                            c3.13-1.1,7.81-3.12,10.16-3.67c2.34-0.55,1.56-3.3,1.96-6.6c0.39-3.3,6.44-5.31,7.41-6.41s5.47-4.58,5.47-7.15
                            s-0.2-5.5,1.94-7.51c2.16-2.02,1.37-11.54,1.96-13.57c0.34-1.19,2.78-4.45,5.08-7.32c-1.76-0.43-10.47-2.55-12.06-2.41
                            c-1.77,0.16-6.55,4.48-10.26,5.32c-3.71,0.82-7.3-1.06-7.3-1.06h-0.02c-0.66,0.68-1.11,1.39-1.39,2.45
                            C373.19,530.99,373.09,531.91,373.48,534.46C373.5,534.54,373.48,534.4,373.48,534.46"/>
                    </g>
                    <g>
                        <path style={props.district==='krk'?styles.select:{}} id='krk' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M267.01,578.75c0,0,7.1,2.34,10.62,2.34c3.51,0,9.57,0.37,12.69,0.37c3.12,0,13.08,0.92,13.08,3.11
                            c0,2.2-1.37,4.4,1.95,4.77c3.32,0.37,6.83,0,7.81,1.65c0.98,1.65,2.54,4.21,4.69,3.11c2.15-1.1,2.93-3.67,3.51-1.47
                            c0.59,2.2,1.17,9.83,1.17,9.83l-0.19-0.05c-0.02-0.01-0.04-0.02-0.06-0.02c-1.46-0.54-2.76-0.66-3.45,0.14
                            c-1.76,2.01-4.5,2.01-2.15,5.31c2.34,3.3,5.67,8.61,4.69,10.45c-0.97,1.84-3.31,6.96-0.97,7.51c2.34,0.55,5.66,3.3,4.88,5.68
                            c-0.78,2.39-3.33,5.69-7.61,6.23c-4.3,0.55-9.77,2.75-11.13,2.01c-1.37-0.74-2.16-3.49-5.08-3.11c-2.93,0.36-8.59,2.56-12.1,4.21
                            c-3.51,1.65-10.34,5.86-10.93,8.25c-0.59,2.39-0.59,11.91-0.59,14.84c0,2.47-2.48,3.06-3.03,5.29l-0.21,0.01
                            c0,0-11.44-0.57-15.8-1.13c-4.37-0.56-6.02-5.37-6.63-8.89c-0.6-3.54,0.46-4.39,0.46-6.93c0-2.54-4.37-9.74-5.72-11.58
                            c-1.36-1.84-1.21-3.4-1.21-4.1c0-0.7-5.72-1.69-9.63-1.84c-3.91-0.14-7.82-10.41-7.82-10.41c2.34-2.01,3.13-3.66,4.69-5.31
                            c1.57-1.65,4.69-4.77,6.84-6.6c2.14-1.84-4.3-5.87-10.74-7.15c-2.07-0.41-3.79-0.56-5.27-0.58c-0.03,0,0.03,0,0,0l0,0
                            c0,0,1.56-10.97,1.95-15.74c0.39-4.76,8.98-0.18,11.32,1.28c2.34,1.47,6.83-2.93,8.39-6.05c1.56-3.11,16.2-1.65,18.16-1.65
                            C265.09,582.56,266.08,580.92,267.01,578.75C267.03,578.72,267.01,578.78,267.01,578.75"/>
                    </g>
                    <g>
                        <path style={props.district==='nrw'?styles.select:{}} id='nrw' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M192.33,584.21c0.45,1.46,0.89,2.91,1.27,3.88c0.78,2.01,2.73,5.13,5.47,6.6c2.73,1.47,4.88,3.11,7.03,3.3
                            c2.15,0.18,5.47-1.28,6.25,1.65c0.78,2.93,1.37,5.31,4.1,5.5c2.73,0.18,5.86-1.1,12.3,0.18c6.44,1.28,12.88,5.31,10.74,7.15
                            c-2.15,1.83-5.27,4.95-6.83,6.6c-1.56,1.65-2.34,3.3-4.69,5.31c-2.34,2.02-9.96,5.31-13.08,6.78c-3.12,1.47-9.96,1.83-13.27,1.65
                            c-3.32-0.18-11.52-1.83-11.52-1.83s-2.93,6.23-5.47,8.06c-2.54,1.83,0.39,5.68-2.73,8.8c-3.12,3.12-3.9,7.51-6.64,10.45
                            c-2.73,2.93-4.3,7.15-5.66,9.71c-1.37,2.56-0.98,3.11-0.78,4.95c0.06,0.57,0.14,1.33,0.25,2.2l-0.09,0.08c0,0-0.03,0-0.08,0
                            l-0.08,0.01c-0.83,0.05-4.57,0.32-6.3,0.95c-1.96,0.7-5.88-1.42-6.34-1.42l-0.2,0.07c-0.41,0.26-0.8,0.58-1.14,0.94
                            c-1.36,1.41-0.9,3.95-4.67,4.94c-3.76,0.99-4.51,2.68-7.52,1.13c-3.01-1.55-8.13-1.41-9.48,0.43c-1.36,1.84-4.52,3.1-5.72,4.23
                            c-1.21,1.13-5.87,3.24-8.43,2.25c-2.55-0.98-6.31-3.67-7.67-2.68c-1.35,0.99-4.66,3.4-6.92,2.68c-2.25-0.7-6.02-0.13-6.31,0.99
                            c-0.3,1.13-3.62,3.54-4.81,2.41c-1.21-1.13-4.22-6.78-6.63-6.36c-2.4,0.42-4.66,2.4-6.62,0.42c-1.96-1.98-1.8-3.39-5.87-2.26
                            c-4.06,1.12-7.22,3.67-8.57,4.94c-1.36,1.28-3.31,2.27-5.12,3.4c-1.8,1.12-1.96,2.4-1.5,4.52c0.45,2.11,0.6,2.25,0,4.09
                            c-0.61,1.84-2.86,3.67-4.81,4.38c-1.96,0.71-10.39,3.25-12.04,1.42c-1.65-1.84-2.41-5.22-4.51-5.37
                            c-1.63-0.11-3.61-0.13-4.44-0.14c1.11-1.67,1.98-3.24,2.37-4.34c1.29-3.63-3.54-14.49-6.43-18.11c-2.89-3.62,1.6-7.85,7.07-10.26
                            c5.46-2.42,5.79-5.43,7.39-9.65c1.6-4.23-0.96-16.9-0.96-24.14c0-7.25,17.68-10.57,26.68-9.36c9,1.21,17.03-4.52,23.46-3.62
                            c6.43,0.9,14.15-2.42,20.58-2.72c6.42-0.31,13.49-10.57,17.36-14.18c3.85-3.62,21.21-10.86,28.6-17.5c0.59-0.53,1-1.12,1.23-1.78
                            c0.91-0.06,2.94-0.26,4.95-1.08c2.73-1.1,9.17-1.65,11.13-1.29c1.96,0.36,4.3,2.2,5.66,2.2c1.26,0,8.56-0.92,9.78-1.08
                            L192.33,584.21z"/>
                    </g>
                    <g>
                        <path style={props.district==='ban'?styles.select:{}} id='ban' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M170.73,682.27c0.65-0.49,0.8-0.58,1.01-0.69c1.05-0.57,7.22-1.13,9.03-1.41c1.81-0.28,4.21-2.82,5.12-3.81
                            c0.9-0.99,3.61-1.55,4.82-3.95c1.2-2.4,4.82-6.21,6.47-7.49c1.66-1.27,5.87,1.84,7.37,3.53c1.5,1.69,6.32-3.53,7.67-4.66
                            c1.35-1.13,5.27,2.97,8.73,4.52c3.46,1.55,6.17-6.21,7.37-7.49c1.2-1.27,1.96-0.42,4.97,0.85c3.01,1.27,3.91-0.99,5.87-1.55
                            c1.96-0.57,1.66,0.42,2.86,1.55c1.2,1.13,1.96,0.42,3.91-0.28c1.96-0.7,6.17-1.98,6.17-1.98l0.02,0.17
                            c-0.01-0.06-0.02-0.11-0.03-0.17c-0.6-3.54,0.46-4.39,0.46-6.93s-4.37-9.74-5.72-11.58c-1.36-1.84-1.21-3.4-1.21-4.1
                            c0-0.7-5.72-1.69-9.63-1.84c-3.6-0.13-7.26-8.81-7.8-10.19l-0.07-0.08c-2.45,2.02-9.95,5.19-13.03,6.64
                            c-3.12,1.47-9.96,1.83-13.27,1.65c-3.32-0.18-11.52-1.83-11.52-1.83s-2.93,6.23-5.47,8.06c-2.54,1.83,0.39,5.68-2.73,8.8
                            c-3.12,3.12-3.9,7.51-6.64,10.45c-2.73,2.93-4.3,7.15-5.66,9.71c-1.37,2.56-0.98,3.11-0.78,4.95c0.2,1.83,0.59,5.5,1.56,8.8
                            c0.02,0.08,0.05,0.16,0.08,0.24L170.73,682.27z"/>
                    </g>
                    <g>
                        <path style={props.district==='lak'?styles.select:{}} id='lak' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M170.43,682.33c0.65-0.49,1.13-0.83,1.34-0.94c1.05-0.57,7.22-1.13,9.03-1.41c1.81-0.28,4.21-2.82,5.12-3.81
                            c0.9-0.99,3.61-1.55,4.82-3.95c1.2-2.4,4.82-6.21,6.47-7.49c1.66-1.27,5.87,1.84,7.37,3.53c1.5,1.69,6.32-3.53,7.67-4.66
                            c1.35-1.13,5.27,2.97,8.73,4.52c3.46,1.55,6.17-6.21,7.37-7.49c1.2-1.27,1.96-0.42,4.97,0.85c3.01,1.27,3.91-0.99,5.87-1.55
                            c1.96-0.57,1.66,0.42,2.86,1.55c1.2,1.13,1.96,0.42,3.91-0.28c1.96-0.7,6.17-1.98,6.17-1.98l-0.04-0.19
                            c0.01,0.06,0.02,0.13,0.03,0.19c0.61,3.53,2.26,8.33,6.63,8.89c4.36,0.56,15.8,1.13,15.8,1.13l0.2,0.04
                            c-0.11,0.42-0.14,0.89-0.08,1.44c0.39,3.47,3.71,8.8,3.13,12.46c-0.59,3.66,2.93,9.16,2.93,12.09c0,2.94,1.36,8.98,2.73,9.53
                            c1.37,0.55,9.96,3.3,8.79,5.86c-0.98,2.17-2.39,5.23-2.81,6.15l-0.16-0.08c-0.3,0.39-2.76,3.6-4.03,5.18
                            c-1.36,1.69-2.41,4.24-3.62,3.4c-1.19-0.85-2.86-1.84-3.76-1.28c-0.9,0.56-4.66,7.34-12.18,10.03
                            c-7.53,2.68-17.16,7.06-20.17,7.63c-3.01,0.56-10.38,5.08-12.64,5.78c-2.26,0.71-3.76,0.71-6.31,1.56
                            c-2.57,0.85-6.63,3.82-8.43,4.23c-1.8,0.43-8.42,0.28-9.32,0.57c-0.75-3.11,4.66-10.74,6.02-11.87c1.35-1.12,1.05-1.26,1.19-3.24
                            c0.15-1.98-5.11-3.81-7.52-5.22c-2.4-1.42-6.77-4.1-7.23-4.95c-0.44-0.85,0.46-3.39,0.46-6.35c0-2.97-4.51-9.18-6.17-11.3
                            c-1.65-2.11-2.11-3.67-2.55-4.94c-0.46-1.28-8.9-5.74-8.9-5.74c-0.84-1.03-3.6-4.34-4.8-5.57c-1.21-1.24-8.47-5.17-8.69-7.96
                            c-0.17-2.09-0.07-3.84-0.01-4.58"/>
                    </g>
                    <g>
                        <path style={props.district==='swn'?styles.select:{}} id='swn' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M174.88,914.38c0,0-2.26-0.14-3.76-1.27c-1.5-1.13-3.91-12.85-4.51-16.1c-0.6-3.25-2.11-11.02-3.76-15.96
                            c-1.66-4.94-0.15-4.38,0.45-6.78c0.6-2.4-4.82-12.57-6.92-16.67c-2.11-4.1-2.56-7.49-2.56-10.59s-4.21-2.97-6.32-6.35
                            c-2.11-3.39-2.26-4.38-2.56-9.32c-0.3-4.94,0.45-6.64,0.75-10.03c0.3-3.39-3.01-16.53-4.06-19.49c-1.05-2.97-3.46-5.23-7.22-8.19
                            c-3.76-2.97-4.21-11.86-4.06-13.56c0.15-1.7-1.35-4.1-3.91-6.36c-0.32-0.28-0.55-0.65-0.71-1.09c0,0-3.93-7.2,0.03-8.75
                            c3.96-1.55,5.61-2.27,7.15-4.13c1.54-1.86,7.37-12.5,11.33-12.7c3.96-0.21,10.78-0.31,12.32-1.45c1.54-1.14,4.95-7.13,7.92-9.3
                            c2.97-2.17,7.81-4.65,9.02-7.85c1.21-3.2,5.17-12.4,7.7-14.15c2.53-1.76,7.59-1.03,10.67,1.86c3.08,2.89,3.85,2.69,8.69,2.48
                            l0.07,0.05c-1.43-2.98-3.99-6.48-5.16-7.97c-1.65-2.11-2.11-3.67-2.55-4.94c-0.46-1.28-8.9-5.74-8.9-5.74
                            c-0.84-1.03-3.6-4.34-4.8-5.57c-1.21-1.24-8.47-5.17-8.69-7.96c-0.22-2.78,0.02-4.97,0.02-4.97c-0.69-2.32-1.03-4.61-1.28-6.52
                            h-0.13c0,0-4.51,0.25-6.47,0.96c-1.96,0.7-5.88-1.42-6.34-1.42l-0.2,0.07c-0.41,0.26-0.8,0.58-1.14,0.94
                            c-1.36,1.41-0.9,3.95-4.67,4.94c-3.76,0.99-4.51,2.68-7.52,1.13c-3.01-1.55-8.13-1.41-9.48,0.43c-1.36,1.84-4.52,3.1-5.72,4.23
                            c-1.21,1.13-5.87,3.24-8.43,2.25c-2.55-0.98-6.31-3.67-7.67-2.68c-1.35,0.99-4.66,3.4-6.92,2.68c-2.25-0.7-6.02-0.13-6.31,0.99
                            c-0.3,1.13-3.62,3.54-4.81,2.41c-1.21-1.13-4.22-6.78-6.63-6.36c-2.4,0.42-4.66,2.4-6.62,0.42c-1.96-1.98-1.8-3.39-5.87-2.26
                            c-4.06,1.12-7.22,3.67-8.57,4.94c-1.36,1.28-3.31,2.27-5.12,3.4c-1.8,1.12-1.96,2.4-1.5,4.52c0.45,2.11,0.6,2.25,0,4.09
                            c-0.61,1.84-2.86,3.67-4.81,4.38c-1.96,0.71-10.39,3.25-12.04,1.42c-1.65-1.84-2.41-5.22-4.51-5.37
                            c-1.63-0.11-3.61-0.13-4.44-0.14c-2.53,3.84-6.33,8.21-8.56,8.63c-3.22,0.6-16.71,11.47-16.71,15.69s4.81,13.58,3.85,19.01
                            c-0.96,5.43,0.96,27.46,2.25,35.91c1.29,8.44,1.6,11.76,2.58,16.9c0.57,3.07,2.19,4.3,2.08,6.55l0.21,0.28
                            c0,0,4.69-1.65,6.84-2.01c2.14-0.37,9.17,0.55,11.13,0c1.94-0.55,9.37-1.84,9.37-1.84l4.49,2.94c0,0,10.16-6.23,11.13-8.25
                            c0.97-2.02,3.51-9.17,8-9.9c4.5-0.74,23.24-5.13,23.24-5.13s-2.34,7.7-3.13,8.98c-0.78,1.28-1.56,4.4-0.78,4.95
                            c0.78,0.55,8.4,4.02,7.61,6.41c-0.77,2.39-3.31,7.88-3.51,9.35c-0.19,1.46,4.69,5.86,5.67,4.4c0.97-1.46,9.17-10.26,9.37-7.15
                            c0.19,3.11,1.56,8.61,0.59,11c-0.98,2.39-3.33,4.76-2.93,7.51c0.39,2.75,2.93,10.82,1.76,18.33c-1.17,7.51-0.59,19.79-0.59,23.83
                            c0,4.02,2.14,4.95,4.29,5.12c2.16,0.19,6.25-0.91,6.64,4.03c0.39,4.95,3.71,14.84,4.1,15.58c0.39,0.73,3.13,1.1,3.51-2.2
                            c0.39-3.3,2.34-7.88,3.13-6.97c0.77,0.92,2.34,3.12,4.87,2.2c2.54-0.91,7.61-5.31,9.96-6.77c2.34-1.47,8.4-4.4,8.4-4.4
                            s2.34,7.15,1.96,12.27c-0.4,5.13-1.76,14.29-1.57,18.33c0.2,4.03,0,6.78-0.39,9.16c-0.39,2.39-0.59,6.23-0.2,8.8
                            c0.27,1.71,2.44,3.43,3.79,5.35l0.12-0.03l15.23-5.32c0,0,1.9-4.31,3.74-8.15"/>
                    </g>
                    <g>
                        <path style={props.district==='dik'?styles.select:{}} id='dik' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M174.9,914.3c0,0-2.26-0.14-3.76-1.27c-1.5-1.13-3.91-12.85-4.51-16.1c-0.6-3.25-2.11-11.02-3.76-15.96
                            c-1.66-4.94-0.15-4.38,0.45-6.78c0.6-2.4-4.82-12.57-6.92-16.67c-2.11-4.1-2.56-7.49-2.56-10.59c0-3.11-4.21-2.97-6.32-6.35
                            c-2.11-3.39-2.26-4.38-2.56-9.32c-0.3-4.94,0.45-6.64,0.75-10.03c0.3-3.39-3.01-16.53-4.06-19.49c-0.61-1.71-1.66-3.18-3.16-4.68
                            l0-0.12c9.48-2.54,13.69-14.41,13.69-14.41s12.19,0.28,16.25,0.42c4.06,0.14,8.28-0.14,10.38-0.85c2.11-0.71,4.97-0.99,8.58-1.13
                            c3.61-0.14,6.62-3.96,7.98-5.79c1.35-1.84,2.11-8.9,2.71-11.16c0.6-2.26,9.78-10.17,10.68-10.45c0.9-0.28,7.52-0.14,9.33-0.56
                            c1.81-0.42,5.87-3.39,8.43-4.24c2.56-0.85,4.06-0.85,6.32-1.55c2.26-0.71,9.63-5.23,12.64-5.79c3.01-0.56,12.64-4.94,20.16-7.63
                            c7.52-2.68,11.29-9.46,12.19-10.03c0.9-0.56,2.56,0.42,3.76,1.27c1.2,0.85,2.26-1.7,3.61-3.39c1.35-1.7,3.85-4.96,3.85-4.96
                            l0.4,0.15c0.79,0.17,4.1,0.86,5.3,0.7c1.36-0.19,6.44-6.23,8.39-5.68c1.96,0.55,5.47,4.77,4.5,6.6
                            c-0.98,1.84-3.33,8.43-3.33,10.45c0,2.02,1.57,8.43-0.59,8.98c-2.14,0.55-5.07,0.36-6.63,1.65c-1.57,1.29-8.2,6.6-9.37,9.53
                            c-1.17,2.92-6.44,6.22-6.44,8.97c0,2.75,1.17,9.53-1.76,10.63c-2.93,1.1-6.25,3.3-6.84,8.25c-0.59,4.95-2.14,11.18-3.51,12.28
                            c-1.36,1.1-2.93,3.11-2.73,4.57c0.2,1.47-0.59,20.34-0.59,20.34s-3.32,2.02-3.71,4.04c-0.39,2.01-7.03,12.64-8.97,15.03
                            c-1.96,2.37-8.4,10.81-9.37,12.64c-0.98,1.83-2.74,2.56-2.74,6.6c0,4.02,0.4,6.05-0.39,9.34c-0.78,3.3-0.2,5.5,0.59,7.51
                            c0.78,2.01-0.78,5.14-0.59,7.15c0.2,2.01,1.96,7.33-0.97,8.43s-4.5,0.91-5.47,3.3c-0.97,2.37-0.97,4.4-3.71,5.86
                            c-2.73,1.46-4.1,2.75-4.49,5.13c-0.39,2.38-0.39,7.15-3.13,5.67c-2.73-1.46-5.86-5.31-8.2-4.21c-2.34,1.1-4.29,2.01-6.24,2.01
                            c-1.96,0-14.84-3.3-14.84-3.3s-4.49,6.6-6.63,6.78c-2.16,0.19-7.61,0.19-9.37,2.94C176.96,910.06,175.97,912.06,174.9,914.3
                            C174.75,914.6,175.04,913.99,174.9,914.3"/>
                    </g>
                    <g>
                        <path style={props.district==='tan'?styles.select:{}} id='tan' onMouseEnter={props.handleProvinceMouseEnter} onMouseLeave={props.handleProvinceMouseLeave} onClick={props.handleDistrictClick} className="st0" d="M208.6,753.72C208.6,753.71,208.63,753.7,208.6,753.72c-0.9,0.28-10.08,8.19-10.69,10.45
                            c-0.6,2.26-1.35,9.32-2.71,11.16c-1.35,1.84-4.36,5.65-7.98,5.79c-3.61,0.14-6.47,0.42-8.58,1.13c-2.11,0.71-6.32,0.99-10.38,0.85
                            c-4.06-0.14-16.25-0.42-16.25-0.42s-4.21,11.86-13.69,14.41l-0.12,0.05c-1.13-1.15-2.29-2.13-3.94-3.44
                            c-3.76-2.97-4.21-11.86-4.06-13.56c0.15-1.7-1.35-4.1-3.91-6.36c-0.32-0.28-0.55-0.65-0.71-1.09c0,0-3.93-7.2,0.03-8.75
                            c3.96-1.55,5.61-2.27,7.15-4.13c1.54-1.86,7.37-12.5,11.33-12.7c3.96-0.21,10.78-0.31,12.32-1.45c1.54-1.14,4.95-7.13,7.92-9.3
                            c2.97-2.17,7.81-4.65,9.02-7.85c1.21-3.2,5.17-12.4,7.7-14.15c2.53-1.76,7.59-1.03,10.67,1.86c3.08,2.89,3.85,2.69,8.69,2.48
                            l0.04-0.01c0.65,1.33,1.04,2.47,1.04,3.39c0,2.97-0.9,5.51-0.46,6.35c0.46,0.85,4.83,3.53,7.23,4.95
                            c2.41,1.41,7.67,3.24,7.52,5.22c-0.14,1.98,0.15,2.12-1.19,3.24c-1.33,1.11-6.54,8.44-6.06,11.67
                            C208.57,753.58,208.58,753.65,208.6,753.72L208.6,753.72"/>
                    </g>
                </g>
                {/* <text transform="matrix(1 0 0 1 434 78.0425)" className="st1 st2">Upper Chitral</text>
                <text transform="matrix(1 0 0 1 345.646 175.8964)" className="st1 st2">Lower Chitral</text>
                <text transform="matrix(1 0 0 1 541.646 268.8964)" className="st1 st2">Upper Kohistan</text>
                <text transform="matrix(1 0 0 1 462.646 289.8964)" className="st1 st2">Swat</text>
                <text transform="matrix(1 0 0 1 380.646 286.8964)" className="st1 st2">Upper Dir</text>
                <text transform="matrix(1 0 0 1 363.646 338.8964)"><tspan x="0" y="0" className="st1 st2">Lower </tspan><tspan x="0" y="10.8" className="st1 st2">Dir</tspan></text>
                <text transform="matrix(1 0 0 1 469.8535 409.3091)" className="st1 st2">Buner</text>
                <text transform="matrix(1 0 0 1 319.8535 362.3091)" className="st1 st2">Bajaur</text>
                <text transform="matrix(1 0 0 1 358.8535 394.3091)" className="st1 st2">Malakand</text>
                <text transform="matrix(1 0 0 1 489.8535 351.3091)" className="st1 st2">Shangla</text>
                <text transform="matrix(1 0 0 1 516.8535 397.3091)" className="st1 st2">Tor</text>
                <text transform="matrix(1 0 0 1 548.1914 348.4043)" className="st1 st2">Battagram</text>
                <text transform="matrix(1 0 0 1 595.1914 372.3618)" className="st1 st2">Mansehra</text>
                <text transform="matrix(1 0 0 1 509.5957 404.4253)" className="st1 st2">Ghar</text>
                <text transform="matrix(1 0 0 1 561.043 469.3193)" className="st1 st2">Abbottabad</text>
                <text transform="matrix(1 0 0 1 512.5957 480.915)" className="st1 st2">Haripur</text>
                <text transform="matrix(1 0 0 1 401.2979 435.5532)" className="st1 st2">Mardan</text>
                <text transform="matrix(1 0 0 1 453.2764 469.2129)" className="st1 st2">Swabi</text>
                <text transform="matrix(1 0 0 1 288.085 410.085)" className="st1 st2">Mohmand</text>
                <text transform="matrix(1 0 0 1 339.085 446.7021)" className="st1 st2">Charsada</text>
                <text transform="matrix(1 0 0 1 262.5317 488.6172)" className="st1 st2">Khyber</text>
                <text transform="matrix(1 0 0 1 325.5107 495.2129)" className="st1 st2">Peshawar</text>
                <text transform="matrix(1 0 0 1 380.4893 494.9785)" className="st1 st2">Nowshera</text>
                <text transform="matrix(1 0 0 1 150.5747 528.957)" className="st1 st2">Kurram</text>
                <text transform="matrix(1 0 0 1 236.1489 529.4253)" className="st1 st2">Orakzai</text>
                <text transform="matrix(1 0 0 1 319.8296 567.6807)" className="st1 st2">Kohat</text>
                <text transform="matrix(1 0 0 1 266.5317 615.4253)" className="st1 st2">Karak</text>
                <text transform="matrix(1 0 0 1 223.6382 572.6382)" className="st1 st2">Hangu</text>
                <text transform="matrix(1 0 0 1 93.4043 645.3193)" className="st1 st2">North Waziristan</text>
                <text transform="matrix(1 0 0 1 198.9575 651.5527)" className="st1 st2">Bannu</text>
                <text transform="matrix(1 0 0 1 211.1274 701.2344)" className="st1 st2">Lakki Marwat</text>
                <text transform="matrix(1 0 0 1 52.3618 750.8086)" className="st1 st2">South Warizistan</text>
                <text transform="matrix(1 0 0 1 159.7021 763.4258)" className="st1 st2">Tank</text>
                <text transform="matrix(1 0 0 1 175.9785 820.7871)" className="st1 st2">Dera Ismail Khan</text> */}
                </svg>
        </>
    )
}

export default KpMap;